import React, { Component } from "react";
import styled from "styled-components";

class SideMenuDiv extends Component {

  onClickNav = (e) => {
    e.stopPropagation();
    e.preventDefault();

    window.location.href = e.currentTarget.dataset.target;
  }

  render() {
    const { isSelected, link, title } = this.props;
    return (
      <>
        <NavDiv>
          <div className={isSelected?"active":""} onClick={this.onClickNav.bind(this)} data-target={link ? link : ''}>
            <p>{title}</p>
          </div>
        </NavDiv>
      </>
    );
  }

}
const NavDiv = styled.div`
  div{
    color: #111111;
    cursor: pointer;
    p{
      font-size: 15px;
      font-weight: 400;
      line-height: 1.5;
      margin: 6px 0px;
    }

    &:hover{
      p{
        color: #dd3a6c;
      }
    }
    
    &.active{
      p{
        color: #dd3a6c;
      }
      span{
        display:block;
      }
    }
  }
`;



export default SideMenuDiv;
