import React, { Component } from "react";
import styled from "styled-components";
import SideMenuDiv from "./SideMenuDiv";
import {FOOTER_MENU} from "../../../../global/string";
import FooterMenu from "../footer/FooterMenu";

class SideMenu extends Component {
    i18n = FOOTER_MENU[this.props.language]

    closeSideMenu = (e) =>{
        let divs = document.getElementsByClassName(Cover.styledComponentId);
        divs[0].addEventListener("transitionend", this.transitionEnd);

        divs[0].classList.add('close');
    }

    transitionEnd = (e) => {
        let container = document.getElementsByClassName(Container.styledComponentId);
        container[0].classList.remove('show');

        let cover = document.getElementsByClassName(Cover.styledComponentId);
        cover[0].removeEventListener("transitionend", this.transitionEnd);
        cover[0].classList.remove('close');

        this.props.closeCallback()
    }
    render() {
    const { language, header, data, isShow, closeCallback} = this.props;
    return (
      <>
        <Container className={ isShow ? "show": ""}>
            <Cover>
                <Wrapper>
                    <CloseBtn onClick={this.closeSideMenu}>
                        <img src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/side_close_icon.png" />
                    </CloseBtn>
                    <Logo className="sidebar-logo">
                        <img src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/real/hanteonews_logo.png" />
                    </Logo>
                    <Line></Line>
                    <Menus>
                        {data? data.map((_header, i)=>{

                            return <SideMenuDiv
                                key={i}
                                isSelected={header?_header.title.toLowerCase()===header.toLowerCase():false}
                                link={`/${language.toLowerCase()}/article/${_header.title.toLowerCase()}`}
                                title={_header.title}/>
                        }): <></>}
                    </Menus>

                    <BottomMenu>
                        <Line className="no-left"></Line>
                        {this.i18n.map((el,i)=>{
                            return <FooterMenu type={"side"} link={el.link} title={el.title} />
                        })}
                    </BottomMenu>
                </Wrapper>
            </Cover>
        </Container>
      </>
    );
  }

}
const BottomMenu = styled.div``;
const Menus = styled.div``;
const Line = styled.div``;
const Logo = styled.div``;
const CloseBtn = styled.div``;
const Wrapper = styled.div``;
const Cover = styled.div``;
const Container = styled.div`
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background: rgba(32,32,32, .6);
    z-index: 20;
    visibility: hidden;
    
    &.show {
        visibility: visible;
    }

    @media (min-width: 1048px) {
        .mobile-show {
            visibility: hidden;
        }
    }
    
    ${Cover}{
        position: relative;
        width: 90%;
        flex-basis: unset;
        font-weight: 400;
        height: 100vh;
        background: rgb(255, 255, 255);
        
        &.close{
            transition: .5s ease;
            transform: translateX(-100%);
        }
        
        ${Wrapper}{
            background: #FFFFFF;
            width: 100%;
            height: 100vh;
            overflow: auto;
            position: relative;
            
            ${CloseBtn}{
                position: absolute;
                top: 25px;
                right: 18px;
                width: 18px;
                height: 18px;
            }
            
            ${Logo}{
                margin: 25px 0 25px 30px;
                height: 21px;
                img{
                    width: auto;
                }
            }
            
            ${Line}{
                margin: 7px 0 14px 0;
                border-top: 1px solid #7d7d7d;
                margin-left: 30px;
                
                &.no-left{
                    margin-left: 0px;
                }
            }
            
            ${Menus}{
                margin: 15px 0px 0px 30px;
                font-size: 15px;
            }
            
            ${BottomMenu}{
                position: absolute;
                bottom: 0%;
                width: 100%;
                padding-left: 30px;
                padding-bottom: 30px;
                a{
                    display: inline-flex;
                    width: 100%;
                    justify-content: space-between;
                    font-size: 11px;
                    
                    p{
                        display: inline-flex;
                        width: 100%;
                        -webkit-box-pack: justify;
                        justify-content: space-between;
                        font-size: 11px;
                        margin-top: 7px;
                        line-height: 1.5;
                    }
                }
            }
        }
    }
`;


export default SideMenu;
