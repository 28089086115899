import React, { Component } from "react";
import styled from "styled-components";

class Logo extends Component {

  render() {
    const { link } = this.props
    return (
        <Container>
          <a href={link}>
            <img src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/bot_news_logo.png" />
          </a>
        </Container>
    );
  }

}

const Container = styled.div`
  @media (min-width: 1048px){
    margin: 0 0 0 20%;
  }

  a {
    color: inherit;
    text-decoration: none;
    
    img{
      width: auto;
      height: 46px;
      margin: auto;
      
      @media (min-width: 1048px){
        height: 78px;
        margin: 0px 0 0 0px;
      }
    }
    
  }
`;



export default Logo;
