import React, { Component } from "react";
import styled from "styled-components";

class MenuBtnGroup extends Component {

  onBtnClick(id, callback) {
    var objs = document.getElementsByClassName(MenuLi.styledComponentId);

    for (var i = 0; i < objs.length; i++) { // For each element
      var obj = objs[i];
      obj.className = obj.className.replace(/\active\b/g, "");
      if (obj.dataset.target === String(id)) {
        obj.classList.add('active');
      }
    }

    callback(id)
  }

  render() {
    return (
      <>
        <MainUl>
          {this.props.btn_list ? this.props.btn_list.map((el, index) => {
            return (<MenuLi data-target={el.id} key={el.id} className={(el.isActive ? 'active' : '')} onClick={this.onBtnClick.bind(this, el.id, this.props.onBtnClickCallBack)}>{el.title}</MenuLi>)
          }) : ''}
        </MainUl>
      </>
    );
  }

}
const MenuLi = styled.li``;
const MainUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  ${MenuLi}{
    float: left;
    border: 1px solid #e7e7e7;
    border-bottom-color: #a1a1a1;
    text-align: center;
    padding: 10px 0;

    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;

    cursor:pointer;
    &.active{
      border: 1px solid #a1a1a1 !important;
      border-bottom: 1px solid #ffffff!important;
    }
  }
`;



export default MenuBtnGroup;
