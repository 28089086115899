import React from "react";
import FooterCsDialog from "../components/view/common/footer/FooterCsDialog";
export const LANGUAGE = ['KO', 'EN', 'ES'];

export const LANGUAGE_MENU = [{title:'한국어', path:'/ko', code:'KO'}, {title:'English', path:'/en', code:'EN'}, {title:'Español', path:'/es', code:'ES'}]

export const NEWS_BOTTOM_BAR = {
    'KO': <>아티스트의<br />최신기사</>,
    'EN': <>Latest News <br />of Artist</>,
    'ES': <>Artículo más reciente<br />del Artísta</>
};

export const FOOTER_MENU = {
    'KO': [
        { title: '회사소개', link: '/ko/company/home' },
        { title: '이용약관', link: '/ko/company/terms' },
        { title: '개인정보 처리방침', link: '/ko/company/policy' },
        { title: '청소년 보호정책', link: '/ko/company/protection' },
        { title: '윤리강령', link: '/ko/company/ethics' },
        { title: '광고 및 제휴 문의', link: function (show, closeCallback){ return <FooterCsDialog closeCallback={closeCallback} show={show} lang={'KO'} category={'Partnership'}/> } },
        { title: '기자 등록 및 기사 제보', link: function (show, closeCallback){ return <FooterCsDialog closeCallback={closeCallback} show={show} lang={'KO'} category={'Submission'}/> } },
        { title: '전체 기사보기', link: '/ko/article/all' }
    ],
    'EN': [
        {title: 'About', link: '/en/company/home' },
        { title: 'Terms & Conditions', link: '/en/company/terms' },
        { title: 'Privacy Policy', link: '/en/company/policy' },
        { title: 'Code of Ethics', link: '/en/company/ethics' },
        { title: 'Partnership', link: function (show, closeCallback){ return <FooterCsDialog closeCallback={closeCallback} show={show} lang={'EN'} category={'Partnership'}/>} },
        { title: 'Submission', link: function (show, closeCallback){ return <FooterCsDialog closeCallback={closeCallback} show={show} lang={'EN'} category={'Submission'}/> } },
        { title: 'See all articles', link: '/en/article/all' }
    ],
    'ES': [
        { title: 'Acerca de', link: '/es/company/home' },
        { title: 'Términos y Condiciones', link: '/es/company/terms' },
        { title: 'Políticas de privacidad', link: '/es/company/policy' },
        { title: 'Código ético', link: '/es/company/ethics' },
        { title: 'Colaborar', link: function (show, closeCallback){ return <FooterCsDialog closeCallback={closeCallback} show={show} lang={'ES'} category={'Partnership'}/>} },
        { title: 'Propuesta', link: function (show, closeCallback){ return <FooterCsDialog closeCallback={closeCallback} show={show} lang={'ES'} category={'Submission'}/>} },
        { title: 'Ver todos los artículos', link: '/es/article/todos' }
    ]
}
export const FOOTER_COMPANY_INFO_PC = {
    'KO': <>
        제호 : 한터뉴스(HANTEO NEWS) <span>|</span> 발행처 : (주)한터글로벌 <span>|</span> 인터넷 신문 등록번호 : 서울 아 52122<br />
        인터넷 신문 등록일 : 2019년 01월 29일 <span>|</span> 발행일자 : 2019년 04월 01일<br />
        발행인 : 곽영호 <span>|</span> 편집인 : 심세나 <span>|</span> 청소년 보호 책임자 : 심세나<br />
        주소 : 서울특별시 강남구 봉은사로109길 4(삼성동, 베페하우스), 2층 <br />
        대표전화 : 02.449.2347 <span>|</span> 팩스 : 02.449.2256 <span>|</span> 사업자등록번호 : 715-86-01143<br />
        광고/제휴 문의 : news.cs@hanteo.com <span>|</span>기자 등록 및 기사 제보 : news@hanteo.com<br />
        <br />
        한터뉴스(HANTEO NEWS)의 모든 콘텐트(기사)는 저작권법의 보호를 받은바, 무단 전재, 복사, 배포 등을 금합니다.<br />
        Copyright ⓒ 2022 HANTEO GLOBAL, Inc. All Rights Reserved
    </>,
    'EN': <>
        Title : 한터뉴스(HANTEO NEWS) <span>|</span> Published by: HANTEO GLOBAL <br />Internet Newspaper Registration Number: 서울 아 52122<br />
        Internet Newspaper Registration Date: 2019/01/29 <span>|</span> Date of Issue : 2019/04/01<br />
        Publisher : 곽영호 <span>|</span> Editor : 심세나 <span>|</span> Youth Protection : 심세나<br />
        Address : 2F 4, Bongeunsa-ro 109-gil, Gangnam-gu, Seoul, 06083, Republic of Korea <br />
        Tel : 02.449.2347 <span>|</span> FAX : 02.449.2256 <span>|</span> Business Registration Number : 715-86-01143<br />
        Partnership Contact : news.cs@hanteo.com <span>|</span>Article Submission Contact : news@hanteo.com<br />
        <br />
        All contents (articles) of HANTEO NEWS is under protection of copyright law;<br />reproduction and
        distribution of the publication without permission is strictly prohibited.<br />
        Copyright ⓒ 2022 HANTEO GLOBAL, Inc. All Rights Reserved
    </>,
    'ES': <>
        Título :  NOTICIAS HANTEO (HANTEO NEWS) <span>|</span> Publicado por: HANTEO GLOBAL, Inc. <br /> Número de Registro de Periódico de Internet : 서울 아 52122<br />
        Fecha de Registro de Periódico de Internet : 2019/01/29 <span>|</span> Fecha de publicación : 2019/04/01<br />
        Editor : 곽영호 <span>|</span> Redactor : 심세나 <span>|</span> Protección juvenil : 심세나<br />
        Dirección : 2do piso, Bongeunsa-ro 109-gil, Gangnam-gu, Seúl, Corea <br />
        Tel : +82)02.449.2347 <span>|</span> FAX : 02.449.2256 <span>|</span> Registro de Negocio : 715-86-01143<br />
        Contacto de Promoción/Colaboración: news.cs@hanteo.com <br />Contacto de Entrega de Artículos: news.cs@hanteo.com<br />
        <br />
        Todos los contenidos (artículos) de NOTICIAS HANTEO están bajo la protección de la ley de derechos de autor,<br />queda estrictamente prohibido la reproducción y distribución de alguna  de las publicaciones sin autorización.<br />
        Copyright ⓒ 2022 HANTEO GLOBAL, Inc. All Rights Reserved
    </>
}
export const FOOTER_COMPANY_INFO_MOBILE = {
    'KO': <>제호 : 한터뉴스(HANTEO NEWS)&nbsp;&nbsp;|&nbsp;&nbsp;발행처 : (주)한터글로벌<br />
        인터넷 신문 등록번호 : 서울 아 52122<br />
        인터넷 신문 등록일 : 2019년 01월 29일 &nbsp;&nbsp;|&nbsp;&nbsp; 발행일자 : 2019년 04월 01일<br />
        발행인 : 곽영호 &nbsp;&nbsp;|&nbsp;&nbsp; 편집인 : 심세나 &nbsp;&nbsp;|&nbsp;&nbsp; 청소년 보호 책임자 : 심세나<br />
        주소 : 서울특별시 강남구 봉은사로109길 4(삼성동, 베페하우스), 2층<br />
        대표전화 :  02.449.2347  &nbsp;&nbsp;|&nbsp;&nbsp; 팩스 : 02.449.2256<br />
        사업자등록번호 : 715-86-01143<br />
        광고/제휴 문의 : news.cs@hanteo.com<br />
        기자 등록 및 기사제보 : news@hanteo.com</>,
    'EN': <>Title : 한터뉴스(HANTEO NEWS)&nbsp;&nbsp;|&nbsp;&nbsp;Published by: HANTEO GLOBAL<br />
        Internet Newspaper Registration Number: 서울 아 52122<br />
        Internet Newspaper Registration Date: 2019/01/29<br />
        Date of Issue : 2019/04/01 &nbsp;&nbsp;|&nbsp;&nbsp; Publisher : 곽영호<br />
        Editor : 심세나 &nbsp;&nbsp;|&nbsp;&nbsp; Youth Protection : 심세나<br />
        Address : 2F 4, Bongeunsa-ro 109-gil, Gangnam-gu, Seoul, 06083, Republic of Korea<br />
        Tel : 02.449.2347  &nbsp;&nbsp;|&nbsp;&nbsp; FAX : 02.449.2256<br />
        Business Registration Number : 715-86-01143<br />
        Partnership Contact : news.cs@hanteo.com<br />
        Article Submission Contact : news@hanteo.com</>,
    'ES': <>Título :  NOTICIAS HANTEO (HANTEO NEWS)<br />
        Publicado por: HANTEO GLOBAL, Inc.<br />
        Número de Registro de Periódico de Internet : 서울 아 52122<br />
        Fecha de Registro de Periódico de Internet : 2019/01/29<br />
        Fecha de publicación : 2019/04/01 &nbsp;&nbsp;|&nbsp;&nbsp; Editor: 곽영호<br />
        Redactor:심세나 &nbsp;&nbsp;|&nbsp;&nbsp; Protección juvenil:심세나<br />
        Dirección : 2do piso, Bongeunsa-ro 109-gil, Gangnam-gu, Seúl, Corea<br />
        Tel : +82)02.449.2347  &nbsp;&nbsp;|&nbsp;&nbsp; FAX : 02.449.2256<br />
        Registro de Negocio : 715-86-01143<br />
        Contacto de Promoción/Colaboración: news.cs@hanteo.com<br />
        Contacto de Entrega de Artículos: news.cs@hanteo.com</>
}

export const FOOTER_BOTTOM_MOBILE = {
    'KO':<>한터뉴스(HANTEO NEWS)의 모든 콘텐트(기사)는 저작권법의 보호를
        받은 바, 무단 전재, 복사, 배포 등을 금합니다.
        <br /><br />
        Copyright  ⓒ 2022 HANTEO GLOBAL, Inc. All Rights Reserved</>,
    'EN':<>All contents (articles) of HANTEO NEWS is under protection of copyright law; reproduction and
        distribution of the publication without permission is strictly prohibited.
        <br /><br />
        Copyright ⓒ 2022 HANTEO GLOBAL, Inc. All Rights Reserved</>,
    'ES':<>Todos los contenidos (artículos) de NOTICIAS HANTEO están bajo la protección de la ley de derechos de autor, queda estrictamente prohibido la reproducción y distribución de alguna  de las publicaciones sin autorización.
        <br /><br />
        ⓒ 2022 HANTEO GLOBAL, Inc.Todos los  derechos reservados.</>
}

export const FOOTER_MENU_SIDE = {
    'KO': [{ title: '전체 기사보기', link: '/ko/article/all' }, { title: '회사소개', link: '/ko/company/home' }, { title: '이용약관', link: '/ko/company/terms' }, { title: '개인정보 처리방침', link: '/ko/company/policy' }, { title: '청소년 보호정책', link: '/ko/company/protection' }, { title: '윤리강령', link: '/ko/company/ethics' }, { title: '광고 및 제휴 문의', link: 'Partnership' }, { title: '기자 등록 및 기사 제보', link: 'Submission' }],
    'EN': [{ title: 'See all articles', link: '/en/article/all' }, { title: 'About', link: '/en/company/home' }, { title: 'Terms & Conditions', link: '/en/company/terms' }, { title: 'Privacy Policy', link: '/en/company/policy' }, { title: 'Code of Ethics', link: '/en/company/ethics' }, { title: 'Partnership', link: 'Partnership' }, { title: 'Submission', link: 'Submission' }],
    'ES': [{ title: 'Ver todos los artículos', link: '/es/article/todos' }, { title: 'Acerca de', link: '/es/company/home' }, { title: 'Términos y Condiciones', link: '/es/company/terms' }, { title: 'Políticas de privacidad', link: '/es/company/policy' }, { title: 'Código ético', link: '/es/company/ethics' }, { title: 'Colaborar', link: 'Partnership' }, { title: 'Propuesta', link: 'Submission' }]
}
export const FOOTER_TEXT = {
    'KO': {
        Partnership: { title: '광고/제휴 문의', text: '광고 및 제휴 문의는 이메일(<b>news.cs@hanteo.com</b>)로 주시기 바랍니다.', btn: '확인' },
        Submission: { title: '기자 등록 및 기사 제보', text: '기자 등록 및 기사 제보는 이메일(<b>news@hanteo.com</b>)로 주시기 바랍니다.', btn: '확인' }
    },
    'EN': {
        Partnership: { title: 'Partnership Inquiries', text: 'Please contact us at <b>news.cs@hanteo.com</b> for all advertisement and partnership inquiries.', btn: 'OK' },
        Submission: { title: 'Contributor Registration & Article Submission', text: 'Please contact us at <b>news@hanteo.com</b> in order to register as a contributor or submit an article and/or news tip.', btn: 'OK' }
    },
    'ES': {
        Partnership: { title: 'Consultas de promociones / colaboraciones', text: 'Por favor contáctanos en <b>news.cs@hanteo.com</b> para todas las consultas de promociones y colaboraciones.', btn: 'OK' },
        Submission: { title: 'Registro de Contribuidores y Entrega de artículos', text: 'Por favor contáctanos en <b>news@hanteo.com</b> para realizar el registro como contribuidor o entrega de un artículo o consejo de noticias.', btn: 'OK' }
    }
}

export const SEARCH_PLACE_HOLDER = {
    'KO': '검색어를 입력해주세요.',
    'EN': 'Please enter a search term',
    'ES': 'Ingresa tu búsqueda'
}
export const SEARCH_ERROR_MSG = {
    'KO': '검색은 두글자 이상부터 가능합니다.',
    'EN': 'More than one character is required.',
    'ES': 'Se requiere más de un carácter'
}

export const APP_POINT_TEXT = {
    'KO': {
        'voteBtn': {
            default: '적립하기(+5)',
            finish: '적립이 완료되었습니다.',
            double: '이미 적립한 콘텐츠입니다.',
            noArtist: '연동된 아티스트가 없습니다.'
        },
        'alertMsg': {
            voteFinish: '적립이 완료된 콘텐츠 입니다.',
            voteInActive: '스크롤을 하단으로 내릴 시 "적립하기" 활성화 됩니다.',
            selectArtist: '아티스트를 선택해주세요.',
            wrongSelect: '잘못된 선택이 있습니다.',
            serverError: '서버 내부 에러입니다. 잠시후에 다시 한번 시도해주세요.',
            dataError: '데이터 세팅 오류. 다시 선택해주세요.'
        },
        'popup': {
            des: {
                noArtist: '연동된 아티스트가 없습니다. 아티스트와 연동된 기사의 경우 아티스트 활동과 포인트를 동시에 적립할 수 있습니다.',
                yesArtist: '선택하신 아티스트에게 +5pt가 반영됩니다. 아티스트는 1명만 선택가능합니다.'
            },
            btn: {
                close: '닫기',
                confirm: '적립하기'
            }
        },
        'loading': {
            save: '포인트를 적립중입니다.<br>잠시만 기다려주세요.',
            doubleCheck: '포인트 적립 확인중입니다.<br>잠시만 기다려주세요',
            finish: '<br>아티스트에게 포인트가 적립되었습니다.',
            double: '이미 적립한 콘텐츠입니다.'
        }
    },
    'EN': {
        'voteBtn': {
            default: 'Add points (+5)',
            finish: 'Your point is successfully added.',
            double: 'You have already received points.',
            noArtist: 'There is no artist associated with this article.'
        },
        'alertMsg': {
            voteFinish: 'You have already received points',
            voteInActive: 'Scroll down the page in order to activate the button.',
            selectArtist: 'Select one artist.',
            wrongSelect: 'You may only select one artist.',
            serverError: 'Please try again after a while.(Internal Error)',
            dataError: 'Please select again.(Data Error)'
        },
        'popup': {
            des: {
                noArtist: 'There is no artist associated with this article. You can receive both artist activities and points on the articles associated with artists.',
                yesArtist: '5pt will be given to the selected artist. You may only select one artist.'
            },
            btn: {
                close: 'Close',
                confirm: 'Add points'
            }
        },
        'loading': {
            save: 'Please wait while your point is being added...',
            doubleCheck: 'Please wait while we are checking the points...',
            finish: 'Your point is added to ',
            double: 'You have already received points'
        }
    },
    'ES': {
        'voteBtn': {
            default: 'Obtén puntos (5+)',
            finish: 'Tus puntos han sido agregados exitosamente',
            double: 'Ya has recibido los puntos',
            noArtist: 'No hay un artista asociado con este artículo'
        },
        'alertMsg': {
            voteFinish: 'Ya has recibido los puntos',
            voteInActive: 'Desplaza hacia abajo de la página para activar el botón',
            selectArtist: 'Seleccionar artista',
            wrongSelect: 'Solo debes seleccionar un artista.',
            serverError: 'Por favor intenta nuevamente más tarde.(Error Interno)',
            dataError: 'Por favor selecciona de nuevo (Error de datos)'
        },
        'popup': {
            des: {
                noArtist: 'No hay un artista asociado con este artículo.Puedes recibir ambas actividades del artista y puntos en los artículos relacionados con el artista. ',
                yesArtist: '5 puntos serán dado al artista seleccionado. Solo debes seleccionar un artista.'
            },
            btn: {
                close: 'Cerrar',
                confirm: 'Dar puntos.'
            }
        },
        'loading': {
            save: 'Por favor espera mientras tus puntos son agregados.',
            doubleCheck: 'Por favor espera, mientras revisamos sus puntos.',
            finish: '¡Tu punto se agrega a',
            double: 'Ya has recibido los puntos'
        }
    }
};
export const SEARCH_POP_UP = {
    'KO': {
        search: '기사 검색 결과',
        description: '* 한터뉴스 기사가 검색됩니다',
        latest: '최신순',
        oldest: '오래된순',
        noList: '검색 결과가 없습니다.<br> 단어의 철자가 정확한지 확인해 보세요.<br>두 단어 이상의 검색어인 경우, 띄어쓰기를 확인해 보세요.'
    },
    'EN': {
        search: 'Search results',
        description: '* Search over HANTEO NEWS articles',
        latest: 'Latest',
        oldest: 'Oldest',
        noList: 'There is no search result.<br>Please check if the word is spelled correctly.'
    },
    'ES': {
        search: 'Buscar',
        description: '* Buscar en artículos de HANTEO NEWS',
        latest: 'Más reciente',
        oldest: 'Más antiguo',
        noList: 'No hay resultados de búsqueda<br>Por favor, revisar que la palabra se encuentre escrita correctamente<br>Si la búsqueda contiene más de una palabra, revisa el espaciado'
    }
}
export const COPY_ALERT = {
    'KO': '저작권 보호를 위하여 기사의 복사를 금지합니다.',
    'EN': '저작권 보호를 위하여 기사의 복사를 금지합니다.',
    'ES': '저작권 보호를 위하여 기사의 복사를 금지합니다.'
}

// WEB MAIN PAGE
export const WEB_MAIN_STR = {
    'KO': {
        'title': {
            all: '전체 기사',
            new: '최신 기사',
            best: '많이 본 기사',
            most: '많이 본 기사',
            photo: '한터뉴스 포토',
            video: '한터뉴스 영상',
            artist: '아티스트별 기사 모아보기',
            related: '관련 기사'
        },
        'subTitle': {
            ofNew: '의 최신 기사',
            ofBest: '의 인기 기사'
        },
        'btn': {
            more: '더 보기 >',
            description: '한터뉴스에서 발행된 기사 중에서 최근 2주간 가장 높은 조회수를 기록한 아티스트의 기사들을 모아볼 수 있습니다.<br><br>해당 아티스트의 최신 기사와 조회수가 높은 기사들을 보여줍니다. (더보기 기능은 추후 제공 예정)'
        },
        'url': {
            photo: '/article/photo&video',
            video: '/article/photo&video',
        }
    },
    'EN': {
        'title': {
            all: 'Show more',
            new: 'Lastest News',
            best: 'Most Popular',
            most: 'Most Popular',
            photo: 'Photos',
            video: 'Videos',
            artist: 'Articles by artist',
            related: 'Related Articles'
        },
        'subTitle': {
            ofNew: 'Latest News of',
            ofBest: 'High-viewed News of'
        },
        'btn': {
            more: 'Load More >',
            description: 'You can read the articles on the artist that have the highest views in the last 2 weeks among all the articles published by Hanteo News.<br><br>The artist\'s latest articles and the articles with the most views will be displayed. (\'Show more\' will be offered at a later date)'
        },
        'url': {
            photo: '/article/photo',
            video: '/article/video',
        }
    },
    'ES': {
        'title': {
            all: 'Ver más',
            new: 'Últimas noticias',
            best: 'Más populares',
            most: 'Más populares',
            photo: 'Fotos',
            video: 'Videos',
            artist: 'Artículos por artista',
            related: 'Artículos relacionados'
        },
        'subTitle': {
            ofNew: 'Artículo más reciente de',
            ofBest: 'Artículos populares de'
        },
        'btn': {
            more: 'Cargar más >',
            description: 'Puedes leer los artículos sobre artistas con la mayor cantidad de visitas de las últimas 2 semanas de todos los artículos publicados por Hanteo News.<br><br>Se desplegarán los artículos más recientes sobre artistas y que tengan la mayor cantidad de visitas. (La opción \'Ver más\' estará disponible más adelante)'
        },
        'url': {
            photo: '/article/foto',
            video: '/article/video',
        }
    }
}

// APP DEEP LINK
export const APP_DEEP_LINK = {
    app: {
        whosfan: {
            base: {
                android: "whosfanglobal://open",
                ios: "whosfan://open"
            },
            web: "http://whosfan.io",
            store: {
                android: "https://play.google.com/store/apps/details?id=com.hanteo.whosfanglobal",
                ios: "https://apps.apple.com/app/id1473903262"
            },
            package: "com.hanteo.whosfan"
        },
        star: {
            base: {
                android: "whosfanglobal://star/",
                ios: "whosfan://star/"
            },
            web: "http://whosfan.io",
            store: {
                android: "https://play.google.com/store/apps/details?id=com.hanteo.whosfanglobal",
                ios: "https://apps.apple.com/app/id1473903262"
            },
            package: "com.hanteo.whosfan"
        },
        'localhost:3000': {
            base: {
                android: "whosfanglobal://open",
                ios: "whosfanglobal://open"
            },
            web: "http://whosfan.io",
            store: {
                android: "https://play.google.com/store/apps/details?id=com.hanteo.whosfanglobal",
                ios: "https://apps.apple.com/app/id1473903262"
            },
            package: "com.hanteo.whosfan"
        },

    }
}

// COMMENT
export const COMMENT_TEXT = {
    'KO': {
        'error':{
            nodata: '등록된 댓글이 없습니다.'
        },
        'input':{
            placeholder: "댓글을 입력해 주세요",
            registerBtn: "등록"
        },
        'ui':{
            title: "댓글",
            recommentBtn: "답글",
            deleteBtn: "삭제하기",
            reportBtn: "신고하기"
        },
        'alert':{
            deleteMsg: "댓글을 삭제하시겠습니까?",
            latestMsg: "해당 기사는 최신 기사입니다.",
            reportMsg: "해당 댓글을 신고하시겠습니까?",
            reportDoneMsg: "신고가 접수되었습니다."
        }
    },
    
    'EN': {
        'error':{
            nodata: 'There are no comments'
        },
        'input':{
            placeholder: "Leave the first comment",
            registerBtn: "Post"
        },
        'ui':{
            title: "Comments",
            recommentBtn: "Reply",
            deleteBtn: "Delete",
            reportBtn: "Report"
        },
        'alert':{
            deleteMsg:  "Would you like to delete the comment?",
            latestMsg: "This is the latest article.",
            reportMsg: "Would you like to report the comment?",
            reportDoneMsg: "We have received your report."
        }
    },
    
    'ES': {
        'error':{
            nodata: 'No hay comentarios por el momento'
        },
        'input':{
            placeholder: "Escribe aquí el primer comentario",
            registerBtn: "Publicar"
        },
        'ui':{
            title: "Comentarios",
            recommentBtn: "Responder",
            deleteBtn: "Eliminar",
            reportBtn: "Reportar"
        },
        'alert':{
            deleteMsg: "¿Deseas eliminar el comentario?",
            latestMsg: "Este es el artículo más reciente.",
            reportMsg: "¿Deseas reportar el comentario?",
            reportDoneMsg: "Hemos recibido tu reporte"
        }
    }
}

// WHOSFAN BANNER
export const WHOSFAN_BANNER = {
    'KO': {
        basic: '후즈팬 앱에서 내가 좋아하는 아티스트 콘텐츠 즐기고 스타차트 랭킹도 올리기!',
        artist: '★%@★<br />후즈팬 아티스트 팬 페이지에서 응원하고 스타차트 랭킹 올리기!'
    },
    'EN': {
        basic: 'Enjoy contents of my favorite artists on Whosfan & rank them up on Star Chart ranking!',
        artist: '★%@★<br />Support %@ on Whosfan fan page & rank up %@ on Star Chart ranking!'
    },
    'ES': {
        basic: 'Enjoy contents of my favorite artists on Whosfan & rank them up on Star Chart ranking!',
        artist: '★%@★<br />Support %@ on Whosfan fan page & rank up %@ on Star Chart ranking!'
    }
}

// MENU
export const MENU_CONST = {
    'main': { 'KO':245, 'EN':246, 'ES':247, },
    'banner': { 'KO': 2, 'EN': 4, 'ES': 6 }, // { 'KO': 1, 'EN': 4, 'ES': 6 };
    'latest': { 'KO': 1, 'EN': 3, 'ES': 5 }, // { 'KO': 1, 'EN': 4, 'ES': 6 };
    'photo': { 'KO': 57, 'EN': 60, 'ES': 139 },
    'video': { 'KO': 167, 'EN': 169, 'ES': 168 },
    'allCategory' : { 
        'KO': {
            title: 'All',
            description: "한터뉴스에서 발행한 케이팝(K-POP) 관련 모든 기사를 최신 순으로 보실 수 있습니다."
        }, 
        'EN': {
            title: 'All',
            description: "Latest News of HANTEO NEWS - K-Pop News, Updates, Issues."
        }, 
        'ES': {
            title: 'Todos',
            description: "Revisa las últimas noticias de todos los nuevos artículos de K-POP publicados por Noticias Hanteo."
        } 
    },
    'latestCategory': {
        'KO': {
            title: '최신기사',
            description: "케이팝(K-POP) 아티스트와 관련된 이슈·인터뷰·앨범·컴백 등의 기사를 최신 순으로 보실 수 있습니다."
        }, 
        'EN': {
            title: 'LATEST NEWS',
            description: "Latest Issues and News on Albums and Interviews from K-POP Artists."
        }, 
        'ES': {
            title: 'ÚLTIMAS NOTICIAS | NOTICIAS HANTEO',
            description: "Revisa las últimas noticias de artistas de K-POP,problematicas,entrevistas,y lanzamiento de álbumes."
        } 
    }
}


