import React, { Component } from "react";
import { useParams } from "react-router-dom";
import Home from "Page/Company/Home"
import Terms from "Page/Company/Terms"
import Policy from "Page/Company/Policy"
import Protection from "Page/Company/Protection"
import Ethics from "Page/Company/Ethics"
import NotFound from "../Error/NotFound";
import styled from "styled-components";

class Company extends Component {

  renderSwitch(language, header) {
    switch (header) {
      case 'home': return <Home language={language} />;
      case 'terms': return <Terms language={language} />;
      case 'policy': return <Policy language={language} />;
      case 'protection': return <Protection language={language} />;
      case 'ethics': return <Ethics language={language} />;
      default: return <NotFound />;
    }
  }

  render() {
    const {language, header} = this.props;
    return (
      <ContentsDiv>
        {this.renderSwitch(language, header)}
      </ContentsDiv>
    );
  }

}
const ContentsDiv = styled.div`
  height: auto;

  @media all and (min-width:1048px) {
    padding: 0px 0 0 0;
    width: 1048px;
    margin: 0 auto;
  }
`;

export default Company;
