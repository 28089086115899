import {Component} from "react";
import styled from "styled-components";

class FooterMenu extends Component{
    constructor() {
        super();
        this.state = {
            show:false
        }
    }
    onClick = (data) => {
        if (typeof data === "function"){
            let show = this.state.show;
            this.setState({show:!show})
            return;
        }

        window.location.href = data;

    }
    closeCallback = (e) => {
        let show = this.state.show;
        this.setState({show:!show})
    }
    render(){
        const {type, link, title} = this.props;
        return <>
            <Container className={type}>
                <div className={type} onClick={this.onClick.bind(this,link)}>
                    <p className={type}>{title}</p>
                </div>
            </Container>
            {(typeof link)==="function"? link(this.state.show, this.closeCallback) :<></>}
        </>
    }
}
const Container = styled.div`
    display: inline-block;
    
    &.side{
        display:block;
    }

    div{
        color: inherit;
        cursor: pointer;
        @media (min-width: 1048px){
            margin: 0 45px 0 0;
        }
        
        
        &.side{
            display: inline-flex;
            width: 100%;
            justify-content: space-between;
            font-size: 11px;
        }
        
        p{
            display: inline-block;
            margin: 0;
            font-size: 12px;
            &.side{
                display: inline-flex;
                width: 100%;
                -webkit-box-pack: justify;
                justify-content: space-between;
                font-size: 11px;
                margin-top: 7px;
                line-height: 1.5;
            }
        }
    }
`;

export default FooterMenu;