
import { API } from "global/api";
import {convertJsonToQueryString} from "../utils/UriUtils";

export function getMainArticle(_params, successCallback){
    let _path = '/v4/article/main'
    let _search = convertJsonToQueryString(_params)

    fetch(`${API}${_path}${_search}`)
        .then(response => response.json())
        .then(successCallback);
}

export function getNewArticle(_params, successCallback){
    let _path = '/v4/article/new'
    let _search = convertJsonToQueryString(_params)

    fetch(`${API}${_path}${_search}`)
        .then(response => response.json())
        .then(successCallback);
}

export function getPhotoArticle(_params, successCallback){
    let _path = '/v4/article/photo'
    let _search = convertJsonToQueryString(_params)

    fetch(`${API}${_path}${_search}`)
        .then(response => response.json())
        .then(successCallback);
}

export function getVideoArticle(_params, successCallback){
    let _path = '/v4/article/video'
    let _search = convertJsonToQueryString(_params)

    fetch(`${API}${_path}${_search}`)
        .then(response => response.json())
        .then(successCallback);
}

export function getMostArticle(_params, successCallback){
    let _path = '/v4/article/most'
    let _search = convertJsonToQueryString(_params)

    fetch(`${API}${_path}${_search}`)
        .then(response => response.json())
        .then(successCallback);
}

export function getBestArtistList(_params, successCallback){
    let _path = '/v4/article/artist'
    let _search = convertJsonToQueryString(_params)

    fetch(`${API}${_path}${_search}`)
        .then(response => response.json())
        .then(successCallback)
}

export function getBestArticleByArtist(_params, successCallback){
    let _path = '/v4/article/artist'
    let _search = convertJsonToQueryString(_params)

    fetch(`${API}${_path}${_search}`)
        .then(response => response.json())
        .then(successCallback)
}

export function getLatestArticleByArtist(_params, successCallback){
    let _path = '/v4/article/artist/latest'
    let _search = convertJsonToQueryString(_params)

    fetch(`${API}${_path}${_search}`)
        .then(response => response.json())
        .then(successCallback)

}

export function getLatestArticle(_params, successCallback){
    let _path = '/v4/article/latest'
    let _search = convertJsonToQueryString(_params)

    fetch(`${API}${_path}${_search}`)
        .then(response => response.json())
        .then(successCallback);
}

export function getCountArticle(_params, successCallback){
    let _path = `/v4/article/count/${_params['header']}`
    let _search = convertJsonToQueryString(_params)

    fetch(`${API}${_path}${_search}`)
        .then(response => response.json())
        .then(successCallback);
}

export function getSearchArticle(_params, successCallback){
    let _path = `/v4/article/search/${_params['header']}`
    let _search = convertJsonToQueryString(_params)

    fetch(`${API}${_path}${_search}`)
        .then(response => response.json())
        .then(successCallback);
}

export function getNewsDetails(_params, successCallback){
    var _path = `/v4/article/detail`
    if(_params['header'] !== undefined){
        _path = _path + `/`+_params['header']
    }

    let _search = convertJsonToQueryString(_params)

    fetch(`${API}${_path}${_search}`)
        .then(response => response.json())
        .then(successCallback);
}



export function getBestArticle(_params, successCallback){
    fetch(`${API}/news/best/` + _params['langCode'])
        .then(response => response.json())
        .then(successCallback);
}


