import {Component} from "react";
import styled from "styled-components";
import {FOOTER_TEXT} from "../../../../global/string";

class FooterCsDialog extends Component{
    _text = FOOTER_TEXT[this.props.lang][this.props.category]

    closeOnClick = (e) => {
        this.props.closeCallback()
    }
    render(){
        const {show, lang, category, closeCallback} = this.props;
        return <>
            <Container className={show?"show":""}>
                <Cover>
                    <Contents>
                        <Header>
                            <h5>{this._text.title}</h5>
                            <button type="button" onClick={this.closeOnClick.bind(this)}>
                                <span aria-hidden="true">×</span>
                            </button>
                        </Header>
                        <Body dangerouslySetInnerHTML={{ __html: this._text.text }}>
                        </Body>
                        <Footer>
                            <button type="button" onClick={this.closeOnClick.bind(this)}>{this._text.btn}</button>
                        </Footer>
                    </Contents>
                </Cover>
            </Container>
        </>
    }
}
const Footer = styled.div``;
const Body = styled.div``;
const Header = styled.div``;
const Contents = styled.div``;
const Cover = styled.div``;
const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    
    &.show {
        display: block;
        z-index: 10000!important;
    }
    
    ${Cover}{
        position: relative;
        width: auto;
        margin: 0.5rem;
        pointer-events: none;
        margin-top: 100px;
        
        max-width: 500px;
        margin: 1.75rem auto;
        
        ${Contents}{
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            pointer-events: auto;
            background-color: #fff;
            background-clip: padding-box;
            border: 1px solid rgba(0,0,0,.2);
            border-radius: 0.3rem;
            outline: 0;
            color: #000;
            
            ${Header}{
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                padding: 1rem;
                border-bottom: 1px solid #dee2e6;
                border-top-left-radius: calc(0.3rem - 1px);
                border-top-right-radius: calc(0.3rem - 1px);
                
                h5{
                    font-weight: 800;
                    margin-bottom: 0;
                    line-height: 1.5;
                }
                button{
                    padding: 1rem;
                    margin: -1rem -1rem -1rem auto;
                    
                    float: right;
                    font-size: 1.5rem;
                    font-weight: 700;
                    line-height: 1;
                    color: #000;
                    text-shadow: 0 1px 0 #fff;
                    opacity: .5;
                }
            }
            ${Body}{
                position: relative;
                flex: 1 1 auto;
                padding: 1rem;
                
                span{
                    font-weight: 800;
                }
            }
            
            ${Footer}{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: flex-end;
                padding: 0.75rem;
                border-top: 1px solid #dee2e6;
                border-bottom-right-radius: calc(0.3rem - 1px);
                border-bottom-left-radius: calc(0.3rem - 1px);
                
                button{
                    cursor: pointer;
                    display: inline-block;
                    font-weight: 400;
                    color: #212529;
                    text-align: center;
                    vertical-align: middle;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                    user-select: none;
                    
                    border: 1px solid #6c757d;
                    padding: 0.375rem 0.75rem;
                    
                    font-size: 1rem;
                    line-height: 1.5;
                    border-radius: 0.25rem;
                    
                    transition: color .15s;
                    
                    color: #fff;
                    background-color: #6c757d;
                    margin: 0.25rem;
                }
            }
        }
    }
`;

export default FooterCsDialog;