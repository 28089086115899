import {Component} from "react";
import styled from "styled-components";
import {FOOTER_COMPANY_INFO_PC} from "../../../../global/string";

class FooterCompanyInfo extends Component{
    render(){
        const {language} = this.props;
        return <>
            <Container>
                <Info>
                    {FOOTER_COMPANY_INFO_PC[language]}
                </Info>
                <InfoLogo>
                    <a href={`/${language}`}>
                        <img src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/real/hanteonews_logo.png" />
                    </a>
                </InfoLogo>
            </Container>
        </>
    }
}
const InfoLogo = styled.div``;
const Info = styled.div``;
const Container = styled.div`
    flex-direction: column;
    @media (min-width: 1048px){
        width: 1048px;
        margin: 0 auto;
        flex-direction: row;
        display:flex;
        padding: 24px 0 25px 0;
        justify-content: space-between;
        
    }
    
    ${Info}{
        float: left;
        font-size: 12px;
        font-family: 'Lato';
        font-weight: 400;
        flex-basis: unset;
        display: inline-block;
        margin: 0 0 20px 0;
        line-height: 1.5;
    }
    
    ${InfoLogo}{
        float: right;
        display: inline-flex;
        display: -ms-flexbox;
        flex-direction: column;
        justify-content: center;
        text-align: right;
        
        a{
            margin: 0 0 50px 0;
            
            img{
                height: 34px;
                width: auto;
            }
        }
    }
    
`;

export default FooterCompanyInfo;