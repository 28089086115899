import React, { Component } from "react";
import styled from "styled-components";

import AppDetailView from 'components/view/detail/App';
import AppCommentAndVoteVM from 'components/view_model/app/AppCommentAndVoteVM';
import AppSeachPopUpVM from 'components/view_model/app/AppSeachPopUpVM';
import { addViewLog } from "api/UserAPI";
import GoogleMainAds from "../../view/ads/GoogleMainAds";

class AppDetailVM extends Component {

    constructor() {
        super();
        this.state = {
            isActive: false,
            keyword: undefined
        }
    }

    componentDidMount() {
        this.articleViewAndLogUpdate(this.props.data, this.props.userInfo.userNum)
    }

    articleViewAndLogUpdate(data, userIdx) {
        
        addViewLog({ 
            ownership: {
                idx: data.idx,
                user: {
                    idx: userIdx,
                    client :{
                        idx: 1022
                    }
                }
            },
            meta : {
                content: { 
                    title: data.title
                },
                language: data.language
            }
        })
    }

    onClickHashTag = (_result) => {
        this.setState({
            keyword: _result,
            isActive: true
        });
        var scrollPosition = window.scrollY || document.documentElement.scrollTop;
        document.documentElement.style.position = 'fixed';
        document.documentElement.style.top = '-' + scrollPosition + 'px';
    }

    onClickClose = () => {
        this.setState({
            isActive: false
        })

        document.documentElement.style.position = 'inherit';
    }

    render() {
        const {language, data, userInfo} = this.props;
        return (
            <>
                <AppDetailView data={data} language={language} onClickCallBack={this.onClickHashTag} />

                {(userInfo.userNum !== null) && (userInfo.userToken !== undefined) ?
                    <AppCommentAndVoteVM 
                        userInfo={userInfo}
                        data={data}  /> : 
                    <></>}
                {this.state.isActive === true && this.state.keyword ?
                    <Cover>
                        <AppSeachPopUpVM keyword={this.state.keyword.replace(/#/gi, "")} language={language} onClickClose={this.onClickClose} />
                    </Cover> : <></>}

            </>
        );
    }

}

const Cover = styled.div``;

export default AppDetailVM;
