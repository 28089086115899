import React, { Component } from "react";

import PaginationView from 'components/view/common/Pagination';

class PaginationVM extends Component {

    constructor() {
        super();
        this.totalPage = 1000
    }

    render() {
        const {current, size, onClick} = this.props;
        var endPageNum = Math.ceil(current/size) * size;
        if (endPageNum > this.totalPage) {
            endPageNum = this.totalPage
        }
        return (
            <>
                <PaginationView 
                    data={{
                        totalPageNum: this.totalPage,
                        startPageNum: (current % size === 0 ? Number(Math.floor(Number(current - 1) / 10) * 10) + 1 : Number(Math.floor(current / 10) * 10) + 1),
                        endPageNum: (current % size === 0 ? current :  endPageNum ),
                        currentPageNum: current,
                        size: size
                    }} 
                    onClick={(data) => {
                        onClick(data)
                    }}/>
            </>
        );
    }

}

export default PaginationVM;
