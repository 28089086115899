import React, { Component } from "react";
import styled from "styled-components";

class GoogleMainAds extends Component {
    constructor() {
        super();
        try {
            (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
            if (process.env.NODE_ENV !== "production")
                console.error("AdvertiseError", e);
        }
    }

    render() {
        if (process.env.NODE_ENV !== "production"){
            return <>
                <div style={{width: "100%", background:"#ff9933"}}>광고 영역</div>
            </>
        }

        return (
            <ins className="adsbygoogle"
                 style={{display:"block"}}
                 data-ad-client="ca-pub-7055578690718076"
                 data-ad-slot="4126562980"
                 data-ad-format="auto"
                 data-full-width-responsive="true" />

        );
    }
}
export default GoogleMainAds;
