import React, { Component } from "react";
import styled from "styled-components";
import NoComments from "img/no_comments.svg";
import { COMMENT_TEXT } from "global/string"

class CommentNothing extends Component {
    
    constructor(){
        super();
    }
    
    render() {
        const {langCode} = this.props;
        return (
            <>
                <Container className="vflex-cc">
                    <img src={NoComments}></img>
                    <p>{COMMENT_TEXT[langCode].error.nodata}</p>
                </Container>
            </>
        );
    }

}
const Container = styled.div`
    img{
        width: 50px;
        height: 50px;
        margin: 45px 0 20px 0;
    }
    p{
        margin: 0 0 200px 0;
    }
`;



export default CommentNothing;
