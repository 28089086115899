import React, { Component } from "react";
import styled from "styled-components";
import { APP_POINT_TEXT } from "global/string";
import { addPoint, checkDuplicatePoint } from "api/UserAPI";

import SavePointView from "components/view/popup/app/savePoint"
import NewsVotePopUp from "components/view/popup/app/newsVotePopUp"

import BottomBar from "components/view/detail/bottombar/BottomBar"
import MainCommentVM from "components/view_model/comment/MainCommentVM"
import { getListOfArtistVoteLog, voteArtist } from "api/ArtistAPI";


class AppCommentAndVoteVM extends Component {

    constructor() {
        super();
        this.state = {
            
            isActive: false,
            
            isLoading: false,
            des: undefined,

            isDouble: false,
            isActivate: false

        }
    }

    componentDidMount() {
        this._checkDoubleVote()
    }

    _checkDoubleVote() {
        if (!this.state.isLoading) {
            this.setState({
                isLoading: true,
                des: APP_POINT_TEXT[this.props.userInfo.lang].loading.doubleCheck
            })
            
            getListOfArtistVoteLog({
                'token': this.props.userInfo.userToken,
                'post.ownership.idx': this.props.data.idx,
            }, this._afterCheckDuplicatePoint)

        }
    }

    _afterCheckDuplicatePoint = (json) => {
        if (json.code != 100) {
            return;
        }

        let list = json.resultData;
        if (list===undefined || list===null) {
            return;
        }

        
        var result = {
            isDouble: true,
            isLoading: false,
            des: undefined
        }

        if (list.length < 1) {
            result.isDouble = false
        }
        
        window.addEventListener('scroll', this.onScroll);

        this.setState(result)
    }


    onClickVotePopUp = (_result) => {
        this.setState({
            isActive: _result
        })
    }

    ConvertSystemHtmlEntity(str) {
        str = str.replace(/&nbsp;/gi, '	');
        str = str.replace(/&amp;/gi, '&');
        str = str.replace(/&lt;/gi, '<');
        str = str.replace(/&gt;/gi, '>');
        str = str.replace(/&quot;/gi, '"');
        str = str.replace(/&acute;/gi, '\'');
        str = str.replace(/&#124;/gi, '|');
        str = str.replace(/&#40;/gi, '(');
        str = str.replace(/&#41;/gi, ')');
        str = str.replace(/&#44;/gi, ',');
        str = str.replace(/&#45;/gi, '-');
        return str;
    }

    onClickVoteBtn = (_result) => {

        if (_result == undefined) {
            this.setState({
                isLoading: true,
                des: APP_POINT_TEXT[this.props.userInfo.lang].alertMsg.selectArtist
            })
            setTimeout(function () {
                this.setState({
                    isLoading: false,
                    des: undefined
                })
            }.bind(this), 1500)
            return
        }

        if (!this.state.isLoading) {
            this.setState({
                isLoading: true,
                des: APP_POINT_TEXT[this.props.userInfo.lang].loading.save
            })

            voteArtist({
                token: this.props.userInfo.userToken,
                language : this.props.userInfo.lang,
                post: {
                    ownership: {
                        idx: this.props.data.idx
                    },
                    meta: {
                        content: {
                            title : this.props.data.title
                        }
                    }
                },
                artist : {
                    idx: _result.idx,
                    title: _result.name
                }
            }, (json) => {
                
                if (json.code !== 100) {
                    this.setState({
                        isLoading: true,
                        des: APP_POINT_TEXT[this.props.userInfo.lang].alertMsg.serverError
                    })
                    return
                }

                if(!json){
                    this.setState({
                        isLoading: true,
                        des: APP_POINT_TEXT[this.props.userInfo.lang].alertMsg.serverError
                    })
                    setTimeout(function () {
                        this.setState({
                            isLoading: false,
                            des: undefined
                        })
                    }.bind(this), 1500)
                    return;
                }
                
                this.setState({
                    isLoading: true,
                    des: this.ConvertSystemHtmlEntity(_result.title) + APP_POINT_TEXT[this.props.userInfo.lang].loading.finish
                })
                setTimeout(function () {
                    this.setState({
                        langCode: this.props.userInfo.lang,
                        text: APP_POINT_TEXT[this.props.userInfo.lang].voteBtn.double,
                        isLoading: false,
                        des: undefined,
                        isActive: false,
                        isActivate: false,
                        isDouble: true
                    })
                }.bind(this), 1500)
            })

        }
    }

    pointClickCallback = () => {
        if(this.state.isDouble){
            alert(APP_POINT_TEXT[this.props.userInfo.lang].alertMsg.voteFinish)
            return;
        }
        
        if(!this.state.isActivate){
            alert(APP_POINT_TEXT[this.props.userInfo.lang].alertMsg.voteInActive)
            return;
        } 

        this.setState({
            isActive:true,
            langCode: this.props.userInfo.lang,
        })
        
    }


    onScroll = (e) => {
        if(this.state.isActivate){
            return;
        }
        
        const scrollTop = ('scroll', e.srcElement.scrollingElement.scrollTop);
        if (document.getElementById('comments').parentNode.children[0].clientHeight - scrollTop < 1000) {
            this.setState({
                isActivate: true
            })
        }
        
    }

    render() {
        const { userInfo, data } = this.props
        return (
            <>  
                <BottomBar language={userInfo.lang} pointClickCallback={this.pointClickCallback} listOfArtist={data.listOfArtist} idx={data.idx}></BottomBar>
                <CommentDiv id="comments">
                    <MainCommentVM
                        contentIdx={data.idx}
                        parentIdx={0}
                        token={userInfo.userToken}
                        langCode={userInfo.lang}></MainCommentVM>
                </CommentDiv>
                <BarBlock></BarBlock>

                {this.state.isActive !== false ?
                    <NewsVotePopUp
                        data={data}
                        language={userInfo.lang}
                        onClickCallBackClose={this.onClickVotePopUp}
                        onClickCallBack={this.onClickVoteBtn} />
                    : <></>}
                {this.state.isLoading !== false ?
                    <LoadingDiv>
                        <LoadText dangerouslySetInnerHTML={{ __html: this.state.des }}></LoadText>
                    </LoadingDiv>
                    : <></>}
            </>
        );
    }

}

const LoadText = styled.div``;
const LoadingDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    text-align: center;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0, .5);
    color: #fff;
    z-index: 15;

    display:flex;
    align-items: center;
    justify-content:center;
    line-height: 1.5;
    ${LoadText}{

    }
`;
const BarBlock = styled.div`
    height: 60px;
`;
const CommentDiv = styled.div`

`;

export default AppCommentAndVoteVM;
