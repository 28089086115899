
import { API, LOG } from "global/api";
import { convertJsonToQueryString } from "utils/UriUtils";

// 2023-03-31 (Checked)
export function getListOfRankArtist(params, successCallback){
    fetch(`${API}/v4/artist/rank${convertJsonToQueryString(params)}`)
        .then(response => response.json())
        .then(successCallback);
}

export function getDashboardOfArtist(params, successCallback) {    
    fetch(`${API}/v4/artist/dashboard${convertJsonToQueryString(params)}`)
        .then(response => response.json())
        .then(successCallback);
}


export function getListOfArtistVoteLog(params, successCallback) {    
    let token = params['token']
    delete params['token']
    
    fetch(`${LOG}/v4/artist/vote${convertJsonToQueryString(params)}`, {
        method: 'GET',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*",
            'Authorization': 'Bearer '+ token
        },
    })
        .then(response => response.json())
        .then(successCallback);
}

export function voteArtist(params, successCallback) {    
    let token = params['token']
    delete params['token']
    
    fetch(`${LOG}/v4/artist/vote`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*",
            'Authorization': 'Bearer '+ token
        },
        body: JSON.stringify(params)
    })
    .then(response => response.json())
    .then(successCallback)

}

