import React, { Component } from "react";
import styled from "styled-components";
import { COMMENT_TEXT } from "global/string";

class CommentCount extends Component {

    render() {
        const { value, langCode } = this.props;
        return (
            <>
                <Container>
                    <p>{COMMENT_TEXT[langCode].ui.title} (<span>{value}</span>)</p>
                </Container>
            </>
        );
    }

}
const Container = styled.div`
    p{
        padding: 6px;
        font-size: 14px;
        span{
            font-weight: bold
        }
    }
`;




export default CommentCount;
