import React, { Component } from "react";
import styled from "styled-components";
import { getQueryParameter } from "utils/UriUtils";

class LatestArticleView extends Component {

    onClick = (_data) => {
        var link = window.location.origin + _data.link;
        if (window.location.pathname.indexOf('app') > 0) {
            link = 'whosfanglobal://open?url=' + encodeURIComponent(window.location.origin+`/app/${_data.language.toLowerCase()}?fc=${_data.idx}`)
        }
        window.location.href = link;
    }

    render() {
        return (
            <>
                <ArticleDiv onClick={this.onClick.bind(this, this.props.data)}>
                    <ThumbnailDiv>
                        <Thumbnail src={this.props.data.thumbnail}></Thumbnail>
                    </ThumbnailDiv>
                    <ContentsDiv>
                        <Title dangerouslySetInnerHTML={{ __html: this.props.data.title }}></Title>
                        <Contents>{this.props.data.content}</Contents>
                    </ContentsDiv>
                </ArticleDiv>
            </>
        );
    }

}


const Title = styled.div``;
const Contents = styled.div``;
const ContentsDiv = styled.div``;

const Thumbnail = styled.img``;
const ThumbnailDiv = styled.div``;
const ArticleDiv = styled.a`
    border-bottom: 1px solid #DCDCDC;
    margin: 14px 0 0 0;
    padding: 0 0 7px 0;
    cursor: pointer;
    display: inline-flex;
    align-items: center;

    ${ThumbnailDiv}{
        width: 42%;
        min-width: 42%;
        max-width: 42%;
        ${Thumbnail}{
            width: 100%;
            height: auto;
        }
    }
    ${ContentsDiv}{
        margin-left: 13px;
        ${Title}{
            line-height: 1.5;
            font-size: 13px;
            font-weight: bolder;
            margin: 0 0 5px 0;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: 'Lato';
        }
        ${Contents}{
            color: #000000;
            opacity: 0.5;
            font-size: 10px;
            font-weight: normal;
            margin: 0 0 0px 0px;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 1.2em;
            height: 1.2em;
        }
    }
`;


export default LatestArticleView;