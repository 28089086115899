import React, { Component } from "react";
import styled from "styled-components";
import DownIcon from "img/icon_down.svg";
import UpIcon from "img/icon_up.svg";
import SettingIcon from "img/settings.svg";
import ProfileIcon from "img/icon_profile.svg";
import Spinner from "img/spinner.svg";

import { deleteComment } from "api/CommentAPI";

import LikeCommentVM from "components/view_model/comment/LikeCommentVM"
import { COMMENT_TEXT } from "global/string"
import { isMobile, isAndroid, isIOS, isConsole } from 'react-device-detect';

class CommentItem extends Component {
    
    constructor(){
        super();
        this.state = {
            isExpand: false,
            isDeleted: false
        }
        this.wordlength = 300
    }
    onExpandClick = (e) => {
        this.setState({
            isExpand:!this.state.isExpand
        })
    }
    onProfileError = (e) => {
        e.target.src = ProfileIcon;
    }
    onSettingClick = (e) => {
        let setting = e.currentTarget.children[1];
        if(setting.classList.contains('show')){
            setting.classList.remove('show');
        }else{
            setting.classList.add('show');
        }
        
    }
    onReCommentClick = (e) => {
        var scheme = "whosfanglobal"
        if(isIOS){
            scheme = "whosfan"
        }
        let link = scheme+'://open?showHeader=0&url=' + encodeURIComponent(window.location.origin+'/app/' + this.props.langCode + '?fc=' + this.props.contentIdx+'&comment='+this.props.comment.idx);
        window.location.href = link;
    }
    onDeleteClick = (e) => {
        e.stopPropagation();
        let _this = this;
        let setting = e.currentTarget.parentNode;

        if (setting.classList.contains('loading')){
            return;
        }
        
        if(!window.confirm(COMMENT_TEXT[this.props.langCode].alert.deleteMsg)){
            setting.classList.remove('show')
            return;
        }
        setting.classList.add('loading')
        deleteComment({
            contentIdx: this.props.contentIdx,
            parentIdx: this.props.parentIdx,
            commentIdx: this.props.comment.idx,
            token: this.props.token
        }, function(json){
            setting.classList.remove('show')
            setting.classList.remove('loading')

            if(json.code!==100){
                return;
            }
            if(_this.props.deleteCallback===undefined || _this.props.itemIndex===undefined){
                return;
            }
            
            _this.props.deleteCallback(_this.props.itemIndex)
        }, function(error){
            setting.classList.remove('show')
            setting.classList.remove('loading')
        })


    }
    onReportClick = (e) => {
        e.stopPropagation();
        let setting = e.currentTarget.parentNode;
        if(!window.confirm(COMMENT_TEXT[this.props.langCode].alert.reportMsg)){
            setting.classList.remove('show')
            return;
        }

        alert(COMMENT_TEXT[this.props.langCode].alert.reportDoneMsg);
    }

    render() {
        const { itemIndex, contentIdx, parentIdx, comment, mylikes, langCode, isParent, token, deleteCallback, likeCallback} = this.props;
        
        return (
            <>
            {this.state.isDeleted?<></>:
                <Container className={"hflex-lefttop"+(isParent?" line":"")}>
                    <LeftBox>
                        <ThumbnailDiv>
                            <img alt="thumbnail" src={comment.user.profileUrl} onError={this.onProfileError}></img>
                        </ThumbnailDiv>
                    </LeftBox>
                    <RightBox>
                        <NameSetting className="hflex-sbc">
                            <Name>{comment.user.nickname}</Name>
                            <Setting onClick={this.onSettingClick}>
                                {!isParent && comment.mine?
                                <>
                                    <img alt="settings" src={SettingIcon}></img>
                                    <SettingBox>
                                        <Delete onClick={this.onDeleteClick}>
                                            <p>{COMMENT_TEXT[langCode].ui.deleteBtn}</p>
                                            <img src={Spinner}></img>
                                        </Delete>
                                    </SettingBox>
                                </>:
                                <>
                                    <img alt="settings" src={SettingIcon}></img>
                                    <SettingBox>
                                        <Delete onClick={this.onReportClick}>
                                            <p>{COMMENT_TEXT[langCode].ui.reportBtn}</p>
                                            <img src={Spinner}></img>
                                        </Delete>
                                    </SettingBox>
                                </>
                                }
                            </Setting>
                        </NameSetting>
                        <DateDiv>
                            {comment.regDate}
                        </DateDiv>
                        <ContentsDiv>
                            {this.state.isExpand?
                                (<>
                                    {comment.content}
                                    <ExpandDiv className="hflex-cc expanded" onClick={this.onExpandClick}>
                                        <img alt="unexpand" src={UpIcon}></img>
                                    </ExpandDiv>
                                </>)
                            :
                                (comment.content.length>this.wordlength?
                                    <>
                                        {comment.content.substr(0, this.wordlength)}
                                        <ExpandDiv className="hflex-cc" onClick={this.onExpandClick}>
                                            <img alt="expand" src={DownIcon}></img>
                                        </ExpandDiv>
                                    </>:<>{comment.content}</>)}
                        </ContentsDiv>
                        {isParent?<></>:
                        
                        <CommentLike className="hflex-sbc">
                            {parentIdx===0?
                                <CommentCount onClick={this.onReCommentClick}>
                                    {COMMENT_TEXT[langCode].ui.recommentBtn}({comment.commentCount})
                                </CommentCount>:
                                <BlankBox></BlankBox>
                            }
                            
                            <LikeCommentVM
                                value={comment.likeCount}
                                isLike={mylikes.indexOf(String(comment.idx))<0?false:true}
                                contentIdx={contentIdx}
                                parentIdx={parentIdx}
                                commentIdx={comment.idx}
                                token={token}
                                itemIndex={itemIndex}
                                likeCallback={likeCallback}
                                ></LikeCommentVM>
                        </CommentLike>}
                    </RightBox>
                </Container>
                }
            </>
        );
    }

}
const BlankBox = styled.div``;
const CommentCount = styled.div``;

const Delete = styled.div``;
const SettingBox = styled.div``;
const Setting = styled.div``;
const Name = styled.div``;

const CommentLike = styled.div``;
const ExpandDiv = styled.div``;
const ContentsDiv = styled.div``;
const DateDiv = styled.div``;
const NameSetting = styled.div``;
const RightBox = styled.div``;

const ThumbnailDiv = styled.div``;
const LeftBox = styled.div``;

const Container = styled.div`
    border-bottom: 1px solid #E2E2E2;
    &.line{       
        border-bottom: none;
    }
    margin: 20px 0 0 0;
    padding: 0 0 15px 0;

    ${LeftBox}{
        ${ThumbnailDiv}{
            margin: 0 10px 0 4px;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            overflow: hidden;
            border: 1px solid #707070;
            
            img{
                width: 100%;
                height: 100%;
            }
        }
    }
    ${RightBox}{
        width: 100%;
        ${NameSetting}{
            ${Name}{
                font-size: 16px;
                font-weight: bold;
                margin: 0 0 4px 0;
            }
            ${Setting}{
                position:relative;
                img {
                    width: 20px;
                    height: 20px;
                }
                ${SettingBox}{
                    display: none;
                    position:absolute;
                    bottom: -35px;
                    right: 10px;
                    border: 1px solid #E2E2E2;
                    border-radius: 5px;
                    white-space: nowrap;
                    background: #ffffff;
                    z-index: 1;
                    
                    &.show{
                        display: block;
                    }
                    ${Delete}{
                        padding: 8px 10px;
                        
                        img{
                            display: none;
                        }
                    }
                    &.loading{
                        ${Delete}{
                            p{
                                display: none;
                            }
                            img{
                                display: block;
                            }
                        }
                    }
                }
            }
        }
        ${DateDiv}{
            color: #AAAAAA;
            font-size: 12px;
            font-weight: normal;
        }
        ${ContentsDiv}{
            font-size: 16px;
            margin: 10px 5px 0 0;
            line-height: 20px;
            position: relative;
            word-break: break-all;

            ${ExpandDiv}{
                position: absolute;
                bottom: 0;
                left:0;
                right:0;
                background: linear-gradient(0, white, 60%, transparent);
                padding: 12px 0 0 0;
                img{
                    width: 15px;
                    height: 15px;
                }

                &.expanded{
                    position: relative;
                    background: transparent;
                    padding: 6px 0 0 0;
                }
            }
        }
        ${CommentLike}{
            margin: 10px 5px 0 0;
            ${CommentCount}{
                color: #FC5BA8;
                font-size: 14px;
            }
            ${BlankBox}{
                width: 1px;
            }

        }
    }
`;


export default CommentItem;
