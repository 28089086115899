export function getQueryParameters(_location){
    var result = {}
    if(_location===undefined || _location==='')
        return result;

    var search = _location.search
    if(search.length>1 && search[0]==='?'){
        search = search.substring(1)

        var searchArr = search.split("&")
        searchArr.forEach(element => {
            var map = element.split('=')
            if(result[map[0]]===undefined)
                result[map[0]] = decodeURIComponent(map[1])
        });
    }

    return result
}

export function getQueryParameter(_key, _location) {
    var result = undefined;
    var _search = _location.search;

    if (_search.length<=0){
        return result;
    }

    _search = _search.substring(1);
    _search = _search.split('&');
    _search.map((_param, _index) => {
        if (_param !== undefined && _param !== '') {
            var _arr = _param.split('=')
            if (_key === _arr[0]) {
                result = _arr[1];
            }
        }
        return true;
    })
    return result;
}

export function getPathArr(_pathname){
    if(_pathname===undefined || _pathname===null){
        return []
    }

    let _arr = _pathname.split('/')
    if(_arr.length>1){
        return _arr.slice(1)
    }
    
    return []
}


// 1차 depth 만 구현
export function convertJsonToQueryString(_json){
    if (_json===undefined || _json == null) {
        console.error("Undefined Object")
        return null;
    }

    if (typeof _json !== "object"){
        console.error("None Object")
        return null
    }

    var queryString = '';
    let keys = Object.keys(_json);
    for(var i = 0; i < keys.length; i++) {
        let key = keys[i]
        let value = _json[key]
        if ( value === undefined || value === null ) {
            console.error(`Object child value is undefined or null : ${key}`)
            continue;
        }
        if ( typeof value === "object" ) {
            console.error("Object Child cannot convert to query string")
            return queryString;
        }

        queryString += `${(queryString===''?'?':'&')}${key}=${value}`
    }

    return queryString

}