import React, { Component } from "react";
import styled from "styled-components";

class Language extends Component {

  render() {
    const { languages, current } = this.props
    return (
        <Container>
          {languages.map((el, i) => {
            return <a key={i} href={el.path} className={el.code===current ? "active": ""}>
              {el.title}
            </a>
          })}
        </Container>
    );
  }

}
const Container = styled.div`
  display: none;
  
  @media (min-width: 1048px){
    display: block;
  }
  a {
    text-decoration: none;
    margin: 0 12px 0 12px;
    font-size: 12px;
  }
  .active{
      color: #DA3A6C;
  }
`;


export default Language;
