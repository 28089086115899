import React, { Component } from "react";
import styled from "styled-components";
import {Dropdown} from "react-bootstrap";

class SideLanguage extends Component {

  render() {
    const { languages, current } = this.props
    return (
        languages ?
          <Container>
            <Dropdown
              className={"side-lang-container"}>
              <Dropdown.Toggle
                  className={"side-lang-header"}
                  variant="secondary" id="dropdown-basic">
                Language
              </Dropdown.Toggle>

              <Dropdown.Menu
                className={"side-lang-menu"}>
                {languages.map((el, i) => {
                  return <Dropdown.Item key={i} href={el.path} className={current===el.code?"active":""}>
                    {el.title}
                  </Dropdown.Item>

                })}
              </Dropdown.Menu>
            </Dropdown>

          </Container>
        :<></>
    );
  }

}
const Container = styled.div`
  margin: 0 10px 0 0;
  .side-lang-container{
    display: inline-block;
    color: #333;
    float: right;
    margin: 21px -5px 0 0px;
    
    .side-lang-header{
      display: inline-block;
      
      color: #212529;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      background-color: transparent;
      border: none;
      border-radius: 0.25rem;
    
      cursor: pointer;
      font-size: 12px;
      
      padding: 0.375rem 0.75rem;
      line-height: 1.5;
      
      white-space: nowrap;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    
    }
    .side-lang-menu{
      width: 100%;
      font-size:13px;
      text-align: center;
      min-width: auto;
      
      .active{
        color: #dd3a6c;
        background-color: #fff;
      }
    }
  }
`;

export default SideLanguage;
