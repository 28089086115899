import React, { Component } from "react";
import styled from "styled-components";
import ProtectionView from "components/view/company/Protection"

class Protection extends Component {

  render() {
    const { language } = this.props;
    return (
      <>
        <MainDiv>
          <Container>
            <ProtectionView language={language} />
          </Container>
        </MainDiv>
      </>
    );
  }

}

const Container = styled.div``;
const MainDiv = styled.div`
  font-family: 'Lato';
  ${Container}{
    border-radius: 2px;
    -webkit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.10);
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.10);
    margin: 50px 15px;
    padding: 30px 0;

  }
`;

export default Protection;
