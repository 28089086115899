import React, { Component } from "react";
import styled from "styled-components";
import {getSearchArticle} from "../../../../api/NewsAPI";
import {getLanguageWithPath} from "../../../../utils/LanguageUtils";
import {SEARCH_ERROR_MSG, SEARCH_PLACE_HOLDER} from "../../../../global/string";

class SearchHeader extends Component {

    constructor() {
        super();
        this.state = {
            show: false
        }
    }
    search() {
        let divs = document.getElementsByClassName(SearchInput.styledComponentId);
        let keyword = divs[0].value;
        if(keyword===undefined || keyword===null || keyword===''){
            alert(SEARCH_ERROR_MSG[this.props.language])
            return;
        }
        let startPage = 1;
        let order = 0;
        window.location.href = `/${this.props.language.toLowerCase()}/search?keyword=${keyword}&startPage=${startPage}&order=${order}`;
    }
    searchClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        this.search();
    }
    inputBoxClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }
    searchInput = (e) => {
        e.stopPropagation();
        e.preventDefault();
        if(e.key === 'Enter') {
            this.search();
        }
    }
    showBox = (e) => {
        e.stopPropagation();
        e.preventDefault();

        let isShow = this.state.show;
        if(!isShow){
            document.body.onclick = () => {
                document.body.onclick = undefined;
                this.setState({show: false})
            }
        }

        this.setState({show: !this.state.show})
    }
  render() {
    const {language} = this.props;
    return (
        <>
            <SearchIcon onClick={this.showBox} >
                <img src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/search_bolder.svg" />
            </SearchIcon>

            <SearchBox className={this.state.show ? "show":""} >
                <SearchContainer>
                    <SearchInput placeholder={SEARCH_PLACE_HOLDER[language]} onClick={ this.inputBoxClick } onKeyUp={this.searchInput}/>
                    <SearchIconR onClick={ this.searchClick }>
                        <img src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/search_bolder.svg" />
                    </SearchIconR>
                </SearchContainer>
            </SearchBox>
        </>
    );
  }

}
const SearchIconR = styled.div``;
const SearchInput = styled.input``;
const SearchContainer = styled.div``;
const SearchBox = styled.div`
    display: none;
    width: 100%;
    background-color: #F5F5F5;
    // position: absolute;
    
    @media (min-width: 1048px){
        display: none;
        position: absolute;
        left: calc(50% + 269px);
        margin-top: 0px;
        height: unset;
        width: unset;
        background-color: #fff;
        box-shadow: 0px 3px 6px #bdbdbd;
        z-index: 10;
    }
    
    &.show{
        display: block;
    }
    
    
    ${SearchContainer}{
        display: inline-flex;
        align-self: center;
        
        ${SearchInput}{
            margin: 12px;
            padding: 3px;
            width: -webkit-fill-available;
            border: none;
            background-color: #fff;
            width: calc(100vw - 24px - 40px);
            
            @media (min-width: 1048px){
                border: none;
                width: 200px;
                height: 36px;
                padding: 0 0 0 8px;
                margin: 0;
                font-size: 14px;
            }
        }
        ${SearchIconR}{
            position: relative;
            margin-right: 4px;
            align-self: center;
            
            img{
                margin: 2px 6px 1px 6px;
                cursor: pointer;
                width: auto;
                height: auto;
            }
        }
    }

`;
const SearchIcon = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    width: 35px;
    height: 100%;
    max-height: 30px;
    z-index: 19;
    min-width: 35px;
    max-width: 35px;
    background-color: white;
    margin: 0 0 0 10px;
    
    
    &.relative{
        position: relative;
    }
    
    @media(min-width: 1048px){
        z-index: unset;
        position: absolute;
        left: calc(50% + 468px);
        width: unset;
        height: unset;
        top: 8px;
        margin: 0px;
    }
    
    img{
        display: block;
        padding: 0px 9px 0px 9px;
        width: 100%;
        border-left: 1px solid #e8e7e7;
        
        @media(min-width: 1048px){
            padding: 7px;
            margin: 0px;
            border: none;
        }
    }
    
    
    
`;

export default SearchHeader;
