import React, { Component } from "react";
import styled from "styled-components";
import YouTube from "react-youtube";
import { APP_DEEP_LINK, WHOSFAN_BANNER } from "global/string";
import GoogleBannerAds from "../ads/GoogleBannerAds";
class App extends Component {

    constructor() {
        super();
    }

    _makeDetailCopy(langCode) {
        switch (langCode) {
            case 'KO':
                return '<ⓒ \'한터뉴스\' 무단전재 및 재배포 금지 >';
            case 'ES':
                return '<ⓒ \'HANTEO NEWS(NOTICIAS HANTEO)\' La reproducción y distribución de esta publicación está estrictamente prohibido sin autorización.>';
            default:
                return '<ⓒ \'HanteoNews\' Reproduction and distribution of this publication is strictly prohibited without permission >';
        }
    }

    renderContents(contents) {
        setTimeout(() => {

            function addScript(src) {
                var my_awesome_script = document.createElement('script');
                my_awesome_script.setAttribute('src', src);
                document.head.appendChild(my_awesome_script);
            }

            addScript("//www.instagram.com/embed.js")
            addScript("https://platform.twitter.com/widgets.js")
        }, 1)


        setTimeout(() => {
            if (document.getElementsByClassName('gliaplayer-container').length > 0) {
                return;
            }

            let container = document.getElementById("detail-content");
            let listP = container.getElementsByTagName("p");
            var count = 0; // 처음 빈값을 제외하고, 3번째, 즉 4번째
            var pDom = undefined;
            for(var i = 0; i < listP.length; i++) {
                let _p = listP[i]
                if (_p.innerText.trim() !== "") {
                    continue;
                }

                count++;
                if (count === 4) {
                    pDom = _p;
                    break;
                }
            }

            if (pDom===undefined) {
                return;
            }

            let video = document.createElement('div');
            video.className = 'gliaplayer-container';
            video.dataset.slot = `adforus_hanteonews_${document.body.offsetWidth < 1048? 'mobile': 'desktop'}`;

            let _script = document.createElement('script');
            _script.async = true;
            _script.src = `https://player.gliacloud.com/player/adforus_hanteonews_${document.body.offsetWidth < 1048? 'mobile': 'desktop'}`

            pDom.before(video)

            pDom.before(_script)

        }, 200);

        return <div id="detail-content" key={0} dangerouslySetInnerHTML={{ __html: contents }}></div>;
    }


    renderWhosfanBanner(listOfArtist) {
        if (listOfArtist===undefined || listOfArtist===null || listOfArtist.length===0) {

            return <>
            <BannerDiv className="article-artist-banner-div">
                <a className="artist-article-banner hflex-stc" href="#" onClick={this._onBannerClick}>
                    <div className="hflex-stc artist-banner-box">
                        <img className="banner-logo-img" src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/banner/banner_logo.png" />
                        <div className="banner-inner-text">
                            {WHOSFAN_BANNER[this.props.language].basic}
                        </div>
                    </div>
                </a>
            </BannerDiv>
            </>
        }

        let artists = listOfArtist.filter((artist) => artist.isDefault===1);
        if (artists.length!==1) return <>
            <BannerDiv className="article-artist-banner-div">
                <a className="artist-article-banner hflex-stc" href="#" onClick={this._onBannerClick}>
                    <div className="hflex-stc artist-banner-box">
                        <img className="banner-logo-img" src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/banner/banner_logo.png" />
                        <div className="banner-inner-text">
                            {WHOSFAN_BANNER[this.props.language].basic}
                        </div>
                    </div>
                </a>
            </BannerDiv>
        </>

        let artist = artists[0];
        return  <BannerDiv className="article-artist-banner-div">
                    <a className="artist-article-banner hflex-stc" href="#" onClick={this._onBannerClick}>
                        <div className="hflex-stc artist-banner-box">
                            <img className="banner-logo-img" src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/banner/banner_logo.png" />
                            <div className="banner-inner-text" dangerouslySetInnerHTML={{ __html: WHOSFAN_BANNER[this.props.language].artist.replaceAll('%@', artist.title)}}></div>
                        </div>
                    </a>
                </BannerDiv>
                            
    }
    
    _onBannerClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        var app = null;
        var appName = null;
        var params = null;
        var os = undefined;
        var browser = undefined;

        var uagent = window.navigator.userAgent.toLowerCase();

        if (uagent.search("android") > -1) {
            os = "android";
            if (uagent.search("chrome") > -1) {
                browser = "android+chrome";
            }
        } else if (uagent.search("iphone") > -1 || uagent.search("ipod") > -1 || uagent.search("ipad") > -1) {
            os = "ios";
        }

        const url = document.getElementsByClassName(BannerDiv.styledComponentId)[0].dataset.url
        if (os === "ios") {

            var goaitapp = window.open(url);
            setTimeout(function () {
                goaitapp.location.href = APP_DEEP_LINK.app.star.store.ios;
            }, 1000);
            window.addEventListener('pagehide', function (timer) {
                return function () {
                    clearTimeout(timer);
                    window.removeEventListener('pagehide', arguments.callee);
                };
            });

            return
        }


        appName = url.split('/')[2];
        params = url.split('/')[3];
        app = APP_DEEP_LINK.app[appName];

        var baseUrl = app.base;

        if (os === "android") {

            if (appName === 'star') {
                baseUrl = baseUrl.android + params;
            }
            if (browser === "android+chrome") {
                window.open("intent:" + baseUrl + "#Intent;package=" + app.package + ";end;");
            } else {
                window.open(baseUrl);
            }
        } else {
            baseUrl = app.web;
            window.location = baseUrl;
        }
    }


    render() {
        const {language, data, onClickCallBack} = this.props;
        return (
            <>
                <AppDetailContainer>
                    <DetailContainer>
                        <HeaderContainer>
                            <Title dangerouslySetInnerHTML={{ __html: data.title }}></Title>
                        </HeaderContainer>
                        <ContentsContainer>
                            <DetailDiv>
                                <ContentsDiv>{this.renderContents(data.content)}</ContentsDiv>
                                <ReportContainer>
                                    <ReporterDiv>
                                        {data.writeUser.badge? 
                                        <ReporterImg src={`https://s3.ap-northeast-2.amazonaws.com/hanteoresource${data.writeUser.badge}`}></ReporterImg>
                                        :<></>}
                                        <ReporterName>{data.writeUser.name} {language === 'KO' ? '기자' : ''}</ReporterName>
                                        {'\u00A0'}
                                        <ReporterEmail>{data.writeUser.email}</ReporterEmail>
                                    </ReporterDiv>
                                    <PublicTime>{language === 'KO' ? '입력 :' : ''} {data.startDate}</PublicTime>
                                </ReportContainer>
                                <ExplainDiv>
                                    <ExplainATag dangerouslySetInnerHTML={{ __html: this._makeDetailCopy(language) }}></ExplainATag>
                                </ExplainDiv>
                                <HashTagContainer>
                                    {data.listOfTag ? data.listOfTag.map((tag, i) =>
                                        <HashTagDiv
                                            key={i}
                                            onClick={onClickCallBack.bind(this, tag)}>
                                            <HashTag>{tag.title}</HashTag>
                                        </HashTagDiv>
                                    ) : <></>}
                                </HashTagContainer>
                                {data?
                                    this.renderWhosfanBanner(data.listOfArtist)
                                :<></>}

                                <GoogleBannerAds
                                    id={"content-bottom-mobile-ad"}
                                    adId={"div-gpt-ad-202407231234365-675"}
                                    adType={"static"}
                                    slot={{
                                        id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_MWEB_article_bottom_300x250",
                                        size: "[300,250]"
                                    }} />
                                : <></>
                            </DetailDiv>
                        </ContentsContainer>
                    </DetailContainer>
                </AppDetailContainer>
            </>
        );
    }
}

const YoutubeDiv = styled.div``;
const Title = styled.p``;
const DetailDiv = styled.div``;
const ReporterDiv = styled.div``;
const ReporterImg = styled.img``;
const ReporterName = styled.p``;
const ReporterEmail = styled.p``;
const PublicTime = styled.p``;
const ExplainATag = styled.a``;
const HashTagDiv = styled.a``;
const HashTag = styled.a``;
const DetailContainer = styled.div``;
const ContentsDiv = styled.div``;
const BannerDiv = styled.div`
    margin: 15px;   
    @media (min-width: 1048px){
        margin: 15px 0px;
    }

    &.article-artist-banner-div{
        .artist-article-banner{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background-image: url(https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/banner/banner_background_mobile.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 141px;
            overflow: hidden;
            border-radius: 10px;
            text-decoration: none;

            @media (min-width: 1048px){
                background-image: url(https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/banner/banner_background_pc.jpg);
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                height: 111px;
            }

            .artist-banner-box{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                height: 100%;

                @media (min-width: 1048px){
                    width: 100%;
                }


                .banner-logo-img{
                    width: auto;
                    height: 46%;
                    padding-bottom: 5px;
                    margin: 0 12px 0 15px;
                    @media (min-width: 1048px){
                        height: 54%;
                        padding-bottom: 5px;
                        margin: 0 15px 0 15px;
                    }
                }
                .banner-inner-text{
                    background: transparent;
                    color: white;
                    font-size: 15px;
                    font-weight: 600;
                    margin: 1px 8px 0 3px;
                    word-break: keep-all;
                    line-height: 1.5;
                    @media (min-width: 1048px){
                        background: transparent;
                        color: #ffffff;
                        font-size: 20px;
                        margin: 0 15px 0 4px;
                    }
                }
            }
        }
    }
`;

const ReportContainer = styled.div`
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 14px 15px 0 15px;
    
    @media (min-width:1048px) {
        flex-direction: row;
    }

    ${ReporterDiv}{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        white-space: nowrap;

        ${ReporterImg}{
            height: 16px;
            width: auto;
            margin: 0 3px 0 0;
        }
        ${ReporterName}, ${ReporterEmail}{

        } 
    }
    ${PublicTime}{
        font-size: 12px;
        margin: 2px 13px 0 0;
        color: #999999;
        font-family: NanumSquare;
        font-weight: 200;
    }
`;

const ExplainDiv = styled.div`
    margin: 26px 15px 0 15px;
`;


const HeaderContainer = styled.div`
    display: block;

    @media (min-width:1048px){
        width: 1048px;
        margin: 0 auto;
    }

    ${Title}{
        font-size: 20px;
        margin: 23px 15px 5px 15px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 1.5;

        @media (min-width:1048px) {
            font-size: 32px;
            margin: 60px 0 17px 0;
        }
    }
`;

const HashTagContainer = styled.div`
    margin: 20px 15px 20px 15px;
    
    @media (min-width:1048px){
        margin: 20px 0;
    }
    
    ${HashTagDiv}{
        display: inline-block;
        border: 1px solid #EEEEEE;
        text-align: center;
        padding: 6px 16px 5px 16px;
        font-size: 12px;
        font-family: NanumSquare, sans-serif;
        font-weight: 400;
        border-radius: 15px;
        margin: 3px 10px 3px 0;
        line-height: 1.5;
        color: #212529;
        cursor: pointer;

        :hover{
            background-color: #ec407a;
            color: #fff;
        }
    }

`;

const ContentsContainer = styled.div`
    display: block;
    overflow: hidden;

    ${DetailDiv}{
        width: 100%;
        line-height: 25px;

        ${ContentsDiv}{

            margin: 20px 15px;
            letter-spacing: 1.5px;
            line-height: 24px;
    
            font-family: 'Lato'!important;
            font-weight: 400;
            font-size: 14px;
            line-height: 24px;
            @media (min-width:1048px) {
                margin: 0 30px 0 0;
                letter-spacing: 1px;
            }
    
            figure{
                margin: 0 0 1rem;
            }
            figcaption{
                color: #adadad;
                font-size: 13px;
                margin: 6px 0 0 10px;
            }
            strong {
                font-weight: bolder;
            }
            em {
                font-style: italic;
            }
            u {
                text-decoration: underline;
            }
            s {
                text-decoration: line-through;
            }


            

            .youtube-wrapper{
                position: relative;
                width: 100%;
                padding-top: 56.25%;

                .youtube-video{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                }
            
            }
        }

    }
`;

const AppDetailContainer = styled.div``;

export default App;
