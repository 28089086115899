import React, { Component } from "react";
import styled from "styled-components";
import { MENU_CONST, WEB_MAIN_STR } from "global/string";

import MostArticleView from 'components/view/article/MostArticleView';
import { getMostArticle } from "api/NewsAPI";
import {ConvertSystemHtmlChartoSource} from "../../../utils/StringUtils";
import { getListOfRankPost } from "api/PostAPI";


class AdBannerAndMostArticleVM extends Component {

    constructor() {
        super();
        this.state = {
            list: undefined
        }
    }

    componentDidMount() {

        getListOfRankPost({
            offset: 0,
            limit: 4,
            order: "DESC",
            clientIdx: 1022,
            language: this.props.language,
        }, (res) => {
            var data = res.resultData;
            var list = [];

            data.map((post, i) => {
                var category = MENU_CONST.allCategory[post.meta.language].title;
                list.push({
                    idx: post.ownership.idx,
                    thumbnail: post.meta.content.thumbnail,
                    title: ConvertSystemHtmlChartoSource(post.meta.content.title),
                    content: ConvertSystemHtmlChartoSource(post.meta.content.seo),
                    date: post.publish.startDate,
                    language: post.meta.language,
                    link: `/${post.meta.language.toLowerCase()}/article/${category.toLowerCase()}?fc=${post.ownership.idx}`
                })
                return true;
            })

            
            this.setState({
                data: list
            })
        
        })

    }


    onArticleClickEvent(_result) {
        window.location.href = window.origin + _result.link;
    }

    render() {
        const {language} = this.props;
        return (
            <>
                {language ?
                    <AdBannerAndMostArticleContainer>
                        <MostViewDiv>
                            <MostViewTitle>{WEB_MAIN_STR[language].title.most}</MostViewTitle>
                            <LineDiv></LineDiv>
                            <MostArticleDiv>
                                {this.state.data ? (
                                    this.state.data.map((post, i) => {
                                        return <MostArticleContainer
                                            key={post.idx}>
                                            <MostArticleView
                                                data={post}
                                                onClickCallBack={this.onArticleClickEvent}>
                                            </MostArticleView>
                                        </MostArticleContainer>
                                    })
                                ) : (<></>)}
                            </MostArticleDiv>
                        </MostViewDiv>
                    </AdBannerAndMostArticleContainer>
                    : <></>}
            </>
        );
    }
}

const MostArticleContainer = styled.div``;
const MostArticleDiv = styled.div``;
const LineDiv = styled.div``;
const MostViewTitle = styled.div``;
const MostViewDiv = styled.div``;
const BannerContainer = styled.div``;
const BannerDiv = styled.div``;
const AdBannerAndMostArticleContainer = styled.div`
    @media (min-width: 1048px){
      margin: 0 0 0 20px;
    }

    ${MostViewDiv}{
        margin: 20px 0 0 0;
        @media (min-width:1048px) {
            margin: 23px 0 0 0;
        }
        ${MostViewTitle}{
            font-size: 18px;
            margin: 0 7px;
            color: #111111;
            line-height: 1.6;
            font-weight: 600;
            @media (min-width:1048px) {
                font-size: 16px;
            }
        }
        ${LineDiv}{

            height: 1px;
            background-color: #7D7D7D;
            margin: 0;
            margin: 7px 0 0 0; 
            
            @media (min-width:1048px) {
                width: 100%;
                height: 1px;
                background-color: #E4E4E4;       
                margin: 8px 0 0 0; 
            }
        }
        ${MostArticleDiv}{
            margin: 0px 10px 25px 10px;
            
            @media (min-width:1048px) {
                margin: 0px;
            }
            ${MostArticleContainer}{
                border-bottom: 1px solid #e8e7e7;
            }
        }
    }
`;

export default AdBannerAndMostArticleVM;
