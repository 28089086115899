import React, { Component } from "react";
import { MENU_CONST } from "global/string";

import DetailPageVM from 'components/view_model/detail/DetailPageVM';
import ListPageVM from 'components/view_model/list/ListPageVM';
import styled from "styled-components";
import { getQueryParameter } from "utils/UriUtils";
import GoogleBannerAds from "../../components/view/ads/GoogleBannerAds";

class WebContents extends Component {

  constructor() {
    super();
    this.contentIdx = getQueryParameter('fc', window.location);
  }

  getHeaderInfo(header, listOfHeader){
    
    if (header === 'all' || header === 'todos') {
      let category = MENU_CONST.allCategory[this.props.language];
      category['listOfChild'] = listOfHeader
      return category;
    }

    if (header === 'latest' ) {
      let category = MENU_CONST.latestCategory[this.props.language];
      return category;
    }

    let _list = listOfHeader.filter((_header) => {
      return _header.title.toLowerCase()===header.toLowerCase()
    })

    if (_list.length!==1) return undefined;
    
    return _list[0]
  }
  render() {
    const { language, header, listOfHeader, contentIdx } = this.props;
    return (
      <ContentsDiv>
        <LeftSideAds>
          <GoogleBannerAds
              id={"left-side-ad"}
              adId={"div-gpt-ad-202407231222167-201"}
              adType={"static"}
              slot={{
                id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_w_lt_160x600",
                size: '[160,600]'
              }}
          />
        </LeftSideAds>
        <ContentsContainer>

          { (this.contentIdx === undefined || this.contentIdx === '') && (contentIdx === undefined || contentIdx === '')?
              (<ListPageVM
                  language={language}
                  header={this.getHeaderInfo(header, listOfHeader)} />)
              : (<DetailPageVM
                  language={language}
                  contentIdx={contentIdx === undefined || contentIdx === '' ? this.contentIdx: contentIdx}
                  header={this.getHeaderInfo(header, listOfHeader)}
                  isApp={false}
              />)}
        </ContentsContainer>
        <RightSideAds>
          <GoogleBannerAds
              id={"right-side-ad"}
              adId={"div-gpt-ad-202407231222165-939"}
              adType={"static"}
              slot={{
                id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_wr_fl_160x600",
                size: '[160,600]'
              }}
          />
        </RightSideAds>
      </ContentsDiv>
    );
  }

}

const LeftSideAds = styled.div``;
const RightSideAds = styled.div``;
const ContentsContainer = styled.div``;
const ContentsDiv = styled.div`
  height: auto;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;


  ${LeftSideAds}, ${RightSideAds} {
    display: none;
    margin: 16px 20px 0 20px;
  }
  
  ${ContentsContainer}{
    width: 100%;
  }

  @media (min-width:1048px) {
    padding: 0px 0 0 0;
    
    ${ContentsContainer} {
      width: 1048px;
    }
  }

  @media (min-width:1448px) {
    ${LeftSideAds} {
      display: block;
    }
    ${RightSideAds} {
      display: block;
      position: sticky;
      top: 62px;
    }
  }
`;


export default WebContents;