import { LOG } from "global/api";

export function checkDuplicatePoint(_data, successCallback){
    var token = undefined;
    if (_data['token'] !== undefined) {
        token = _data['token']
    }
    var data = {}
    if (_data['finalIdx'] !== undefined) {
        data['finalIdx'] = _data['finalIdx']
    }
    if (_data['lang'] !== undefined) {
        data['lang'] = _data['lang']
    }

    fetch(`${LOG}/v4/user/point/article/duplicate`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*",
            'Authorization': 'Bearer '+token
        },
        body: JSON.stringify(_data)
    })
    .then(response => response.json())
    .then(successCallback)
}

export function addPoint(_data, successCallback){
    var token = undefined;
    if (_data['token'] !== undefined) {
        token = _data['token']
    }
    var data = {}
    if (_data['finalIdx'] !== undefined) {
        data['finalIdx'] = _data['finalIdx']
    }
    if (_data['artistIdx'] !== undefined) {
        data['artistIdx'] = _data['artistIdx']
    }
    if (_data['lang'] !== undefined) {
        data['lang'] = _data['lang']
    }
    if (_data['etc'] !== undefined) {
        data['etc'] = _data['etc']
    }


    fetch(`${LOG}/v4/user/point/article`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*",
            'Authorization': 'Bearer '+token
        },
        body: JSON.stringify(data)
    }).then(response => response.json())
        .then(successCallback)
}


export function addViewLog(_data){
    fetch(`${LOG}/v4/log`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': "*",
        },
        body: JSON.stringify(_data)
    })
}