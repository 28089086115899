import React, { Component } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper";

import styled from "styled-components";
import HeaderMenu from "components/view/common/header/HeaderMenu";
import SearchHeader from "components/view/common/header/SearchHeader";

class HeaderMain extends Component {

    render() {
        const { data, header, language } = this.props;
        return (
            data ?
                <Container>
                    <Swiper
                        slidesPerView={'auto'}
                        spaceBetween={0}
                        freeMode={true}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[FreeMode]}
                        initialSlide={data.findIndex(element => {
                            if (header===undefined) {
                                return false
                            }
                            
                            return element.title.toLowerCase()===header.toLowerCase() 
                        })}
                        className={"header-swiper"}
                    >
                        {data.map((_header, i)=>{
                            return <SwiperSlide
                                key={i}
                                className={"header-slide"} >
                                <HeaderMenu
                                    isSelected={header?_header.title.toLowerCase()===header.toLowerCase():false}
                                    link={`/${language.toLowerCase()}/article/${_header.title.toLowerCase()}`}
                                    title={_header.title}  />
                            </SwiperSlide>
                        })}
                    </Swiper>
                    <SearchHeader language={language} />
                </Container>


                : <></>
        );
    }

}
const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    
    align-items: center;
    justify-content: center;
    
    width: 100%;
    margin-right: 0px!important;
    margin-left: 0px!important;
    border-top: 1px solid #c1c1c1;
    border-bottom: 1px solid #c1c1c1;
    
    .header-swiper{
        @media (min-width: 1048px){
            // margin-right: 0px;
        }
        
        .header-slide{
            width: auto!important;
        }
    }
`;

export default HeaderMain;
