import React, { Component } from "react";
import styled from "styled-components";
import { MENU_CONST, WEB_MAIN_STR } from "global/string";

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/scrollbar';

import ArtistListView from 'components/view/article/ArtistList';
import ArtistArticleView from 'components/view/article/ArtistArticle';
import { getDashboardOfArtist, getListOfRankArtist } from "api/ArtistAPI";
import { ConvertSystemHtmlChartoSource } from "utils/StringUtils";
import { mapOfCategoryChild } from "utils/CategoryUtils";

class ArtistArticleVM extends Component {

    constructor() {
        super();
        this.state = {
            listOfArtist: undefined,
            oneOfNew: undefined,
            listOfBest: undefined,
            isClickDes: false
        }
    }

    componentDidMount() {

        getListOfRankArtist({
            language: this.props.language,
            clientIdx: 1022,
            offset: 0, 
            limit: 10
        }, (res) => {
            var data = res.resultData;
            var list = [];

            data.map((artist, i) => {
                list.push({
                    idx: artist.idx,
                    name: artist.title,
                    logo: `https://resource.hanteonews.com/hanteonews/artist/logo/${artist.idx}.jpg`
                })
                return true;
            })
            

            this.setState({
                listOfArtist: list
            })

            
            this.makeArticleByArtistIdx({
                idx: list[0].idx, 
                name: list[0].name
            });
        })
    }

    onArtistIdxSubmit = (_result) => {
        this.makeArticleByArtistIdx({
            idx: _result.idx,
            name: _result.title 
        })
    }

    makeArticleByArtistIdx(artist) {
        // artist = id, name

        getDashboardOfArtist({
            clientIdx: 1022,
            language: this.props.language,
            listOfArtist: `${artist.idx}`
        }, (res) => {
            var data = res.resultData;
            
            var newone = data.latest;
            let _cate = newone.listOfCategory[0]

            var category = MENU_CONST.allCategory[newone.meta.language].title

            let mapOfHeader = mapOfCategoryChild(this.props.listOfHeader)
            let listOfKey = Object.keys(mapOfHeader);
            for (var i = 0 ; i < listOfKey.length; i++) {
                let key = listOfKey[i];
                let listOfChildIdx = mapOfHeader[key]
                if (listOfChildIdx.indexOf(`${_cate.idx}`) > -1) {
                    category = key
                    break;
                }
            }

            var oneOfNew = {
                idx: newone.ownership.idx,
                thumbnail: newone.meta.content.thumbnail,
                title: ConvertSystemHtmlChartoSource(newone.meta.content.title),
                content: ConvertSystemHtmlChartoSource(newone.meta.content.seo),
                date: newone.publish.startDate,
                language: newone.meta.language,
                artist: artist,
                link: `/${newone.meta.language.toLowerCase()}/article/${category.toLowerCase()}?fc=${newone.ownership.idx}`            
            }

            var list = data.listOfRank;
            var listOfBest = []
            list.map((post, i) => {
                var category = MENU_CONST.allCategory[post.meta.language].title;
                let listOfKey = Object.keys(mapOfHeader);
                for (var i = 0 ; i < listOfKey.length; i++) {
                    let key = listOfKey[i];
                    let listOfChildIdx = mapOfHeader[key]
                    if (listOfChildIdx.indexOf(`${_cate.idx}`) > -1) {
                        category = key
                        break;
                    }
                }
                listOfBest.push({
                    idx: post.ownership.idx,
                    thumbnail: post.meta.content.thumbnail,
                    title: ConvertSystemHtmlChartoSource(post.meta.content.title),
                    content: ConvertSystemHtmlChartoSource(post.meta.content.seo),
                    date: post.publish.startDate,
                    language: post.meta.language,
                    artist: artist,
                    link: `/${post.meta.language.toLowerCase()}/article/${category.toLowerCase()}?fc=${post.ownership.idx}`
                })
            })
            
            this.setState({
                oneOfNew: oneOfNew,
                listOfBest: listOfBest
            })
        })

    }

    btnOnClick = (e) => {
        this.setState({
            isClickDes: !this.state.isClickDes
        })
    }

    render() {
        const {language} = this.props;
        return (
            <>
                {this.state.listOfArtist ? (
                    <ArtistArticleContanier>
                        <TitleAndBtn>
                            <ArticleTitle>{WEB_MAIN_STR[language].title.artist}</ArticleTitle>
                            <BtnDiv onClick={this.btnOnClick.bind(this)}>
                                <QuestionMark>?</QuestionMark>
                                {this.state.isClickDes !== false ?
                                    <ArticleDesDiv>
                                        <ArticleDes dangerouslySetInnerHTML={{ __html: WEB_MAIN_STR[language].btn.description }}></ArticleDes>
                                    </ArticleDesDiv>
                                    : <></>}
                            </BtnDiv>
                        </TitleAndBtn>
                        <ListContainer>
                            <Swiper
                                slidesPerView='auto'
                                spaceBetween={0}
                                freeMode={{
                                    enable: true,
                                    momentum: true
                                }}
                            >
                                {this.state.listOfArtist.map((artist, i) => {
                                    return <SwiperSlide key={i}>
                                        <ArtistListView
                                            data={{
                                                'idx': artist.idx,
                                                'title': artist.name,
                                                'imgSrc': artist.logo
                                            }}
                                            isSelected={i === 0 ? true : false}
                                            onClickCallBack={this.onArtistIdxSubmit} />
                                    </SwiperSlide>
                                })}

                            </Swiper>
                        </ListContainer>

                        <ArticleArticleDiv>
                            {this.state.oneOfNew ? (
                                <ArtistArticleView
                                    oneOfNew={this.state.oneOfNew}
                                    listOfBest={this.state.listOfBest}
                                    language={language}
                                >
                                </ArtistArticleView>
                            ) : (<></>)}
                        </ArticleArticleDiv>
                    </ArtistArticleContanier>
                ) : (<></>)}
            </>
        );
    }
}

const ArticleDes = styled.p``;
const ArticleDesDiv = styled.div``;
const ArticleArticleDiv = styled.div``;
const ListContainer = styled.div``;
const DescriptionDiv = styled.div``;
const QuestionMark = styled.p``;
const BtnDiv = styled.div``;
const ArticleTitle = styled.div``;
const TitleAndBtn = styled.div``;
const ArtistArticleContanier = styled.div`
    position: relative;
    margin: 28px 0px 28px 0px;
    overflow: hidden;
    @media (min-width:1048px){
        margin: 45px 0px 28px 0px;
    }

    .swiper-container {
        z-index: 0!important;
        .swiper-slide{
            width: auto;
            height: auto;
        }
    }

    ${TitleAndBtn}{
        border-bottom: 1px solid #7d7d7d;

        padding: 0px 0px 7px 0px;
        @media (min-width:1048px){
            padding: 0px 0px 21px 0px;
        }


        ${ArticleTitle}{
            display: inline-block;
            font-size: 18px;
            margin: 0px 20px 0 10px;
            font-weight: 600;
            @media (min-width:1048px){
                font-size: 22px;
            }
        }

        ${BtnDiv}{
            display: inline-flex;
            justify-content: center;
            align-items: center;

            position: relative;
            width: 20px;
            height: 20px;
            -webkit-border-radius: 100%;
            -moz-border-radius: 100%;
            border-radius: 100%;
            background-color: #D4D4D4;
            color: #fff;
            cursor: pointer;
            float: right;
            margin: 0 10px 0 0;

            @media (min-width:1048px){
                float: none;
                margin: 0;
            }

            ${QuestionMark}{
                margin-bottom: 1px;
            }
            ${DescriptionDiv}{
                position: absolute;
                display: none;
                z-index: 5;
                font-size: 13px;
                padding: 13px 8px;
                background-color: #fff;
                border: 1px solid #707070;
                border-radius: 5px 0px 5px 5px;
                width: 250px;
                top: 14px;
                right: 13px;
                -webkit-box-shadow: 2px 3px 5px rgba(0,0,0,.2);
                -moz-box-shadow: 2px 3px 5px rgba(0,0,0,.2);
                box-shadow: 2px 3px 5px rgba(0,0,0,.2);
                color: #2d2d2d;

                @media (min-width:1048){
                    font-size: 13px;
                    border-radius: 0 5px 5px 5px;
                    top: 12px;
                    left: 9px;
                    right: auto;
                }
            }
            ${ArticleDesDiv}{
                position: absolute;
                font-size: 13px;
                border-radius: 5px 0px 5px 5px;
                top: 12px;
                left: -240px;
                padding: 13px 8px;
                background-color: #fff;
                border: 1px solid #707070;
                width: 250px;
                box-shadow: 2px 3px 5px rgba(0,0,0,.2);
                color: #2d2d2d;
                z-index: 2;

                @media (min-width:1048px){
                    border-radius: 0 5px 5px 5px;
                    left: 9px;
                }
            }
        }

    }

    ${ListContainer}{
        margin: 14px 10px 17px 10px;
        @media (min-width:1048px){
            margin: 15px 10px 17px 10px;
        }
        
        .swiper-slide{
            width: auto;
        }
    }
`;

export default ArtistArticleVM;
