import React, { Component } from "react";
import styled from "styled-components";

class Chart extends Component {

  render() {
    return (<div></div>);
  }

}


export default Chart;
