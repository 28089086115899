import {Component} from "react";
import styled from "styled-components";
import {
    FOOTER_BOTTOM_MOBILE,
    FOOTER_COMPANY_INFO_MOBILE,
    FOOTER_MENU
} from "../../global/string";
import {getLanguageWithPath} from "../../utils/LanguageUtils";
import FooterMenu from "../../components/view/common/footer/FooterMenu";


class FooterMobile extends Component{
    info = FOOTER_COMPANY_INFO_MOBILE[this.props.language];
    menus = FOOTER_MENU[this.props.language];
    bottom = FOOTER_BOTTOM_MOBILE[this.props.language];
    render() {
        const {language} = this.props;
        return <Container>
            <Cover>
                <ContentDiv>
                    {this.info}
                </ContentDiv>
                <MenuDiv>
                    <div>
                        {Object.keys(this.menus).map((_key, i)=>{
                            return <>
                                <FooterMenu link={this.menus[_key].link} title={this.menus[_key].title}/>
                                <p className="m-footer-mline">|</p>
                            </>
                        })}
                    </div>
                </MenuDiv>
                <BottomDiv>
                    {this.bottom}
                </BottomDiv>
            </Cover>
        </Container>
    }
}
const ContentDiv = styled.div``;
const MenuDiv = styled.div``;
const BottomDiv = styled.div``;

const Cover = styled.div``;
const Container = styled.div`
    display: block;
    @media (min-width: 1048px){
        display: none;
    }
    
    ${Cover}{
        padding: 24px 0 0 0;
        background: #2E2F34;
        font-family: NanumSquare, sans-serif;
        font-weight: 400;
        color: #B5B5B5;
    
        ${ContentDiv}{
            margin: 0px 10px 20px 10px;
            font-size: 10px;
            line-height: 1.1;
        }
        
        ${MenuDiv}{
            border-top: 1px solid #707070;
            border-bottom: 1px solid #707070;
          
            padding: 10px 0 10px 0;
            
            p{
                display: inline-block;
                margin: 0;
                font-size: 12px;
                padding: 0 10px;
            }
        }
        
        ${BottomDiv}{
            margin: 0 10px;
            padding: 17px 0 18px 0;
            font-size: 11px;
        }
    }
    
    
`;


export default FooterMobile;