import React, {Component} from "react";
import styled from "styled-components";

import MainArticleVM from 'components/view_model/article/MainArticleVM';
import NewArticleVM from 'components/view_model/article/NewArticleVM';
import PhotoAndVideoArticleVM from 'components/view_model/article/PhotoAndVideoArticleVM';
import LatestArticleVM from 'components/view_model/article/LatestArticleVM';
import GoogleMainAds from "../../components/view/ads/GoogleMainAds";
import GoogleBannerAds from "../../components/view/ads/GoogleBannerAds";

class AppMainContent extends Component {

  render() {
    const {language} = this.props;
    return (
      <>
        <ContentsDiv>
          <ContentsContainer>
            <MainArticleVM
              language={language} />
              
            <NewArticleVM
              language={language} />
            
            <PhotoAndVideoArticleVM
              language={language} />

            <LatestArticleVM
              language={language} />

            <BottomAnchorAds>
              <GoogleBannerAds
                id={"bottom-anchor-ad"}
                adType={"anchor"}
                slot={{
                  id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_MWEB_bottom_anchor_320x100"
                }}
              />
            </BottomAnchorAds>
          </ContentsContainer>
        </ContentsDiv>
      </>
    );
  }

}
const BottomAnchorAds = styled.div``;
const ContentsDiv = styled.div``;
const ContentsContainer = styled.div``;

export default AppMainContent
