import React, { Component } from "react";
import styled from "styled-components";
import { APP_DEEP_LINK, WHOSFAN_BANNER } from "global/string";

import AdBannerAndMostArticleVM from 'components/view_model/article/AdBannerAndMostArticleVM';
import { addViewLog } from "api/UserAPI";
import GoogleMainAds from "../ads/GoogleMainAds";
import {ConvertSystemHtmlChartoSource} from "../../../utils/StringUtils";
import GoogleBannerAds from "../ads/GoogleBannerAds";

class WebDetailView extends Component {

    constructor() {
        super();
        this.state = {
            os: undefined,
            browser: undefined,
            video: undefined,
            isVideo: false,
            fontSize: 14
        }
    }

    componentDidMount() {

        // window.Kakao.init('eb9995942ed0aecb8f72daa633161944');

        this.articleViewAndLogUpdate(this.props.data)
    }

    _onBannerClick = (e) => {
        e.stopPropagation();
        e.preventDefault();

        var app = null;
        var appName = null;
        var params = null;
        var os = undefined;
        var browser = undefined;

        var uagent = window.navigator.userAgent.toLowerCase();

        if (uagent.search("android") > -1) {
            os = "android";
            if (uagent.search("chrome") > -1) {
                browser = "android+chrome";
            }
        } else if (uagent.search("iphone") > -1 || uagent.search("ipod") > -1 || uagent.search("ipad") > -1) {
            os = "ios";
        }

        const url = document.getElementsByClassName(BannerDiv.styledComponentId)[0].dataset.url
        if (os === "ios") {

            var goaitapp = window.open(url);
            setTimeout(function () {
                goaitapp.location.href = APP_DEEP_LINK.app.star.store.ios;
            }, 1000);
            window.addEventListener('pagehide', function (timer) {
                return function () {
                    clearTimeout(timer);
                    window.removeEventListener('pagehide', arguments.callee);
                };
            });

            return
        }


        appName = url.split('/')[2];
        params = url.split('/')[3];
        app = APP_DEEP_LINK.app[appName];

        var baseUrl = app.base;

        if (os === "android") {

            if (appName === 'star') {
                baseUrl = baseUrl.android + params;
            }
            if (browser === "android+chrome") {
                window.open("intent:" + baseUrl + "#Intent;package=" + app.package + ";end;");
            } else {
                window.open(baseUrl);
            }
        } else {
            baseUrl = app.web;
            window.location = baseUrl;
        }
    }

    articleViewAndLogUpdate(data) {

        addViewLog({ 
            ownership: {
                idx: data.idx,
                user: {
                    idx: undefined,
                    client :{
                        idx: 1022
                    }
                }
            },
            meta : {
                content: { 
                    title: data.title
                },
                language: data.language
            }
        })
    }

    HashTagClick = (tag) => {
        if(tag.title.indexOf("#")>-1){
            tag.title = tag.title.replace(/#/gi, "");
        }
        var link = window.location.origin + `/${this.props.language.toLowerCase()}/search?keyword=${tag.title}`;
        window.location.href = link
    }


    onClickSns = (_data, _path) => {
        var str = ConvertSystemHtmlChartoSource(this.props.data.title.replace(/(<([^>]+)>)/ig, " "))
        var weburl = window.location.origin + _path;
        switch (_data) {
            case 'facebook':
                return window.open("https://www.facebook.com/sharer/sharer.php?u=" + weburl);
            case 'twitter':
                return window.open("https://twitter.com/intent/tweet?text=" + encodeURIComponent(str) + "&url=" + weburl);
            case 'story':
                return window.Kakao.Story.share({
                    url: weburl,
                    text: str
                });
            case 'naver':
                var url = encodeURI(encodeURIComponent(weburl));
                var title = encodeURI(str);
                var shareURL = "https://share.naver.com/web/shareView.nhn?url=" + url + "&title=" + title;
                return window.open(shareURL);
            case 'kakao':
                return window.Kakao.Link.sendScrap({
                    requestUrl: weburl,
                    installTalk: true
                });
            case 'blog':
                var shareURL = "https://blog.naver.com/openapi/share?url=" + weburl;
                return window.open(shareURL);
            case 'shrink':
                return this.setState({
                    fontSize: this.state.fontSize - 1
                })
            case 'extend':
                return this.setState({
                    fontSize: this.state.fontSize + 1
                })
            case 'print':
                return window.print();
            default:
                break;
        }
    }

    renderContents(contents) {
        setTimeout(() => {

            function addScript(src) {
                var my_awesome_script = document.createElement('script');
                my_awesome_script.setAttribute('src', src);
                document.head.appendChild(my_awesome_script);
            }

            addScript("//www.instagram.com/embed.js")
            addScript("https://platform.twitter.com/widgets.js")
        }, 1)

        setTimeout(() => {
            if (document.getElementsByClassName('gliaplayer-container').length > 0) {
                return;
            }


            let container = document.getElementById("detail-content");
            let listP = container.getElementsByTagName("p");
            var count = 0; // 처음 빈값을 제외하고, 3번째, 즉 4번째
            var pDom = undefined;
            for(var i = 0; i < listP.length; i++) {
                let _p = listP[i]
                if (_p.innerText.trim() !== "") {
                    continue;
                }

                count++;
                if (count === 4) {
                    pDom = _p;
                    break;
                }
            }

            if (pDom===undefined) {
                return;
            }

            let _video = document.createElement('div');
            _video.className = 'gliaplayer-container';
            _video.dataset.slot = `adforus_hanteonews_${document.body.offsetWidth < 1048? 'mobile': 'desktop'}`;

            let _script = document.createElement('script');
            _script.async = true;
            _script.src = `https://player.gliacloud.com/player/adforus_hanteonews_${document.body.offsetWidth < 1048? 'mobile': 'desktop'}`;

            pDom.before(_video)
            pDom.before(_script)

        }, 200);

        return <div id={'detail-content'} key={0} dangerouslySetInnerHTML={{ __html: contents }}></div>;
    }


    renderWhosfanBanner(listOfArtist) {
        if (listOfArtist===undefined || listOfArtist===null || listOfArtist.length===0) {

            return <>
            <BannerDiv className="article-artist-banner-div">
                <a className="artist-article-banner hflex-stc" href="#" onClick={this._onBannerClick}>
                    <div className="hflex-stc artist-banner-box">
                        <img className="banner-logo-img" src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/banner/banner_logo.png" />
                        <div className="banner-inner-text">
                            {WHOSFAN_BANNER[this.props.language].basic}
                        </div>
                    </div>
                </a>
            </BannerDiv>
            </>
        }

        let artists = listOfArtist.filter((artist) => artist.isDefault===1);
        if (artists.length!==1) return <>
            <BannerDiv className="article-artist-banner-div">
                <a className="artist-article-banner hflex-stc" href="#" onClick={this._onBannerClick}>
                    <div className="hflex-stc artist-banner-box">
                        <img className="banner-logo-img" src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/banner/banner_logo.png" />
                        <div className="banner-inner-text">
                            {WHOSFAN_BANNER[this.props.language].basic}
                        </div>
                    </div>
                </a>
            </BannerDiv>
        </>

        let artist = artists[0];
        return  <BannerDiv className="article-artist-banner-div">
                    <a className="artist-article-banner hflex-stc" href="#" onClick={this._onBannerClick}>
                        <div className="hflex-stc artist-banner-box">
                            <img className="banner-logo-img" src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/banner/banner_logo.png" />
                            <div className="banner-inner-text" dangerouslySetInnerHTML={{ __html: WHOSFAN_BANNER[this.props.language].artist.replaceAll('%@', artist.title)}}></div>
                        </div>
                    </a>
                </BannerDiv>
                            
    }
    render() {
        const {language, data, detailCopy} = this.props;
        return (
            <>
                <DetailContainer>
                    <HeaderContainer>
                        <Title dangerouslySetInnerHTML={{ __html: data.title }}></Title>
                        <ReportContainer>
                            <ReporterDiv>
                                {data.writeUser.badge? 
                                <ReporterImg src={`https://s3.ap-northeast-2.amazonaws.com/hanteoresource${data.writeUser.badge}`}></ReporterImg>
                                :<></>}
                                <ReporterName>{data.writeUser.name} {language === 'KO' ? '기자' : ''} {data.writeUser.email}</ReporterName>
                            </ReporterDiv>
                            <ReportTimeDiv>
                                <PublicTime>
                                    {language === 'KO' ? '입력 : ' : ''}{data.startDate} (UTC)</PublicTime>
                                    {data.modifyDate !== undefined && data.modifyDate !== null && data.modifyDate !== '' ?
                                    <>
                                        <span>|</span>
                                        <PublicTime>{language === 'KO' ? '수정 : ' : ''} {data.modifyDate} (UTC)</PublicTime>
                                    </>
                                    : <></>}
                            </ReportTimeDiv>
                        </ReportContainer>
                        <EtcContainer>
                            <SnsDiv>
                                <ShareImgPC src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/real/share_icon.svg"></ShareImgPC>
                                <FaceBookImg src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/share_facebook_logo_sample.svg" onClick={this.onClickSns.bind(this, 'facebook', data.link)}></FaceBookImg>
                                <TwitterImg src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/share_twitter_logo_sample.svg" onClick={this.onClickSns.bind(this, 'twitter', data.link)}></TwitterImg>
                                <KakaoStoryImg src="https://developers.kakao.com/sdk/js/resources/story/icon_small.png" onClick={this.onClickSns.bind(this, 'story', data.link)}></KakaoStoryImg>
                                <NaverImg src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/naver_square_24x24.png" onClick={this.onClickSns.bind(this, 'naver', data.link)}></NaverImg>
                                <KakaoImg src="//developers.kakao.com/assets/img/about/logos/kakaolink/kakaolink_btn_medium.png" onClick={this.onClickSns.bind(this, 'kakao', data.link)}></KakaoImg>
                                <NaverBlogImg src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/naver_blog_24x24.png" onClick={this.onClickSns.bind(this, 'blog', data.link)}></NaverBlogImg>
                                <ShareImgMobile src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/real/share_icon.svg"></ShareImgMobile>
                            </SnsDiv>
                            <EtcDiv>
                                <ShrinkImg src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/font_shrink_ko.jpg" onClick={this.onClickSns.bind(this, 'shrink', data.link)}></ShrinkImg>
                                <ExtendImg src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/font_extend_ko.jpg" onClick={this.onClickSns.bind(this, 'extend', data.link)}></ExtendImg>
                                <PrintImg src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/print_icon.png" onClick={this.onClickSns.bind(this, 'print', data.link)}></PrintImg>
                            </EtcDiv>
                        </EtcContainer>
                    </HeaderContainer>
                    <ContentsContainer>
                        <DetailDiv>
                            <ContentsDiv style={{ 'fontSize': this.state.fontSize }}>{this.renderContents(data.content)}</ContentsDiv>
                            <ReporterDiv>
                                {data.writeUser.badge? 
                                <ReporterImg src={`https://s3.ap-northeast-2.amazonaws.com/hanteoresource${data.writeUser.badge}`}></ReporterImg>
                                :<></>}
                                <ReporterName>{data.writeUser.name} {language === 'KO' ? '기자' : ''} {data.writeUser.email}</ReporterName>
                            </ReporterDiv>
                            <ExplainDiv>
                                <ExplainATag dangerouslySetInnerHTML={{ __html: detailCopy }}></ExplainATag>
                            </ExplainDiv>
                            <HashTagContainer>
                                {data && data.listOfTag ? data.listOfTag.map((tag, i) =>
                                    <HashTagDiv key={i} onClick={this.HashTagClick.bind(this, tag)}>
                                        <HashTag>#{tag.title}</HashTag>
                                    </HashTagDiv>
                                ) : <></>}
                            </HashTagContainer>
                            {data?
                                this.renderWhosfanBanner(data.listOfArtist)
                            :<></>}
                            {document.body.offsetWidth < 1048?
                                <GoogleBannerAds
                                    id={"content-bottom-mobile-ad"}
                                    adId={"div-gpt-ad-202407231234365-675"}
                                    adType={"static"}
                                    slot={{
                                        id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_MWEB_article_bottom_300x250",
                                        size: "[300,250]"
                                    }} />
                                : <></>}
                            {/* {this.props.relatedData && this.props.relatedData.length>0?
                                <RelatedDiv>
                                    <RelatedTitle>{WEB_MAIN_STR[language].title.related}</RelatedTitle>
                                    {this.props.relatedData.map((post, i) => {
                                        return (
                                            <RelateArticleDiv key={post.finalIdx} onClick={this.onClick.bind(this, post)}>
                                                <RelateThumbnailDiv><RelateThumbnailImg src={post.thumbnail}></RelateThumbnailImg></RelateThumbnailDiv>
                                                <RelateContentsDiv>
                                                    <Title dangerouslySetInnerHTML={{ __html: post.title }}></Title>
                                                    <Contents dangerouslySetInnerHTML={{ __html: post.title }}></Contents>
                                                </RelateContentsDiv>
                                            </RelateArticleDiv>
                                        )
                                    })}
                                </RelatedDiv>
                                :<></>} */}

                        </DetailDiv>
                        <AdBannerAndMostArticleDiv>
                            <SideTopAds>
                                <GoogleBannerAds
                                    id={"side-top-ad"}
                                    adId={"div-gpt-ad-202407231222169-916"}
                                    adType={"static"}
                                    slot={{
                                        id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_famousarticle_top_300x250",
                                        size: "[300,250]"
                                    }}
                                />
                            </SideTopAds>
                            <AdBannerAndMostArticleVM
                                language={language} />
                            <SideBottomAds>
                                <GoogleBannerAds
                                    id={"side-bottom-ad"}
                                    adId={"div-gpt-ad-202407231234360-230"}
                                    adType={"static"}
                                    slot={{
                                        id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_famousarticle_bottom_300x600",
                                        size: "[300,600]"
                                    }}
                                />
                            </SideBottomAds>
                        </AdBannerAndMostArticleDiv>
                    </ContentsContainer>
                </DetailContainer>

                {document.body.offsetWidth < 1048?
                    <GoogleBannerAds
                        id={"bottom-anchor-ad"}
                        adType={"anchor"}
                        slot={{
                            id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_MWEB_bottom_anchor_320x100"
                        }}
                    />
                    :<></>}
            </>
        );
    }
}



const Contents = styled.div``;
const DetailContainer = styled.div``;
const SnsDiv = styled.div``;
const ShareImgPC = styled.img``;
const ShareImgMobile = styled.img``;
const FaceBookImg = styled.img``;
const TwitterImg = styled.img``;
const KakaoStoryImg = styled.img``;
const NaverImg = styled.img``;
const KakaoImg = styled.img``;
const NaverBlogImg = styled.img``;
const EtcDiv = styled.div``;
const ShrinkImg = styled.img``;
const ExtendImg = styled.img``;
const PrintImg = styled.img``;
const Title = styled.h1``;
const ContentsDiv = styled.div``;

const ReportTimeDiv = styled.div``;
const ReporterDiv = styled.div``;
const ReporterImg = styled.img``;
const ReporterName = styled.div``;

const PublicTime = styled.div``;
const ExplainATag = styled.a``;
const HashTagDiv = styled.a``;
const HashTag = styled.a``;
const RelatedTitle = styled.h5``;
const RelateArticleDiv = styled.a``;
const RelateThumbnailDiv = styled.div``;
const RelateThumbnailImg = styled.img``;
const RelateContentsDiv = styled.div``;


const SideTopAds = styled.div``;
const SideBottomAds = styled.div``;
const AdBannerAndMostArticleDiv = styled.div``;
const BannerDiv = styled.div`
    margin: 15px;   
    @media (min-width: 1048px){
        margin: 15px 0px;
    }

    &.article-artist-banner-div{
        .artist-article-banner{
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            background-image: url(https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/banner/banner_background_mobile.jpg);
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 141px;
            overflow: hidden;
            border-radius: 10px;
            text-decoration: none;

            @media (min-width: 1048px){
                background-image: url(https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/banner/banner_background_pc.jpg);
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                height: 111px;
            }

            .artist-banner-box{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                height: 100%;

                @media (min-width: 1048px){
                    width: 100%;
                }


                .banner-logo-img{
                    width: auto;
                    height: 46%;
                    padding-bottom: 5px;
                    margin: 0 12px 0 15px;
                    @media (min-width: 1048px){
                        height: 54%;
                        padding-bottom: 5px;
                        margin: 0 15px 0 15px;
                    }
                }
                .banner-inner-text{
                    background: transparent;
                    color: white;
                    font-size: 15px;
                    font-weight: 600;
                    margin: 1px 8px 0 3px;
                    word-break: keep-all;
                    line-height: 1.5;
                    @media (min-width: 1048px){
                        background: transparent;
                        color: #ffffff;
                        font-size: 20px;
                        margin: 0 15px 0 4px;
                    }
                }
            }
        }
    }
`;

const ContentsContainer = styled.div`
    display: block;
    overflow: hidden;

    
    @media (min-width: 1048px){
        display: flex;
        flex-wrap: wrap;
    }

    ${AdBannerAndMostArticleDiv}{
        width: 100%;
        
        ${SideTopAds}, ${SideBottomAds} {
          display: none;
        }
        @media (min-width: 1048px){
            width:320px;


            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            width: 320px;

            ${SideTopAds}{
                display: block;
                margin: 55px 0 0 0;
            }
            ${SideBottomAds}{
                display: block;
            }
        }
    }
`;

const RelatedDiv = styled.div`
    margin: 30px 15px 20px 15px;

    @media (min-width: 1048px){
        width: 100%;
        margin: 30px auto;
    }

    ${RelatedTitle}{
        font-family: 'Lato';
        font-weight: 600;
        color: #111111;
        font-size: 16px;
        padding: 15px 0 10px 0;
        border-bottom: 1px solid #7d7d7d;
        margin: 10px 0 15px 0;
    }

    ${RelateArticleDiv}{
        cursor: pointer;
        display: flex;
        border-bottom: 1px solid #e8e7e7;
        color: #111111;
        font-size: 14px;

        @media (min-width:1048px){
            display: inline-block;
            width: 225px;
            margin: 12px 6px;
            border-bottom: none;
        }
        ${RelateThumbnailDiv}{
            margin: 12px 0px;

            ${RelateThumbnailImg}{
                width: 150px;
                height: auto;
                @media (min-width:1048px){
                    width: 100%;
                    height: auto;
                }
            }

        }
        ${RelateContentsDiv}{
            margin-left: 4px;
            align-self: center;

            ${Title}{
                font-size: 15px;
                word-break: break-all;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                margin: 0 0 3px 4px;

                @media (min-width:1048px){
                    margin: 0;
                    font-size: 15px;
                    word-break: break-all;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }
            ${Contents}{
                margin: 0 0 3px 4px;
                font-size: 12px;
                word-break: break-all;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                line-height: 1.5;
                @media (min-width:1048px){
                    display:none;
                }
            }
        }

    }
`;

const ExplainDiv = styled.div`
    font-family: NanumSquare;
    font-weight: 400;
    font-size: 14px;
    margin: 0 15px;
    margin-top: 26px;
`;

const ReportContainer = styled.div`
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 14px 15px 0 15px;

    @media (min-width:1048px) {
        flex-direction: row;
        margin: 0;
    }
    

    ${ReporterDiv}{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        white-space: nowrap;

        ${ReporterImg}{
            height: 16px;
            width: auto;
            margin: 0 3px 0 0;
        }
        ${ReporterName}{
            font-size: 12px;
            margin: 2px 13px 0 0;
            color: #999999;
            font-family: NanumSquare;
            font-weight: 200;

            line-height: 1.5;

            @media (min-width:1048px) {
                font-size: 14px;
                margin: 0 13px 0 0;
            }
        }
    }
    ${ReportTimeDiv}{
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start!important;

        font-size: 12px;
        margin: 2px 13px 0 0;
        color: #999999;
        font-family: NanumSquare;
        font-weight: 200;
        line-height: 1.5;

        @media (min-width:1048px) {
            font-size: 14px;
            margin: 0 13px 0 0;
        }

        span{
            margin: 0 10px;
        }

        ${PublicTime}{}
    }
`;

const EtcContainer = styled.div`
    display: flex;
    padding: 8px 0 7px 0;
    place-content: flex-end;

    border-top: 1px solid #E4E4E4;
    border-right: 0px;
    border-left: 0px;
    border-bottom: 0px;
    margin: 4px 0 0 0;

    @media (min-width:1048px){
        place-content: space-between;
        border-top: 1px solid #C3C3C3;
        border-right: 0px;
        border-left: 0px;
        border-bottom: 0px;
        margin: 7px 0 0 0;
    }

	${SnsDiv}{
        display: flex;
        align-items: center;

        ${ShareImgPC}{
            display: none;
            @media (min-width:1048px){
                display: inline-block;
                width: 14px;
                height: 14px;
                margin: 0 3px 0 10px;
            }
        }
        ${ShareImgMobile}{
            display: inline-block;
            width: 14px;
            height: 14px;
            margin: 0 15px 0 3px;
            @media (min-width:1048) {
                display: none;
            }
        }
        ${FaceBookImg}, ${TwitterImg}, ${KakaoStoryImg}, ${NaverImg}, ${KakaoImg}, ${NaverBlogImg}{
            display: inline-block;
            width: 23px;
            height: 23px;
            margin: 0 5px;
            cursor: pointer;
        }
    }
    ${EtcDiv}{
        display: none;
        text-align: left;
        @media (min-width:1048px) {
            display: flex;
        }

        ${ShrinkImg}, ${ExtendImg}{
            cursor: pointer;
        }
        ${PrintImg}{
            cursor: pointer;
            margin: 0 0 0 6px;
        }
    }
`;

const HeaderContainer = styled.div`

    ${Title}{
        font-size: 20px;
        margin: 23px 15px 5px 15px;
        font-family: 'Lato';
        font-weight: 600;
        line-height: 1.5;

        @media (min-width:1048px) {
            font-size: 32px;
            margin: 60px 0 17px 0;
        }
    }
`;


const DetailDiv = styled.div`
    width: 100%;
    line-height: 25px;
    @media (min-width:1048px) {
        margin-top: 35px;
        width: 728px;
    }

    ${ContentsDiv}{
        margin: 20px 15px;
        letter-spacing: 1.5px;
        line-height: 24px;

        font-family: 'Lato'!important;
        font-weight: 400;
        line-height: 24px;


        figure{
            margin: 0 0 1rem;
        }
        figcaption{
            color: #adadad;
            font-size: 13px;
            margin: 6px 0 0 10px;
        }
        strong {
            font-weight: bolder;
        }
        em {
            font-style: italic;
        }
        u {
            text-decoration: underline;
        }
        s {
            text-decoration: line-through;
        }
        a {
            color: blue;
            text-decoration: underline;
        }

        .youtube-wrapper{
            position: relative;
            width: 100%;
            padding-top: 56.25%;

            .youtube-video{
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
            }
        
        }
    }
    ${ReporterDiv}{
        font-family: NanumSquare;
        font-weight: 400;
        font-size: 14px;
        color: #111111;
        margin: 0 15px;
        margin-top: 42px;
        
        display: flex;
        justify-content: flex-start;
        align-items: center;
        white-space: nowrap;
        

        ${ReporterImg}{
            height: 16px;
            width: auto;
            margin: 0 3px 0 0;
        }
        ${ReporterName}{
            margin: 0;
            font-family: NanumSquare;
            font-weight: 400;
            font-size: 14px;
        }
    }
`;

const HashTagContainer = styled.div`
    margin: 20px 15px 20px 15px;
    
    @media (min-width:1048px){
        margin: 20px 0;
    }
    
    ${HashTagDiv}{
        display: inline-block;
        border: 1px solid #EEEEEE;
        text-align: center;
        padding: 6px 16px 5px 16px;
        font-size: 12px;
        font-family: NanumSquare, sans-serif;
        font-weight: 400;
        border-radius: 15px;
        margin: 3px 10px 3px 0;
        line-height: 1.5;
        color: #212529;
        cursor: pointer;

        :hover{
            background-color: #ec407a;
            color: #fff;
        }
    }

`;

export default WebDetailView;
