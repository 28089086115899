import React, { Component } from "react";
import styled from "styled-components";
import { writeComment } from "api/CommentAPI";
import SpinnerPink from "img/spinner_pink.svg";
import { Spinner } from "react-bootstrap";
import { COMMENT_TEXT } from "global/string";


class CommentInput extends Component {
    constructor(){
        super();
        this.comment = '';
        this.state = {
            inputValue: ''
        }
    }
    onEnterKey = (e) =>{
        if (e.key === "Enter") {
            this.setComment(e.target.value);

            let _txt = e.currentTarget.parentNode.children[1].children[0]
            let _load = e.currentTarget.parentNode.children[1].children[1]
            this.addComment(e.target, _txt, _load)
        }
    }
    onRegisterBtnClick = (e) => { 
        let _txt = e.currentTarget.children[0]
        let _load = e.currentTarget.children[1]
        this.addComment(e.currentTarget.parentNode.children[0], _txt, _load)
    }
    onInputBlur = (e) => {
        this.setComment(e.target.value);
    }
    
    setComment(_value){
        this.comment = _value
    }
    addComment(_input, _txt, _load){
        if(_txt.classList.contains('hidden')){
            return;
        }
        
        if(this.comment===undefined || this.comment===null || this.comment.length < 3 || this.comment.length > 2200){
            alert('3자 이상 2200자 이하로 입력해주세요')
            return;
        }
        let _this = this;
        
        _input.disabled = true;
        _txt.classList.add('hidden')
        _load.classList.add('show')

        writeComment({
            contentIdx: this.props.contentIdx,
            parentIdx: this.props.parentIdx,
            content: this.comment,
            language: this.props.langCode,
            token: this.props.token
        }, function(json){
            _input.disabled = false;
            _input.value = '';
            _this.comment = '';
            _txt.classList.remove('hidden')
            _load.classList.remove('show')
            _this.writeSuccessCallback(json)
        }, function(error){
            _input.disabled = false;
            _txt.classList.remove('hidden');
            _load.classList.remove('show');
        })
        
    }
    writeSuccessCallback = (json) => {
        if(json.code!==100){
            alert(json.message)
            return;
        }
        
        this.props.registerCallback(json.resultData)
    }
    render() {
        const { contentIdx, parentIdx, token, registerCallback,langCode } = this.props;
        return (
            <>
                <Container className="hflex-sbc">
                    <input type="text" placeholder={COMMENT_TEXT[langCode].input.placeholder} onKeyUp={this.onEnterKey} onBlur={this.onInputBlur}></input>
                    <RegisterBtn onClick={this.onRegisterBtnClick}>
                        <p>{COMMENT_TEXT[langCode].input.registerBtn}</p>
                        <img src={SpinnerPink}></img>
                    </RegisterBtn>
                </Container>
            </>
        );
    }

}

const RegisterBtn = styled.div``;
const Container = styled.div`
    border-top: 1px solid #E2E2E2;
    padding: 10px 0;
    input{
        width: 100%;
        margin: 0 0 0 16px; 
        padding: 6px 8px 6px 8px;

        &::placeholder{
            color: #AAAAAA;
        }
    }
    ${RegisterBtn}{
        min-width: 50px;
        text-align: center;
        font-size: 14px;
        color: #FC5BA8;
        border: 1px solid #FC5BA8;
        border-radius: 20px;
        padding: 2px 8px 3px 8px;
        margin: 0 12px 0 0;
        p{
            &.hidden{
                display: none;
            }
        }
        img {
            display: none;
            width: 24px;
            height: 24px;
            &.show{
                display: inline-block;
            }
        }
    }
`;



export default CommentInput;
