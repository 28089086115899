import React, { Component } from "react";
import styled from "styled-components";
import CloseIcon from "img/icon_delete_black.svg";
import BackIcon from "img/back_icon.svg";
import { isMobile, isAndroid, isIOS, isConsole } from 'react-device-detect';
import { COMMENT_TEXT } from "global/string";

class CommentHeader extends Component {
    
    constructor(){
        super();
    }
    onCloseClick = (e) => {
        if(isAndroid){
            window.whosfanJs.closeActivity()
        }else if(isIOS){
            window.webkit.messageHandlers.closeActivity.postMessage({});
        }
        
    }
    render() {
        const { langCode } = this.props;
        return (
            <>
                <Container className="hflex-sbc">
                    <Left className="hflex-cc">
                        <Back onClick={this.onCloseClick}>
                            <img alt="close" src={BackIcon}></img>
                        </Back>
                        <p>{COMMENT_TEXT[langCode].ui.title}</p>
                    </Left>
                    <Right onClick={this.onCloseClick}>
                        <img alt="close" src={CloseIcon}></img>
                    </Right>
                </Container>
            </>
        );
    }

}
const Back = styled.div``;

const Right = styled.div``;
const Left = styled.div``;
const Container = styled.div`
    padding: 15px 0;
    box-shadow: 0px 1px 3px 0px rgb(147 147 147 / 80%);
    ${Left}{
        ${Back}{
            margin: 0 20px 0 15px;
            width: 20px;
            height: 20px;
        }
        
    }
    ${Right}{
        margin: 0 15px 0 0;
        img{
            width: 20px;
            height: 20px;
        }
    }
`;



export default CommentHeader;
