import React, { Component } from "react";
import styled from "styled-components";
import SideMenu from "./SideMenu";

class SideMenuContainer extends Component {
    constructor() {
        super();
        this.state = {
            show:false
        }
    }
  openSideMenu = (e) => {
        let show = this.state.show;
        this.setState({
            show:!show
        })
  }
  render() {
    const { language, header, data } = this.props;
    return (
      <>
        <Container className="cursor-p header-side-btn" onClick={this.openSideMenu}>
          <img src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/sidemenu_icon.png" />
        </Container>

        <SideMenu language={language} header={header} data={data} isShow={this.state.show} closeCallback={this.openSideMenu}/>
      </>
    );
  }

}
const Container = styled.div`
  margin: 22px 0 0 2px;
  cursor:point;
  
  img{
    width: 23px;
    margin: 22px 0px 0px 0px;
  }
`;


export default SideMenuContainer;
