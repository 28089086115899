import React, { Component } from "react";
import styled from "styled-components";

class Protection extends Component {

    protectionBody(language) {
        switch (language) {
            default:
                return (
                    <>
                    
                        <Header>
                            <h1>청소년 보호정책</h1>
                        </Header>
                        <Body>
                            <div>
                                <b>01.청소년보호를 위한 활동</b>
                            </div>
                            <div><br /></div>
                            <div>회사는 각종 청소년 유해정보로부터 청소년을 보호하고자 관련 법률에 따라 19세 미만의 청소년들이 유해정보에 접근할 수 없도록 청소년 보호정책을 마련하여 시행하고 있습니다. 또한 회사는 청소년들의 건전한 성장을 위하여 다음과 같이 활동하고 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>① 청소년 유해정보로부터의 청소년 보호계획</div>
                            <div>- 회사는 청소년 보호계획을 회사의 개인정보 보호 계획에 포함하여 수립하여 시행하고 있습니다.</div>
                            <div><br /></div>
                            <div>② 청소년 유해정보에 대한 청소년 접근제한 및 관리 조치</div>
                            <div>- 회사는 청소년이 아무런 제한장치 없이 청소년 유해정보에 노출되지 않도록 청소년 유해 매체물에 대해서는 별도의 인증장치를 마련, 적용하며 청소년 유해정보가 노출되지 않기 위한 예방차원의 조치를 취합니다.</div>
                            <div><br /></div>
                            <div>③ 정보통신업무 종사자에 대한 청소년 유해정보로부터의 청소년 보호를 위한 교육</div>
                            <div>- 개인정보 취급자를 대상으로 청소년보호 관련 법령 및 제재기준, 유해정보 발견 시 대처법, 위반사항 처리에 대한 보고절차 등을 교육하고 있습니다.</div>
                            <div><br /></div>
                            <div>④ 청소년 유해정보로 인한 피해상담 및 고충처리</div>
                            <div>- 고객님은 하단에 명시한 ‘청소년보호 책임자 및 담당자’의 연락처를 참고하여 전화나 메일을 통하여 피해상담 및 고충처리를 요청할 수 있습니다.</div>
                            <div><br /></div>
                            <div>⑤ 그 밖에 청소년 유해정보로부터 청소년을 보호하기 위하여 필요한 사항</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>02.청소년보호 책임자 및 담당자</b>
                            </div>
                            <div><br /></div>
                            <div>청소년보호 책임자</div>
                            <div>성 명 : 심세나</div>
                            <div>직 책 : 선임기자</div>
                            <div>소 속 : 홍보팀</div>
                            <div>전화번호 : 02-449-2347</div>
                            <div>전자우편주소 : news@hanteo.com</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>청소년보호 담당자</div>
                            <div>성 명 : 박송이</div>
                            <div>직 책 : 주임</div>
                            <div>소 속 : 홍보팀</div>
                            <div>전화번호 : 02-449-2347</div>
                            <div>전자우편주소 : news.cs@hanteo.com</div>
                        </Body>
                    </>
                );
        }
    }

    render() {
        const {language} = this.props;
        return (
            <>
                {this.protectionBody(language)}
            </>
        );
    }
}
const Header = styled.div`
    margin: 0 30px;
    h1{
        font-size: 2.5rem;
    }
`;
const Body = styled.div`
    margin: 50px 30px;
    line-height: 1.5;
    word-break: break-word;
    b{
        font-weight: 600;
    }
`;

export default Protection;
