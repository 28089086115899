import React, { Component } from "react";
export const HANTEO_INFO = {
    'KO':{
        title:<>한터는 K-POP 빅 데이터를 기반으로<br />아티스트와 팬들이 모두 만족할 수 있는 가치를 만들어가고자 합니다.</>,
        titleMobile:<><h3><span>회사소개</span><br /><span>About Hanteo</span></h3><p>한터는 <span>K-POP 빅 데이터</span>를 기반으로 아티스트와 팬들이 모두 만족할 수 있는 가치를 만들어가고자 합니다.</p></>,
        contents:[{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/intro-icons-01.png',
            imageSrcMobile:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/company-1.png',
            title:<><span>F</span>IRST</>,
            description:<>한터는 대한민국 최초로 <strong>‘다스름 POS’</strong>를 개발하여 음반 판매점에 보급하였으며, 음반물에 바코드 식별자를 최초 도입하는 등 1990년대 초부터 대한민국 음악산업과 함께 성장하며 <strong>K-POP 세계화</strong>를 위하여 열심히 노력하며 경주하고 있습니다.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/intro-icons-02.png',
            imageSrcMobile:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/company-2.jpg',
            title:<><span>O</span>NLY</>,
            description:<>한터는 <strong>전 세계에서 유일한 실시간 음악차트</strong>를 서비스하고 있습니다. 또한 한터는 <strong>전 세계에서 가장 많은 K-POP 메타 데이터를 보유</strong>한 기업입니다.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/intro-icons-03.png',
            imageSrcMobile:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/company-3.png',
            title:<><span>G</span>LOBAL</>,
            description:<>한터는 글로벌 한류를 선도하는 <strong>‘종합 음악 플랫폼’</strong>을 지향합니다. 세계 K-POP 시장의 엑셀레이터가 되기 위해 <strong>‘한터뉴스’, ‘한터매거진’, ‘한터 해외지사화’</strong> 등의 사업을 펼치고 있습니다.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/intro-icons-04.png',
            imageSrcMobile:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/company-4.jpg',
            title:<><span>V</span>ALUE</>,
            description:<>한터는 글로벌 K-POP 팬들을 위한 <strong>커뮤니케이션 플랫폼</strong>, 아티스트의 저작권을 보호하는 <strong>HATS</strong> 등을 개발하여 음악산업에서의 핵심 가치를 끊임없이 찾아가려고 노력합니다.</>  
          }]
    },
    'EN':{
        title:<>Hanteo aims to create value that both artists and fans can be satisfied based on K-POP Big Data.</>,
        titleMobile:<><h3><span>About Hanteo</span></h3><p>Hanteo aims to create value that both artists and fans can be satisfied based on <span>K-POP Big Data</span>.</p></>,
        contents:[{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/intro-icons-01.png',
            imageSrcMobile:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/company-1.png',
            title:<><span>F</span>IRST</>,
            description:<>HANTEO has been growing with the Korean music industry since early 1990s and devoted to <strong>the globalization of K-POP</strong>.We developed and distributed to music stores Korea's first <strong>'Dasurum POS’</strong> and introduced barcode on albums.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/intro-icons-02.png',
            imageSrcMobile:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/company-2.jpg',
            title:<><span>O</span>NLY</>,
            description:<>HANTEO is servicing <strong>the world's one and only real-time music chart</strong>. We also have <strong>the biggest K-POP metadata collection in the world</strong>.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/intro-icons-03.png',
            imageSrcMobile:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/company-3.png',
            title:<><span>G</span>LOBAL</>,
            description:<>HANTEO aims to become the <strong>‘Comprehensive Music Platform’</strong> that leads the global K-wave (Hallyu). HANTEO is expanding business through projects like <strong>‘HANTEO NEWS,’ 'HANTEO MAGAZINE,’ and 'HANTEO overseas branch’</strong> in order to become an accelerator of the world's K-POP market.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/intro-icons-04.png',
            imageSrcMobile:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/company-4.jpg',
            title:<><span>V</span>ALUE</>,
            description:<>HANTEO constantly seeks for the core value of the music industry through developing <strong>a communication platform</strong> for global K-POP fans and <strong>HATS</strong> that protects the artists’ copyrights.</>  
          }]
    },
    'ES':{
        title:<>Hanteo tiene como objetivo crear valor para satisfacer tanto artistas como fanáticos basándose en grandes bases de datos de K-POP.</>,
        titleMobile:<><h3><span>Sobre Hanteo</span></h3><p>Hanteo tiene como objetivo crear valor para satisfacer tanto artistas como fanáticos basándose en grandes bases de <span>datos de K-POP</span>.</p></>,
        contents:[{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/intro-icons-01.png',
            imageSrcMobile:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/company-1.png',
            title:<><span>P</span>rimer</>,
            description:<>HANTEO ha crecido junto con la industria musical coreana desde inicios de los 90's, y se ha dedicado a <strong>la globalización del K-POP</strong>. Nosotros desarrollamos y distribuimos a tiendas de música el Sistema de Punto de <strong>Venta Dasurum</strong>, y la integración del código de barras en los álbumes.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/intro-icons-02.png',
            imageSrcMobile:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/company-2.jpg',
            title:<><span>Ú</span>nico</>,
            description:<>HANTEO brinda el <strong>único chart musical en tiempo real</strong>. También contamos con <strong>la colección más grande de datos sobre el K-POP en el mundo</strong>.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/intro-icons-03.png',
            imageSrcMobile:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/company-3.png',
            title:<><span>G</span>lobal</>,
            description:<>HANTEO busca convertirse en la <strong>“Plataforma Integral de Música”</strong> líder global  de  la ola coreana (hallyu). HANTEO está expandiendo su negocio a través de proyectos como <strong>“HANTEO NEWS(NOTICIAS HANTEO)”, “REVISTA HANTEO” y “HANTEO sucursal en el extranjero”</strong> como una forma de convertirse en acelerador del mercado mundial de K-POP.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/intro-icons-04.png',
            imageSrcMobile:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/company-4.jpg',
            title:<><span>V</span>alor</>,
            description:<>HANTEO busca constantemente el valor agregado en la industria musical por medio del desarrollo de <strong>una plataforma global de comunicación</strong> para los fans del kpop y  <strong>“HATS”</strong> un sistema que protege los derechos de autor de los artistas.</>  
          }]
    },
}
export const HANTEONEWS_INFO = {
    'KO':{
        title:<>한터뉴스는 전 세계 음악산업과 문화를 날카롭게 관찰하고 분석하기 위해 탄생한 매체이며,<br />K-POP 빅데이터 분석 시스템을 바탕으로 개성 넘치는 매체가 되겠습니다.</>,
        titleMobile:<><h3><span>한터뉴스소개</span><br /><span>Hanteo News</span></h3><p>한터뉴스는 <span>전 세계 음악산업과 문화</span>를 날카롭게 관찰하고 분석하기 위해 탄생한 매체이며, K-POP 빅데이터 분석 시스템을 바탕으로 개성 넘치는 매체가 되겠습니다.</p></>,
        contents:[{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/eye_dd3a6c.svg',
            title:<><span>E</span>YES</>,
            description:<><strong>한터뉴스</strong>의 <span>눈</span>은 전 세계 음악산업과 문화를 날카롭고 바라보고 냉정하게 분석하며, 한터차트가 제공하는 <strong>정확한 음악 데이터</strong>를 바탕으로 독점 기사를 생산하겠습니다.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/nose_dd3a6c.svg',
            title:<><span>N</span>OSE</>,
            description:<><strong>한터뉴스</strong>의 <span>코</span>는 트렌드의 냄새를 맡고 변화에 민감하게 반응하고, 빠르게 트렌드를 캐치하기 위해 <strong>현장과 사람에 가장 가까이 다가가는 매체</strong>를 지향하겠습니다.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/mouth_dd3a6c.svg',
            title:<><span>M</span>OUTH</>,
            description:<><strong>한터뉴스</strong>의 <span>입</span>은 가장 정확한 소식만을 전달하는 매체가 되겠습니다.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/ear_dd3a6c.svg',
            title:<><span>E</span>ARS</>,
            description:<><strong>한터뉴스</strong>의 <span>귀</span>는 언제나 열려 있으며, <strong>팬과 아티스트 및 기획자, 그 외의 모든 음악산업 관계자들과 대중의 목소리</strong>에 항상 귀를 기울이며, 그 소리에 맞는 의미 있는 기사를 생산할 수 있도록 노력하겠습니다.</>  
          }]
    },
    'EN':{
        title:<>HANTEO NEWS was established in order to keenly observe and analyze the world's music industry<br />and culture, and we will become a media that stands out among others<br />based on K-POP Big Data analysis system.</>,
        titleMobile:<><h3><span>Hanteo News</span></h3><p>HANTEO NEWS was established in order to keenly observe and analyze <span>the world's music industry and culture</span>, and we will become a media that stands out among others based on K-POP Big Data analysis system.</p></>,
        contents:[{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/eye_dd3a6c.svg',
            title:<><span>E</span>YES</>,
            description:<><strong>HANTEO NEWS</strong>’s <span>“eyes”</span> will objectively observe and analyze the world's music industry and culture and will produce exclusives based on <strong>accurate music data</strong> provided by HANTEO CHART.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/nose_dd3a6c.svg',
            title:<><span>N</span>OSE</>,
            description:<><strong>HANTEO NEWS</strong>'s <span>“nose”</span> will catch trend, keenly react to changes, and strive to become <strong>the media closest to the scene and the people</strong>.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/mouth_dd3a6c.svg',
            title:<><span>M</span>OUTH</>,
            description:<><strong>HANTEO NEWS</strong>'s <span>"mouth"</span> will always deliver the most accurate news.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/ear_dd3a6c.svg',
            title:<><span>E</span>ARS</>,
            description:<><strong>HANTEO NEWS</strong>'s <span>"ears"</span> are always open, attentively listening to <strong>fans, artists, producers, and many others who work in the music industry</strong>. We will strive to write and publish materials that meaningfully responds to those various voices.</>  
          }]
    },
    'ES':{
        title:<>HANTEO NEWS(NOTICIAS HANTEO) fue establecido para analizar a profundidad la industria  y cultura  musical mundial, y nos convertiremos en un medio que destaca de los demás al basarse en un sistema de análisis de datos de Kpop de grandes dimensiones.</>,
        titleMobile:<><h3><span>Hanteo News</span></h3><p>NOTICIAS HANTEO fue establecido para analizar <span>a profundidad la industria  y cultura  musical mundial</span>, y nos convertiremos en un medio que destaca de los demás al basarse en un sistema de análisis de datos de Kpop de grandes dimensiones.</p></>,
        contents:[{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/eye_dd3a6c.svg',
            title:<><span>O</span>JOS</>,
            description:<><strong>HANTEO NEWS(NOTICIAS HANTEO)</strong> varios <span>“ojos”</span> observarán objetivamente la industria y cultura musical y producirán exclusivas basados en <strong>datos precisos provistos</strong> por HANTEO CHART.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/nose_dd3a6c.svg',
            title:<><span>N</span>ARIZ</>,
            description:<><strong>HANTEO NEWS(NOTICIAS HANTEO)</strong> nuestra <span>“nariz”</span> nos ayudará a percibir lo que está de moda y reaccionar a los cambios, esforzándonos para convertirnos en <strong>el medio más cercano a la escena y la gente</strong>.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/mouth_dd3a6c.svg',
            title:<><span>B</span>OCA</>,
            description:<><strong>HANTEO NEWS(NOTICIAS HANTEO)</strong> nuestra <span>“boca”</span> siempre entregará las noticias más precisas.</>  
          },{
            imageSrc:'https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/ear_dd3a6c.svg',
            title:<><span>O</span>íDOS</>,
            description:<><strong>HANTEO NEWS(NOTICIAS HANTEO)</strong> nuestros <span>“oídos”</span> están siempre abiertos para escuchar detenidamente <strong>a los fanáticos, artistas, productores y cualquier otro que trabaja en la industria musical</strong>. Nos esforzaremos por escribir y publicar materiales que respondan de forma significativa a esas variedad de voces.</>  
          }]
    },
}