export const localTimeToUtcTime = (localTime) => {
    
    var date = new Date()
    if (localTime) date = new Date(localTime)
    
        
    let year = `${date.getUTCFullYear()}`.padStart(4, '0')
    let month = `${date.getUTCMonth()+1}`.padStart(2, '0') 
    let day = `${date.getUTCDate()}`.padStart(2, '0');
    
    let hours = `${date.getUTCHours()}`.padStart(2, '0');
    let minute = `${date.getUTCMinutes()}`.padStart(2, '0');
    let seconds = `${date.getUTCSeconds()}`.padStart(2, '0')
    return `${year}-${month}-${day}T${hours}:${minute}:${seconds}`
}

export const utcTimeToLocalTime = (utcTime) => {
    
    var date = new Date()
    if (utcTime) date = new Date(`${utcTime}+00:00`)

    let year = `${date.getFullYear()}`.padStart(4, '0')
    let month = `${date.getMonth()+1}`.padStart(2, '0') 
    let day = `${date.getDate()}`.padStart(2, '0');
    
    let hours = `${date.getHours()}`.padStart(2, '0');
    let minute = `${date.getMinutes()}`.padStart(2, '0');
    let seconds = `${date.getSeconds()}`.padStart(2, '0')
    return `${year}-${month}-${day}T${hours}:${minute}:${seconds}`
}