import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import "styles/common.css"
import NSLeot from "fonts/NanumSquareL.eot";
import NSLttf from "fonts/NanumSquareL.ttf";
import NSLwoff from "fonts/NanumSquareL.woff";
import NSeot from "fonts/NanumSquare.eot";
import NSttf from "fonts/NanumSquare.ttf";
import NSwoff from "fonts/NanumSquare.woff";
import NSBeot from "fonts/NanumSquareB.eot";
import NSBttf from "fonts/NanumSquareB.ttf";
import NSBwoff from "fonts/NanumSquareB.woff";
import NSEBeot from "fonts/NanumSquareB.eot";
import NSEBttf from "fonts/NanumSquareB.ttf";
import NSEBwoff from "fonts/NanumSquareB.woff";
import NSRLeot from "fonts/NanumSquareRoundL.eot";
import NSRLttf from "fonts/NanumSquareRoundL.ttf";
import NSRLwoff from "fonts/NanumSquareRoundL.woff";
import NSRReot from "fonts/NanumSquareRoundR.eot";
import NSRRttf from "fonts/NanumSquareRoundR.ttf";
import NSRRwoff from "fonts/NanumSquareRoundR.woff";
import NSRBeot from "fonts/NanumSquareRoundB.eot";
import NSRBttf from "fonts/NanumSquareRoundB.ttf";
import NSRBwoff from "fonts/NanumSquareRoundB.woff";
import NSREBeot from "fonts/NanumSquareRoundEB.eot";
import NSREBttf from "fonts/NanumSquareRoundEB.ttf";
import NSREBwoff from "fonts/NanumSquareRoundEB.woff";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/scss';

// 'Lato', 'Noto Sans SC', 'NanumSquare';
const GlobalStyles = createGlobalStyle`
${reset}
* {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    /* 스크롤 바 */
  ::-webkit-scrollbar {
    width: 2px;
    height:1px;
  }
  /* 스크롤 바 밑의 배경 */
  ::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }
  /* 실질적 스크롤 바 */
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
  }
  /* 실질적 스크롤 바 위에 마우스를 올려다 둘 때 */
  ::-webkit-scrollbar-thumb:hover {
    background: #fff;
  }
  /* 실질적 스크롤 바를 클릭할 때 */
  ::-webkit-scrollbar-thumb:active {
    background: #bf70f5;
  }
  /* 스크롤 바 상 하단 버튼 */
  ::-webkit-scrollbar-button {
    display: none;
  }
}

@font-face {
  font-family: 'NanumSquare';
  font-weight:200;
  src: url(${NSLeot});
  src: url(${NSLeot}?#iefix) format('embedded-opentype'),
  url(${NSLwoff}) format('woff'),
  url(${NSLttf}) format('truetype');
}
@font-face {
  font-family: 'NanumSquare';
  font-weight:400;
  src: url(${NSeot});
  src: url(${NSeot}?#iefix) format('embedded-opentype'),
  url(${NSwoff}) format('woff'),
  url(${NSttf}) format('truetype');
}
@font-face {
  font-family: 'NanumSquare';
  font-weight:600;
  src: url(${NSBeot});
  src: url(${NSBeot}?#iefix) format('embedded-opentype'),
  url(${NSBwoff}) format('woff'),
  url(${NSBttf}) format('truetype');
}
@font-face {
  font-family: 'NanumSquare';
  font-weight:800;
  src: url(${NSEBeot});
  src: url(${NSEBeot}?#iefix) format('embedded-opentype'),
  url(${NSEBwoff}) format('woff'),
  url(${NSEBttf}) format('truetype');
}
@font-face {
  font-family: 'NanumSquareRound';
  font-weight:200;
  src: url(${NSRLeot});
  src: url(${NSRLeot}?#iefix) format('embedded-opentype'),
  url(${NSRLwoff}) format('woff'),
  url(${NSRLttf}) format('truetype');
}
@font-face {
  font-family: 'NanumSquareRound';
  font-weight:400;
  src: url(${NSRReot});
  src: url(${NSRReot}?#iefix) format('embedded-opentype'),
  url(${NSRRwoff}) format('woff'),
  url(${NSRRttf}) format('truetype');
}
@font-face {
  font-family: 'NanumSquareRound';
  font-weight:700;
  src: url(${NSRBeot});
  src: url(${NSRBeot}?#iefix) format('embedded-opentype'),
  url(${NSRBwoff}) format('woff'),
  url(${NSRBttf}) format('truetype');
}
@font-face {
  font-family: 'NanumSquareRound';
  font-weight:800;
  src: url(${NSREBeot});
  src: url(${NSREBeot}?#iefix) format('embedded-opentype'),
  url(${NSREBwoff}) format('woff'),
  url(${NSREBttf}) format('truetype');
}
html{
}
  a {
    color: inherit;
    text-decoration: none;
  }
  a:hover{
    color: inherit;
    text-decoration: none;
  }
  input, button {
    background-color: transparent;
    border: none;
    outline: none;
  }
  ol, ul, li {
    list-style: none;
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
  }
body{
  color:#222;
  overflow-x:hidden;
  font-family: 'Lato';
}


.hflex-cc{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.hflex-ctop{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.hflex-leftc{
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.hflex-lefttop{
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}
.hflex-leftstr{
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
}
.hflex-sbc{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.hflex-sbtop{
  display:flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}



.vflex-cc{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.vflex-leftc{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.vflex-stc{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
  `;

export default GlobalStyles;
