import React, { Component } from "react";
import styled from "styled-components";

class Video extends Component {

    onClick = (_data) => {
        if (window.location.pathname.indexOf('app') > 0) {
            var link = 'whosfanglobal://open?url=' + encodeURIComponent(window.location.origin + `/app/${_data.language.toLowerCase()}?fc=${_data.idx}`);
            window.location.href = link;
        } else {
            var link = window.location.origin + _data.link;
            window.location.href = link;
        }
    }

    render() {
        const { data } = this.props;
        return (
            <>
                {this.props.data ?
                    <ArticleDiv onClick={this.onClick.bind(this, data)}>
                        <ThumbnailDiv>
                            <ThumbnailImg src={data.thumbnail}></ThumbnailImg>
                            <GradationCover></GradationCover>
                            <IconDiv>
                                <img src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/video_tag_asset.png">
                                </img>
                            </IconDiv>
                        </ThumbnailDiv>
                        {data.title !== undefined && data.title !== null ? <TitleDiv dangerouslySetInnerHTML={{ __html: data.title }}></TitleDiv> : <></>}
                    </ArticleDiv> : ''}
            </>
        );
    }
}

const TitleDiv = styled.div``;
const IconDiv = styled.div``;
const GradationCover = styled.div``;
const ThumbnailImg = styled.img``;
const ThumbnailDiv = styled.div``;
const ArticleDiv = styled.a`
    cursor: pointer;

    @media (min-width: 1048px) {
        cursor: pointer;
    }

    ${ThumbnailDiv}{
        position: relative;

        @media (min-width: 1048px) {
            position: relative;
        }

        ${ThumbnailImg}{
            width: 100%;
            height: auto;

        }
        ${GradationCover}{
            display: block;

            @media (min-width: 1048px){
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                background-image: linear-gradient(rgba(0, 0, 0, 0.00) 60%, rgba(0, 0, 0, 0.64) 100%);
                background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.00) 60%, rgba(0, 0, 0, 0.64) 100%);
                background-image: -o-linear-gradient(rgba(0, 0, 0, 0.00) 60%, rgba(0, 0, 0, 0.64) 100%);
                background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.00) 60%, rgba(0, 0, 0, 0.64) 100%);
            }
        }
        ${IconDiv}{
            @media (min-width: 1048px) {
                
            }

            img{
                position: absolute;
                bottom: 5%;
                left: 3%;
                width: 8%;
                height: auto;
            }
        }
    }
    ${TitleDiv}{

        color: black;
        font-size: 16px;
        margin: 9px 0 9px 0;
        word-break: break-all;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;
    }
`;
export default Video;
