import React, { Component } from "react";
import styled from "styled-components";
import {getQueryParameter} from "../../../utils/UriUtils";

class Pagination extends Component {

    makeLeftBtnDiv() {
        var layout = [];

        if (this.props.data.currentPageNum > 10) {
            layout.push(<Num key={'ll'}><DbLeftBtn src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/arrow_left_end.jpg" onClick={this.onClickArrow.bind(this)} data-target={this.props.data.size} data-arrow={'-'}></DbLeftBtn></Num>)
        }

        if (this.props.data.currentPageNum === 1) {
            layout = null;
        } else {
            layout.push(<Num key={'l'}><LeftBtn src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/arrow_left.jpg" onClick={this.onClickArrow.bind(this)} data-target={'1'} data-arrow={'-'}></LeftBtn></Num>)
        }


        return layout;
    }

    makeRightBtnDiv() {
        var layout = [];

        if (this.props.data.currentPageNum === this.props.data.totalPageNum) {
            layout = null;
        } else {
            layout.push(<Num key={'r'}><RightBtn src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/arrow_right.jpg" onClick={this.onClickArrow.bind(this)} data-target={'1'} data-arrow={'+'}></RightBtn></Num>)
        }

        if (this.props.data.totalPageNum - this.props.data.currentPageNum >= 10) {
            layout.push(<Num key={'rr'}><DbRightBtn src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/arrow_right_end.jpg" onClick={this.onClickArrow.bind(this)} data-target={this.props.data.size} data-arrow={'+'}></DbRightBtn></Num>)
        }

        return layout;
    }

    onClickArrow = (e) => {
        
        var currentPageNum = Number(this.props.data.currentPageNum);
        if(currentPageNum < 1) currentPageNum = 1

        var pageNum = undefined;
        if (e.currentTarget.dataset.arrow === '+') {
            pageNum = Number(currentPageNum) + Number(e.currentTarget.dataset.target)
        } else {
            pageNum = Number(currentPageNum) - Number(e.currentTarget.dataset.target)
        }
        if (pageNum > this.props.data.totalPageNum) {
            pageNum = this.props.data.totalPageNum;
        }

        this.props.onClick({
            page: pageNum
        })
    }

    makePaginationNum() {
        var layout = [];
        
        if (this.props.data.totalPageNum > 0) {
            for (var i = this.props.data.startPageNum - 1; i < this.props.data.endPageNum; i++) {
                layout.push(<Num key={i} className={Number(this.props.data.currentPageNum) === (i + 1) ? "active" : ""} onClick={this.onClick.bind(this)} data-target={i + 1}>{i + 1}</Num>)
            }
        } else {
            layout = null;
        }
        return layout;
    }

    onClick = (e) => {
        this.props.onClick({
            page: Number(e.currentTarget.dataset.target)
        })
    }


    render() {
        const {data, onClick} = this.props;
        return (
            <>
                {data ?
                    <PaginationContainer>
                        <LBtn>
                            {this.makeLeftBtnDiv()}
                        </LBtn>
                        <NumberDiv>
                            {this.makePaginationNum()}
                        </NumberDiv>
                        <RBtn>
                            {this.makeRightBtnDiv()}
                        </RBtn>
                    </PaginationContainer>
                    : <></>}
            </>
        );
    }

}

const DbLeftBtn = styled.img`
    display: none;
    width:17px;
    height:17px;
    @media (min-width:1048px) {    
        display: block;
        margin: 0 8px;
    }
`;
const LeftBtn = styled.img`
    width:17px;
    height:17px;
`;
const RightBtn = styled.img`
    width:17px;
    height:17px;
`;
const DbRightBtn = styled.img`
    display: none;
    width:17px;
    height:17px;
    @media (min-width:1048px) {    
        display: block;
        margin: 0 8px;
    }
`;

const Num = styled.a``;
const NumberDiv = styled.div``;


const RBtn = styled.div``;
const LBtn = styled.div``;
const PaginationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    ${LBtn}{
        display: inline-flex;
        justify-content: flex-end;
        width: 66px;
        height: 24px;
        ${Num}{
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin: 0 6px;
            @media (min-width:1048px) {    
                margin: 0 8px;
            }
    
        }
    }
    ${RBtn}{
        display: inline-flex;
        width: 66px;
        height: 24px;
        ${Num}{
            
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin: 0 6px;
            @media (min-width:1048px) {    
                margin: 0 8px;
            }
        }
    }
    ${NumberDiv}{
        display: inline-flex;
        margin: 14px 15px;
        font-size: 13px;
        @media (min-width:1048px) {
            margin: 0px 15px;
        }
        ${Num}{
            cursor: pointer;
            margin: 0 6px;
            &.active{
                text-decoration: underline !important;
                text-decoration-color: #DA3A6C !important;
                color: #DA3A6C !important;
            }
            @media (min-width:1048px) {
                margin: 0 8px;
            }
        }
    }
`;

export default Pagination;
