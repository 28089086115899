import React, { Component } from "react";
import styled from "styled-components";

import CategoryHeaderView from 'components/view/list/CategoryHeader';
import ListView from 'components/view/list/ListView';
import PaginationVM from "components/view_model/common/PaginationVM";
import AdBannerAndMostArticleVM from 'components/view_model/article/AdBannerAndMostArticleVM';
import NoArticleView from "components/view/common/NoArticle"
import { getSearchArticle } from "api/NewsAPI";
import GoogleMainAds from "../../view/ads/GoogleMainAds";
import {ConvertSystemHtmlChartoSource} from "../../../utils/StringUtils";
import { getListOfPost } from "api/PostAPI";
import { MENU_CONST } from "global/string";
import { getQueryParameter } from "utils/UriUtils";
import { utcTimeToLocalTime } from "utils/DateUtils";
import {convertListOfIdx} from "../../../utils/CategoryUtils";
import GoogleBannerAds from "../../view/ads/GoogleBannerAds";

class ListPageVM extends Component {

    constructor() {
        super();
        this.state = {
            data: undefined
        }
        this.startPage = getQueryParameter('startPage', window.location) ?? 1
        this.order = getQueryParameter('order', window.location) ?? 0
        this.size = 10;
    }

    componentDidMount() {

        if (this.props.header===undefined || this.props.header===null || this.props.header.length===0) return;

        var startPage = 1;
        if (this.startPage !== undefined) {
            startPage = this.startPage
        }

        var listOfSection = undefined
        var listOfCategory = undefined
        if (this.props.header.title===MENU_CONST.latestCategory[this.props.language].title) {
            listOfSection = MENU_CONST.latest[this.props.language]
        } else {
            listOfCategory = convertListOfIdx([this.props.header]).join(',')
        }

        var offset = (startPage - 1) * this.size;
        var limit = this.size;

        var params = {
            offset: offset,
            limit: limit,
            order: this.order === 0? "DESC": "ASC",
            clientIdx: 1022,
            language: this.props.language,
            summary: true
        }
        if (listOfCategory) params['listOfCategory'] = listOfCategory
        if (listOfSection) params['listOfSection'] = listOfSection
        

        getListOfPost(params, (res) => {
            var listOfPost = res.resultData;
            var list = []
            
            listOfPost.map((post, i) => {
                var category = this.props.header.title
                
                let startDate = utcTimeToLocalTime(post.publish.startDate);
                let startDay = startDate.split("T")[0]
                let startTime = startDate.split("T")[1]
                list.push({
                    idx: post.ownership.idx,
                    thumbnail: post.meta.content.thumbnail,
                    title: ConvertSystemHtmlChartoSource(post.meta.content.title),
                    content: ConvertSystemHtmlChartoSource(post.meta.content.seo),
                    date: `${startDay} ${startTime}`,
                    language: post.meta.language,
                    link: `/${post.meta.language.toLowerCase()}/article/${category.toLowerCase()}?fc=${post.ownership.idx}`
                })
                return true;
            })

            this.setState({
                data: list
            })
        })
    }

    onClickArticle(_result) {
        window.location.href = window.location.origin + _result.link;
    }

                                       
    goNextPage(data) {
        var link = '?startPage=' + data.page
        window.location.href = link
    }

    renderContentAds(post) {
        if (document.body.offsetWidth < 1048) {
            return <ListAds>
                <GoogleBannerAds
                    id={`content-ad-${post.idx}`}
                    adId={`div-gpt-ad-202407231234366-276-${post.idx}`}
                    adType={"static"}
                    slot={{
                        id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_MWEB_category_middle_320x100",
                        size: "[320,100]"
                    }}
                />
            </ListAds>
        }
        return <ListAds>
            <GoogleBannerAds
                id={`content-ad-${post.idx}`}
                adId={`div-gpt-ad-202407231222165-661-${post.idx}`}
                adType={"static"}
                slot={{
                    id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_category_middle_728x90",
                    size: "[728,90]"
                }}
            />
        </ListAds>
    }

    render() {
        const {language, header} = this.props;
        return (
            <>
                <ListPageContainer>
                    <LeftSideDiv>
                        {header ? <CategoryHeaderView data={{
                            'title': header.title.toUpperCase(),
                            'description': header.description
                        }}></CategoryHeaderView> : <></>}
                        <ArticleContainer>
                            <ArticleListDiv>
                                {this.state.data ? this.state.data.map((post, i) => {
                                    return <>
                                        <ListView
                                            key={i}
                                            data={post}
                                            onClickCallBack={this.onClickArticle}></ListView>
                                            {i%4===3? this.renderContentAds(post):<></>}
                                    </>
                                }) : <></>}
                            </ArticleListDiv>
                            {this.state.data? 
                                <PaginationVM 
                                    current={this.startPage} 
                                    size={this.size} 
                                    onClick={this.goNextPage}/> 
                                : <NoArticleView language={language} />}
                        </ArticleContainer>

                    </LeftSideDiv>
                    <RightSideDiv>
                        <SideTopAds>
                            <GoogleBannerAds
                                id={"side-top-ad"}
                                adId={"div-gpt-ad-202407231222169-916"}
                                adType={"static"}
                                slot={{
                                    id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_famousarticle_top_300x250",
                                    size: "[300,250]"
                                }}
                            />
                        </SideTopAds>
                        <AdBannerAndMostArticleVM
                            language={language} />
                        <SideBottomAds>
                            <GoogleBannerAds
                                id={"side-bottom-ad"}
                                adId={"div-gpt-ad-202407231234360-230"}
                                adType={"static"}
                                slot={{
                                    id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_famousarticle_bottom_300x600",
                                    size: "[300,600]"
                                }}
                            />
                        </SideBottomAds>
                    </RightSideDiv>
                </ListPageContainer>

                <GoogleBannerAds
                    id={"bottom-anchor-ad"}
                    adType={"anchor"}
                    slot={{
                        id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_bottom_anchor_970x90"
                    }}
                />
            </>
        );
    }

}

const ListAds = styled.div``;
const ArticleListDiv = styled.div``;
const ArticleContainer = styled.div``;

const SideTopAds = styled.div``;
const SideBottomAds = styled.div``;
const RightSideDiv = styled.div``;
const LeftSideDiv = styled.div``;
const ListPageContainer = styled.div`
    display: block;
    overflow: hidden;
    @media (min-width:1048px) {
        display: flex;
    }
    ${LeftSideDiv}{
        line-height: 1.5;
        max-width: 100%;
        @media (min-width:1048px) {
            width: 728px;
            flex-basis: auto;
            flex-grow: unset;
            margin: 0 0 100px 0;
        }

        ${ArticleContainer}{
            ${ArticleListDiv}{
                margin: 0 0 0 0;
                border-top: 1px solid #999999;
                width: 100%;
                
                @media (min-width:1048px) {
                    margin: 0 0 22px 0;
                    font-size: 14px;
                    color: #444444;
                    border-top: 1px solid #EEEEEE;
                }
            }
        }
    }
    ${RightSideDiv}{
        line-height: 1.5;
      
        ${SideTopAds}, ${SideBottomAds} {
          display: none;
        }
      
        @media (min-width:1048px) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            width: 320px;
          
            ${SideTopAds}, ${SideBottomAds}{
              display: block;
              margin: 16px 0 0 0;
            }
        }
    }
`;

export default ListPageVM;
