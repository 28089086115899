import React, { Component } from "react";
import styled from "styled-components";

class SearchHeader extends Component {

    render() {
        return (
            <>
                {this.props.data ?
                    <HeaderContainer>
                        <HeaderDiv>
                            <Keyword>{this.props.data.keyword}</Keyword>
                            {'\u00A0'}
                            <HeaderText>{this.props.data.search}</HeaderText>
                        </HeaderDiv>
                        <OrderDiv>
                            <OrderOne className={this.props.data.order !== "1" ? "active" : ""} onClick={this.props.onClickCallBack.bind(this, 0)}>{this.props.data.latest}</OrderOne>
                            {'\u00A0'}|{'\u00A0'}
                            <OrderTwo className={this.props.data.order === "1" ? "active" : ""} onClick={this.props.onClickCallBack.bind(this, 1)}>{this.props.data.oldest}</OrderTwo>
                        </OrderDiv>
                    </HeaderContainer>
                    : null}
            </>
        );
    }

}

const OrderOne = styled.p``;
const OrderTwo = styled.p``;

const HeaderText = styled.p``;
const Keyword = styled.p``;
const OrderDiv = styled.div``;
const HeaderDiv = styled.div``;
const HeaderContainer = styled.div`
    position: relative;
    display: inline-flex;
    width: 100%;
    margin: 10px 0 0 0;
    @media (min-width:1048px) {
        margin: 35px 0 0 0;
    }

    ${HeaderDiv}{
        display: inline-flex;
        margin: 15px 0 15px 10px;
        

        ${Keyword}{
            font-size: 18px;
            font-weight: 500;
            line-height: 1.2;
            margin: 0px;
            color: #DA3A6C;
            max-width: 115px;
            word-break: break-all;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        ${HeaderText}{
            font-size: 18px;
            margin: 0px;
            font-weight: 500;
            line-height: 1.2;
        }
    }
    ${OrderDiv}{
        display: inline-flex;
        position: absolute;
        right: 13px;
        align-self: center;
        font-size: 13px;

        @media (min-width:1048px) {
            right: 0;
        }

        ${OrderOne}{
            cursor: pointer;
            &.active{
                color: #DA3A6C;
            }
        }
        ${OrderTwo}{
            cursor: pointer;
            &.active{
                color: #DA3A6C;
            }
        }
    }
`;

export default SearchHeader;
