import React, { Component } from "react";
import styled from "styled-components";

class Ethics extends Component {

    ethicsBody(language) {
        switch (language) {
            case 'KO':
                return (
                    <>
                        <Header>
                            <h2><b>한터뉴스 </b><b style={{ color: 'red' }}>윤리강령</b></h2><b>최종 수정 : 2020년 3월 31일</b>
                        </Header>
                        <Body>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>서문</b>
                            </div>
                            <div><br /></div>
                            <div>한터뉴스는 언론으로서 자유와 책임을 실천하고, 사회 정의를 실현하기 위하여 ‘한터뉴스 윤리 강령’을 제정한다. 한터뉴스에 종사하는 모든 언론인과 임직원들은 이를 준수할 것을 다짐한다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>제1조 취재 및 보도의 기준과 방향</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1.    표현의 자유와 책임</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      국민의 알 권리 보장을 보도의 목적으로 삼는다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      취재와 편집 및 표현의 자유를 신장하되 이에 따르는 책임에 토대가 있음을 명심하고 건전한 여론 형성, 문화의 창달, 공공복리 증진, 국민의 기본권 수호에 노력한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      정치, 경제, 사회, 문화의 권력으로부터 독립해 취재 활동을 하고 이러한 권력의 압력과 청탁을 거부한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>2.    진실성 추구</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      사실과 진실을 토대로, 정확하고 공정한 보도를 최우선시한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      취재하며 얻게 된 정보를 왜곡하지 않고, 사실 그대로를 적시하여 표기한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      취재하며 얻게 된 정보는 사실 여부를 반드시 확인하도록 한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      기사의 정확성과 신뢰성을 위하여 출처 공개를 원칙으로 한다. 다만, 제보자 혹은 취재원의 신변 보호를 위하여 불가피하다고 판단될 경우는 예외로 둔다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      양쪽의 이익이 상충되는 내용을 취재, 보도할 때에는 균형성을 유지한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>3.    편집</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      독자가 사실과 의견을 혼동하지 않도록 표현하고 편집한다. 또한 기사와 광고를 명확히 구분할 수 있도록 한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      지나친 광고 지면 할애로 기사의 내용 전달이 어렵지 않도록 유의한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      로봇 저널리즘에 의한 ‘한터뉴스 봇’ 기사의 경우에는 인력으로 쓴 다른 기사들과 구분되도록 편집하며, 기사의 정체성을 반드시 기사 내부에 포함하도록 한다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>제2조 직업 윤리</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1.    저작권 보호</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      공표된 저작물을 보도에 활용하며, 이외의 경우 반드시 정확한 출처를 표시한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      타 언론사나 타인의 글과 콘텐츠, 아이디어를 도용하거나 표절하지 않는다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      타인의 저작물을 이용할 경우에는 반드시 저작권자의 동의 하에, 저작권 사용의 허용 범위 내에서 이용한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>2.    약자 보호와 차별 금지</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      미성년자의 건전한 인격 형성과 정서함양을 위해 노력하고 음란하거나 폭력적인 유해 환경으로부터 이들을 보호한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      어떠한 경우에도 편견과 차별, 혐오를 배제하며, 이러한 편견에 근거해 개인이나 집단을 차별하지 않는다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      어린이, 장애인, 외국인 등 사회적 약자와 소수자의 권리를 보호한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      비극적 사건 등으로 고통을 겪은 사람들과 그 친지들을 취재하거나 보도할 때에 특별히 주의를 기울인다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>3.    언론인으로서의 윤리</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      취재와 보도를 말미암아 타인으로부터 금품 등을 요구하지 않는다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      취재 과정에서 알게된 정보를 이용해 금전적 이익을 얻거나 손실을 회피하는 행위를 하지 않는다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      취재된 내용이 보도되기 전에는 외부인이나 제3자에게 해당 내용을 공개해서는 안 된다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      공익이 우선하지 않는 한 개인의 명예를 훼손하지 않고 타인의 사생활 보호에 노력한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      경제적 이득과 관련된 방식이 아니더라도 취재를 위해 도청, 신분 사칭 등의 비윤리적 행위를 하지 않는다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      부당한 방법으로 광고나 협찬을 수주하지 아니하며, 회사의 명예를 실추하는 어떠한 행위도 하지 않는다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>제3조 보도로 인한 피해의 구제</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1.    오보 수정</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      오보에 대한 제보를 받을 수 있는 창구를 마련해 놓는다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      오보가 발생할 경우 사실 여부를 확인 후 최대한 신속하게 기사 내용을 수정한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      기사 내용이 수정이 어려울 경우, 오보에 대한 기사를 따로 작성하며, 이에 대한 접근 및 접속이 용이하도록 배려한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>2.    반론권 보장</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      보도로 인해 불리한 입장에 처할 수 있는 개인이나 단체 등에 대해 해명할 수 있도록 사전에 반론 기회를 제공한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      취재원이 반론권을 행사할 경우 반드시 기사 내용에 포함시키도록 한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      기사에 대한 피해와 반론을 제기하는 당사자가 있을 경우, 그 의견에 최대한 귀 기울이며 보도된 기사 내용에 수정이 필요한 경우에는 최대한 신속하게 수정한다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>제4조 SNS(소셜 네트워크 서비스) 활동</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1.    매체 공식 SNS 사용</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      한터뉴스로 출고된 기사는 한터뉴스 공식 SNS 계정에 게시함을 원칙으로 한다. 또한 정확하고 효율적인 취재와 보도를 위해 한터뉴스의 공식 SNS 계정을 이용할 수도 있다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      한터뉴스의 공식 SNS 계정 관리자는 해당 계정에 대한 비밀 정보(아이디, 비밀번호)를 비담당자나 제3자에게 노출하지 않는다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>2.    개인 SNS 활용</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      개인적으로 하는 SNS 활동은 각자의 책임 하에 시행하며, 그 결과에 스스로 책임지는 것을 원칙으로 한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      개인 활동에 앞서 직업적 윤리와 한터뉴스의 소속 언론인임을 유념하며, 공정성과 신뢰성을 의심받지 않도록 주의한다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>제5조 윤리위원회</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1.     윤리위원회 설치 및 운영</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      한터뉴스는 자체 윤리강령의 실천에 대하여 심의 판단할 '윤리위원회'를 설치 및 운영한다.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      한터뉴스 내부 언론인과 임직원뿐만 아니라 한터뉴스의 외부 필자들도 한터뉴스의 윤리강령에 따를 수 있도록 고지하고 교육한다.</div>
                        </Body>
                    </>
                );
            case 'es':
                return (
                    <>
                        <Header><h2><b style={{ color: 'red' }}>Código ético </b><b>de Hanteo News</b></h2><b>Actualizado: 31 de marzo, 2020</b></Header>
                        <Body>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Introducción</b>
                            </div>
                            <div><br /></div>
                            <div>Para poder practicar la libre expresión, poseer responsabilidad comunicativa y aplicar justicia social, como medio comunicacional a través del presente, Hanteo News promulga el ‘Código ético de Hanteo News’, en el cual todos los corresponsales y empleados de Hanteo News están en la obligación de guiarse bajo el siguiente código.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>Artículo 1. Dirección y estándares de reportaje y cobertura</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1.      Libertad de expresión y responsabilidad comunicativa</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Nuestro objetivo respecto a la cobertura periodística es el derecho que posee el público a enterarse del acontecer diario.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Destacamos la libertad de reportaje, edición, y expresión, pero cabe mencionar que estos ítems se basan en las responsabilidades ligadas periodismo que se esfuerzan en crear una opinión pública objetiva, el desarrollo cultural, la difusión del bienestar público, y la protección de los derechos básicos del pueblo.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Reportamos noticias no ligadas a la política, economía, situación social o cultural, y denegamos cualquier presión o solicitud que realicen agentes de dichos campos.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>2.      La búsqueda de hechos verídicos</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Priorizamos el informar de forma clara y precisa en base a hechos y evidencia concreta.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     No distorsionamos la información obtenida al momento de comunicar las noticias y nos atenemos a los hechos tal como lo son.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Nos aseguramos de verificar si la información obtenida es verídica.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Revelamos nuestras fuentes con motivos de precisar la información del artículo, además de generar confianza en su contenido. Sin embargo, podrían ocurrir excepciones en casos donde se deba proteger el informante de esa fuente.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Mantenemos un balance entre ambas partes cuando nuestro artículo informa sobre conflictos de intereses.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>3.      Edición</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Redactamos y editamos nuestros artículos para que nuestros lectores no confundan los hechos con opiniones personales. También, diferenciamos de forma clara lo que son artículos y lo que son anuncios publicitarios.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     También tomamos la precaución de no crear contenido difícil de leer debido a un exceso de publicidad.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     En el caso de los artículos redactados por nuestro software a cargo de ‘Hanteo News Bot’, éstos son editados para poder diferenciarlos de aquellos escritos por nuestros corresponsales, y así poder también esclarecer la identidad del artículo dentro de este mismo.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>Artículo 2. Ética profesional</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1.      Protección de los derechos de autor</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Utilizamos contenido publicado con fines informativos e indicamos la fuente exacta.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     No robamos ni plagiamos textos, contenidos o ideas de otros medios o entidades de comunicación.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Cuando se utilice el trabajo de otro agente, se utiliza al margen de la ley de derechos de autor y bajo el consentimiento del propietario. </div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>2.   Protección al más débil y prohibición de discriminación</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Nos esforzamos por crear una identidad sana y cultivar buenas emociones en los menores de edad para protegerlos de ambientes obscenos, violentos o dañinos.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Excluimos los prejuicios, la discriminación y el odio bajo cualquier circunstancia, al igual que no discriminamos a los individuos o grupos en base a dichos ítems.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      Protegemos los derechos de aquellos socialmente desfavorecidos y las minorías, incluyendo, pero no limitándose a, niños, discapacitados, y extranjeros.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      Somos cuidadosos al momento de informar sobre personas juntos a sus familiares que han sufrido trágicos eventos.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>3. Ética como corresponsales</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     No recibimos ni pedimos dinero u otros objetos de valor a cambio de reportajes o artículos.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     No utilizamos la información reunida para un artículo para obtener beneficios financieros o evadir pérdidas de esta índole.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     No revelamos el contenido de nuestros artículos con personas externas hasta que el reportaje ha sido publicado.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     A menos que se priorice el interés público, no comprometemos la reputación de individuos y nos esforzamos por proteger la privacidad de los demás.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Incluso si no está relacionado a beneficios económicos, no participamos en actos poco éticos, como espionaje o suplantación de identidad para lograr la creación de un reportaje.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     No recibimos anuncios o patrocinios de ninguna manera ilegítima, y no actuamos de ninguna manera que podría poner en peligro la reputación de la empresa.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>Artículo 3. Medidas de solución por daños causados por cobertura mediática</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1.      Corrección de información imprecisa</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-    Proporcionamos un medio para recibir declaraciones sobre información imprecisa.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Cuando se detecta una información imprecisa, revisaremos los hechos y corregiremos el artículo lo antes posible.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Si el contenido del artículo es difícil de modificar, se redactará otro artículo esclareciendo la información imprecisa expuesta anteriormente, al cual el público pueda acceder de forma fácil.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>2. Garantía de objeción</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Proporcionamos una oportunidad previa para explicar o argumentar los hechos, a todos aquellos que resulten en desventaja por la publicación del artículo.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Si la fuente hace uso de su derecho a objeción, nos aseguraremos de incluirlo en el artículo.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Si existe una parte involucrada en el artículo que presenta una objeción o reclama verse afectado por este mismo, tomaremos en cuenta la opinión tanto como sea posible y, si es necesario, corregiremos el contenido del artículo ya publicado lo antes posible.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>Artículo 4. Actividades en redes sociales</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1.      Uso de las redes sociales oficiales</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Esencialmente, los artículos publicados por Hanteo News deben publicarse en las redes sociales oficiales de Hanteo News. También estas redes sociales podrían utilizarse para un reportaje más preciso y eficiente.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     El administrador de la red social oficial de Hanteo News no ha de revelar información confidencial de la cuenta (nombre de usuario y contraseña) a empleados que no se encuentren a cargo o terceros.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>2.      Uso de redes sociales a nivel personal</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Las actividades personales en redes sociales son de exclusiva responsabilidad de sus dueños, y no nos hacemos responsable de su contenido.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Aparte de publicar contenido personal, trabajamos bajo una ética profesional y el hecho que somos corresponsales de Hanteo News. También, tomamos la precaución que nuestros actos no resulten en el cuestionamiento de nuestra credibilidad.</div>
                            <div><br /></div>
                            <div><b>Artículo 5. Comité de ética</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1.    Establecimiento y operación del comité de ética</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Hanteo News ha establecido y opera un comité ético que delibera y juzga sobre la implementación de su propio código.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     Hanteo News notifica y educa no solo a los corresponsales y empleados de Hanteo News, pero también a aquellos periodistas externos a que sigan el código ético impuesto por Hanteo News.<div style={{ 'margin-left': '4em' }}></div>
                                <div><br /></div>
                            </div>
                        </Body>
                        

                    </>
                );
            default:
                return (
                    <>
                        <Header><h2><b>Hanteo News </b><b style={{ color: 'red' }}>Code of Ethics</b></h2><b>Last edited: March 31, 2020</b></Header>
                        <Body>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Introduction</b>
                            </div>
                            <div><br /></div>
                            <div>In order to practice freedom and responsibility as a media and realize social justice, Hanteo News hereby enacts the ‘Hanteo News Code of Ethics.’ All journalists and employees of Hanteo News are committed to complying with the code below.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>Article 1. Standards and Direction of coverage and reporting</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1. Freedom of expression and responsibility</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      Our goal of reporting is the public’s right to know.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We enhance the freedom of coverage, editing, and expression, but bear in mind that these are founded upon the responsibilities that follow and strive for the formation of sound public opinion, the development of culture, the promotion of public welfare, and the protection of the basic rights of the people.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We cover news independent from political, economic, social, and cultural powers and deny the pressure and solicitation of such powers.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>2. Pursuit of Truth</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We prioritize accurate and fair reporting based on facts and truths.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We do not distort the information obtained while covering the news and indicate the facts as they are.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We ensure to check whether the information obtained is true.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-     We disclose the source of the information in principle for the sake of accuracy and reliability of the article. However, exception may be made if it is considered inevitable to protect the informer or the source.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We maintain balance between two parties when reporting on conflicting interests.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>3. Editing</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We write and edit so that readers do not confuse facts with opinions. We also clearly differentiate between articles and advertisements.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We also take caution not to make it difficult to deliver the content of an article due to excessive advertising.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      In the case of the ‘Hanteo News Bot’ article by robot journalism, we edit it so that it is distinguishable from other articles written by humans, and we ensure to include the article's identity inside the article.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>Article 2. Professional Ethics</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1. Copyright Protection</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We utilize the publicly published works for reporting and indicate the exact source otherwise.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We do not steal or plagiarize text, content or ideas from other media or entities.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      When using other people's works, we use them within the allowable range of copyright use, with the consent of the copyright holder.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>2. Protection of the Weak and Prohibition of Discrimination</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We strive to help to build healthy personality and to cultivate emotions of minors and protect them from obscene, violent, or harmful environments.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We exclude prejudice, discrimination, and hate under any circumstances, and we do not discriminate individuals or groups based on such prejudice or hate.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We protect the rights of the socially disadvantaged and minorities, including, but not limited to, children, the disabled, and foreigners.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We pay particular attention when covering or reporting on people and their relatives who suffered from tragic events.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>3. Ethics as a Journalist</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We do not ask for money or valuables from others in exchange for coverage and reporting.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We do not use the information learned during the coverage to obtain financial benefits or avoid losses.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We do not disclose the contents to outsiders or third parties until the coverage is reported.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      Unless the public interest is prioritized, we do not compromise the reputation of the individual and strive to protect the privacy of others.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      Even if it is not related to economic benefits, we do not engage in unethical acts such as eavesdropping or identity impersonation for coverage.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We do not receive advertisements or sponsorships in any unjust way, and we do not act in any way that undermines the company's reputation.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>Article 3. Remedy for Damage Caused by Reporting</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1. Correcting Misinformatio</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We provide a way to receive information about misinformation.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      When misinformation occurs, check the facts and correct the article as soon as possible.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      If the content of the article is difficult to modify, write an article regarding misinformation and arrange easy access to it.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>2. Guarantee of Objection</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      We provide an opportunity to explain and/or argue to those individuals or groups who may face disadvantages due to reporting in advance.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      If the source exercises the right to object, we ensure to include it in the article.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      If there is a party who raises an objection to or claims disadvantages by an article, we listen to the opinion as much as possible and, if necessary, correct the contents of the reported article as soon as possible.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>Article 4. Social Media Activities</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1. Using the Official Social Media Account</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      In principle, articles published on Hanteo News should be posted on the official social media account of Hanteo News. We may also use Hanteo News’ official social media account for accurate and efficient coverage and reporting.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      The official social media account manager of Hanteo News does not disclose confidential information of the account (ID, password) to non-managers or third parties.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>2. Using personal Social Media Accounts</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      Personal social media account activities are conducted under each member’s responsibility, and we take responsibility on our own for the results in principle.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      Prior to personal activities, we bear in mind professional ethics and the fact that we are a journalist of and Hanteo News, and we take caution not to be questioned for fairness and reliability.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><b>Article 5. Ethics Committee</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '2em' }}><b>1.     Establishment and Operation of Ethics Committee</b></div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      Hanteo News establishes and operates an 'ethics committee' to deliberate and judge the implementation of its own code of ethics.</div>
                            <div><br /></div>
                            <div style={{ 'margin-left': '4em' }}>-      Hanteo News notifies and educates not only the internal journalists and employees of Hanteo News but also external writers to comply with Hanteo News' Code of Ethics.</div>
                            <div><br /></div>
                        </Body>
                        

                    </>
                );
        }
    }

    render() {
        const {language} = this.props;
        return (
            <>
                {this.ethicsBody(language)}
            </>
        );
    }
}

const Header = styled.div`
    margin: 0 30px;
    h2{
      font-size: 2rem;
      margin-bottom: .5rem;
      font-weight: 500;
      line-height: 1.2;
    }
    b{
      font-weight: 600;
    }
`;
const Body = styled.div`
    margin: 50px 30px;
    line-height: 1.5;
    word-break: break-word;
    b{
        font-weight: 600;
    }
`;
export default Ethics;
