import {Component} from "react";
import {FOOTER_MENU} from "../../global/string";
import FooterMenu from "../../components/view/common/footer/FooterMenu";
import styled from "styled-components";
import FooterCompanyInfo from "../../components/view/common/footer/FooterCompanyInfo";

class FooterPC extends Component{
    
    menus = FOOTER_MENU[this.props.language];
    render() {
        const {language} = this.props;
        return <>
            <Container>
                <MenuContainer>
                    <MenuCover>
                        {Object.keys(this.menus).map((_key,i)=>{
                            return <FooterMenu key={i}
                                link={this.menus[_key].link}
                                title={this.menus[_key].title}
                            />
                        })}
                    </MenuCover>
                </MenuContainer>

                <FooterCompanyInfo language={language}/>
            </Container>
        </>
    }
}

const MenuCover = styled.div``;
const MenuContainer = styled.div`
    
    border-top: 2px solid #C9C9C9;
    border-bottom: 1px solid #c9c9c9;
    padding: 11px 0 10px 0;
    
    font-family: NanumSquare, sans-serif;
    font-weight: 400;


    ${MenuCover}{
        font-size: 14px;
        @media (min-width: 1048px){
            width: 1048px;
            margin: 0 auto;
            flex-direction: row;
            display:flex;
            
        }
    }
`;

const Container = styled.div`
    display: none;
    @media (min-width: 1048px){
        display: block;
    }
`;


export default FooterPC;