import React, { Component } from "react";
import styled from "styled-components";

import NoArticleView from "components/view/common/NoArticle"

class searchPopUp extends Component {

    constructor() {
        super();
        this.state = {
            searchKeyword: undefined,
            keyword: undefined,
            header: undefined,
            startPage: 1
        }
    }

    componentDidMount() {
        const box = document.getElementsByClassName(PopUpContainer.styledComponentId)[0];
        box.style.height = "90%";

        const targetWindow = document.getElementsByClassName(ArticleListDiv.styledComponentId)
        targetWindow[0].addEventListener('scroll', this.onScroll);
        this.setState({
            searchKeyword: this.props.keyword,
            keyword: this.props.keyword,
            header: (this.props.langCode === 'es' ? 'todos' : 'all')
        })
    }

    onScroll = (e) => {
        const targetWindow = document.getElementsByClassName(ArticleListDiv.styledComponentId)
        if (!(e.srcElement.scrollHeight - e.srcElement.clientHeight > (e.srcElement.scrollTop + 10))) {
            this.props.onScrollCallBack({
                langCode: this.props.langCode,
                keyword: this.state.keyword,
                startPage: (targetWindow[0].childElementCount > 9 ? Number((targetWindow[0].childElementCount / 10) + 1) : null),
                header: this.state.header,
                order: this.props.order
            })
        }
    };

    updateInputValue = (e) => {
        this.setState({
            keyword: e.target.value
        })
    }

    onClcik = (e) => {
        const box = document.getElementsByClassName(ArticleListDiv.styledComponentId)[0];
        box.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        this.setState({
            searchKeyword: this.state.keyword
        })

        this.props.onClickSearchCallBack({
            langCode: this.props.langCode,
            keyword: this.state.keyword,
            startPage: this.state.startPage,
            header: this.state.header,
            order: e
        })
    }

    onPressEnterKey = (e) => {
        if (e.key === 'Enter') {
            const box = document.getElementsByClassName(ArticleListDiv.styledComponentId)[0];
            box.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

            this.setState({
                searchKeyword: this.state.keyword
            })

            this.props.onClickSearchCallBack({
                langCode: this.props.langCode,
                keyword: this.state.keyword,
                startPage: this.state.startPage,
                header: this.state.header,
                order: 0
            })
        }
    }
    onClickClose = (e) => {

        const box = document.getElementsByClassName(PopUpContainer.styledComponentId)[0];
        box.style.height = "0px";

        var _this = this;
        setTimeout(function () {
            _this.props.onClickCloseCallBack()
        }, 1200)
    }

    render() {
        return (
            <>
                <BackGroundCover>
                    <CloseCover onClick={this.onClickClose}></CloseCover>
                    <PopUpContainer>
                        <TopBarDiv onClick={this.props.onClickCloseCallBack.bind(this)}>
                            <TopBar></TopBar>
                        </TopBarDiv>
                        <SearchInputDiv>
                            <InputAndSearchBtn>
                                <InputSearchKeyword onKeyPress={this.onPressEnterKey} onChange={this.updateInputValue} value={this.state.keyword}></InputSearchKeyword>
                                <SearchImg src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/search_bolder.svg" onClick={this.onPressEnterKey}>
                                </SearchImg>
                            </InputAndSearchBtn>
                            <SearchDesDiv>
                                <SearchDes>{this.props.text.description}</SearchDes>
                            </SearchDesDiv>
                        </SearchInputDiv>
                        <SearchConditionViewDiv>
                            <SearchConditionByKeyWord>
                                <Keyword className='active'>{this.state.searchKeyword}</Keyword>
                                {'\u00A0'}
                                <Keyword>{this.props.text.search}</Keyword>
                            </SearchConditionByKeyWord>
                            <SearchConditionByOrder>
                                <OrderOne className={this.props.order !== 1 ? "active" : ""} onClick={this.onClcik.bind(this, 0)}>{this.props.text.latest}</OrderOne>
                                {'\u00A0'}|{'\u00A0'}
                                <OrderOne className={this.props.order === 1 ? "active" : ""} onClick={this.onClcik.bind(this, 1)}>{this.props.text.oldest}</OrderOne>
                            </SearchConditionByOrder>
                        </SearchConditionViewDiv>
                        <ArticleListDiv>
                            {this.props.isArticle > 0 ? this.props.articleList.map((el, i) => {
                                return (
                                    <OneArticle onClick={this.props.onClickCallBack.bind(this, el)}>
                                        <OneArticleATag>
                                            <ArticleThumbnailDiv>
                                                <ArticleThumbnail src={el.thumbnail}></ArticleThumbnail>
                                            </ArticleThumbnailDiv>
                                            <ArticleTextDiv>
                                                <Title dangerouslySetInnerHTML={{ __html: el.title }}></Title>
                                                <Content>{el.content}</Content>
                                            </ArticleTextDiv>
                                        </OneArticleATag>
                                    </OneArticle>
                                )
                            }) :
                                <NoArticleView langCode={this.props.langCode} />}
                        </ArticleListDiv>
                        <TopBtnDiv>
                            <TopBtn>
                                <TopBtnImg src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/arrow_top.png"></TopBtnImg>
                            </TopBtn>
                        </TopBtnDiv>
                    </PopUpContainer>
                </BackGroundCover>

            </>
        );
    }

}

const CloseCover = styled.div`
    width: 100%;
    height: 10%;
`;

const TopBtnImg = styled.img``;
const TopBtn = styled.a``;
const TopBtnDiv = styled.div``;
const Content = styled.p``;
const Title = styled.p``;
const ArticleTextDiv = styled.div``;
const ArticleThumbnail = styled.img``;
const ArticleThumbnailDiv = styled.div``;
const OneArticleATag = styled.a``;
const OneArticle = styled.div``;
const ArticleListDiv = styled.div``;
const OrderOne = styled.a``;
const SearchConditionByOrder = styled.div``;
const Keyword = styled.h1``;
const SearchConditionByKeyWord = styled.div``;
const SearchConditionViewDiv = styled.div``;
const SearchDes = styled.p``;
const SearchDesDiv = styled.div``;
const SearchImg = styled.img``;
const InputSearchKeyword = styled.input``;
const InputAndSearchBtn = styled.div``;
const SearchInputDiv = styled.div``;
const TopBar = styled.div``;
const TopBarDiv = styled.div``;
const PopUpContainer = styled.div``;
const BackGroundCover = styled.div`

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0, .5);
    z-index: 11;

    ${PopUpContainer}{
        transition: all 1s cubic-bezier(0.65, 0.05, 0.36, 1);
        display: block;
        position: fixed;
        bottom: 0;
        width: 100%;

        ${TopBarDiv}{
            position: fixed;
            margin: -20px calc(50% - 75px);

            ${TopBar}{
                position: relative;
                width: 150px;
                height: 10px;
                background-color: #FFFFFF;
                opacity: 55%;
                margin-left: calc(50% - 75px);
                border-radius: 5px;
            }
        }

        ${SearchInputDiv}{
            position: fixed;
            width: 100%;
            height: 80px;
            z-index: 11;
            background-color: white;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;

            ${InputAndSearchBtn}{
                position: relative;
                text-align: center;
                margin-top: 15px;

                ${InputSearchKeyword}{
                    color: #DA3A6C;
                    font-weight: bold;
                    width: 95%;
                    height: 35px;
                    border: 1px solid #E8E7E7;
                    border-radius: 8px;
                    padding: 10px;
                }
                ${SearchImg}{
                    position: absolute;
                    margin: 5px;
                    right: calc(4%);
                    z-index: 11;
                    width: auto;
                    height: auto;
                    top: 0;
                }
            }
            ${SearchDesDiv}{
                display: flex;

                ${SearchDes}{
                    margin: 4px 8px 4px auto;
                    color: #979797;
                }
            }
        }

        ${SearchConditionViewDiv}{
            position: relative;
            display: inline-flex;
            width: 100%;
            padding-top: 80px;
            background: #ffffff;
            border-top-left-radius: 15px;
            border-top-right-radius: 15px;

            ${SearchConditionByKeyWord}{
                display: inline-flex;
                margin: 15px 0 15px 10px;

                ${Keyword}{
                    margin-left: 2px;
                    font-size: 18px;

                    &.active{
                        color: #DA3A6C;
                        font-weight: bold;
                        max-width: 115px;
                        word-break: break-all;
                        display: -webkit-box;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

            }
            ${SearchConditionByOrder}{
                display: inline-flex;
                position: absolute;
                right: 13px;
                align-self: center;
                font-size: 13px;

                ${OrderOne}{
                    cursor: pointer;
                    margin: 0px 4px;

                    &.active{
                        color: #DA3A6C;
                    }
                }
            }
        }

        ${ArticleListDiv}{
            background: #ffffff;
            height: calc(100% - 130px);
            overflow: auto;
            line-height: 1.5;

            ${OneArticle}{
                display: inline-flex;
                border-bottom: 1px solid #e8e7e7;
                padding: 0 10px;
                background: #ffff;

                ${OneArticleATag}{
                    display: inline-flex;
                    align-items: center;
                    margin: 7px 0px;
                    cursor: pointer;

                    ${ArticleThumbnailDiv}{
                        display: block;
                        width: 150px;
                        min-width: 150px;
                        overflow: hidden;

                        ${ArticleThumbnail}{

                        }
                    }

                    ${ArticleTextDiv}{
                        margin-left: 8px;
                        margin-top: -1px;

                        ${Title}{
                            margin: 0 0 8px 0px;
                            font-weight: 600;
                            line-height: 1.6;
                            font-size: 15px;
                            word-break: break-all;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                        ${Content}{
                            font-size: 13px;
                            font-weight: normal;
                            word-break: break-all;
                            color: #7D7D7D;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                }
            }
        }

        ${TopBtnDiv}{
            position: fixed;
            bottom: 25px;
            right: 22px;
            z-index: 10;

            ${TopBtn}{
                background: #F8F8F8;
                text-align: center;
                width: 40px;
                height: 40px;
                font-size: 10px;
                font-family: 'Lato';
                -webkit-box-shadow: 2px 3px 5px rgba(0,0,0,.2);
                -moz-box-shadow: 2px 3px 5px rgba(0,0,0,.2);
                box-shadow: 2px 3px 5px rgba(0,0,0,.2);
                -webkit-border-radius: 5px;
                -moz-border-radius: 5px;
                border-radius: 5px;

                ${TopBtnImg}{
                }
            }
        }
    }
`;

export default searchPopUp;
