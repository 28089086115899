import React, { Component } from "react";

import Language from "../../components/view/common/header/Language";
import Chart from "../../components/view/common/header/Chart";
import Logo from "../../components/view/common/header/Logo";
import styled from "styled-components";
import SideMenuContainer from "../../components/view/common/header/SideMenuContainer";
import SideLanguage from "../../components/view/common/header/SideLanguage";
import {getLanguageWithPath} from "../../utils/LanguageUtils";
import {LANGUAGE_MENU} from "../../global/string";


class HeaderLanguage extends Component {

    constructor() {
        super();
    }

    render() {
        const { data, header, language} = this.props;
        return (
            <TopCover>
                <TopCoverDiv>
                    <MobileLeft>
                        <SideMenuContainer language={language} data={data} header={header}/>
                    </MobileLeft>
                    <TopContent>
                        <TopContentDiv>
                            <Chart />
                            <Logo link={`/${language.toLowerCase()}`}/>
                            <Language languages={LANGUAGE_MENU} current={language}/>
                        </TopContentDiv>
                    </TopContent>
                    <MobileRight>
                        <SideLanguage languages={LANGUAGE_MENU} current={language}/>
                    </MobileRight>
                </TopCoverDiv>
            </TopCover>
        );
    }

}

const MobileRight = styled.div``;
const MobileLeft = styled.div``;

const TopContentDiv = styled.div``;
const TopContent = styled.div``;

const TopCoverDiv = styled.div``;
const TopCover = styled.div`
    width: 100%;
    
    margin-right: auto;
    margin-left: auto;
    max-width: none;
    
    @media (min-width: 1048px){
        max-width: 1048px;
    }
  
    ${TopCoverDiv}{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        
        @media (min-width: 1048px){
            display: block;
        }
        
    
        ${TopContent}{
        
            min-height: 52px;
            margin-bottom: 8px;
            margin-left: 12%;
            
            @media (min-width: 1048px){
                min-height: 108px;
                margin: 15px 0 0px 0;
        }
    
        ${TopContentDiv}{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }
    }
    
    ${MobileLeft}{
        margin: 0 0 0 15px;
        @media (min-width: 1048px){
            display: none;
        }
    }
    
    ${MobileRight}{
        @media (min-width: 1048px){
            display: none;
        }
    }
}
  
`;


export default HeaderLanguage;
