export function ConvertSystemHtmlChartoSource(str) {
    if (str !== undefined && str !== null) {
        str = str.replace(/&lt;/gi, '<');
        str = str.replace(/&gt;/gi, '>');
        str = str.replace(/&quot;/gi, '"');
        str = str.replace(/&#39;/gi, "'");
        str = str.replace(/&apos;/gi, "'");
        str = str.replace(/<br \/>/gi, '\n');
        str = str.replace(/&nbsp;/gi, ' ');
        str = str.replace(/&amp;/gi, '&');
        str = str.replace(/(<([^>]+)>)/gi, '');
        str = str.substring(0, 300);
    }
    return str;
}