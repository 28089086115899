import React, { Component } from "react";
import styled from "styled-components";
import WebDetailView from 'components/view/detail/WebDetailView';
import AppDetailVM from 'components/view_model/detail/AppDetailVM';
import {MENU_CONST} from "../../../global/string";
import { getListOfPost } from "api/PostAPI";
import { ConvertSystemHtmlChartoSource } from "utils/StringUtils";
import GoogleBannerAds from "../../view/ads/GoogleBannerAds";

class DetailPageVM extends Component {

    constructor() {
        super();
        this.state = {
            data: undefined,
            // relatedData: [],
        }
    }

    componentDidMount() {
    
        getListOfPost({
            offset: 0,
            limit: 1,
            order: "DESC",
            clientIdx: 1022,
            language: this.props.language,
            listOfIdx: this.props.contentIdx,
            summary: false
        }, (res) => {
            var listOfPost = res.resultData;
            if (listOfPost.length!==1) return;
            
            var result = undefined
            listOfPost.map((post, i) => {
                var category = this.props.header ? this.props.header.title: MENU_CONST.allCategory[post.meta.language].title;
                
                let startDay = post.publish.startDate.split("T")[0]
                let startTime = post.publish.startDate.split("T")[1]
                
                let modifyDay = post.publish.modifyDate? post.publish.modifyDate.split("T")[0]: undefined
                let modifyTime = post.publish.modifyDate? post.publish.modifyDate.split("T")[1]: undefined

                result = {
                    idx: post.ownership.idx,
                    thumbnail: post.meta.content.thumbnail,
                    title: ConvertSystemHtmlChartoSource(post.meta.content.title),
                    content: post.meta.content.description,
                    language: post.meta.language,
                    link: `/${post.meta.language.toLowerCase()}/article/${category.toLowerCase()}?fc=${post.ownership.idx}`,
                    writeUser: {
                        name: post.meta.user.userName,
                        email: post.meta.user.userEmail,
                        badge: (post.meta.user.extraJson? JSON.parse(post.meta.user.extraJson).badge: undefined)
                    },
                    startDate: `${startDay} ${startTime}`,
                    modifyDate: modifyDay ? `${modifyDay} ${modifyTime}`: modifyDay,
                    listOfArtist: post.listOfArtist,
                    listOfTag: post.listOfTag
                }

                return true;
            })

            this.setState({
                data: result
            })
        })
    }

    _makeDetailCopy(language) {
        switch (language) {
            case 'KO':
                return '<ⓒ \'한터뉴스\' 무단전재 및 재배포 금지 >';
            case 'ES':
                return '<ⓒ \'HANTEO NEWS(NOTICIAS HANTEO)\' La reproducción y distribución de esta publicación está estrictamente prohibido sin autorización.>';
            default:
                return '<ⓒ \'HanteoNews\' Reproduction and distribution of this publication is strictly prohibited without permission >';
        }
    }

    render() {
        const { language, contentIdx, header, isApp, userInfo } = this.props
        return (
            <>
            {this.state.data?
                isApp === true?
                <AppDetailVM
                    language={language}
                    data={this.state.data}
                    userInfo={userInfo} 
                    />
                : 
                <Container>
                    <WebDetailView
                        language={language}
                        data={this.state.data}
                        detailCopy={this._makeDetailCopy(language)} />
                </Container>
            :<></>
            }

            </>
        );
    }
}

const Container = styled.div``;

export default DetailPageVM;
