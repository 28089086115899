import React, { Component } from "react";
import styled from "styled-components";

class savePoint extends Component {

    constructor() {
        super();
        this.state = {
            onClickBtn: false,
            newsVotePopUp: false,
            color: '#c1c1c1'
        }
    }

    componentDidMount() {
        if (this.props.isClick) {
            window.addEventListener('scroll', this.onScroll);
        }
    }

    onScroll = (e) => {
        const scrollTop = ('scroll', e.srcElement.scrollingElement.scrollTop);
        if (document.documentElement.offsetHeight - scrollTop < 1000) {
            this.setState({
                onClickBtn: true,
                color: '#ff659b'
            })
        }
    }


    render() {
        return (
            <>
                {this.props.artistData.length > 0 ?
                    <ModelPopUp onClick={this.props.onClickCallBackClose.bind(this, this.props.isClick ? this.state.onClickBtn : false)} style={{ backgroundColor: this.props.isClick ? this.state.color : '#c1c1c1' }}>
                        <ModelDiv>
                            <SavePoint>{this.props.text}</SavePoint>
                        </ModelDiv>
                    </ModelPopUp> : <></>}
            </>
        );
    }

}


const SavePoint = styled.p``;
const ModelDiv = styled.a``;
const ModelPopUp = styled.div`
    position: fixed;
    bottom: 0px;
    width: 100%;
    height: 40px;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 10;

    ${ModelDiv}{
        cursor: pointer;

        ${SavePoint}{
            color: #ffffff;
            font-size: 20px;
            margin: 5px 0;
        }
    }
`;

export default savePoint;
