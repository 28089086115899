import { LANGUAGE } from "global/string";
import {getPathArr} from "./UriUtils";

export function getLanguage(_language){
    var result = 'EN';
    if(_language===undefined || _language==='')
        return 'KO';

    var _index = LANGUAGE.indexOf(_language.toUpperCase());
    if (_index >= 0) {
      result = LANGUAGE[_index];
    }

    return result;
}


export function getLanguageWithPath(_pathname){
    let _paths = getPathArr(_pathname)
    if ( _paths.length < 1) {
        return LANGUAGE[0]
    }
    var result = _paths[0].toUpperCase();
    if (result===''){
        return LANGUAGE[0]
    }

    if(result==='APP'){
        if ( _paths.length === 1) {
            return LANGUAGE[1]
        }

        result = _paths[1].toUpperCase();
    }

    let _index = LANGUAGE.indexOf(result);

    result = LANGUAGE[1];
    if (_index >= 0) {
        result = LANGUAGE[_index];
    }

    return result;
}