import React, { Component } from "react";
import styled from "styled-components";
import { getQueryParameter } from "utils/UriUtils";
import {ConvertSystemHtmlChartoSource} from "../../../utils/StringUtils";

class MainBannerView extends Component {

    onClickEvent = (e) => {
        if (document.location.pathname.indexOf('app') > 0) {
            let packagename = getQueryParameter('package', window.location);
            var link = 'whosfan';
            if(packagename==='com.hanteo.whosfanglobal'){
                link = 'whosfanglobal';
            }
            link = link + '://open?url='  + encodeURIComponent(window.location.origin + '/app/' + this.props.data.language.toLowerCase() + '?fc=' + this.props.data.idx);

            window.location.href = link;
        } else {
            var link = window.location.origin + '/' + this.props.data.language.toLowerCase() + '/article/' + this.props.data.category.toLowerCase() + '?fc=' + this.props.data.idx;
            window.location.href = link;
        }
    }

    render() {
        const {data, showTag} = this.props;
        return (
            <>
                {data ?
                    <OneArticle onClick={this.onClickEvent}>
                        <a>
                            <OneArticleImg src={data.thumbnail}></OneArticleImg>
                            <GradationCover></GradationCover>
                            {showTag === true ?
                                (<OneArticleTags>
                                    {data.category !== null ? '#' + ConvertSystemHtmlChartoSource(data.category) : null}
                                    {'\u00A0'}
                                    {data.artist !== undefined && data.artist !== null && data.artist!=='' ? '#' + ConvertSystemHtmlChartoSource(data.artist) : null}
                                </OneArticleTags>)
                                : null
                            }
                            <OneArticleTitle className={showTag === true ? '' : 'sub'}>{ConvertSystemHtmlChartoSource(data.title)}</OneArticleTitle>
                        </a>
                    </OneArticle>
                    : <OneArticle></OneArticle>}
            </>
        );
    }
}

const OneArticleTitle = styled.p``;
const OneArticleTags = styled.p``;
const GradationCover = styled.div``;
const OneArticleImg = styled.img``;
const OneArticle = styled.div`
    position: relative;
    cursor: pointer;

    @media (min-width: 1048px){
        cursor: pointer;
    }

    a{
        ${OneArticleImg}{
            width: 100%;
            height: auto;
        }
        ${GradationCover}{
            display: block;
        
            @media (min-width: 1048px){
                position: absolute;
                width: 100%!important;
                height: 100%;
                top: 0;
                background-image: linear-gradient(rgba(0, 0, 0, 0.00) 60%, rgba(0, 0, 0, 0.64) 100%);
                background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.00) 60%, rgba(0, 0, 0, 0.64) 100%);
                background-image: -o-linear-gradient(rgba(0, 0, 0, 0.00) 60%, rgba(0, 0, 0, 0.64) 100%);
                background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.00) 60%, rgba(0, 0, 0, 0.64) 100%);
            }
        }
        ${OneArticleTags}{
            font-size: 11px;
            word-spacing: 15px;
            color: #323483;
            margin: 6px 10px 3px 10px;
        
            @media (min-width: 1048px){
                position: absolute;
                color: #f5f5f5;
                margin: 0 0 5px 10px;
                bottom: 22%;
                font-size: 13px;
                font-weight: bolder;
                word-break: break-all;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }

        ${OneArticleTitle}{
            color: black;
            font-weight: bolder;
            word-break: break-all;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 0 10px;
            font-size: inherit;
            
            @media (min-width: 1048px){
                position: absolute;
                color: white;
                margin: 0 10px 10px 10px;
                bottom: 0%;
                font-weight: bolder;
                word-break: break-all;
                display: -webkit-box;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical
            }


            &.sub{
                margin: 7px 4px 0 4px;   
                @media (min-width: 1048px){
                    margin: 0 10px 8px 10px;
                    font-size: 14px;
                }
            }

        }
    }
`;

export default MainBannerView;
