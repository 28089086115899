import React, { Component } from "react";
import styled from "styled-components";
import { MENU_CONST, WEB_MAIN_STR } from "global/string";

import NewArticleView from 'components/view/article/NewArticleView';
import { getQueryParameter } from "utils/UriUtils";
import { getNewArticle } from "api/NewsAPI";
import {ConvertSystemHtmlChartoSource} from "../../../utils/StringUtils";
import { getListOfPost } from "api/PostAPI";
import { mapOfCategoryChild } from "utils/CategoryUtils";
import { utcTimeToLocalTime } from "utils/DateUtils";

class NewArticleVM extends Component {

    constructor() {
        super();
        this.state = {
            data: undefined,
            isApp: false
        }
    }

    componentDidMount() {

        if (window.location.pathname.indexOf('app') > 0) {
            this.setState({
                isApp: true
            })
        }

        
        let section = MENU_CONST.latest;

        getListOfPost({
            offset: 0,
            limit: 5,
            order: "DESC",
            clientIdx: 1022,
            language: this.props.language,
            listOfSection: section[this.props.language],
            summary: true
        }, (res) => {
            var listOfPost = res.resultData;
            var list = [];

            listOfPost.map((post, i) => {
                
                let _cate = post.listOfCategory[0]

                var category = MENU_CONST.allCategory[post.meta.language].title;
                if (this.props.listOfHeader) {
                    let mapOfHeader = mapOfCategoryChild(this.props.listOfHeader)
                    let listOfKey = Object.keys(mapOfHeader);
                    for (var i = 0 ; i < listOfKey.length; i++) {
                        let key = listOfKey[i];
                        let listOfChildIdx = mapOfHeader[key]
                        if (listOfChildIdx.indexOf(`${_cate.idx}`) > -1) {
                            category = key
                            break;
                        }
                    }
                }
                
                
                let startDate = utcTimeToLocalTime(post.publish.startDate);
                let startDay = startDate.split("T")[0]
                let startTime = startDate.split("T")[1]
                list.push({
                    idx: post.ownership.idx,
                    thumbnail: post.meta.content.thumbnail,
                    title: ConvertSystemHtmlChartoSource(post.meta.content.title),
                    content: ConvertSystemHtmlChartoSource(post.meta.content.seo),
                    date: `${startDay} ${startTime}`,
                    language: post.meta.language,
                    link: `/${post.meta.language.toLowerCase()}/article/${category.toLowerCase()}?fc=${post.ownership.idx}`
                })
                return true;
            })

            this.setState({
                data: list,
            })  
        })
    }


    onClickEvent(_result) {

        if (window.location.pathname.indexOf('app') > 0) {
            let packagename = getQueryParameter('package', window.location);
            var link = 'whosfan';
            if(packagename==='com.hanteo.whosfanglobal'){
                link = 'whosfanglobal';
            }
            link = link + '://open?url='  + encodeURIComponent(window.location.origin + '/app/' + _result.language.toLowerCase() + '?fc=' + _result.idx);
            window.location.href = link;
        } else {
            var link = window.location.origin + _result.link;
            window.location.href = link;
        }
    }

    btnOnClick = (e) => {
        window.location.href = window.location.origin + '/' + this.props.language.toLowerCase()  + e.currentTarget.dataset.target
    }

    // WEB 최신기사
    render() {
        const { language } = this.props;
        return (
            <>
                {this.state.data ? (
                    <NewArticleContainer>
                        <TitleAndBtn>
                            <ArticleTitle>{WEB_MAIN_STR[language].title.new}</ArticleTitle>
                            <BtnDiv onClick={this.btnOnClick.bind(this)} data-target={'/article/latest'}>{this.state.isApp === false ? WEB_MAIN_STR[language].btn.more : ''}</BtnDiv>
                        </TitleAndBtn>
                        <LineDiv></LineDiv>
                        <NewArticleDiv>
                            {this.state.data ? this.state.data.map((el, index) => {
                                return <NewArticleView
                                    key={index}
                                    data={el}
                                    onClickCallBack={this.onClickEvent}
                                ></NewArticleView>
                            }) : ''}
                        </NewArticleDiv>
                    </NewArticleContainer>
                ) : (<></>)}
            </>
        );
    }
}


const ArticleTitle = styled.div``;
const BtnDiv = styled.div``;
const NewArticleDiv = styled.div``;
const LineDiv = styled.div``;
const TitleAndBtn = styled.div``;
const NewArticleContainer = styled.div`
    margin: 36px 0px 48px 0px;

    @media (min-width:1048px){
        margin: 28px 0px 19px 0px;
    }

    ${TitleAndBtn}{
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 0 10px 0px 10px;

        @media (min-width:1048px){
            margin: 0px 0px 21px 0px;
        }

        ${ArticleTitle}{
            display: inline-block;
            font-size: 18px;
            margin: 0px 20px 0 0px;
            font-weight: 600;
            @media (min-width:1048px){
                font-size: 22px;
            }
        }
        ${BtnDiv}{
            cursor: pointer;
            display: inline-block;
            float: right;
            font-size: 13px;
            margin: 0 10px 0 0;
            color: #2d2d2d;
            text-decoration: none;
            @media (min-width:1048px){
                margin: 0 0px 0 0;
            }
        }
    }
    ${LineDiv}{
        margin: 7px 0 14px 0;
        border-top: 1px solid #7d7d7d;

        @media (min-width:1048px){
            margin: 10px 0 15px 0;
        }
    }
    ${NewArticleDiv}{
        margin: 0 10px;

        @media (min-width:1048px){
            margin: 0;
        }
    }
`;

export default NewArticleVM;
