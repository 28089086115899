import React, { Component } from "react";
import styled from "styled-components";
import UnLikeIcon from "img/icon_good_gray.svg";
import LikeIcon from "img/icon_good_pink.svg";
import { likeComment } from "api/CommentAPI";

class LikeCommentVM extends Component {
    
    constructor(){
        super();
    }
    onLikeClick = (e) => {
        let _img = e.currentTarget.children[0];
        var _like = 1;
        let _countText = e.currentTarget.children[1];
        
        if(_img.classList.contains('like')){
            _img.src=UnLikeIcon;
            _img.classList.add('unlike');
            _img.classList.remove('like');
            _like = -1;
        }else{
            _img.src=LikeIcon;
            _img.classList.add('like');
            _img.classList.remove('unlike');
        }
        let _count = Number(_countText.innerText) + _like;

        let _this = this;

        likeComment({
            contentIdx: this.props.contentIdx,
            parentIdx: this.props.parentIdx,
            commentIdx: this.props.commentIdx,
            like: _like,
            token: this.props.token
        }, function(json){
            if(json.code===100){
                _countText.innerText = _count;
                if (_this.props.likeCallback===undefined){
                    return;
                }
                
                _this.props.likeCallback({
                    likeCount: _count,
                    itemIndex: _this.props.itemIndex,
                    commentIdx: _this.props.commentIdx,
                    like: _like
                })
            }
        }, function(error){
            console.log(error);
        })

    }
    
    render() {
        const { value, isLike, contentIdx, parentIdx, commentIdx, token, likeCallback, itemIndex } = this.props;
        return (
            <>
                
                <LikeCount className="hflex-cc" onClick={this.onLikeClick}>
                    {isLike?<img className="like" alt="like" src={LikeIcon}></img>:<img className="unlike" alt="unlike" src={UnLikeIcon}></img>}
                    <p>{value}</p>
                </LikeCount>
            </>
        );
    }

}
const LikeCount = styled.div`
    img{
        width:15px;
        height:15px;
        margin: 1px 0 0 0;
    }
    p{
        margin: 0 0 0 5px;
    }
`;



export default LikeCommentVM;
