import React, { Component } from "react";
import styled from "styled-components";


class ListView extends Component {

    render() {
        const { data, onClickCallBack } = this.props;
        return (
            <>
                {this.props.data ?
                    <ArticleDiv onClick={onClickCallBack.bind(this, data)}>
                        <ArticleATag>
                            <Thumbnail src={data.thumbnail}></Thumbnail>
                            <Contents>
                                <Title dangerouslySetInnerHTML={{ __html: data.title }}></Title>
                                <Description dangerouslySetInnerHTML={{ __html: data.content}}></Description>
                                <DateTime>{data.date}</DateTime>
                            </Contents>
                        </ArticleATag>
                    </ArticleDiv>
                    : <></>}
            </>
        );
    }

}


const Description = styled.div``;
const DateTime = styled.div``;
const Title = styled.div``;
const Contents = styled.div``;
const Thumbnail = styled.img``;
const ArticleATag = styled.a``;
const ArticleDiv = styled.div`
    display: flex;
    cursor:pointer;
    margin: 0;
    flex-wrap: nowrap;
    border-bottom: 1px solid #EEEEEE;
    padding: 12px 14px 13px 14px;
    cursor: pointer;

    @media (min-width:1048px) {
        border-bottom: 1px solid #EEEEEE;
        padding: 16px 0 15px 0;
    }
    ${ArticleATag}{
        display: flex;
        max-width: 100%;
        width: 100%;
        align-items: flex-start;
        @media (min-width:1048px) {
            align-items: center;    
        }

        ${Thumbnail}{
            width: 122px;
            height: 60px;
            margin-right: 10px!important;
            overflow: hidden;
            align-self: flex-start!important;

            @media (min-width:1048px) {
                width: 210px;
                height: 103px;
                margin-right: 20px!important;
            }
        }
        ${Contents}{
            flex: 1;
            
            ${Title}{
                color: #111111;
                font-family: 'Lato';
                font-weight: 600;
                font-size: 14px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                text-align: left;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                word-break: break-word;
                margin-bottom: 4px;
                margin-top: 3px!important;
                line-height: 1.2;

                @media (min-width:1048px) {
                    color: #111111;
                    padding: 1px 0 0 0;
                    font-size: 16px;
                    line-height: 1.6;
                }
            }
            ${Description}{
                color: #444444;
                white-space: normal;
                text-align: left;
                word-wrap: break-word;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                font-family: 'Lato';
                font-weight: 400;
                font-size: 13px;
                overflow: hidden;
                
                @media (min-width:1048px) {
                    margin: 0 0 5px 0;
                    font-size: 14px;
                    line-height: 1.6;
                }
            }
            ${DateTime}{
                margin: 0;
                text-align: left;
                display: none;
                font-family: NanumSquare;
                font-weight: 400;
                @media (min-width:1048px) {
                    display: block;
                    font-size: 14px;
                    color: #444444;
                }
            }
        }
    }
`;



export default ListView;
