import React, { Component } from "react";
import styled from "styled-components";

import MainBannerView from 'components/view/article/MainBannerView';
import { getListOfPost } from "api/PostAPI";
import { MENU_CONST } from "global/string";
import { convertListOfIdx, mapOfCategoryChild } from "utils/CategoryUtils";

class MainArticleVM extends Component {

    constructor() {
        super();
        this.state = {
            data: undefined
        }
    }

    componentDidMount() {
        let section = MENU_CONST.banner;
        
        getListOfPost({
            offset: 0,
            limit: 3,
            order: "DESC",
            clientIdx: 1022,
            language: this.props.language,
            listOfSection: section[this.props.language],
            summary: true
        }, (res) => {
            var listOfPost = res.resultData;
            
            var list = []
            listOfPost.map((post, i) => {
                
                let _cate = post.listOfCategory[0]

                var category = MENU_CONST.allCategory[post.meta.language].title;

                if (this.props.listOfHeader){
                    let mapOfHeader = mapOfCategoryChild(this.props.listOfHeader)
                    let listOfKey = Object.keys(mapOfHeader);
                    for (var i = 0 ; i < listOfKey.length; i++) {
                        let key = listOfKey[i];
                        let listOfChildIdx = mapOfHeader[key]
                        if (listOfChildIdx.indexOf(`${_cate.idx}`) > -1) {
                            category = key
                            break;
                        }
                    }
                }
                
                
                var artist = ''
                if (post.listOfArtist && post.listOfArtist.length > 0) {
                    let _filter = post.listOfArtist.filter((artist) => artist.isDefault===1);
                    if (_filter.length==1) {
                        artist = _filter[0].title
                    } else {
                        artist = post.listOfArtist[0].title
                    }
                    
                }
                list.push({
                    idx: post.ownership.idx,
                    thumbnail: post.meta.content.thumbnail,
                    title: post.meta.content.title,
                    category: category,
                    artist: artist,
                    language: post.meta.language
                });
                return true;
            })

            this.setState({
                data: list
            })
        })

    }

    render() {
        const { language, listOfHeader } = this.props;
        return (
            <>
                {this.state.data && this.state.data.length==3 ? (
                    < MainArticleContainer >
                        <MainArticleDiv>
                            <MainBannerView data={this.state.data[0]} showTag={true}></MainBannerView>
                        </MainArticleDiv>
                        <SubArticleDiv>
                            <MainBannerView data={this.state.data[1]} showTag={false}></MainBannerView>
                            <MainBannerView data={this.state.data[2]} showTag={false}></MainBannerView>
                        </SubArticleDiv>
                    </MainArticleContainer>
                ) : (<MainArticleContainer></MainArticleContainer>)}
            </>
        );
    }
}

const SubArticleDiv = styled.div``;
const MainArticleDiv = styled.div``;
const MainArticleContainer = styled.div`
    line-height: initial;
    @media (min-width: 1048px){
        display: flex;
        margin-top: 16px;
    }

    ${MainArticleDiv}{
        margin-bottom: 15px;
        font-size: 18px;
    
        @media (min-width: 1048px){
            margin: 0px 8px 15.6px 0px;
            width: 698px;
            font-size: 21px;
        }
    }

    ${SubArticleDiv}{
        display: inline-flex;
        font-size: 11px!important;

        @media (min-width: 1048px){
            flex-direction: column;
            width: 342px;
            margin: 0px 0 0 0;
            font-size: 16px;
        }
    
        img {
            height: auto;
            @media (min-width: 1048px){
                margin-bottom : 0px;
            }
        }
        div {
            width: 50%;
            @media (min-width: 1048px){
                width: auto;
            }
        }
        div:nth-child(1) {
            
            margin: 0 8px 0 0;
            
            @media (min-width: 1048px){
                margin: 0 0 8px 0;
            }
        }
    
       
    }
`;

export default MainArticleVM;
