import React, { Component } from "react";
import DetailPageVM from 'components/view_model/detail/DetailPageVM';

class Contents extends Component {

    constructor() {
        super();
    }

    render() {
        // VM를 담기 위한 Container
        const { language, contentIdx, userInfo, params } = this.props
        return (
            <>
                {userInfo.userNum===undefined?<></>:
                <DetailPageVM
                    language={language}
                    contentIdx={contentIdx}
                    isApp={true}

                    userInfo={userInfo} 
                    params={params} />
                }
                
            </>
        );
    }

}

export default Contents;
