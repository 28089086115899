import React, { Component } from "react";
import styled from "styled-components";

class CategoryHeader extends Component {

    ConvertSystemHtmlChartoSource(str) {
        str = str.replace(/&lt;/gi, '<');
        str = str.replace(/&gt;/gi, '>');
        str = str.replace(/&quot;/gi, '"');
        str = str.replace(/&#39;/gi, "'");
        str = str.replace(/<br \/>/gi, '\n');
        str = str.replace(/&nbsp;/gi, ' ');
        str = str.replace(/(<([^>]+)>)/gi, '');
        str = str.substring(0, 300);
        return str;
    }

    render() {
        return (
            <>
                {this.props.data ?
                    <HeaderContainer>
                        <HeaderTitle>{this.props.data.title}</HeaderTitle>
                        <HeaderExplain>{this.props.data.description}</HeaderExplain>
                    </HeaderContainer>
                    : null}
            </>
        );
    }

}

const HeaderExplain = styled.div``;
const HeaderTitle = styled.div``;
const HeaderContainer = styled.div`
    display: block;
    margin: 10px 15px;
    @media (min-width:1048px) {
        display: block;
        margin: 25px 0 0 0;
    }
    ${HeaderTitle}{
        font-size: 23px;
        color: #dd3a6c;
        font-weight: 600;
        font-family: 'lato';
        margin: 0px;
        @media (min-width:1048px) {
            font-size: 30px;
        }
    }
    ${HeaderExplain}{
        @media (min-width:1048px) {
            font-size: 18px;
            margin: 0 0 10px 0;
        }
    }
`;

export default CategoryHeader;
