import React, { Component } from "react";
import styled from "styled-components";

class Terms extends Component {

    termsBody(language) {
        switch (language) {
            case 'KO':
                return (
                    <>
                        <Header>
                            <h1>이용약관</h1>
                        </Header>
                        <Body>
                            <div>
                                <b>제 1장 총 칙</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제1조 (목적)</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>이 약관은 ㈜한터글로벌(이하 “회사”라 합니다)에서 운영하는 인터넷 관련 서비스 한터뉴스 (www.hanteonews.com) (이하 “서비스”라 합니다)를 이용함에 있어서 회원과 회사간의 권리, 의무 및 책임사항, 서비스의 이용 조건과 이용 절차 및 제반 필요사항을 규정함을 목적으로 합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제2조 (약관의 효력 및 변경)</b>
                            </div>
                            <div><br /></div>
                            <div>① 이 약관은 대한민국 내에서 서비스를 이용하고자 하는 모든 회원에 대하여 그 효력이 발생합니다. 한편, 회사가 해외 권리자 등과의 계약 체결을 통하여 해당 국가에서의 서비스가 가능한 경우 이 약관은 해당 국가 내에서 서비스를 이용하고자 하는 모든 회원에 대하여도 그 효력이 발생합니다.</div>
                            <div><br /></div>
                            <div>② 이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공시하고, 이에 동의한 회원이 서비스에 가입함으로써 효력이 발생합니다. 회사는 회원이 동의하기에 앞서 약관의 내용을 회원이 쉽게 이해하여 착오 없이 거래할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 회원의 확인을 구합니다.</div>
                            <div><br /></div>
                            <div>③ 회사는 필요하다고 인정하는 경우, “약관의규제에관한법률”, “정보통신망이용촉진및정보보호에관한법률", “콘텐츠산업진흥법”, “전자상거래등에서의소비자보호에관한법률” 등 관련 법령을 위반하지 않는 범위에서 이 약관을 변경할 수 있으며, 회사가 약관을 변경하는 경우에는 적용일자 및 변경사유를 명시하여 그 적용일자 15일 전부터 서비스 사이트에 공지하고, 회원에게 불리한 약관의 변경의 경우 개정 내용을 회원이 알기 쉽게 표시하여 그 적용일자 30일 전부터 공지하며, 이메일 주소, 문자메시지 등으로 회원에게 개별 통지합니다. 회원의 연락처 미기재, 변경 등으로 인하여 개별 통지가 어려운 경우, 회원이 등록한 연락처로 공지를 하였음에도 2회 이상 반송된 경우 이 약관에 의한 공지를 함으로써 개별 통지한 것으로 간주합니다.</div>
                            <div><br /></div>
                            <div>④ 회사가 제3항에 따라 변경 약관을 공지 또는 통지하면서 회원에게 약관 변경 적용 일까지 거부의사를 표시하지 아니할 경우, 약관의 변경에 동의한 것으로 간주한다는 내용을 공지 또는 통지하였음에도 회원이 명시적으로 약관 변경에 대한 거부의사를 표시하지 아니하면, 회사는 회원이 변경 약관에 동의한 것으로 간주합니다.</div>
                            <div><br /></div>
                            <div>⑤ 변경된 약관에 대하여 거부의사를 표시한 회원은 계약의 해지 또는 회원 탈퇴를 선택할 수 있습니다.</div>
                            <div><br /></div>
                            <div>⑥ 회사는 이 약관을 회원이 그 전부를 인쇄할 수 있고 확인할 수 있도록 필요한 기술적 조치를 취합니다.</div>
                            <div><br /></div>
                            <div>⑦ 이 약관은 회원이 이 약관에 동의한 날로부터 회원 탈퇴 시까지 적용하는 것을 원칙으로 합니다. 단, 이 약관의 일부 조항은 회원이 탈퇴 후에도 유효하게 적용될 수 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제3조 (약관 외 준칙)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 제공하는 개별 서비스에 대해서 별도의 이용약관 및 정책을 둘 수 있으며, 해당 내용이 이 약관과 상충할 경우 개별 서비스의 이용약관을 우선하여 적용합니다.</div>
                            <div><br /></div>
                            <div>② 본 약관에 명시되지 않은 사항은 콘텐츠산업진흥법, 전자상거래등에서의소비자보호에관한법률, 저작권법 등 관련 법령의 규정과 일반 상관례에 의합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 2장 서비스 이용 계약</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제1조 (용어의 정의)</b>
                            </div>
                            <div>① 회원은 서비스를 이용하기 위하여 서비스 사이트에 접속하여 이 약관에 동의하거나 기타 회사가 요청하는 절차를 거쳐 서비스 이용 계약을 체결한 자를 말합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제2조 (이용계약의 성립)</b>
                            </div>
                            <div>① 이 약관은 서비스를 이용하고자 하는 회원이 가입단계에서 고객이 동의 버튼을 누르면 약관에 동의하는 것으로 봅니다.</div>
                            <div><br /></div>
                            <div>② 이용 계약은 고객이 이 약관 제3조(이용신청)의 이용신청에 대하여 회사가 이를 승낙함으로써 성립합니다.</div>
                            <div><br /></div>
                            <div>③ 회원은 서비스 이용신청 계약을 체결하기 전에 해당 유료서비스에 대하여 회사가 공지하는 아래와 같은 사항을 사전에 숙지하고, 착오 없이 정확히 거래할 수 있도록 하여야 합니다</div>
                            <div>1.서비스의 내용, (이하 유료서비스의 경우) 가격, 이용기간, 이용가능 기기를 포함한 이용방법, 개별 이용권의 속성(이용권의 종류), 청약철회 및 해지 조건 및 그 방법, 환불에 관한 사항 및 유료서비스의 선택과 관련한 사항</div>
                            <div>2.(유료서비스의 경우) 선택한 유료서비스의 결제방법의 선택 및 선택한 결제방법에 필요한 결제정보의 입력</div>

                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제3조 (이용신청)</b>
                            </div>
                            <div><br /></div>
                            <div>① 이용신청은 서비스의 이용자 등록 화면에서 고객이 다음 사항을 가입신청 양식에 기록하는 방식으로 행합니다.</div>
                            <div>성명</div>
                            <div>아이디(ID, 이메일(전자우편)주소</div>
                            <div>닉네임(별명)</div>
                            <div>비밀번호(Password)</div>
                            <div>생년월일</div>
                            <div>서비스 접속 국가</div>
                            <div>기타 회사가 필요하다고 인정하는 사항</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제4조 (이용신청의 승낙)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 제6조에서 정한 사항을 정확히 기재하고, 이메일 주소 승인 또는 문자메시지 인증 등 회사가 정한 인증 절차를 완료한 고객에 대하여 서비스 이용 신청을 승낙합니다.</div>
                            <div><br /></div>
                            <div>② 회사와 회원간 서비스 이용계약은 회사의 승낙이 회원에게 도달한 시점(유료서비스의 경우, ‘구매/결제완료’ 등의 표시가 회원에게 절차상 표시된 시점)에 성립합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제5조 (이용신청에 대한 승낙의 제한)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 다음 각 호에 해당하는 사유가 발생할 경우 이용신청에 대한 승낙 또는 일정시간 동안 승낙을 유보하는 등 가입을 제한하거나, 사안의 경중에 따라서는 손해배상을 청구할 수 있습니다.</div>
                            <div>1.회사의 업무상, 기술상의 사정으로 서비스 제공이 불가능한 경우</div>
                            <div>2.이용자 등록 사항을 누락하거나 오기하여 신청하는 경우, 허위서류를 첨부하는 경우 등 이용자의 귀책사유로 인하여 승인이 불가능한 경우</div>
                            <div>3.사회의 안녕질서 또는 미풍양속을 저해하거나, 저해할 목적으로 신청한 경우</div>
                            <div>4.만 14세 미만 아동이 법정대리인(부모 등)의 동의를 얻지 아니한 경우</div>
                            <div>5.제25조(계약해지 및 이용제한)에 의하여 이전에 회원 자격을 상실한 적이 있는 경우. 다만, 동 자격 상실 이후 1년 이상 경과한 자로 회사의 회원 재가입 승낙을 받은 경우는 예외로 합니다.</div>
                            <div>6.서비스 이용 후 회원이 회사에 등록한 결제수단의 임의 해지 및 지급 정지, 지불 불능 등의 사유로 정당한 사유 없이 회사가 청구한 서비스 요금을 납부하지 아니한 경우</div>
                            <div>7.회사가 서비스 사업권 내지 저작권을 허락 받지 아니한 국가에 거주, 체류 중인 자이거나 동 국가에서 사이트에 접속하는 경우</div>
                            <div>8.범죄행위, 특정한 일정 기간 동안에 유료회원 가입, 해지를 반복하여 정상적인 용도 이외로 사용한 경우가 명백한 경우 등 회사의 서비스 방해 등의 사유로 회원자격 상실(탈퇴) 이력이 있는 경우</div>
                            <div>9.기타 회사가 정한 이용신청 요건이 만족되지 않았을 경우</div>
                            <div><br /></div>
                            <div>② 이용신청에 대한 승낙 후에 제1항 각호의 1에 해당하는 사유가 발견되는 경우, 회사는 이 약관에 따라 체결된 서비스 이용계약을 해지하거나 중단할 수 있습니다.</div>
                            <div><br /></div>
                            <div>③ 회원의 자격 혹은 나이에 따라 서비스 이용의 일부가 제한될 수 있습니다.</div>
                            <div>1.만19세 미만의 회원은(단, 만 19세에 도달하는 해의 1월 1일을 맞이한 자는 제외, 이하 본 조에서 동일함) 정보통신망이용촉진및정보보호등에관한법률 및 청소년보호법의규정에 의하여 청소년유해매체물은 이용할 수 없습니다.</div>
                            <div>2.청소년 유해매체물을 이용하시기 위해서는 만 19세 이상의 회원이어야 하며, 정보통신망이용촉진및정보보호등에관한법률 및 청소년보호법의 규정에 의하여 실명인증을 통해 본인 및 연령 인증을 받으셔야 합니다. 인증을 받지 않으시면, 해당 서비스의 이용이 제한됩니다.</div>

                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제6조 (미성년자 회원가입에 대한 특칙)</b>
                            </div>
                            <div><br /></div>
                            <div>① 만 14세 미만의 “이용자”는 개인정보의 수집 및 이용목적에 대하여 충분히 숙지하고 부모 등 법정대리인의 동의를 얻은 후에 회원가입을 신청하고 본인의 개인정보를 제공하여야 합니다.</div>
                            <div><br /></div>
                            <div>② 회사는 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은 만 14세 미만 이용자에 대하여는 가입을 취소 또는 불허합니다.</div>
                            <div><br /></div>
                            <div>③ 만 14세 미만 “이용자”의 부모 등 법정대리인은 아동에 대한 개인정보의 열람, 정정, 갱신을 요청하거나 회원가입에 대한 동의를 철회할 수 있으며, 이러한 경우에 “회사”는 지체 없이 필요한 조치를 취해야 합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제7조 (계약 사항의 변경)</b>
                            </div>
                            <div><br /></div>
                            <div>회원은 이용신청 시 기재한 사항이 변경되었을 경우 회사가 정한 별도의 이용방법으로 정해진 양식 및 방법에 의하여 수정하여야 합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 3장 서비스 이용</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제1조 (서비스의 이용 개시)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 회원의 이용신청을 승낙한 때부터 서비스를 개시합니다. 단, 일부 서비스의 경우에는 지정된 일자부터 서비스를 개시하며, 유료서비스의 경우 회사가 지정하는 수단으로 결제가 완료된 후 이용 가능합니다.</div>
                            <div><br /></div>
                            <div>② 회사의 업무상 또는 기술상의 장애로 인하여 서비스를 개시하지 못하는 경우에는 사이트에 공시하거나 회원에게 이를 통지합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제2조 (서비스의 이용시간)</b>
                            </div>
                            <div><br /></div>
                            <div>① 서비스의 이용은 연중무휴 1일 24시간을 원칙으로 합니다. 다만, 정기점검 등 회사의 업무상 또는 기술상의 이유로 서비스가 일시 중지될 수 있으며, 운영상의 목적으로 회사가 정한 기간에는 서비스가 일시 중지될 수 있습니다. 이러한 경우 회사는 원칙적으로 서비스의 일시 중지 사실 및 중지 기간을 사전에 공지하되, 이를 미리 공지할 수 없는 급박하거나 불가피한 사정이 있을 때에는 사후에 즉시 공지합니다.</div>
                            <div><br /></div>
                            <div>② 회사는 서비스를 일정범위로 분할하여 각 범위 별로 이용 가능한 시간을 별도로 정할 수 있으며 이 경우 그 내용을 공지합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제3조 (서비스의 변경 및 중지)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 상당한 이유가 있는 경우 운영상, 기술상 필요에 따라 서비스를 변경할 수 있으며, 이 경우 변경될 서비스의 내용 및 제공일자를 제2조(약관의 효력 및 변경) 제3항에서 정한 방법으로 회원에게 통지합니다. 단 변경된 내용이 중대하거나 회원에게 불리한 경우에는 회사가 회원으로부터 이 약관 또는 한터뉴스 유료서비스 약관에서 정한 방법으로 통지하고 동의를 받습니다.</div>
                            <div><br /></div>
                            <div>② 회사는 다음 각 호에 해당하는 경우 서비스의 전부 또는 일부를 일시적으로 제한하거나 중지할 수 있습니다.</div>
                            <div>1. 서비스용 설비의 보수 등 공사로 인한 부득이한 경우</div>
                            <div>2. 회원이 의도적으로 일정기간 내 특정서비스의 가입 및 해지를 반복하는 등 회사의 정상적 영업 및 서비스 제공 활동을 방해하는 경우</div>
                            <div>3. 정전, 제반 설비의 장애 또는 이용량의 폭주 등으로 정상적인 서비스 이용에 지장이 있는 경우</div>
                            <div>4. 서비스 제공업자와의 계약 종료 등과 같은 회사의 제반 사정으로 서비스를 유지할 수 없는 경우</div>
                            <div>5. 기타 천재지변, 국가비상사태, 방송통신위원회, 한국정보보호진흥원 등 국가기관, 정부조직, 수사기관, 법원 등의 행정ㆍ사법 처분 등 행정행위로 인한 서비스 중단 등 회사가 통제할 수 없는 불가항력적 사유가 있는 경우</div>
                            <div><br /></div>
                            <div>③ 제2항에 의한 서비스 중단의 경우에는 회사가 제2조(약관의 효력 및 변경) 제3항에서 정한 방법으로 이용자에게 통지합니다. 다만, 미리 통지하는 것이 곤란하거나 급박하거나 불가피한 사정이 있는 경우에는 사후에 통지할 수 있습니다.</div>
                            <div><br /></div>
                            <div>④ 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책 및 운영의 필요상 수정, 중단, 변경할 수 있으며, 이에 대하여 회원에게 별도의 보상을 하지 않습니다.</div>
                            <div><br /></div>
                            <div>⑤ 유료서비스의 정지 또는 중단과 관련한 제반 사항에 대하여는 한터뉴스 유료서비스 약관 제19조를 적용합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제4조 (이메일에 대한 회원의 의무와 책임)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 회원에게 사이트별로 이메일 서비스를 제공할 수 있습니다. 회사는 회원의 이메일 내용을 편집하거나 감시하지 않으며 메일 내용에 대한 책임은 각 회원에게 있습니다.</div>
                            <div><br /></div>
                            <div>② 회원은 회사의 이메일을 통하여 음란물이나 불온한 내용, 정크메일(Junk Mail), 스팸메일(Spam Mail), 행운의 편지(chain letters) 등을 발송하거나 피라미드 조직 등을 권유하거나 타인에게 피해를 주거나 미풍양속을 해치는 메일을 보내서는 안됩니다.</div>
                            <div><br /></div>
                            <div>③ 본 조 제2항을 위반하여 발생되는 모든 책임은 회원에게 있으며 이 경우 회사는 관련 법령에 정한 절차에 따라 회원의 아이디(ID) 비밀번호(PASSWORD) 등 회원의 개인정보를 수사기관에 제공할 수 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제5조 (정보의 제공 및 광고의 게재)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 서비스를 운영함에 있어 각종 정보나 광고 등을 서비스 화면에 게재하거나 이메일 및 서신우편, 문자메시지 등의 방법으로 회원에게 제공할 수 있습니다.</div>
                            <div><br /></div>
                            <div>② 제1항과 관련하여 회원은 관련 법령에 따른 거래 관련 정보 및 고객 문의 등에 대한 답변 등을 제외하고는 언제든지 이메일 등에 대하여 수신을 거절할 수 있으며, 회사는 수신거절 방법을 명시합니다. 단, 회사는 관련 법령에 따라 동의 의무가 면제되는 거래관련 정보 및 고객 문의 사항 등을 제외한 광고 등을 전화, 모사전송기기 등을 통하여 회원에게 전송할 경우 회원의 사전 수신 동의를 받아서 전송합니다.</div>
                            <div><br /></div>
                            <div>③ 회원이 서비스상에 게재되어 있는 광고를 이용하거나 서비스를 통한 광고주의 판촉활동에 참여하는 등의 방법으로 교신 또는 거래를 하는 것은 전적으로 회원과 광고주 간의 문제입니다. 만약 회원과 광고주간에 문제가 발생할 경우에도 회원과 광고주가 직접 해결하여야 하며, 이와 관련하여 회사는 어떠한 책임도 지지 않습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제6조 (게시물 또는 내용물의 삭제)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 회원이 게시하거나 전달하는 서비스 내의 내용물(회원간 전달 포함)이 다음 각 호의 경우에 해당한다고 판단되는 경우 사전통지 없이 삭제할 수 있으며, 이에 대해 회사는 어떠한 책임도 지지 않습니다.</div>
                            <div>1.회사, 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용인 경우</div>
                            <div>2.공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형 등의 유포에 해당하는 경우</div>
                            <div>3.범죄적 행위에 결부된다고 인정되는 내용인 경우</div>
                            <div>4.회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 내용인 경우</div>
                            <div>5.제2항 소정의 세부이용지침을 통하여 회사에서 규정한 게시기간을 초과한 경우</div>
                            <div>6.회사에서 제공하는 서비스와 관련 없는 내용인 경우</div>
                            <div>7.승인되지 않은 광고, 판촉물을 게재하는 경우</div>
                            <div>8.기타 관계 법령 및 회사의 지침 등에 위반된다고 판단되는 경우</div>
                            <div><br /></div>
                            <div>② 회사는 게시물에 관련된 세부 이용지침을 별도로 정하여 시행할 수 있으며, 회원은 그 지침에 따라 각종 게시물(회원간 전달 포함)을 등록하거나 삭제하여야 합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제7조 (게시물의 저작권)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회원이 서비스 내에 게시한 게시물(회원간 전달 포함)의 저작권은 회원이 소유하며 회사는 서비스 내에 이를 게시할 수 있는 권리를 갖습니다.</div>
                            <div><br /></div>
                            <div>② 회사는 게시한 회원의 동의 없이 게시물을 다른 목적으로 사용할 수 없습니다.</div>
                            <div><br /></div>
                            <div>③ 회사는 회원이 서비스 내에 게시한 게시물이 타인의 저작권, 프로그램 저작권 등을 침해하더라도 이에 대한 민, 형사상의 책임을 부담하지 않습니다. 만일 회원이 타인의 저작권, 프로그램저작권 등을 침해하였음을 이유로 회사가 타인으로부터 손해배상청구 등 이의 제기를 받은 경우 회원은 회사의 면책을 위하여 노력하여야 하며, 회사가 면책되지 못한 경우 회원은 그로 인해 회사에 발생한 모든 손해를 부담하여야 합니다.</div>
                            <div><br /></div>
                            <div>④ 회사는 회원이 이용계약을 해지하거나 적법한 사유로 이용계약이 해지 된 경우 해당 회원이 게시하였던 게시물을 삭제할 수 있습니다. 회원의 게시물이 정보통신망이용촉진및정보보호등에관한법률 및 저작권법 등 관련 법령에 위반되는 내용을 포함할 경우, 해당 권리자는 관련 법령이 정한 절차에 의거하여 해당 게시물의 게시중단 및 삭제 등을 회사에 요청할 수 있으며, 회사는 관련 법령에 따른 조치를 취하여야 합니다.</div>
                            <div><br /></div>
                            <div>⑤ 회사는 전항의 권리자의 요청이 없을 경우에도 사생활 침해 또는 명예훼손 등 타인의 권리를 침해한다고 인정되는 경우에는 관련 법령에 의거 해당 게시물에 대한 접근을 임시적으로 차단하거나 삭제할 수 있습니다.</div>
                            <div><br /></div>
                            <div>⑥ 회사가 작성한 저작물, 기타 콘텐츠에 대한 저작권 기타 권리는 회사에 귀속합니다.</div>
                            <div><br /></div>
                            <div>⑦ 회원은 서비스를 이용하여 얻은 정보를 가공, 판매하는 행위 등 서비스에 게재된 자료를 영리목적으로 이용하거나 제3자에게 이용하게 할 수 없으며, 게시물에 대한 저작권 침해는 관계 법령의 적용을 받습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제8조 (한터뉴스 유료서비스 약관)</b>
                            </div>
                            <div>서비스 이용을 위하여 한터뉴스 유료서비스 약관, 위치기반 서비스 약관 등 별도의 약관이 존재할 수 있습니다. 추가되는 유료서비스에 따라 별도의 약관을 제정할 수 있으며, 이용약관과 한터뉴스 유료서비스 약관의 내용이 상충될 경우 한터뉴스 유료서비스 약관이 우선 적용됩니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 4장 계약당사자의 의무</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제1조 (회사의 의무)</b>
                            </div>
                            <div>① 회사는 서비스 제공과 관련하여 알고 있는 회원의 신상정보를 본인의 승낙 없이 제3자에게 누설, 배포하지 않습니다. 단, 관계법령에 의한 수사상의 목적으로 관계기관으로부터 요구 받은 경우나 방송통신심의위원회의 요청이 있는 경우 등 법률의 규정에 의한 경우에는 그러하지 않습니다.</div>
                            <div><br /></div>
                            <div>② 회사는 업무와 관련하여 회원의 사전 동의 없이 회원 전체 또는 일부의 개인정보에 관한 통계자료를 작성하여 이를 사용할 수 있고, 이를 위하여 회원의 컴퓨터에 쿠키를 전송할 수 있습니다. 이 경우 회원은 쿠키의 수신을 거부하거나 쿠키의 수신에 대하여 경고하도록 사용하는 컴퓨터 브라우저의 설정을 변경할 수 있으며, 쿠키의 설정 변경에 의해 서비스 이용이 변경되는 것은 회원의 책임입니다.</div>
                            <div><br /></div>
                            <div>③ 회사는 서비스와 관련한 회원의 불만사항이 접수되는 경우 이를 신속하게 처리하여야 하며, 신속한 처리가 곤란한 경우 그 사유와 처리 일정을 서비스 화면에 게재하거나 이메일 등을 통하여 동 회원에게 통지합니다.</div>
                            <div><br /></div>
                            <div>④ 회사는 이 약관에 정한 회사의 의무를 위반함으로써 회원이 입게 된 손해를 배상합니다.</div>
                            <div><br /></div>
                            <div>⑤ 회사는 정보통신망이용촉진및정보보호등에관한법률, 통신비밀보호법 등 서비스의 운영, 유지와 관련 있는 법규를 준수합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제2조 (회원의 의무)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회원은 서비스를 이용할 때 다음 각 호의 행위를 하여서는 아니 됩니다.</div>
                            <div>1.이용신청 또는 변경 시 허위 사실을 기재하거나, 다른 회원의 아이디(ID) 및 비밀번호를 도용, 부정하게 사용하는 행위</div>
                            <div>2.회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는 유통시키거나 상업적으로 이용하는 행위</div>
                            <div>3.사이트 내에서 제공받은 서비스를 사적 목적으로 이용하는 것 외에, 공공장소 및 영리를 목적으로 하는 영업장, 매장 등에서 사용하는 등의 방법으로 이용하는 행위</div>
                            <div>4.타인의 명예를 손상시키거나 불이익을 주는 행위</div>
                            <div>5.게시판 등에 음란물을 게재하거나 음란사이트를 연결(링크)하는 행위</div>
                            <div>6.회사의 저작권, 제3자의 저작권 등 기타 권리를 침해하는 행위</div>
                            <div>(국내외 제3자의 저작권 등을 침해하는 행위로서 회사가 IP 접속 차단 등 기술적인 조치를 통하여 타인에 대한 권리 침해 방지 조치를 취하였음에도 불구하고 이용자가 고의로 또는 회사를 기망하는 수단과 방법을 통하여 한터뉴스 사이트에 접속 하는 등 제3자의 저작권 등을 침해하는 행위를 포함합니다.)</div>
                            <div>7.공공질서 및 미풍양속에 위반되는 내용의 정보, 문장, 도형, 음성 등을 타인에게 유포하는 행위</div>
                            <div>8.서비스와 관련된 설비의 오 동작이나 정보 등의 파괴 및 혼란을 유발시키는 컴퓨터 바이러스 감염 자료를 등록 또는 유포하는 행위</div>
                            <div>9.서비스 운영을 고의로 방해하거나 서비스의 안정적 운영을 방해할 수 있는 정보 및 수신자의 명시적인 수신거부의사에 반하여 광고성 정보를 전송하는 행위 또는 (불법)스팸을 전송하는 행위</div>
                            <div>10.타인으로 가장하는 행위 및 타인과의 관계를 허위로 명시하는 행위</div>
                            <div>11.다른 회원의 개인정보를 수집, 저장, 공개하는 행위</div>
                            <div>12.자기 또는 타인에게 재산상의 이익을 주거나 타인에게 손해를 가할 목적으로 허위의 정보를 유통시키는 행위</div>
                            <div>13.재물을 걸고 도박하거나 사행행위를 하는 행위</div>
                            <div>14.윤락행위를 알선하거나 음행을 매개하는 내용의 정보를 유통시키는 행위</div>
                            <div>15.수치심이나 혐오감 또는 공포심을 일으키는 말이나 음향, 글이나 화상 또는 영상을 계속하여 상대방에게 도달하게 하여 상대방의 일상적 생활을 방해하는 행위</div>
                            <div>16.서비스에 게시된 정보를 변경하는 행위</div>
                            <div>17.관련 법령에 의하여 그 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램 포함)의 전송 또는 게시 행위</div>
                            <div>18.회사의 직원이나 운영자를 가장하거나 사칭하여 또는 타인의 명의를 도용하여 글을 게시하거나 메일을 발송하는 행위</div>
                            <div>19.컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 이메일으로 발송하는 행위</div>
                            <div>20.스토킹(stalking) 등 다른 회원을 괴롭히는 행위</div>
                            <div>21.1개월 이내 회원 가입 및 서비스 구매 후 다시 해지하는 행위를 2회 이상 반복하는 등 회사 서비스를 부당하게 악용하는 행위</div>
                            <div>22.기타 불법적이거나 부당한 행위</div>
                            <div><br /></div>
                            <div>② 회원은 회사가 제공하는 유료서비스 이용시 관계법령, 해당 약관, 세부이용지침, 서비스 이용안내 및 사이트 내 공지한 주의사항, 회사가 서비스 이용과 관련하여 회원에게 통지하는 사항 등을 준수하여야 하며, 회원은 관계 법령, 이 약관의 규정, 이용안내 및 서비스상에 공지한 주의사항, 회사가 통지하는 사항 등을 준수하여야 하며, 기타 회사의 업무에 방해되는 행위를 하여서는 안됩니다.</div>
                            <div><br /></div>
                            <div>③ 회원은 회사에서 공식적으로 인정한 경우를 제외하고는 서비스를 이용하여 유료서비스를 판매하거나 기타 영리 활동을 할 수 없으며 또한, 해킹, 광고, 음란사이트 홍보 또는 이를 통한 영리행위, 상용소프트웨어 불법배포 등을 할 수 없습니다. 이를 위반하여 발생한 모든 결과에 대하여 회사는 책임을 지지 않으며, 회원은 이와 같은 행위로 인하여 회사에 발생한 손해를 배상하여야 합니다. 또한, 회사는 위와 같은 사실을 발견한 경우 그와 같은 사실을 행정기관에 신고하거나 수사기관에 고발하는 등 법적조치를 취할 수 있습니다.</div>
                            <div><br /></div>
                            <div>④ 회원이 서비스 이용을 위하여 개인정보를 등록하는 경우 현재의 사실과 일치하는 완전한 정보를 제공하여야 합니다(이하 본 조에서 그와 같이 등록된 정보를 ‘등록정보’라고 합니다).</div>
                            <div><br /></div>
                            <div>⑤ 회원은 등록정보에 변경사항이 발생할 경우 즉시 갱신하여야 합니다. 회원이 제공한 등록정보 및 갱신한 등록정보가 부정확할 경우, 기타 회원이 본 조 제1항에 명시된 행위를 한 경우에 회사는 이 약관 제25조에 의해 서비스이용계약을 해지하거나 회원의 서비스 이용을 제한 또는 중지 할 수 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제3조 (회원 아이디(ID)와 비밀번호(Password)관리에 대한 의무와 책임)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 사이트 내에서 일부 서비스 신청 시 이용요금을 부과할 수 있으므로, 회원은 회원 아이디(ID) 및 비밀번호(Password) 관리를 철저히 해야 합니다.</div>
                            <div><br /></div>
                            <div>② 회원 아이디(ID)와 비밀번호(Password)의 관리 소홀로 인하여 발생하는 모든 결과에 대한 책임은 회원 본인에게 있으며, 회사의 시스템 고장 등 회사의 책임 있는 사유로 발생하는 문제에 대해서는 회사가 책임을 집니다.</div>
                            <div><br /></div>
                            <div>③ 회원은 본인의 아이디(ID) 및 비밀번호(Password)를 제3자에게 이용하게 해서는 안되며, 회원 본인의 아이디(ID) 및 비밀번호(Password)를 도난 당하거나 제3자가 사용하고 있음을 인지하는 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우 그에 따라야 합니다.</div>
                            <div><br /></div>
                            <div>④ 제3항의 경우 해당 회원이 아이디(ID) 등의 도용 등의 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여는 회사는 책임지지 않습니다.</div>
                            <div><br /></div>
                            <div>⑤ 회원의 아이디(ID)는 회사의 사전 동의 없이 변경할 수 없습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제4조 (회원에 대한 통지)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회원에 대한 통지를 하는 경우 회사는 회사가 발급한 이메일 주소 또는 회원이 등록한 이메일 주소 또는 문자메시지 등으로 할 수 있습니다.</div>
                            <div><br /></div>
                            <div>② 회원의 연락처 미 기재, 변경 등으로 인하여 개별 통지가 어려운 경우, 회원이 등록한 연락처로 통지를 하였음에도 2회 이상 반송된 경우 회사는 서비스 게시판 등에 7일 이상 게시함으로써 개별 통지에 갈음할 수 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제5조 (이용자의 개인정보보호)</b>
                            </div>
                            <div><br /></div>
                            <div>회사는 관련 법령이 정하는 바에 따라서 회원 등록정보를 포함한 회원의 개인정보를 보호하기 위하여 노력합니다. 회원의 개인정보보호에 관해서는 관련법령 및 회사가 정하는 "개인정보취급방침"에 정한 바에 의합니다. 특히, 회사는 정보통신망 이용촉진및정보보호등에관한법률 등 관계 법령에 따라 회원의 사전 동의를 얻은 경우, 동의를 받은 범위 내에서 회원의 개인정보를 제3자에게 제공하며, 회사의 ‘개인정보취급방침’에 그 절차와 방법 등에 대하여 상세히 기재하도록 합니다.</div>
                            <div><br /></div>
                            <div>회사가 서비스 홈페이지를 통하여 게재, 공시하는 '개인정보취급방침'은 이 약관의 일부를 구성하며, 회원은 이에 동의합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제6조 (개인정보의 수집, 제공 및 취급 위탁)</b>
                            </div>
                            <div><br /></div>
                            <div>회사는 수집된 개인정보의 취급 및 관리 등의 업무(이하 “업무”)를 스스로 수행함을 원칙으로 하나, 필요한 경우 업무의 일부 또는 전부를 회사가 선정한 회 사에 위탁할 수 있으며, 개인정보의 취급 및 관리 등의 업무를 제3자 등에게 위탁하게 될 경우, 회사가 정하는 “개인정보취급방침”에 제반 사항을 기재하여 회원에게 고지, 안내하고 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제7조 (계약해지 및 이용제한)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회원이 서비스 이용계약을 해지하고자 할 경우에는 본인이 서비스 사이트 상에서 또는 전화 등 기타 회사가 제공하는 그 밖의 방법으로 회사에 해지신청을 하여야 합니다. 회사는 회원의 유료서비스의 청약 철회, 환불, 계약의 해지와 관련하여 회사의 “한터뉴스 유료서비스 약관”에 그 절차 및 제반 사항을 안내하고 있습니다. 회원이 계약을 해지할 경우 관련 법령 및 회사의 개인정보 취급방침에 따라 회사가 회원정보를 보유하는 경우를 제외하고는 해지 즉시 회원의 모든 개인정보 및 데이터는 삭제되므로, 해지, 탈퇴 시 사전 확인하기 바라며, 이에 대하여 회사가 데이터 등의 삭제를 안내하였음에도 불구하고 회원이 개인 데이터의 보존 등 적절한 조치를 취하지 아니하는 경우에는 회사는 책임을 지지 않습니다.</div>
                            <div><br /></div>
                            <div>② 회사는 회원이 제20조(회원의 의무)에 규정한 회원의 의무를 이행하지 아니하거나 제8조(이용신청에 대한 승낙의 제한)의 가입 제한 사유를 사후에 발견한 경우 회원의 서비스 이용 제한, 회원자격상실, 계약 해지 등의 조치를 취할 수 있으며, 회사가 회원에 대하여 이와 같은 조치를 취하고자 할 경우, 최소한 15일 이상의 기간을 정하여 소명할 기회를 부여하며, 이용자가 자신의 고의, 과실 없었음을 입증한 경우 회사는 서비스를 정지한 기간만큼 이용 기간을 연장합니다.</div>
                            <div><br /></div>
                            <div>③ 회사는 회원이 이용계약을 체결하여 아이디(ID)와 비밀번호(Password)를 부여 받은 후에라도 회원의 자격에 따른 서비스 이용을 제한할 수 있습니다.</div>
                            <div><br /></div>
                            <div>④ 회사는 회원 가입 후 6개월 동안 서비스 사용 이력이 없는 회원에 대해 사용의사를 묻는 고지를 하고, 회사가 정한 기한 내에 답변이 없는 경우 이용계약을 해지할 수 있습니다.</div>
                            <div><br /></div>
                            <div>⑤ 본 조 제2항 및 제3항의 회사 조치에 대하여 회원은 회사가 정한 절차에 따라 이의신청을 할 수 있습니다.</div>
                            <div><br /></div>
                            <div>⑥ 본 조 제5항의 이의가 정당하다고 회사가 인정하는 경우, 회사는 즉시 서비스의 이용을 재개합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제8조 (양도 금지)</b>
                            </div>
                            <div><br /></div>
                            <div>회원은 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여할 수 없으며 게시물에 대한 저작권을 포함한 모든 권리 및 책임은 이를 게시한 회원에게 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제9조 (손해 배상)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사의 고의 또는 중대한 과실로 인하여 회원에게 손해가 발생한 경우 회사는 회원에게 실제 발생한 손해만을 배상합니다.</div>
                            <div><br /></div>
                            <div>② 회원이 서비스를 이용함에 있어 행한 불법행위나 이 약관 위반행위로 인하여 회사가 당해 회원 이외의 제3자로부터 손해배상 청구 또는 소송을 비롯한 각종 이의제기를 받는 경우 당해 회원은 자신의 책임과 비용으로 회사를 면책하여야 하며, 회사가 면책되지 못한 경우 당해 회원은 그로 인하여 회사에 발생한 모든 손해를 배상하여야 합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제10조 (면책사항)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.</div>
                            <div><br /></div>
                            <div>② 회사는 회원의 귀책사유로 인한 서비스의 이용장애에 대하여 책임을 지지 않습니다.</div>
                            <div><br /></div>
                            <div>③ 회사는 회원이 서비스를 이용하여 기대하는 수익을 상실한 것에 대하여 책임을 지지 않으며 그 밖에 서비스를 통하여 얻은 자료로 인한 손해 등에 대하여도 책임을 지지 않습니다. 회사는 회원이 사이트에 게재한 정보, 자료, 사실의 신뢰도 및 정확성 등 내용에 대하여는 책임을 지지 않습니다.</div>
                            <div><br /></div>
                            <div>④ 회사는 회원 상호간 또는 회원과 제3자 상호간에 서비스를 매개로 발생한 분쟁에 대해서는 개입할 의무가 없으며 이로 인한 손해를 배상할 책임도 없습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제11조 (관할법원)</b>
                            </div>
                            <div><br /></div>
                            <div>① 서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의하고, 협의가 안될 경우 콘텐츠산업진흥법상 콘텐츠분쟁조정위원회에 분쟁조정을 신청할 수 있습니다.</div>
                            <div><br /></div>
                            <div>② 본 조 제1항의 협의에서도 분쟁이 해결되지 않을 경우 양 당사자는 소를 제기할 수 있으며, 회사와 회원 간의 소의 관할은 [서울특별시 동부 지방 법원]으로 합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>[한터뉴스 위치기반서비스 약관]</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제1장 총칙</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 1 조 (목적)</b>
                            </div>
                            <div><br /></div>
                            <div>본 약관은 회원(한터뉴스 및 한터뉴스 관련 서비스 약관에 동의한 자를 말합니다. 이하 "회원"이라고 합니다.)이 주식회사 한터뉴스(이하 "회사"라고 합니다.)이 제공하는 한터뉴스 및 한터뉴스 관련 서비스(이하 "서비스"라고 합니다)를 이용함에 있어 회사와 회원의 권리ㆍ의무 및 책임사항을 규정함을 목적으로 합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 2 조 (이용약관의 효력 및 변경)</b>
                            </div>
                            <div><br /></div>
                            <div>① 본 약관은 서비스를 신청한 고객 또는 개인위치정보주체가 본 약관에 동의하고 회사가 정한 소정의 절차에 따라 서비스의 이용자로 등록함으로써 효력이 발생합니다.</div>
                            <div><br /></div>
                            <div>② 회원이 온라인에서 본 약관의 "동의하기" 버튼을 클릭하였을 경우 본 약관의 내용을 모두 읽고 이를 충분히 이해하였으며, 그 적용에 동의한 것으로 봅니다.</div>
                            <div><br /></div>
                            <div>③ 회사는 위치정보의 보호 및 이용 등에 관한 법률, 콘텐츠산업 진흥법, 전자상거래 등에서의 소비자보호에 관한 법률, 소비자기본법 약관의 규제에 관한 법률 등 관련법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.</div>
                            <div><br /></div>
                            <div>④ 회사가 약관을 개정할 경우에는 기존약관과 개정약관 및 개정약관의 적용일자와 개정사유를 명시하여 현행약관과 함께 그 적용일자 10일 전부터 적용일 이후 상당한 기간 동안 공지만을 하고, 개정 내용이 회원에게 불리한 경우에는 그 적용일자 30일 전부터 적용일 이후 상당한 기간 동안 각각 이를 서비스 홈페이지에 게시하거나 회원에게 전자적 형태(전자우편, SMS 등)로 약관 개정 사실을 발송하여 고지합니다.</div>
                            <div><br /></div>
                            <div>⑤ 회사가 전항에 따라 회원에게 통지하면서 공지 또는 공지∙고지일로부터 개정약관 시행일 7일 후까지 거부의사를 표시하지 아니하면 이용약관에 승인한 것으로 봅니다. 회원이 개정약관에 동의하지 않을 경우 회원은 이용계약을 해지할 수 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 3 조 (관계법령의 적용)</b>
                            </div>
                            <div><br /></div>
                            <div>본 약관은 신의성실의 원칙에 따라 공정하게 적용하며, 본 약관에 명시되지 아니한 사항에 대하여는 관계법령 또는 상관례에 따릅니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 4 조 (서비스의 내용)</b>
                            </div>
                            <div><br /></div>
                            <div>회사가 제공하는 서비스는 아래와 같습니다.</div>
                            <div><br /></div>
                            <div>① 한터뉴스</div>
                            <div>-다국어 서비스 제공</div>
                            <div>-지역뉴스 등의 맞춤형 컨텐츠 제공</div>
                            <div><br /></div>
                            <div>② 상기 서비스는 제공 예정이며, 위치기반서비스와 관련한 추가 서비스가 제공 될 시 이 항목은 변경될 수 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 5 조 (서비스 이용요금)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사가 제공하는 서비스는 기본적으로 무료입니다. 단, 별도의 유료 서비스의 경우 해당 서비스에 명시된 요금을 지불하여야 사용 가능합니다.</div>
                            <div><br /></div>
                            <div>② 회사는 유료 서비스 이용요금을 회사와 계약한 전자지불업체에서 정한 방법에 의하거나 회사가 정한 청구서에 합산하여 청구할 수 있습니다.</div>
                            <div><br /></div>
                            <div>③ 유료서비스 이용을 통하여 결제된 대금에 대한 취소 및 환불은 회사의 결제 이용약관 등 관계법에 따릅니다.</div>
                            <div><br /></div>
                            <div>④ 회원의 개인정보도용 및 결제사기로 인한 환불요청 또는 결제자의 개인정보 요구는 법률이 정한 경우 외에는 거절될 수 있습니다.</div>
                            <div><br /></div>
                            <div>⑤ 무선 서비스 이용 시 발생하는 데이터 통신료는 별도이며 가입한 각 이동통신사의 정책에 따릅니다.</div>
                            <div><br /></div>
                            <div>⑥ MMS 등으로 게시물을 등록할 경우 발생하는 요금은 이동통신사의 정책에 따릅니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 6 조 (서비스내용변경 통지 등)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사가 서비스 내용을 변경하거나 종료하는 경우 회사는 회원의 등록된 전자우편 주소로 이메일을 통하여 서비스 내용의 변경 사항 또는 종료를 통지할 수 있습니다.</div>
                            <div><br /></div>
                            <div>② ①항의 경우 불특정 다수인을 상대로 통지를 함에 있어서는 웹사이트 등 기타 회사의 공지사항을 통하여 회원들에게 통지할 수 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 7 조 (서비스이용의 제한 및 중지)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 아래 각 호의 1에 해당하는 사유가 발생한 경우에는 회원의 서비스 이용을 제한하거나 중지시킬 수 있습니다.</div>
                            <div>1.회원이 회사 서비스의 운영을 고의 또는 중과실로 방해하는 경우</div>
                            <div>2.서비스용 설비 점검, 보수 또는 공사로 인하여 부득이한 경우</div>
                            <div>3.전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</div>
                            <div>4.국가비상사태, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 서비스 이용에 지장이 있는 때</div>
                            <div>5.기타 중대한 사유로 인하여 회사가 서비스 제공을 지속하는 것이 부적당하다고 인정하는 경우</div>
                            <div><br /></div>
                            <div>② 회사는 전항의 규정에 의하여 서비스의 이용을 제한하거나 중지한 때에는 그 사유 및 제한기간 등을 회원에게 알려야 합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 8 조 (개인위치정보의 이용 또는 제공)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 개인위치정보를 이용하여 서비스를 제공하고자 하는 경우에는 미리 이용약관에 명시한 후 개인위치정보주체의 동의를 얻어야 합니다.</div>
                            <div><br /></div>
                            <div>② 회원 및 법정대리인의 권리와 그 행사방법은 제소 당시의 이용자의 주소에 의하며, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.</div>
                            <div><br /></div>
                            <div>③ 회사는 타사업자 또는 이용 고객과의 요금정산 및 민원처리를 위해 위치정보 이용•제공․사실 확인자료를 자동 기록•보존하며, 해당 자료는 1년간 보관합니다.</div>
                            <div><br /></div>
                            <div>④ 회사는 개인위치정보를 회원이 지정하는 제3자에게 제공하는 경우에는 개인위치정보를 수집한 당해 통신 단말장치로 매회 회원에게 제공받는 자, 제공일시 및 제공목적을 즉시 통보합니다. 단, 아래 각 호의 1에 해당하는 경우에는 회원이 미리 특정하여 지정한 통신 단말장치 또는 전자우편주소로 통보합니다.</div>
                            <div>1.개인위치정보를 수집한 당해 통신단말장치가 문자, 음성 또는 영상의 수신기능을 갖추지 아니한 경우</div>
                            <div>2.회원이 온라인 게시 등의 방법으로 통보할 것을 미리 요청한 경우</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 9 조 (개인위치정보주체의 권리)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회원은 회사에 대하여 언제든지 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의의 전부 또는 일부를 철회할 수 있습니다. 이 경우 회사는 수집한 개인위치정보 및 위치정보 이용, 제공사실 확인자료를 파기합니다.</div>
                            <div><br /></div>
                            <div>② 회원은 회사에 대하여 언제든지 개인위치정보의 수집, 이용 또는 제공의 일시적인 중지를 요구할 수 있으며, 회사는 이를 거절할 수 없고 이를 위한 기술적 수단을 갖추고 있습니다.</div>
                            <div><br /></div>
                            <div>③ 회원은 회사에 대하여 아래 각 호의 자료에 대한 열람 또는 고지를 요구할 수 있고, 당해 자료에 오류가 있는 경우에는 그 정정을 요구할 수 있습니다. 이 경우 회사는 정당한 사유 없이 회원의 요구를 거절할 수 없습니다.</div>
                            <div>1.본인에 대한 위치정보 수집, 이용, 제공사실 확인자료</div>
                            <div>2.본인의 개인위치정보가 위치정보의 보호 및 이용 등에 관한 법률 또는 다른 법률 규정에 의하여 제3자에게 제공된 이유 및 내용</div>
                            <div><br /></div>
                            <div>④ 회원은 제1항 내지 제3항의 권리행사를 위해 회사의 소정의 절차를 통해 요구할 수 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 10 조 (법정대리인의 권리)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 14세 미만의 회원에 대해서는 개인위치정보를 이용한 위치기반서비스 제공 및 개인위치정보의 제3자 제공에 대한 동의를 당해 회원과 당해 회원의 법정대리인으로부터 동의를 받아야 합니다. 이 경우 법정대리인은 제9조에 의한 회원의 권리를 모두 가집니다.</div>
                            <div><br /></div>
                            <div>② 회사는 14세 미만의 아동의 개인위치정보 또는 위치정보 이용․제공사실 확인자료를 이용약관에 명시 또는 고지한 범위를 넘어 이용하거나 제3자에게 제공하고자 하는 경우에는 14세미만의 아동과 그 법정대리인의 동의를 받아야 합니다. 단, 아래의 경우는 제외합니다.</div>
                            <div>1.위치정보 및 위치기반서비스 제공에 따른 요금정산을 위하여 위치정보 이용, 제공사실 확인자료가 필요한 경우</div>
                            <div>2.통계작성, 학술연구 또는 시장조사를 위하여 특정 개인을 알아볼 수 없는 형태로 가공하여 제공하는 경우</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 11 조 (8세 이하의 아동 등의 보호의무자의 권리)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 아래의 경우에 해당하는 자(이하 "8세 이하의 아동"등이라 한다)의 보호의무자가 8세 이하의 아동 등의 생명 또는 신체보호를 위하여 개인위치정보의 이용 또는 제공에 동의하는 경우에는 본인의 동의가 있는 것으로 봅니다.</div>
                            <div>1.8세 이하의 아동</div>
                            <div>2.금치산자</div>
                            <div>3.장애인복지법 제2조제2항제2호의 규정에 의한 정신적 장애를 가진 자로서 장애인고용촉진및직업재활법 제2조제2호의 규정에 의한 중증장애인에 해당하는 자(장애인복지법 제29조의 규정에 의하여 장애인등록을 한 자에 한한다)</div>
                            <div><br /></div>
                            <div>② 8세 이하의 아동 등의 생명 또는 신체의 보호를 위하여 개인위치정보의 이용 또는 제공에 동의를 하고자 하는 보호의무자는 서면동의서에 보호의무자임을 증명하는 서면을 첨부하여 회사에 제출하여야 합니다.</div>
                            <div><br /></div>
                            <div>③ 보호의무자는 8세 이하의 아동 등의 개인위치정보 이용 또는 제공에 동의하는 경우 개인위치정보주체 권리의 전부를 행사할 수 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 12 조 (위치정보관리책임자의 지정)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 위치정보를 적절히 관리․보호하고 개인위치정보주체의 불만을 원활히 처리할 수 있도록 실질적인 책임을 질 수 있는 지위에 있는 자를 위치정보관리책임자로 지정해 운영합니다.</div>
                            <div><br /></div>
                            <div>② 위치정보관리책임자는 위치기반서비스를 제공하는 부서의 부서장으로서 구체적인 사항은 본 약관의 부칙에 따릅니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 13 조 (손해배상)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사가 위치정보의 보호 및 이용 등에 관한 법률 제15조 내지 제26조의 규정을 위반한 행위로 회원에게 손해가 발생한 경우 회원은 회사에 대하여 손해배상 청구를 할 수 있습니다. 이 경우 회사는 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.</div>
                            <div><br /></div>
                            <div>② 회원이 본 약관의 규정을 위반하여 회사에 손해가 발생한 경우 회사는 회원에 대하여 손해배상을 청구할 수 있습니다. 이 경우 회원은 고의, 과실이 없음을 입증하지 못하는 경우 책임을 면할 수 없습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 14 조 (면책) </b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 다음 각 호의 경우로 서비스를 제공할 수 없는 경우 이로 인하여 회원에게 발생한 손해에 대해서는 책임을 부담하지 않습니다.</div>
                            <div>1.천재지변 또는 이에 준하는 불가항력의 상태가 있는 경우</div>
                            <div>2.서비스 제공을 위하여 회사와 서비스 제휴계약을 체결한 제3자의 고의적인 서비스 방해가 있는 경우</div>
                            <div>3.회원의 귀책사유로 서비스 이용에 장애가 있는 경우</div>
                            <div>4.제1호 내지 제3호를 제외한 기타 회사의 고의∙과실이 없는 사유로 인한 경우</div>
                            <div><br /></div>
                            <div>② 회사는 서비스 및 서비스에 게재된 정보, 자료, 사실의 신뢰도, 정확성 등에 대해서는 보증을 하지 않으며 이로 인해 발생한 회원의 손해에 대하여는 책임을 부담하지 아니합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 15 조 (규정의 준용)</b>
                            </div>
                            <div><br /></div>
                            <div>① 본 약관은 대한민국법령에 의하여 규정되고 이행됩니다.</div>
                            <div><br /></div>
                            <div>② 본 약관에 규정되지 않은 사항에 대해서는 관련법령 및 상관습에 의합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 16 조 (분쟁의 조정 및 기타)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 위치정보의 보호 및 이용 등에 관한 법률 제28조의 규정에 의한 방송통신위원회에 재정을 신청할 수 있습니다.</div>
                            <div><br /></div>
                            <div>② 회사 또는 고객은 위치정보와 관련된 분쟁에 대해 당사자간 협의가 이루어지지 아니하거나 협의를 할 수 없는 경우에는 개인정보보호법 제43조의 규정에 의한 개인정보분쟁조정위원회에 조정을 신청할 수 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 17 조 (회사의 연락처)</b>
                            </div>
                            <div><br /></div>
                            <div>회사의 상호 및 주소 등은 다음과 같습니다.</div>
                            <div>1. 상 호 : ㈜ 한터뉴스</div>
                            <div>2. 주 소 : 서울특별시 강남구 봉은사로109길 4(삼성동, 베페하우스), 2층</div>
                            <div>3. 대표전화 : 02-449-2347</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>부 칙</b>
                            </div>
                            <div><br /></div>
                            <div>제1조 (시행일) 이 약관은 2019년 4월 1일부터 시행한다.</div>
                            <div><br /></div>
                            <div>제2조 위치정보관리책임자는 2019년 4월을 기준으로 다음과 같이 지정합니다.</div>
                            <div>1. 소 속 : (주)한터글로벌</div>
                            <div>2. 연락처 : 02-449-2347</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>[한터뉴스 유료서비스 약관]</b>
                            </div><div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 1장 총칙</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제1조 (목적)</b>
                            </div>
                            <div>이 약관은 주식회사 한터뉴스(이하 ‘회사’라 합니다)가 제공하는 인터넷 뉴스인 한터뉴스(Hanteo Chart) 및 한터뉴스 관련 제반 서비스의 유료서비스를 이용함에 있어 회사와 회원간 제반 법률관계 및 기타 관련 사항을 규정함을 목적으로 합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제2조 (용어의 정의)</b>
                            </div>
                            <div><br /></div>
                            <div>① 이 약관에서 사용되는 용어의 정의는 다음과 같습니다.</div>
                            <div>1.‘회원가입’이라 함은 한터뉴스 서비스 또는 유료서비스를 이용하고자 하는 자(‘고객’이라고도 합니다)가 한터뉴스 이용약관에 동의하고 회사와 그 이용계약을 체결하는 것을 의미합니다. 회원가입을 하는 경우 고객은 한터뉴스서비스 회원이 됩니다.</div>
                            <div>2.‘회원탈퇴’라 함은 회원이 한터뉴스 이용약관 또는 한터뉴스 유료서비스약관의 체결로 발생한 제반 권리와 의무 등의 법률관계를 자의로 영구히 종료하거나, 포기하는 것을 의미합니다.</div>
                            <div>3.‘유료서비스’라 함은 회원이 회사에 일정 금액을 지불해야만 이용할 수 있는 회사의 서비스 또는 이용권(상품)을 의미하거나, 회사 또는 제3자와의 거래 내지 약정 조건을 수락하는 대가로 이용하게 되는 회사의 서비스 또는 이용권(상품)을 의미합니다. 유료서비스의 세부내용은 각각의 서비스 또는 이용권(상품) 구매 페이지 및 해당 서비스 또는 이용권(상품)의 결제 페이지에 상세히 설명되어 있습니다. 회사 또는 제3자로부터 적립 받는 행위, 사이트에 연결(링크)된 유료 콘텐츠 제공업체 및 회사와 계약을 통하여 입점한 제휴서비스는 회사의 유료서비스 이용행위로 보지 않으며, 본 약관을 적용하지 아니합니다.</div>
                            <div>4.‘유료회원’이라 함은 별도의 금액을 지불하여 유료서비스를 구매한 회원 및 회사 또는 제3자가 정하는 이벤트, 마케팅에 참여하는 등의 방법으로 회사의 유료서비스를 이용하는 회원을 말합니다.</div>
                            <div>5.‘무료회원’이라 함은 유료회원이 아닌 회원으로 회원가입 이후에 기본적으로 모든 회원에게 부여되는 자격을 가진 회원을 의미합니다.</div>
                            <div>6.‘결제’라 함은 회원이 특정 유료서비스를 이용하기 위하여 각종 지불수단을 통하여 회사가 정한 일정 금액을 회사에 지불하는 것을 의미합니다.</div>
                            <div>7. ‘구매’라 함은 회사가 유료서비스에 대한 이용 승낙 및 유료서비스 제공이 가능할 수 있도록 회원이 이용하고자 하는 유료서비스를 선택하여 지불수단을 통한 결제를 함으로써 그 대가를 지급하는 행위를 의미합니다.</div>
                            <div><br /></div>
                            <div>② 이 약관에서 사용하는 용어의 정의는 제1항에서 정하는 것을 제외하고는 관계 법령 및 서비스별 안내에서 정하는 바에 의합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제3조 (약관의 효력/변경 등)</b>
                            </div>
                            <div><br /></div>
                            <div>① 이 약관은 유료서비스 이용하기를 희망하는 회원이 동의함으로써 효력이 발생하며, 회원이 이 약관에 대하여 “동의하기” 버튼을 클릭한 경우, 이 약관의 내용을 충분히 이해하고 그 적용에 동의한 것으로 봅니다.</div>
                            <div><br /></div>
                            <div>② 유료서비스 이용에 필요한 최소한의 기술사양은 아래 표와 같습니다.</div>
                            <div>
                                <div style={{ display: 'table', 'textAlign': 'center', 'border': '1px solid #111111' }}>
                                    <div style={{ display: 'table-row' }}>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111', 'borderRight': '1px solid #111' }}>OS</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111', 'borderRight': '1px solid #111' }}>CPU</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111', 'borderRight': '1px solid #111' }}>RAM</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111', 'borderRight': '1px solid #111' }}>사운드카드</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111' }}>Direct X</div>
                                    </div>
                                    <div style={{ display: 'table-row' }}>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderRight': '1px solid #111' }}>Windows XP Service Pack 3 이상</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderRight': '1px solid #111' }}>Core2Duo P8700이상</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderRight': '1px solid #111' }}>2G 이상</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderRight': '1px solid #111' }}>16 비트 이상	</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px' }}>Direct X 9.0이상</div>
                                    </div>
                                </div>
                            </div>
                            <div><br /></div>
                            <div>③ 회사는 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다. 회사가 약관을 개정하는 경우에는 적용일자 및 변경사유를 명시하여 그 적용일자 15일 전부터 홈페이지에 공지합니다. 다만, 회원에게 불리한 약관의 변경의 경우에는 그 개정 내용을 고객이 알기 쉽게 표시하여 그 적용일자 30일 전부터 공지하며, 이메일 주소, 문자메시지 등으로 회원에게 개별 통지합니다. 회원의 연락처 미기재, 변경 등으로 인하여 개별 통지가 어려운 경우, 회원이 등록한 연락처로 공지를 하였음에도 2회 이상 반송된 경우 이 약관에 의한 공지를 함으로써 개별 통지한 것으로 간주합니다.</div>
                            <div><br /></div>
                            <div>④ 회사가 제3항의 공지 또는 통보를 하면서 개정 약관의 적용/시행일까지 회원이 거부 의사를 표시하지 아니할 경우 약관의 개정에 동의한 것으로 간주한다는 내용을 고지하였으나, 회원이 명시적으로 약관 변경에 대한 거부의사를 표시하지 아니하면, 회사는 회원이 적용/시행일자 부로 변경 약관에 동의한 것으로 간주합니다. 개정/변경 약관에 대하여 거부의사를 표시한 회원은 계약의 해지 또는 회원 탈퇴를 선택할 수 있습니다.</div>
                            <div><br /></div>
                            <div>⑤ 회사는 이 약관을 회원이 그 전부를 인쇄할 수 있고 확인할 수 있도록 필요한 기술적 조치를 취합니다.</div>
                            <div><br /></div>
                            <div>⑥ 이 약관은 회원이 이 약관에 동의한 날로부터 회원 탈퇴 시까지 적용하는 것을 원칙으로 합니다. 단, 이 약관의 일부 조항은 회원이 탈퇴 후에도 유효하게 적용될 수 있습니다.</div>
                            <div><br /></div>
                            <div>⑦ 이 유료서비스약관의 제반 규정은, 한터뉴스 이용약관의 관련 규정에 우선 적용되며, 이용약관과 이 약관의 정의, 내용 등이 서로 상충되는 경우 이 약관의 관련 규정을 적용합니다. 이 약관에 명시되지 아니한 사항에 대하여는 콘텐츠산업진흥법, 전자상거래 등에서의 소비자 보호에 관한 법률, 약관의 규제에 관한 법률 등 관련 법령에 따릅니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 2장 유료서비스 이용 계약</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제1조 (유료서비스 이용계약의 성립 및 승낙의 제한)</b>
                            </div>
                            <div><br /></div>
                            <div>① 유료서비스에 대한 이용계약은 이 약관에 동의한다는 의사표시(동의함을 선택)와 함께 이용 신청을 하고 회사가 이를 승낙함으로써 비로소 성립합니다.</div>
                            <div><br /></div>
                            <div>② 회원은 유료서비스 이용계약을 체결하기 전에 해당 유료서비스에 대하여 이 약관에서 회사가 명시, 고지하는 사항을 숙지하고, 착오 없이 정확히 거래할 수 있도록 하여야 합니다.</div>
                            <div><br /></div>
                            <div>③ 회사와 회원간 유료서비스 이용계약은 회사의 승낙이 회원에게 도달한 시점(유료서비스의 “구매/결제완료 등”의 표시가 회원에게 절차상 표시된 시점)에 성립합니다.</div>
                            <div><br /></div>
                            <div>④ 회사는 한터뉴스 이용약관 제8조(이용신청에 대한 승낙의 제한)에서 정하는 사유가 발생하는 경우, 이용신청을 승낙하지 아니하거나 소정의 조건 성취 또는 제한 사유가 해소될 때까지 일정 기간 동안 승낙을 유보할 수 있습니다.</div>
                            <div><br /></div>
                            <div>⑤ 회원은 유료서비스 이용 신청을 위한 제반 사항을 기재할 경우 회원의 현재의 사실과 일치하는 정보를 기재하여야 하며, 회원이 이용하고자 하는 유료서비스의 결제방법의 선택 및 선택한 결제방법에 필요한 결제정보를 정확히 회사에 제공하여야 하며, 해당 사항이 변경될 경우 지체 없이 회사가 정한 절차에 따라 변경사항을 고지, 반영하여야 합니다.</div>
                            <div><br /></div>
                            <div>⑥ 회사는 한터뉴스 이용약관 제6조(이용신청)의 기재사항 이외에 회원의 서비스 이용에 필요한 최소한의 정보를 수집할 수 있습니다. 이를 위해 회사가 문의한 사항에 대해 회원은 성실하게 고지합니다. 회사는 이 경우 수집하는 회원의 정보를 한터뉴스 이용약관, 개인정보취급방침 및 정보통신망의 이용촉진 및 정보보호 등에 관한 법률이 정한 바에 따라 이용, 관리합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제2조 (결제수단 등)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회원이 유료서비스의 결제를 위하여 이용할 수 있는 지불수단은 다음 각 호와 같습니다.</div>
                            <div>1.제휴된 신용카드</div>
                            <div>2.제휴된 통신사 청구서 통합 결제</div>
                            <div>3.제휴된 상품권</div>
                            <div>4.기타 회사가 제3자와 제휴계약 등을 체결하여 회원에게 결제 가능 여부 및 그 방법을 안내하게 되는 결제 수단에 의한 대금 지급</div>
                            <div><br /></div>
                            <div>② 결제를 통해 회원은 아래와 같은 행위를 할 수 있습니다.</div>
                            <div>1.유료서비스의 가입 및 구입</div>
                            <div><br /></div>
                            <div>③ 월 정기결제 시 SK Telecom 청구서 과금을 선택한 회원이 이동통신사의 변경 없이 이동전화번호만 변경한 경우, 변경된 이동전화번호로 청구서 과금이 지속됩니다.</div>
                            <div><br /></div>
                            <div>④ 회사는 한터뉴스코인 또는 유료서비스를 이용하는 회원의 거래금액에 대하여 내부정책 및 외부 결제업체(은행사, 카드사 등), 기타 관련 법령의 변경에 따라 회원 당 월 누적 결제액, 결제한도 등의 거래한도를 정할 수 있으며, 회원은 회사가 정한 거래한도를 초과하는 범위의 유료서비스를 이용하고자 할 경우 거래한도의 초과로 인하여 유료서비스의 추가 이용이 불가능할 수 있습니다.</div>
                            <div><br /></div>
                            <div>⑤ 제4항의 사정이 발생하게 될 경우, 회사는 회원이 결제시 해당 결제창에서 결제 가능 여부를 확인할 수 있도록 회원에게 안내합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 3장 유료서비스 이용</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제1조 (유료서비스와 유료회원)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사가 회원의 이용신청을 승낙한 때(신규 및 일부 서비스의 경우, 이용 신청 접수 후 사전 공지한 지정된 일자)로부터 유료서비스는 개시되며, 회사의 기술적 사유 등 기타 사정에 의하여 서비스를 개시할 수 없는 경우에는 제3조(약관의 효력/변경 등)의 방법에 따라 회원에게 사전 공지합니다.</div>
                            <div><br /></div>
                            <div>② 회사는 회원의 이용신청이 있게 되는 경우, 그 사실을 통지하며, 회사의 통지를 받은 회원은 의사표시의 불일치 등이 있는 경우 불일치 사실을 정정 또는 수정을 요청하여야 하며, 회사는 회원의 요청에 따라 처리하여야 합니다. 다만, 이미 대금을 지불한 경우에는 청약 철회 등에 관한 제17조(청약 철회 및 서비스 이용계약의 해제 • 해지)의 규정에 따릅니다.</div>
                            <div><br /></div>
                            <div>③ 회사의 유료서비스는 회사의 사정, 기타 제반 여건에 따라 서비스 내용을 추가하거나 변경할 수 있습니다. 각 유료서비스의 내용은 홈페이지 내 해당 유료서비스 구매페이지, 고객센터(FAQ) 등에서 회원에게 자세히 표시하고 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제2조 (B2B 거래)</b>
                            </div>
                            <div><br /></div>
                            <div>① B2B 거래란 특정 법인 에게 한터뉴스 유료서비스를 대량으로 판매하는 거래를 말합니다.</div>
                            <div><br /></div>
                            <div>② B2B 거래 시 거래량 및 해당 서비스의 용도에 따라 일정 수준의 할인율을 적용할 수 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 4장 유료서비스의 변경, 청약 철회, 해지, 정지 및 중단</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제1조 (회원의 유료서비스 예약 변경, 즉시 변경 등)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회원이 이미 구매/가입한 유료서비스는 원칙적으로 변경 처리가 되지 않고 해지 후 익월 다른 이용권으로 가입하도록 되어 있으나, 회사가 사전에 공지하고 정하는 일부 이용권에 한하여 회원이 변경 신청할 경우, 1개월 후 예약 변경(이하 ‘예약 변경’) 또는 즉시 변경 처리(이하 ‘즉시 변경’)를 할 수 있습니다.</div>
                            <div><br /></div>
                            <div>② 예약 변경의 경우, 회원이 변경 요청한 달의 이용기간이 만료되는 날까지 기존 구매/가입한 이용권을 이용할 수 있으며, 이용기간 만료일 익일부터 변경 요청한 이용권으로 변경 적용됩니다.</div>
                            <div><br /></div>
                            <div>③ 즉시 변경의 경우, 회원이 즉시 변경 신청을 하면 회사가 접수 완료 후, 3일 내에 기존 구매/가입한 이용권의 이용이 중단되고, 신청한 이용권으로 변경 적용됩니다.</div>
                            <div><br /></div>
                            <div>④ 모든 이용권의 ‘예약’ 변경의 경우 및 ‘즉시’ 변경의 경우에는 이용권 변경 속성상 환불이 일체 이루어 지지 않습니다. 이용권의 ‘즉시’ 변경의 경우로서 다음과 같은 경우, 회원의 즉시 변경 신청 적용일 현재 변경 전 이용권에 잔여 금액이 있을 경우, 차액 만큼 추가 결제합니다. 이 때 결제 수단은 회사가 정한 수단으로 제한 될 수 있습니다.</div>
                            <div><br /></div>
                            <div>⑤ 회사는 유료서비스 이용권에 대한 변경이 있게 될 경우, 이 약관 제3조(약관의 효력/변경 등)가 정하는 방법에 따라 그 사실 및 내용, 변경일자를 사전에 고지합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제2조 (청약 철회 및 서비스 이용계약의 해제 • 해지)</b>
                            </div>
                            <div><br /></div>
                            <div>① 유료회원은 전자상거래 등에서의 소비자 보호에 관한 법률, 콘텐츠산업진흥법, 콘텐츠이용자보호지침 등 기타 관계 법령을 통해 통상적으로 단순 변심일 때 7일 이내에 환불을 받으 실 수 있으나, 한터뉴스가 제공하는 유료 서비스의 경우 서비스 구매 즉시 해당 유료 서비스의 가치가 현저히 떨어지며, 타인에게 재 판매가 불가능한 사항인 '청약 철회 불가 상품'이기 때문에 일체의 청약 철회가 제한됩니다.</div>
                            <div><br /></div>
                            <div>② 회사는 회원이 유료서비스 내지 이용권을 구매하기 전 이용 화면 등에 아래 각 호의 사항을 회원이 알 수 있도록 안내하며, 청약 철회가 제한되는 콘텐츠(차트 열람 서비스, 기타 유료 서비스 등)는 그 사실을 팝업화면이나 연결화면으로 표시하며, 회원의 착오 구매를 방지하기 위하여 해당 콘텐츠 구매 시 페이지에 명시하고 있습니다.</div>
                            <div><br /></div>
                            <div>③ 회사는 콘텐츠이용자보호지침 등에 따라, 회사가 부담하였거나 부담할 부대비용, 수수료를 차감하여 환불할 수 있습니다.</div>
                            <div><br /></div>
                            <div>④ 회원이 유료서비스 또는 이용권을 선물 받거나, 프로모션 등을 통해 무료/무상으로 취득하는 등 회원이 직접 비용을 지불하지 아니한 서비스에 대하여는 회사는 환불 의무를 부담하지 않습니다.</div>
                            <div><br /></div>
                            <div>⑤ 회원이 이 약관에서 정하는 회원의 의무를 위반하였을 경우, 계약을 해제, 해지하거나 서비스 이용 제한, 손해배상 청구 등의 조치를 취할 수 있습니다. 이 경우 회원은 해당 회사의 조치에 대하여 회사가 정한 절차에 따라 이의 신청을 할 수 있으며, 회사는 정당하다고 확인하는 경우 서비스 이용 재개 등을 할 수 있으며, 이용자가 자신의 고의, 과실 없었음을 입증한 경우 회사는 서비스를 정지한 기간만큼 이용 기간을 연장합니다.</div>
                            <div><br /></div>
                            <div>⑥ 탈퇴하는 경우 해당 이용권은 즉시 해지되며, 이 경우 회원의 정보와 기타 저장 내용 등 한터뉴스서비스 이용내용은 관련 법령이 정하는 경우를 제외하고는 한터뉴스이용약관 및 이 약관에 따릅니다.</div>
                            <div><br /></div>
                            <div>⑦ 회원 탈퇴 시 및 계약 해지/해제 시 잔액이 남아 있는 경우, 이를 신청 전에 소진하여야 합니다. 회사가 잔액 정보를 안내하고 회원에게 사용 의사 유무를 확인하였음에도 불구하고 자진 탈퇴한 경우, 회사는 잔액의 복구 등에 대한 일체의 책임을 부담하지 아니합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제3조 (과오금)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 유료서비스 결제와 관련하여 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로 과오금 전액을 환불합니다. 다만, 동일한 방법으로 환불이 불가능할 때는 이를 사전에 고지합니다.</div>
                            <div><br /></div>
                            <div>② 회사의 귀책사유로 과오금이 발생한 경우 과오금 전액을 환불합니다. 단, 회원의 귀책사유로 과오금이 발생한 경우, 회사가 과오금을 환불하는데 소요되는 비용은 합리적인 범위 내에서 이용자가 부담하여야 하며, 회사는 해당 비용을 차감 후 과오금을 환불할 수 있습니다.</div>
                            <div><br /></div>
                            <div>③ 회사는 회원이 요구하는 과오금에 대하여 환불을 거부할 경우, 정당하게 유료서비스 요금이 부과되었음을 입증할 책임을 부담합니다.</div>
                            <div><br /></div>
                            <div>④ 회사는 과오금의 세부 환불절차 및 기타 사항에 대하여 다음과 같이 콘텐츠 이용자보호지침이 정하는 바에 따릅니다.</div>
                            <div>1.회사나 회원이 과오금의 발생사실을 안 때에는 전화, 전자우편 등 회사가 제시한 방법에 따라 상대방에게 통보</div>
                            <div>2.회사는 회원에게 환불에 필요한 정보를 요청(회원 성명, 결제증빙서류, 전화번호, 환불 요청 계좌 등)</div>
                            <div>3.회원은 환불에 필요한 2.호의 정보를 회사에 제공</div>
                            <div>4.회사는 이용자의 정보 제공일로부터 7일 이내 환불 처리</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제4조 (유료서비스의 정지, 중단)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 원칙적으로 연중무휴 1일 24시간 유료서비스를 제공합니다.</div>
                            <div><br /></div>
                            <div>② 회사는 이용자에 대한 서비스 개선을 목적으로 하는 설비 점검 및 보수 시에는 유료서비스의 전부 또는 일부의 제공을 제한, 중지, 중단할 수 있습니다. 이 경우 회사는 가능한 한 그 중단사유, 중단 기간 등을 이 약관 제3조(약관의 효력/변경 등)의 방법을 통하여 사전에 회원에게 공지하며, 아래 각 호의 불가피한 경우에는 경위와 원인이 확인된 즉시 사후에 공지할 수 있습니다.</div>
                            <div>1.회원, 기타 불특정 제3자의 불법, 범죄행위로 인하여 기술적으로 정상적인 서비스의 제공이 어려운 경우</div>
                            <div>2.시스템 또는 기타 서비스 설비의 장애, 유무선 Network 장애 또는 유료서비스 이용의 폭주 등으로 정상적인 유료서비스 제공이 불가능할 경우</div>
                            <div>3.기타 천재지변, 국가비상사태, 정전 등 회사가 통제할 수 없는 불가항력적 사유로 인한 경우</div>
                            <div><br /></div>
                            <div>③ 회사는 제2항의 각 호에 해당하는 사유가 아닌 회사의 귀책사유로 서비스의 제공이 중단됨으로 인하여 회원이 입은 손해에 대하여 아래와 같이 콘텐츠 이용자보호지침에서 정하는 바에 따라 배상합니다. 다만, 천재지변 등 불가항력으로 인한 경우는 아래 이용중지 또는 장애발생 시간에 산입하지 아니합니다. 또한, 각 호를 적용함에 있어 사전고지는 서비스 중지, 장애시점을 기준으로 24시간 이전에 고지된 것에 한합니다.</div>
                            <div>1) 사업자의 책임 있는 사유로 인한 서비스 중지 또는 장애의 경우 : 서비스 중지•장애시간의 3배를 무료로 연장</div>
                            <div>2) 불가항력 또는 제3자의 불법행위 등으로 인해 서비스의 중지 또는 장애가 발생한 경우 : 계약을 해제할 수는 없지만, 서비스의 중지 또는 장애시간만큼 무료로 이용기간을 연장</div>
                            <div><br /></div>
                            <div>사업자가 서비스의 중지•장애에 대하여 사전고지한 경우에 있어서 이용자의 피해구제 등은 다음 각호에 의합니다. 다만, 서비스 개선을 목적으로 한 설비 점검 및 보수시 1개월을 기준으로 최대 24시간은 중지•장애 시간에 포함하지 아니합니다.</div>
                            <div>가) 1개월을 기준으로 서비스 중지•장애시간이 10시간을 초과하는 경우 : 10시간과 이를 초과한 시간의 2배의 시간만큼 이용기간을 무료로 연장</div>
                            <div>나) 1개월을 기준으로 서비스 중지•장애시간이 10시간을 초과하지 않은 경우 : 중지•장애 시간에 해당하는 시간을 무료로 연장</div>
                            <div><br /></div>
                            <div>④ 회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책, 운영상의 긴요한 사유로 수정, 중단, 변경할 수 있으며, 이에 대하여 관련 법령에 별도 규정이 있지 아니하는 한 별도의 보상을 하지 않습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제 5장 계약당사자의 의무, 손해배상 등</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제1조 (회원의 의무, 위반시 회사의 조치 등)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회원은 회사가 제공하는 유료서비스 이용 시 관계법령, 약관, 세부이용지침, 서비스 이용안내 및 사이트 내 공지한 주의사항, 회사가 서비스 이용과 관련하여 회원에게 통지하는 사항 등을 준수하여야 하며, 기타 회사 및 타인의 명예를 훼손하거나 서비스 운영 기타 업무수행에 지장을 초래하는 행위를 해서는 안됩니다.</div>
                            <div><br /></div>
                            <div>② 회원은 아이디 및 비밀번호를 관리할 책임이 있으며, 본인이 아닌 타인이 사용하게 하여서는 안됩니다. 이를 위반하여 회원의 개인정보를 타인이 사용하여 유료서비스를 이용함으로써 발생한 결과에 대한 책임은 회원에게 있습니다. 회원은 타인의 명의, 아이디, 비밀번호, 휴대폰번호, 계좌번호, 신용카드번호 등 개인정보를 도용하거나 부정하게 사용하여서는 안됩니다.</div>
                            <div><br /></div>
                            <div>③ 회원은 회사가 사전에 허용한 경우를 제외하고는 유료서비스를 영업활동 등 영리목적으로 이용하거나 이 약관에서 금지하는 행위를 하거나 허용하는 범위를 벗어난 이용행위를 하여서는 안됩니다.</div>
                            <div><br /></div>
                            <div>④ 회원은 유료서비스 이용과정에서 위법행위 또는 선량한 풍속 기타 사회질서에 반하는 행위를 하여서는 아니 되며, 제3자와 유상으로 거래하거나 현금으로 전환하는 행위를 하여서는 안됩니다.</div>
                            <div><br /></div>
                            <div>⑤ 회원은 유료서비스를 이용하여 얻은 정보를 회사의 사전 승낙 없이 서비스의 이용 이외의 목적으로 사용하여서는 안됩니다.</div>
                            <div><br /></div>
                            <div>⑥ 회원은 유료서비스를 구매하여 제공받은 서비스 등을 사적 이용 범위 내에서 사용하여야 하고 영리를 목적으로 하는 영업장, 매장 등에서 재생 등의 방법으로 사용할 수 없습니다. 또한 한터뉴스의 유료서비스 구매를 통해 획득한 콘텐츠를 불법적으로 유포, 공유하여서는 아니 되며, 이를 위반하여 발생한 제반 문제에 대한 책임은 회원 본인에게 있습니다. (이 경우, 국내외 제3자의 저작권 등을 침해하는 행위로서 회사가 IP 접속 차단 등 기술적인 조치를 통하여 타인에 대한 권리 침해 방지 조치를 취하였음에도 불구하고 이용자가 고의로 또는 회사를 기망하는 수단과 방법을 통하여 한터뉴스 사이트에 접속하는 등 제3자의 저작권 등을 침해하는 행위를 포함합니다.)</div>
                            <div><br /></div>
                            <div>⑦ 회사는 제1항 내지 제6항의 회원의 위반 행위가 있는 경우 해당 회원에 대하여 위반 사실을 고지하고 서비스 제공을 1개월간 정지시킬 수 있고, 동위반행위가 재발할 경우 서비스 이용계약을 해제 또는 해지할 수 있습니다. 본 항의 회사의 의사표시는 회원에게 도달한 날로부터 효력이 발생합니다. 회사의 해제/해지 및 이용 정지에 대하여 회원은 회사가 정한 절차에 따라 이의신청할 수 있습니다. 이 경우 이용자가 자신의 고의, 과실 없었음을 입증하거나 회원의 이의가 정당하다고 회사가 인정하는 경우 회사는 계정을 정지한 기간만큼 이용기간을 연장합니다.</div>
                            <div><br /></div>
                            <div>⑧ 유료서비스 이용과 관련하여 진실한 정보를 입력하지 않은 회원은 법적인 보호를 받을 수 없으며, 서비스 이용에 제한을 받을 수 있습니다.</div>
                            <div><br /></div>
                            <div>⑨ 민법에 의한 미성년회원이 유료서비스 내지 이용권을 이용하고자 하는 경우, 법정대리인(부모님)의 동의를 얻거나 계약 체결 후 추인을 얻지 않으면 미성년자 본인 또는 법정대리인이 그 계약을 취소할 수 있습니다. 만 14세 미만 아동이 서비스를 이용하기 위한 정보 제공 시에는 법정대리인의 동의를 받아야 합니다.</div>
                            <div><br /></div>
                            <div>⑩ 청소년 유해 매체물을 이용하기 위해서는 만 19세 이상의 회원이어야 하며(단, 만 19세에 도달하는 해의 1월 1일을 맞이한 자를 포함합니다), 정보 통신망 이용촉진 및 정보 보호 등에 관한 법률 및 청소년 보호법의 규정에 의한 인증수단을 통해 성인 인증을 받아야 합니다. 인증을 받지 않는 경우, 해당 서비스의 이용이 제한됩니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제2조 (손해배상)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 서비스의 결함에 의하여 회사가 제공하는 유료서비스의 내용인 콘텐츠가 손상, 훼손되어 서비스 이용에 손해를 입은 회원에게 해당 콘텐츠의 복원으로 배상합니다. 회원에게 손해가 발생한 경우 회사는 회원에게 실제 발생한 손해만을 배상합니다. 다만, 회사의 고의 또는 과실 없이 회원에게 발생한 일체의 손해에 대하여는 책임을 지지 아니합니다. 회사는 이 약관에서 명시되지 아니한 회사의 귀책사유로 인하여 유료서비스의 이용 회원에게 손해가 발생한 경우 회사의 배상 책임과 관련하여 회사는 '콘텐츠 이용자 보호지침'의 관련 규정 및 기타 상관례를 따릅니다.</div>
                            <div><br /></div>
                            <div>② 고객이 이 약관의 이용 제한 관련 각 규정에 근거, 서비스의 이용이 제한되거나 이용계약이 해지된 경우, 고객이 보유한 모든 디지털콘텐츠의 사용권은 상실되며 회사는 해당 디지털콘텐츠의 구매대금을 반환할 의무를 부담하지 아니합니다.</div>
                            <div><br /></div>
                            <div>③ 회원이 이 약관상의 의무를 위반함으로 인하여 회사에 손해가 발생한 경우 또는 회원이 유료서비스를 이용함에 있어 회사에 손해를 입힌 경우, 회원은 회사에 그 손해를 배상하여야 합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제3조 (면책)</b>
                            </div>
                            <div><br /></div>
                            <div>① 회사는 다음 각 호의 사유로 인하여 회원 또는 제3자에게 발생한 손해에 대하여는 그 책임을 지지 아니합니다.</div>
                            <div>1.천재지변 또는 이에 준하는 불가항력으로 인해 유료서비스를 제공할 수 없는 경우</div>
                            <div>2.회원이 자신의 아이디 또는 비밀번호 등의 관리를 소홀히 한 경우</div>
                            <div>3.회사의 관리영역이 아닌 공중통신선로의 장애로 서비스이용이 불가능한 경우</div>
                            <div>4.기타 회사의 귀책사유가 없는 통신서비스 등의 장애로 인한 경우</div>
                            <div><br /></div>
                            <div>② 회사는 회원이 유료서비스를 이용하여 기대하는 수익을 얻지 못하거나 상실한 것, 서비스에 게시된 게시물에 대한 취사 선택 또는 이용으로 발생하는 손해 등에 대해서는 책임을 지지 않습니다.</div>
                            <div><br /></div>
                            <div>③ 회사는 회원 상호간 또는 회원과 제3자 상호간에 유료서비스와 관련하여 발생한 분쟁에 대하여 개입할 의무가 없으며, 회사에 귀책사유가 없는 한 이로 인하여 발생한 손해를 배상할 책임이 없습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제4조 (분쟁의 해결)</b>
                            </div>
                            <div><br /></div>
                            <div>① 유료서비스 이용과 관련하여 회사와 회원 사이에 분쟁이 발생한 경우, 회사와 회원은 분쟁의 해결을 위해 성실히 협의하고, 협의가 안될 경우 콘텐츠산업진흥법 상 콘텐츠분쟁 조정 위원회에 분쟁조정을 신청할 수 있습니다.</div>
                            <div><br /></div>
                            <div>② 전항에 따라 분쟁이 해결되지 않을 경우 양 당사자는 소를 제기할 수 있으며, 회사와 회원 간의 소의 관할은 [서울 특별시 동부 지방 법원]으로 합니다.</div>
                        </Body>
                    </>
                );
            default:
                return (
                    <>

                        <Header>
                            <h1>Terms & Conditions</h1>
                        </Header>
                        <Body>
                            <div>
                                <b>Chapter 1 General</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 1 (Purpose)</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>This agreement is to set conditions, procedures and requirements in using the service (herein and after “Service”) according to each article under the related law on Electric Communication Business and Information Network Use Promotion and Information Protection in the rights and obligations between the member and company and the conditions of use and procedures in combining the member ID and password into one use.</div>
                            <div><br /></div>
                            <div>1. Online service provided by HANTEOGLOBAL, Inc.</div>
                            <div>2. Online service provided by HANTEOCHART, Inc.</div>
                            <div>3. All of the contents provided by joint contracts with other companies or developed by the company.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 2 (Disclosure, Effect and Amendment of the Terms and Conditions)</b>
                            </div>
                            <div><br /></div>
                            <div>① The content of the terms and conditions become effective through disclosing it online or through other methods to the members.</div>
                            <div><br /></div>
                            <div>② In reasonable cases, the company can change the content of the terms and conditions without prior notice. The company discloses the amended content of the terms and conditions to the Members by the way mentioned in Clause 1 and when there is no further notice, it becomes effective the moment the Member receives notice.</div>
                            <div><br /></div>
                            <div>③ If the Member does not agree with the amended User Agreement, he can request to withdraw his membership. When the Member continues to use the service after the amended agreement is effective, the company regards that the Member has agreed to the amended Agreement.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 3 (Other Regulations)</b>
                            </div>
                            <div><br /></div>
                            <div>① Other contents not mentioned in this Agreement follows other related laws and regulations as the Electric Communication General Law, Electric Communication Business Law and Information Network Use Promotion and Information Protection.</div>
                            <div><br /></div>
                            <div>② Company can set the detailed matters for the individual services and the content of the service is disclosed through notification.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Chapter 2 Service Use Contract</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 5 (Definitions)</b>
                            </div>
                            <div>① Member: “Member(s) means those who have agreed to the user agreement and have been issued a user ID and password entitled to use the information and services.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 6 (Service Use Contract)</b>
                            </div>
                            <div>① To subscribe to this service, the Member should read the User Agreement and agree to it. By pressing the “Agree” button on the screen, it is considered as agreeing to the User Agreement.</div>
                            <div><br /></div>
                            <div>② The person who wishes to use the service enters their true personal information in the service application form after agreeing to the User Agreement and after examining the application and accepting it, the Company can conduct name verification procedure.</div>
                            <div><br /></div>
                            <div>③ Users under age fourteen can use the service after receiving agreement from their legal guardian according to the company law beside filling out the forms provided by the company.</div>
                            <div><br /></div>
                            <div>④ When registering as a corporate member, there may be a verification procedure.</div>
                            <div><br /></div>
                            <div>⑤ Company can refuse or cancel membership for the following application, and those who have registered in names other than their real names have no rights.</div>
                            <div>1. Those who have applied using other people’s name, social security number and personal information.</div>
                            <div>2. When the content in the service application form is fraudulent.</div>
                            <div>3. Suggesting actions that hurt social order, safety and public moral when using the Service.</div>
                            <div>4. Disturbing other people in using the service and embezzling information.</div>
                            <div>5. When the person has done something that is prohibited in Electric Communication General Law, Electric Communication Business Law, Korea Internet Safety Committee, Act on Internet Safety, Program Protection Law and other related laws.</div>
                            <div>6. When the person has been refused service by other service-providing companies, or have taken penal responsibility for the reason stated in Clause 5.</div>
                            <div>7. When the application form provided by the company is insufficiently filled out.</div>
                            <div>8. When using the service for commercial purpose or other injudicious purposes.</div>
                            <div>9. When violating other regulations set by the company.</div>
                            <div><br /></div>
                            <div>⑥ For the following reasons, the company can reject the application until the reason is cleared up.</div>
                            <div>1. When there is no spare room in the service-related equipment.</div>
                            <div>2. When there is a technical difficulty.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 6 (Protection of Personal Information)</b>
                            </div>
                            <div><br /></div>
                            <div>① Company should protect and manage the Member’s personal information according to the related laws and take proper measures and post the personal information protection policy (https://www.hanteonews.com/en/company/policy) and related laws and the Company’s personal information protection policy is applied when protecting and using the personal information. In addition, Company does not take responsibility for the exposed information that is attributed by the Member.</div>
                            <div><br /></div>
                            <div>② Company has the right to use the Member’s personal information to provide various information service and benefits related to internet shopping, online education, community service, job search, membership card, insurance TM marketing and much more. When Company provides the personal information to the allies, it notices the ally company, purpose and the content of which personal information it will use to the Members and get consent. If a Member does not want their personal information to be used or it to be provided to the ally company, the Member can chose not to use the specific service or that certain event and can refuse at anytime through e-mail and other ways to not use the service.</div>
                            <div><br /></div>
                            <div>③ Company can create a collected statistical data using the personal information of the Members in whole or in part related to their business. When providing the service, the Company can send cookie to the Member’s computers. In this case, the Members can change their computer browser setting so it can alert receiving cookie or refuse to receive cookie.</div>
                            <div><br /></div>
                            <div>④ Members by filling out their personal information in the application form and registering for the service according to the User Agreement of the Company is regarded as agreeing to provide their information to the company to collect and use it.</div>
                            <div><br /></div>
                            <div>⑤ To protect their personal information and managing it, Member can change and delete their personal information as frequently as they need. When a Member requests to withdraw from agreeing to collect, use and provide their personal information by letter, phone, e-mail, online documentation, Company should identify whether it is the Member himself/herself or not and unless it is an exceptional case according to the related laws, it should terminate the personal information or take other actions. When the Company has terminated the personal information with the request to withdraw from the agreement by the Member, Company should immediately notice this fact to the Member.</div>
                            <div><br /></div>
                            <div>⑥ When a Member requests to view or change their personal information by letter, phone, e-mail, online documentation, Company should identify whether it is the Member himself/herself or not and immediately take appropriate measures. When a representative visits the Company to view or change the information, Company should check if the person really is the representative. In this case, Company can request for evidence to the representative.</div>
                            <div><br /></div>
                            <div>⑦ Company should destroy the personal information without delay when it achieved the purpose of collecting the information or achieved the purpose when receiving the personal information. The following are exceptions.</div>
                            <div>1. When there is a need to protect personal information under Business Law and other related laws and regulation.</div>
                            <div>2. When they have noticed the duration period to the users or have receive agreement from the users individually.</div>
                            <div>3. When it has not exceeded thirty days since the member withdrew his membership.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Chapter 3 Obligation of the contract parties</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 7 (Obligation of the Company)</b>
                            </div>
                            <div><br /></div>
                            <div>① Company should continuously work hard to provide and maintain stable service promised in the user agreement and when there is difficulty with the system, it will do its best to restore the difficulty. However, in inevitable situations as natural disasters and state of emergency, the company can temporarily stop providing the service.</div>
                            <div><br /></div>
                            <div>② Company does not distribute or disclose the personal information of the Members acquired by providing service without the consent of the Member to a third party. However, the following are exceptions.</div>
                            <div>1. When there is a request from government organizations based on other laws or regulations such as Electric Communication General Law.</div>
                            <div>2. When there is purpose to investigate a crime or there is request from the Korea Internet Safety Commission or other procedures set by related laws.</div>
                            <div><br /></div>
                            <div>③ Company should immediately take care of the complaints and opinions raised by the Members if they are reasonable. But, if immediate measures are difficult, then the Company should advise the reason and further schedule to the Member.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 8 (Member Obligations)</b>
                            </div>
                            <div><br /></div>
                            <div>① Members have the obligation to manage their own member ID and password and any results that occur due to the imputation of the member user, the responsibility is attributed to the member. At any time a member acknowledges that a third person is using his or her member ID, the member concerning this incident should immediately report to the company and should follow the guidelines it will provide.</div>
                            <div><br /></div>
                            <div>② When using the service, members should not take the following actions.</div>
                            <div>1. Illegally using other member’s ID, password and social security number.</div>
                            <div>2. Using the information acquired through the service provided by the company for the purpose besides personal service as coping, publishing, broadcasting the information or providing it to a third party.</div>
                            <div>3. Violating the right or copyright of the company or others.</div>
                            <div>4. Distributing documents, writings or figures that violate the public order or public morals or induce information network difficulty.</div>
                            <div>5. Actions that are directly or indirectly linked to crimes.</div>
                            <div>6. Other actions that violate any other laws.</div>
                            <div>7. Using false information when registering as a member or changing the account information.</div>
                            <div>8. Misrepresent oneself as the manager, employee, staff or person related to the company.</div>
                            <div>9. Harvest or otherwise collect information about users, including email addresses, without their consent.</div>
                            <div><br /></div>
                            <div>③ Member cannot use the service to perform commercial activities without prior approval of the company and the company does not take responsibility for the commercial activity results or any commercial activities that violate the User Agreement. If the company experience any loss due to such commercial activity of a Member, the Member should compensate the loss to the Company.</div>
                            <div><br /></div>
                            <div>④ Member cannot transfer or give its right of service and other status stated in the user contract without the consent of the company.</div>
                            <div><br /></div>
                            <div>⑤ Member can access to their personal information at any given time and make revisions. When there is any change from the registration period, Member should immediately change the information online or through other methods and the Company does not take responsibility for problems that arise for not doing so.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Chapter 4 Service</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 9 (Range of Service)</b>
                            </div>
                            <div><br /></div>
                            <div>Members can use the member ID issued when registering as a member to use the service.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 10 (Providing Information)</b>
                            </div>
                            <div><br /></div>
                            <div>Company can provide information which it thinks the member must know related to change in business policy or when using the services through posting a notice or sending e-mail.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 11 (Fee charged for information)</b>
                            </div>
                            <div><br /></div>
                            <div>Basically, the service provided by the company is free of charge. But, for paid information and services, members should pay the stated fee to use the service.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 12 (Charged Information and Charged Value Added Service)</b>
                            </div>
                            <div><br /></div>
                            <div>For the user to use the charged information and charged value added services, they must provide the following purchasing information by phone or online.</div>
                            <div><br /></div>
                            <div>① Name of payer</div>
                            <div>② Resident ID Number</div>
                            <div>③ Address to receive receipt</div>
                            <div>④ Method of payment and details</div>
                            <div>⑤ Phone Number</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 13 (Posting material and using the community of Members)</b>
                            </div>
                            <div><br /></div>
                            <div>Company can operate various community services and bulletin boards for Members to share their idea and express their thoughts. Company can delete the posted material or restrict the use of the community and bulletin board of certain members when the posted material is one of the following.</div>
                            <div><br /></div>
                            <div>① Blaming and seriously intriguing a third member and damaging their reputation.</div>
                            <div>② Distributing or making a link with contents that violate public order and public morals.</div>
                            <div>③ Acknowledged to be linked with crime.</div>
                            <div>④ Violate copyrights and rights of the Company and other individuals.</div>
                            <div>⑤ Exceeded the posting period set by the company.</div>
                            <div>⑥ Linking to the Member’s Web site or bulletin board.</div>
                            <div>⑦ Posting material that does not coincide with the characteristics of the bulletin board.</div>
                            <div>⑧ When judged to have violated other related laws.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 14 (Copyright)</b>
                            </div>
                            <div><br /></div>
                            <div>The rights of the material related to the service are the following.</div>
                            <div><br /></div>
                            <div>① The right and obligation of the posted material accrues to the person who posted the material. However, the company has the right to post the material within the service and has the right to process and edit the material created using the Joins service and regards that the member has agreed to this when posting the material.</div>
                            <div>② Members can not use the material posted through the service commercially as by processing it and editing it.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 15 (Service Period)</b>
                            </div>
                            <div><br /></div>
                            <div>① Unless there is difficulty with the business of the company or technical difficulties, service is provided 24 hours a day throughout the year.</div>
                            <div><br /></div>
                            <div>② When the company wishes to stop the service due to unavoidable situations or regular maintenance, it should give notice of the purpose of the stoppage of service and the period.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 16 (Responsibility for using the service)</b>
                            </div>
                            <div><br /></div>
                            <div>Member cannot use the service for any kind of commercial activity and especially cannot engage in ot hacking, advertisements for earning money, commercial activities using pornography sites, distributing licensed software unless approved officially through documents by the company. Member is responsible in violations for the resulting loss, legal measures and other responsibilities.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 17 (Service Suspension)</b>
                            </div>
                            <div><br /></div>
                            <div>① For the following cases, Company can suspend the service.</div>
                            <div>1. Unavoidable situation such as repairing the service facilities.</div>
                            <div>2. When Communication Company listed in the Electric Communication Business Law has suspended Electronic Communication Service.</div>
                            <div>3. Other reasons involving forces beyond control</div>
                            <div><br /></div>
                            <div>② Company can limit the service in whole or in part or even restrict the use of Service when there are forces beyond control as national emergency, blackout, difficulty in service equipment, congestion of users. The company does not hold responsibility for the deleted, unsent, or any loss of information and data.</div>
                            <div><br /></div>
                            <div>③ When Company limits or restricts the use of Service due to Clause 1 or Clause 2, it notices the reason and period in beforehand to the Members if possible.</div>
                            <div><br /></div>
                            <div>④ When Members violated the User Agreement, the Company can restrict the use of Service without any prior notice. In this case, Company can limit the access of the certain member, Member ID and can delete all or part of the material posted by the Member. When a Member has not used the service for over three months, Company can limit the service by sending a notice e-mail and posting a notice one week in advance.</div>
                            <div><br /></div>
                            <div>⑤ When Company feels the need to change or delete the ID or other information due to the initial Member ID, information use rate, purpose of the service and other reasons, Company can advice to the member to change it. When the member does not object, Company can change or delete the aforementioned content freely.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Chapter 5 Termination of Use Contract and Limitation of Use</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 18 (Termination of Use Contract)</b>
                            </div>
                            <div><br /></div>
                            <div>① When a member wishes to terminate the Use Contract, the member must register to terminate the use by on-line or through other ways to the company.</div>
                            <div><br /></div>
                            <div>② The Company may terminate the Use Contract in the event of occurrence or verification of the following without prior notice or prohibit service for a certain period.</div>
                            <div>1. Using other member’s Member ID and password illegally.</div>
                            <div>2. Obstruction of the service procedure on purpose.</div>
                            <div>3. Providing names other than one’s real name in the application form.</div>
                            <div>4. When one Member registers twice with a different ID.</div>
                            <div>5. Distributing contents on purpose that hurt the public order and public moral.</div>
                            <div>6. When the Member uses the service or plans to use with the purpose to damage the national interest or social public interest.</div>
                            <div>7. Damaging other’s reputation or giving disadvantage</div>
                            <div>8. Sending advertisement information contrary to the intention of the other members.</div>
                            <div>9. Distributing computer virus programs that can damage, destroy or change the communication equipments or information.</div>
                            <div>10. Violating the intellectual property right of the company or other people.</div>
                            <div>11. Requested to delete the material by outside organizations as Korea Internet Safety Commission or received authoritative interpretation by the National Election Commission for illegal election campaign.</div>
                            <div>12. Using other member’s personal information, Member ID and password wrongly.</div>
                            <div>13.Distribute of copy the information obtained through the company service or use it commercially without the approval of the company.</div>
                            <div>14. Posting lewd content on the member’s bulletin board or posting a link connecting to a Web site with contents that harm public morals.</div>
                            <div>15. Violating the content within the User Agreement and other user agreements set by the company.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Chapter 6 Compensation for Damage and others</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 19 (Compensation for Damage)</b>
                            </div>
                            <div>The company does not take any responsibility for the damage of the members occurred using the service provided by free unless the accident was intentionally done by the company or resulted from a major fault of the company.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 20 (Exemption Clause)</b>
                            </div>
                            <div><br /></div>
                            <div>① When the company cannot provide the service due to natural disaster or any similar incident, the company does not take responsibility for not providing the service.</div>
                            <div><br /></div>
                            <div>③ There can be inaccurate or error in printing within the information, software and products included in the service provided by the company.</div>
                            <div><br /></div>
                            <div>The company does not take responsibility for the accuracy of the information.</div>
                            <div><br /></div>
                            <div>④ Company does not take any responsibility of the content provided through the service or through consultation. The company also does not take responsibility for losing the expected return by using the service. Therefore, when a member plans to make a law, medical or other financial decision, they should always consult with an expert beforehand.</div>
                            <div><br /></div>
                            <div>⑤ Company does not take responsibility for the reliability and accuracy of the information, data and facts posted by other members.</div>
                            <div><br /></div>
                            <div>⑥ Company does not have the obligation to take part in a dispute between members or member and a third company regarding the service and does not have the responsibility to compensate for the loss.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Chapter 7 Special Treaty for Children</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 21 (Collecting Children’s Information)</b>
                            </div>
                            <div><br /></div>
                            <div>① When the Company wishes to collect the personal information of the users under age 14 or wants to provide the information to a third party or use the information exceeding the range stated in the service user agreement, the company should receive consent from the legal representative of the child.</div>
                            <div><br /></div>
                            <div>② To receive consent regarding Clause 1, Company can request minimum information of the legal representative as name and contact address. In this case, the Company should request the information to the child in easy language so the child can understand it without difficulty.</div>
                            <div>1. Purpose of collecting and using the personal information</div>
                            <div>2. Why the consent of legal representative is needed.</div>
                            <div><br /></div>
                            <div>③ Company does not use the legal representative’s personal information obtained by Clause 2 for other purposes other than receiving consent from the legal representative and does not provide to third party.</div>
                            <div><br /></div>
                            <div>④ Company does reasonable effort to confirm whether the user is under age 14.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 22 (Notification to the Legal Representative and Method of Consent)</b>
                            </div>
                            <div><br /></div>
                            <div>① When the Company wishes to receive consent from the legal representative according to Article 21.1, it should notify the following to the legal representative.</div>
                            <div>1. When the company collects the child’s personal information.</div>
                            <div>2. When it provides the personal information to a third party or uses it exceeding the range notifies in Article 6. ②.</div>
                            <div><br /></div>
                            <div>② Notifying under Clause 1 observes the following methods.</div>
                            <div>1. Telephone, Fax or Mail</div>
                            <div>2. Request the child to print the notification on the Web site and present it to the legal representative</div>
                            <div>3. Send e-mail to the legal representative with the hyperlink so he or she can see the personal information protection policy on the Web site.</div>
                            <div>4. Other reasonable ways for the notification to be delivered effectively to the legal representative.</div>
                            <div><br /></div>
                            <div>③ Consent to Clause 1 should be by one of the following ways.</div>
                            <div>1. Using the e-mail that has been electronically signed by the legal representative.</div>
                            <div>2. Legal representative signing directly on the document provided by mail or by downloading the form on the Web site and sending the document to the service provided by mail or fax.</div>
                            <div>3. Other reasonable ways to confirm that the legal representative has truly consented to the fact</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 23 (Obligations of the Legal Representative)</b>
                            </div>
                            <div><br /></div>
                            <div>① Legal representative can withdraw their consent of Article 21. ① and can request to view and change the error the child has provided.</div>
                            <div><br /></div>
                            <div>② Article 6. ⑧ applies by Clause 1 for the legal representative’s request to view and change the error in the information and to withdraw the consent. In this case, the “Legal Representative” is the “User.”</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Chapter 8 Others</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 24 (Grievance)</b>
                            </div>
                            <div><br /></div>
                            <div>The Company collects opinions from the Members related to personal information through phone, letter, e-mail and Web site and handles their complaints.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>Article 25 (Resolving dispute and Competent Court)</b>
                            </div>
                            <div><br /></div>
                            <div>① When a dispute arises regarding the personal information, the Company and the Member can request dispute settlement to the Korean Information Security Agency.</div>
                            <div><br /></div>
                            <div>② When there is a law suit while using the service, the competent court will be in the court that competences the headquarter of the company.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                        </Body>

                    </>
                );
        }
    }

    render() {
        const {language} = this.props;
        return (
            <>
                {this.termsBody(language)}
            </>
        );
    }
}
const Header = styled.div`
    margin: 0 30px;
    h1{
        font-size: 2.5rem;
    }
`;
const Body = styled.div`
    margin: 50px 30px;
    line-height: 1.5;
    word-break: break-word;
    b{
        font-weight: 600;
    }
`;

export default Terms;
