import React, { Component } from "react";
import styled from "styled-components";
import Contents from "Page/Detail/App";
import AppMainContent from "Page/Main/AppMainContent";
import { getQueryParameters } from "utils/UriUtils"
import CommentPage from "Page/Comments/CommentPage"
import { isAndroid, isIOS } from 'react-device-detect';

class AppMain extends Component {

  constructor() {
    super();
    this.state = {
      userInfo: undefined,
      userNum: undefined
    }

    window.whosfan = this;
    window.setWhosfanRequiredParameters = function (data) {
        window.whosfan.setWhosfanRequiredParameters(data)
    }


  }

  setWhosfanRequiredParameters = (data) =>{
    let json = JSON.parse(data.trim())
    // userToken, os, appVersion, lang, package, gmt
    let userInfo = this.state.userInfo
    
    if (this.props.whosfanUserNum) {
      json['userNum'] = this.props.whosfanUserNum
    }

    this.setState({
      userInfo:json
    })
  }
  
  onInitWhosfanData() {
    // dev
    // this.setState({
    //   userInfo: {
    //     userNum: 10000013,
    //     userToken: 10000013,
    //     os: 'AOS',
    //     appVersion: '1.0.0',
    //     lang: this.props.language,
    //     package: "com.hanteoglboal",
    //     gmt: ""
    //   }
    // })
    // return;


    if(isIOS && window.webkit!==undefined && window.webkit.messageHandlers!==undefined && window.webkit.messageHandlers.initWhosfanRequiredParameters!==undefined){
      window.webkit.messageHandlers.initWhosfanRequiredParameters.postMessage({});
      return
    }
    
    if(isAndroid){
      if(window.whosfanJs===undefined){
        return
      }
      window.whosfanJs.initWhosfanRequiredParameters();
      return
    }
    
    console.log('This is not ios or android')
  }

  componentDidMount() {
    this.onInitWhosfanData();
  }
  
  detailRouterApp(userInfo) {
    let get_params = getQueryParameters(window.location)
    
    if(Object.keys(get_params).indexOf('fc') < 0){
      return <AppMainContent language={this.props.language} />;
    }

    if(Object.keys(get_params).indexOf('comment') < 0){
      get_params['lang'] = this.props.language
      get_params['isApp'] = true;

      

      userInfo['lang'] = this.props.language
      return <Contents 
              language={this.props.language}
              contentIdx={get_params['fc']}
              userInfo={userInfo} params={get_params}></Contents>;
    }


    get_params['lang'] = this.props.language
    userInfo['lang'] = this.props.language
    return <CommentPage userInfo={userInfo} params={get_params}></CommentPage>;
  }

  onClick = (e) => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  render() {
    const {language, whosfanUserNum} = this.props;
    return (
      <>
        {(this.state.userInfo!==undefined?
          <div>
            <ContentsDiv>
              {this.detailRouterApp(this.state.userInfo)}
              <TopBtnDiv onClick={this.onClick}>
                <TopBtn>
                  <BtnImg src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/arrow_top.png"></BtnImg>
                </TopBtn>
              </TopBtnDiv>
            </ContentsDiv>
          </div>
          :<></>)}
      </>
    );
  }
}

const BtnImg = styled.img``;
const TopBtn = styled.div``;
const TopBtnDiv = styled.div``;
const ContentsDiv = styled.div`
  ${TopBtnDiv}{
    position: fixed;
    bottom: 60px;
    right: 22px;
    z-index: 10;

    ${TopBtn}{
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F8F8F8;
      text-align: center;
      width: 40px;
      height: 40px;
      font-size: 10px;
      box-shadow: 2px 3px 5px rgba(0,0,0,.2);
      border-radius: 5px;

      ${BtnImg}{
        width: 15px;
        height: 15px;
      }
    }
  }
`;

export default AppMain;