import React, { Component } from "react";
import styled from "styled-components";

class NotFound extends Component {
  componentDidMount() {
  }
  
  render() {
    return (
      <ContentsDiv>
        Not Found
      </ContentsDiv>
    );
  }
}
const ContentsDiv = styled.div`
  height: auto;

  @media all and (min-width:1048px) {
    padding: 0px 0 0 0;
    width: 1048px;
    margin: 0 auto;
  }
`;


export default NotFound;
