import React, { Component } from "react";
import styled from "styled-components";

class Policy extends Component {

    policyBody(language) {
        switch (language) {
            case 'KO':
                return (
                    <>
                        <Header>
                            <h1>개인정보정책</h1>
                        </Header>
                        <Body>
                            <div>
                                <b>제1장 총칙</b>
                            </div>
                            <div><br /></div>
                            <div>주식회사 한터글로벌(이하 “회사”)의 인터넷 뉴스서비스인 Hanteonews(이하 "한터뉴스")는 고객의 개인정보를 소중하게 생각하고 고객의 개인정보를 효과적으로 관리하고 안전하게 보호하기 위하여 최선의 노력을 다 하고 있습니다. “한터뉴스”는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」과 「개인정보보호법」등 각종 관계 법규를 준수하고 있습니다. 또한 「개인정보 취급방침」을 제정하여 이를 준수하고 있으며, 본 취급방침을 “한터뉴스” 홈페이지에 공개하여 고객께서 언제나 쉽게 열람하실 수 있도록 하고 있습니다. “한터뉴스”의 「개인정보 취급방침」은 관련 법률 및 지침의 변경 또는 내부 운영 방침의 변경에 따라 변경될 수 있습니다. 「개인정보 취급방침」이 변경될 경우 변경된 사항을 “한터뉴스” 홈페이지와 “한터뉴스” 서비스 내 공지사항을 통하여 공지합니다.</div>
                            <div><br /></div>
                            <div>1. "개인정보"란 생존하고 있는 개인에 관한 정보로서 성명 • 생년월일 등에 의하여 당해 개인을 알아볼 수 있는 부호, 문자, 음성, 음향, 영상 및 생체특성 등에 관한 정보(당해 정보만으로는 특정 개인을 알아볼 수 없는 경우에도 다른 정보와 용이하게 결합하여 알아볼 수 있는 것을 포함합니다)를 말합니다.</div>
                            <div><br /></div>
                            <div>2. “한터뉴스”는 고객의 개인정보보호를 매우 중요시하며, 「개인정보 취급방침」을 통하여 고객이 제공하는 개인정보의 수집목적 및 이용목적, 개인정보 보호를 위해 “한터뉴스”에서 취하고 있는 조치 등을 알려드립니다.</div>
                            <div><br /></div>
                            <div>3. “한터뉴스”는 「개인정보 취급방침」을 홈페이지(www.hanteonews.com)에 공개함으로써 고객이 언제나 용이하게 볼 수 있도록 조치하고 있습니다.</div>
                            <div><br /></div>
                            <div>4. 회사가 영업의 전부 또는 일부를 양도하거나 합병•상속 등으로 그 권리•의무를 이전하는 경우 서면•전자우편 등을 통하여 고객님께 개별적으로 통지하거나, “한터뉴스” 홈페이지 첫 화면에서 식별할 수 있도록 표기하여 30일 이상 그 사실을 공지합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제2장 개인정보의 수집 및 이용목적</b>
                            </div>
                            <div><br /></div>
                            <div>“한터뉴스”는 개인정보의 수집 시 관련법규에 따라 「개인정보 취급방침」에 그 수집범위 및 목적을 사전 고지합니다.  “한터뉴스”가 수집한 개인정보는 다음의 목적을 위해 사용되며, 이용 목적이 변경될 시에는 사전 동의를 구할 것입니다. 또한 이벤트나 행사를 진행할 경우 그 목적에 맞게 동의 절차를 거쳐 수집하고 있으며 본 개인정보 보호정책(Privacy statement)과는 별도로 개인정보 수집내용과 이용방법, 개인정보보호 방법을 명시하고 있습니다.</div>
                            <div><br /></div>
                            <div>1. 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금정산</div>
                            <div>컨텐츠 제공, 특정 맞춤 서비스 제공, 상품 배송 및 대금결제, 본인인증, 구매 및 요금 결제, 요금 추심 등</div>
                            <div><br /></div>
                            <div>2. 회원관리</div>
                            <div>회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인확인, 개인식별, 불량회원의 부 정 이용 방지와 비인가 사용방지, 가입의사 확인, 연령확인, 만 14세 미만 아동 개인정보 수집 시 법정 대리인 동의여부 확인, 불만처리 등 민원처리, 고지사항 전</div>
                            <div><br /></div>
                            <div>3. 신규 서비스 개발 및 마케팅•광고에의 활용</div>
                            <div>신규 서비스 개발과 이벤트 행사에 따른 정보 전달 및 맞춤 서비스 제공, 인구 통계학 적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>

                            <div>
                                <b>제3장 수집하는 개인정보의 항목 및 수집방법</b>
                            </div>
                            <div><br /></div>
                            <div>“한터뉴스”는 회원가입, 상담, 서비스 제공을 위하여 아래와 같이 최소한의 개인정보만을 수집하며, 인종 및 민족, 사상 및 신조, 출신지 및 본적지, 정치적 성향 및 범죄기록, 건강상태 등 고객의 기본적 인권을 현저하게 침해할 우려가 있는 개인정보를 수집하지 않습니다. “한터뉴스”는 회원 가입 시 개인정보 제공에 동의하지 않을 경우 “한터뉴스” 상품•서비스 제공을 받기 어렵다는 점을 충분히 설명해드리고 있습니다.</div>
                            <div><br /></div>
                            <div>
                                <b>1. 수집하는 개인정보의 항목</b>
                            </div>
                            <div><br /></div>
                            <div>가. 필수정보</div>
                            <div><br /></div>
                            <div>. 신청인의 휴대전화번호, 이메일주소 등 필요한 최소한의 식별정보 단, 실명인증 기관에서 인증한 경우 생년월일, 본인에 대한 고유 인증 키 값을 추가로 전달 받습니다.</div>
                            <div><br /></div>
                            <div>. 만 14세 미만 미성년자(이하 “아동”)의 경우, 법정대리인의 연락전화번호 실명인증 기관을 통한 법정대리인 실명 및 본인인증을 진행 합니다 (단, “아동” 개인정보는 법정대리인의 동의 필요) 나. 선택정보</div>
                            <div><br /></div>
                            <div>. 유료서비스 이용 시 기기등록을 위해 수집하는 정보</div>
                            <div>- 휴대전화 : 휴대전화번호, 가입 통신사, 결제승인번호, 모델명, 기기고유정보</div>
                            <div>- 그외 스마트 OS탑재 모바일기기(Tablet PC 등) : 전화번호, 모델명, 기기고유정보</div>
                            <div><br /></div>
                            <div>. 유료서비스 결제 시 수집하는 정보</div>
                            <div>- 신용카드 : 카드사명, 카드번호, 카드유효기간, 전화번호, 이메일</div>
                            <div>- 휴대전화: 휴대전화번호, 가입 통신사, 결제승인번호 등</div>
                            <div>- 계좌이체시 : 금융기관명, 계좌번호, 예금주의 성명</div>
                            <div>- 회사 및 협력사, 제휴사의 멤버쉽 등 제휴•할인상품 결제 시, 사용권한 및 결제가능 여부 인증을 위한 멤버쉽 카드 번호, 보유 포인트 조회 등</div>
                            <div>- 번호정보 안내 등 부가서비스 이용과 관련된 정보</div>
                            <div>- 이용요금의 할인을 위한 국가유공자증명, 복지할인증명 등 제 증명 사항</div>
                            <div>- 주소변경서비스 이용과 관련된 정보</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>나. 서비스 이용 또는 업무처리 과정에서 생성되어 수집될 수 있는 정보</div>
                            <div><br /></div>
                            <div>. 서비스 이용시간/이용기록, 접속로그, 비밀번호, 이용콘텐츠, 쿠키, 접속IP 정보, 요금청구 및 결제기록/정보, 이용 정지 기록, 주소, 신용카드 정보, 휴대전화 로그인 인증 시 자동 수집 후 활용되는 휴대전화 번호</div>
                            <div><br /></div>
                            <div>. 서비스 문의하기를 이용하여 발신된 아이디, 이메일, 연락처(비로그인 상태에서는 이메일과 연락처)</div>
                            <div><br /></div>
                            <div>. 서비스 이용에 대한 플랫폼 구분에 따른 OS, OS 버전 정보, 기기 모델명, 브라우저 정보 . 단말기에 저장된 이름 및 전화번호</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>2. “한터뉴스”는 다음과 같은 방법으로 개인정보를 수집합니다.</b>
                            </div>
                            <div><br /></div>
                            <div>가. 회원 가입을 통한 개인정보(서비스를 이용에 필요한 최소한의 정보 : 휴대전화번호, ID, 이메일 등)</div>
                            <div><br /></div>
                            <div>나. 홈페이지•서면양식•전화•팩스를 통한 본인•대리인 확인, 전화•상담게시판•이메일을 통한 서비스 문의 및 이벤트•경품 행사 응모, 배송 요청</div>
                            <div><br /></div>
                            <div>다. 서비스 이용 또는 업무 처리 과정에서 생성되거나 협력회사, 제휴사로부터의 제공</div>
                            <div><br /></div>
                            <div>라. 유•무선인터넷 서비스 사용시 생성정보 수집 툴을 통한 방법 등</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제4장 개인정보 수집 및 이용 등에 관한 동의방법</b>
                            </div>
                            <div><br /></div>
                            <div>1. 고객의 개인정보 수집 시에는 관련 법령의 규정에 따라 개인정보의 수집 이용 목적과 제3자 제공 및 취급을 위탁하고 있는 내용을 고객께 알리고 동의를 얻거나 「개인정보 취급방침」을 통해 고지하고 있습니다.</div>
                            <div><br /></div>
                            <div>2. “한터뉴스”의 '서비스 이용약관' 및 「개인정보 취급방침」에 따른 고객의 개인정보 수집, 이용에 대해 고객께서는 아래와 같은 방법 중 하나를 선택하여 동의하실 수 있습니다.</div>
                            <div><br /></div>
                            <div>가. 모바일(웹사이트 포함)로 가입 또는 요금상품 가입/변경 시 동의내용 또는 회사의 홈페이지에 게시된 「개인정보 취급방침」의 동의내용을 확인하신 후 동의 버튼을 클릭하는 방법</div>
                            <div><br /></div>
                            <div>나. 서비스 '가입/이용/변경 신청서' 등에 첨부된 '개인정보의 수집/이용 위탁 제공 동의서'의 세부 내용을 숙지하시고 ‘동의서’에 서명하는 방법</div>
                            <div><br /></div>
                            <div>다. 고객센터 및 기타 전화 등을 통한 전화상담의 경우 상담원으로부터 직접 동의내용의 안내를 받으시거나, 상담원의 안내에 따라 회사의 홈페이지(www.hanteonews.com) 「개인정보 취급방침」의 동의 내용을 확인하신 후 전화상으로 본인이 동의 의사를 표시하는 방법</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제5장 개인정보의 보유 및 이용기간</b>
                            </div>
                            <div><br /></div>
                            <div>1. 고객의 동의 하에 수집된 개인정보는 “한터뉴스”가 고객에게 서비스를 제공하는 기간에 한하여 보유 또는 이용되며, 고객이 해지를 요청하는 경우에는 아래 ‘개인정보의 파기절차 및 방법’에 따라 파기됩니다. 다만, “한터뉴스”는 고객의 개별 동의를 받은 경우, 고객이 요금을 미납한 경우, 개인정보 도용 등으로 인한 고객 피해발생시 피해자 보호, 소비자의 불만 및 분쟁 처리 등 관계법령의 규정에 따라 보존의 필요성이 있는 경우에는 아래와 같은 개인정보에 대하여 명시한 기간 동안 보존하는 것으로 합니다.</div>
                            <div><br /></div>
                            <div>가. 해지, 탈퇴 등으로 서비스가 종료되었으나, 상품 또는 서비스의 미납이 존재하는 경우</div>
                            <div>. 요금 완납 시까지</div>
                            <div><br /></div>
                            <div>나. 회사와 고객 간 민원, 소송 등 분쟁 과정 중 법률로 정한 보유기간이 경과한 경우</div>
                            <div>. 분쟁 해결 시까지</div>
                            <div><br /></div>
                            <div>다. 회사가 개별적으로 고객의 동의를 받은 경우</div>
                            <div>. 동의를 받은 기간까지</div>
                            <div><br /></div>
                            <div>2. 관련 법령에 의한 개인정보 보유 및 사유, 기간</div>
                            <div>
                                <div style={{ display: 'table', 'textAlign': 'center', border: '1px solid #111111' }}>
                                    <div style={{ display: 'table-row' }}>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111', 'borderRight': '1px solid #111' }}>보존정보</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111', 'borderRight': '1px solid #111' }}>보존근거법령</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111' }}>보존기간</div>
                                    </div>
                                    <div style={{ display: 'table-row' }}>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111', 'borderRight': '1px solid #111' }}>계약 또는 청약철회에 관한 기록</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111', 'borderRight': '1px solid #111' }}>전자상거래 등 소비자보호에 관한 법률</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111' }}>5년</div>
                                    </div>
                                    <div style={{ display: 'table-row' }}>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111', 'borderRight': '1px solid #111' }}>대금 결제 및 재화등의 공급에 관한 기록</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111', 'borderRight': '1px solid #111' }}>전자상거래 등 소비자보호에 관한 법률, 국세 기본법, 법인세법, 부가가치세법</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111' }}>5년</div>
                                    </div>
                                    <div style={{ display: 'table-row' }}>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111', 'borderRight': '1px solid #111' }}>소비자의 불만 또는 분쟁처리에 관한 기록</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111', 'borderRight': '1px solid #111' }}>전자상거래 등 소비자보호에 관한 법률</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderBottom': '1px solid #111' }}>3년</div>
                                    </div>
                                    <div style={{ display: 'table-row' }}>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderRight': '1px solid #111' }}>사이트 방문에 관한 기록</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px', 'borderRight': '1px solid #111' }}>통신비밀보호법</div>
                                        <div style={{ display: 'table-cell', 'padding': '3px 5px' }}>3개월</div>
                                    </div>
                                </div>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제6장 개인정보의 파기절차 및 방법</b>
                            </div>
                            <div><br /></div>
                            <div>“한터뉴스”는 수집한 개인정보의 이용목적이 달성된 후에는 보관기간 및 이용기간에 따라 해당 정보를 지체없이 파기합니다. 파기대상, 파기절차 및 방법은 다음과 같습니다.</div>
                            <div><br /></div>
                            <div>1. 파기대상</div>
                            <div>가. 회원가입 정보</div>
                            <div>나. 그 외 보유기간 및 관련법령에 따른 보존기간이 종료된 고객정보</div>
                            <div><br /></div>
                            <div>2. 파기절차</div>
                            <div>회원이 서비스 가입 등을 위해 기재한 개인정보는 서비스 해지 등 이용목적이 달성된 후 내부 방침 및 기타 관계 법령에 의한 정보보호 사유에 따라 일정기간(제5장 개인정보의 보유 및 이용기간 참조) 동안 저장 보관된 후 삭제되거나 파기합니다.</div>
                            <div><br /></div>
                            <div>3. 파기방법</div>
                            <div>신분증 사본 등 서면양식에 기재하거나, 종이에 출력된 개인정보는 분쇄기로 분쇄하여 파기하고, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제7장 개인정보의 취급위탁 및 제3자 제공</b>
                            </div>
                            <div><br /></div>
                            <div>1. “한터뉴스”는 보다 나은 서비스 제공, 고객편의 제공 등 원활한 업무 수행을 위하여 다음과 같이 개인정보 취급 업무를 외부 전문업체에 위탁하여 운영하고 있습니다. 해당 위탁사업자는 계약체결 기간 동안 고객의 개인정보를 보유합니다. 단, 관계법령에서 법정 보유기간을 명시하고 있는 경우는 당해 업체는 해당 기간 동안 보유합니다.</div>
                            <div><br /></div>
                            <div>2. “한터뉴스”는 위탁업무계약서 등을 통하여 개인정보보호 관련 법규의 준수, 개인정보에 관한 비밀유지, 제3자 제공에 대한 금지, 사고시의 책임부담, 위탁기간, 처리 종료 후의 개인정보의 반환 또는 파기 의무 등을 규정하고, 이를 준수하도록 관리하고 있습니다.</div>
                            <div><br /></div>
                            <div>3. 개인정보 취급업무 수탁사 및 위탁내용</div>
                            <div><br /></div>
                            <div>4. 개인정보의 제3자 제공</div>
                            <div>가. “한터뉴스”는 원칙적으로 수집한 개인정보를 제3자에게 제공하지 않으나, 서비스 제공에 있어 다양한 서비스 및 품질향상, 이벤트 등의 목적을 위하여 수집한 개인정보를 제3자에게 제공하는 경우에는 법령에 따른 동의를 구합니다. 다만 법원 등 국가기관이 법령에 의하여 이용자의 개인정보를 제공하도록 강제한 경우에는 예외로 합니다.</div>
                            <div>나. “서비스” 별 개인정보 제공 및 이벤트에 따른 개인정보 제공의 경우 수시로 발생하는 서비스 제휴와 이벤트 진행으로 인해 개인정보취급방침 개정의 어려움으로 서비스 제공내역과 이벤트 제공내역을 아래 링크를 통해 확인할 수 있습니다.</div>
                            <div><br /></div>
                            <div>제3자 제공 내역 확인하기</div>
                            <div><br /></div>
                            <div><br /></div>

                            <div>
                                <b>제8장 이용자 및 법정 대리인의 권리와 그 행사 방법</b>
                            </div>
                            <div><br /></div>
                            <div>1. 고객은 언제든지 등록되어 있는 고객의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 「내 정보」를 클릭하여 직접 열람 또는 정정하실 수 있습니다.</div>
                            <div><br /></div>
                            <div>2. 회원가입 등을 통해 개인정보의 수집, 이용, 제공에 대해 동의하신 내용을 언제든지 철회하실 수 있습니다. 동의 철회(회원탈퇴)를 하고자 할 경우에는 「회원탈퇴」를 클릭하여 직접 신청하실 수 있습니다.</div>
                            <div><br /></div>
                            <div>3. “한터뉴스”는 고객 또는 고객의 법정대리인이 개인정보에 대한 열람•증명 또는 정정을 요청하는 경우에는 고객의 요청에 성실하게 대응하며, 개인정보에 오류가 있거나 보존기간을 경과한 것이 판명되는 등 정정 또는 삭제할 필요가 있다고 인정되는 경우, 지체 없이 조치합니다.</div>
                            <div><br /></div>
                            <div>4. 법정대리인은 "아동"의 개인정보 수집 • 이용 또는 제공에 대한 동의를 철회할 수 있으며, "아동"이 제공한 개인정보에 대한 열람 또는 오류의 정정을 요구할 수 있습니다.</div>
                            <div><br /></div>
                            <div>5. “한터뉴스”는 위 3항 및 4항의 경우 다른 법령에서 그 개인정보가 수집 또는 보관의 대상으로 명시되어 있는 경우 등 개인정보에 대한 열람•증명 또는 정정 요청에 대하여 연기 또는 제한•거절할 경우에는 이메일 또는 고객센터를 통하여 그 사유 및 고객 등이 이의를 제기할 수 있는 방법을 알려드립니다.</div>
                            <div><br /></div>
                            <div>6. 유선 또는 서면을 통하여 고객 본인 또는 "아동"인 고객의 법정대리인이 열람, 증명을 요구하는 경우에는 본인 확인 내지 대리관계를 증명하는 위임장 및 인감증명서, 요청인 또는 대리인의 신분증 사본 등의 증표를 제시 받아 진정한 본인 의사와 대리인 여부를 확인합니다.</div>
                            <div><br /></div>
                            <div>7. 고객 또는 법정대리인의 요청에 의해 해지 또는 삭제, 정정된 개인정보는 "개인정보의 보관기간 및 이용기간"에 명시된 바에 따라 처리되고, 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제9장 아동의 개인정보 보호</b>
                            </div>
                            <div><br /></div>
                            <div>1. 회원은 언제든지 등록되어 있는 회원의 개인정보를 열람하거나 정정하실 수 있습니다. 개인정보 열람 및 정정을 하고자 할 경우에는 「회원정보변경」을 클릭하여 직접 열람 또는 정정하실 수 있습니다.</div>
                            <div><br /></div>
                            <div>2. 회원 가입 등을 통해 개인정보의 수집/이용/제공에 대해 동의하신 내용은 언제든지 철회하실 수 있습니다. 개인정보의 처리에 대한 동의 철회(회원탈퇴)를 「회원탈퇴」를 클릭하여 직접 신청하실 수 있습니다.</div>
                            <div><br /></div>
                            <div>3. 회원 또는 회원의 법정대리인이 개인정보에 대한 열람•증명 또는 정정을 요청하는 경우에는 회원의 요청에 성실하게 대응하며, 개인정보에 오류가 있거나 보존기간을 경과한 것이 판명되는 등 정정 또는 삭제할 필요가 있다고 인정되는 경우, 지체 없이 조치합니다.</div>
                            <div><br /></div>
                            <div>4. 회사는 만 14세 미만 아동(이하 '아동')이 제공한 개인정보로 인하여 아동 및 법정대리인이 불이익을 입지 않도록 보호 조치를 취하고 있습니다.</div>
                            <div><br /></div>
                            <div>(1) 아동의 서비스가입을 위한 개인정보를 수집하거나 최초 서비스 가입 시 얻은 동의의 범위를 넘어 이동의 개인정보를 이용하거나 제3자에게 제공하고자 하는 경우에는 해당 아동의 법정대리인의 동의를 얻도록 하고 있습니다.</div>
                            <div><br /></div>
                            <div>(2) 법정대리인의 동의를 얻기 위하여 법정대리인의 성명, 연락처 등 필요한 최소한의 정보를 요구할 수 있습니다. 이 경우 개인정보의 수집•이용 또는 제공 목적 및 법정대리인의 동의가 필요하다는 취지를 아동이 쉽게 이해 할 수 있는 평이한 표현으로 아동에게 고지 합니다.</div>
                            <div><br /></div>
                            <div>(3) 법정대리인의 동의를 얻기 위하여 수집한 법정대리인의 개인정보를 해당 법정대리인의 동의 여부를 확인하는 목적 외의 용도로 이를 이용하거나 제3자에게 제공하지 않습니다.</div>
                            <div><br /></div>
                            <div>(4) 법정대리인은 만 14세 미만 아동의 개인정보 수집•이용 또는 제공에 대한 동의를 철회할 수 있으며, 만 14세 미만 아동이 제공한 개인정보에 대한 열람 또는 오류의 정정을 요구할 수 있습니다.</div>
                            <div><br /></div>
                            <div>5. 위 3.항 및 4.항의 경우 다른 법령에서 그 개인정보가 수집 또는 보관의 대상으로 명시되어 있는 경우 등 개인정보에 대한 열람∙증명 또는 정정 요청에 대하여 연기 또는 제한∙거절할 경우에는 회원이 기재한 이메일 또는 고객지원센터를 통하여 그 사유 및 고객 등이 이의를 제기할 수 있는 방법을 알려드립니다.</div>
                            <div><br /></div>
                            <div>6. 유선 또는 서면을 통하여 회원 본인 또는 만 14세 미만 아동인 회원의 법정대리인이 열람, 증명을 요구하는 경우에는 본인 확인 내지 대리관계를 증명하는 위임장 및 인감증명서, 요청인 또는 대리인의 신분증 사본 등의 증표를 제시 받아 진정한 본인 의사와 대리인 여부를 확인합니다.</div>
                            <div><br /></div>
                            <div>7. 회원 또는 법정대리인의 요청에 의해 해지 또는 삭제, 정정된 개인정보는 "개인정보의 보관기간 및 이용기간"에 명시된 바에 따라 처리되고, 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제10장 개인정보 자동 수집장치의 설치 운영 및 그 거부에 관한 사항</b>
                            </div>
                            <div><br /></div>
                            <div>“한터뉴스”는 서비스 운영에 있어 필요 시 고객의 정보를 찾아내고 저장하는 '쿠키(Cookie)'를 운용합니다. 쿠키란 웹 서버가 웹 브라우저에 보내어 저장했다가 서버의 부가적인 요청이 있을 때 다시 서버로 보내주는 문자열 정보를 말합니다. 고객이 웹사이트에 접속을 하면 고객의 브라우저에 있는 쿠키의 내용을 읽고, 추가정보를 찾아 접속에 따른 성명 등의 추가 입력 없이 서비스를 제공할 수 있습니다.</div>
                            <div><br /></div>
                            <div>
                                <b>1. 쿠키에 의해 수집되는 정보 및 이용 목적</b>
                            </div>
                            <div><br /></div>
                            <div>가. 수집 정보 : ID, 접속IP, 접속로그, 이용 컨텐츠 등 서비스 이용정보</div>
                            <div><br /></div>
                            <div>나. 이용목적</div>
                            <div>. 고객의 관심분야에 따라 차별화된 정보를 제공</div>
                            <div>. 회원과 비회원의 접속 빈도나 방문 시간 등을 분석하여 이용자의 취향과 관심분야를 파악하여 타켓(Target) 마케팅에 활용(구입한 품목들에 대한 정보와 관심 있게 둘러본 품목들에 대한 자취를 추적하여 다음 번 상품 구입 때 개인 맞춤 서비스를 제공, 유료서비스 이용 시 이용기간 안내, 고객들의 습관을 분석 등) 및 서비스 개편 등의 척도로 활용합니다.</div>
                            <div><br /></div>
                            <div>
                                <b>2. 고객은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 사용 디바이스의 웹브라우저에서 옵션을 설정함으로써 쿠키에 의한 정보 수집 수준의 선택을 조정하실 수 있습니다.</b>
                            </div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제11장 개인정보의 기술적, 관리적 보호</b>
                            </div>
                            <div><br /></div>
                            <div>
                                <b>1. 기술적 대책</b>
                            </div>
                            <div><br /></div>
                            <div>“한터뉴스”는 고객의 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 아래와 같은 기술적 대책을 적용하고 있습니다.</div>
                            <div><br /></div>
                            <div>가. 고객의 개인정보는 외부망에서 접근 및 침입이 불가능하도록 방화벽 시스템 등 기술적인 장치를 포함, 기타 내부망을 활용하여 관리되고 있으며, 파일 및 전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 보안기능을 통해 철저하게 보호되고 있습니다.</div>
                            <div><br /></div>
                            <div>나. “한터뉴스”는 개인정보처리 시스템 및 개인정보취급자가 개인정보 처리에 이용하는 정보기기에 컴퓨터바이러스, 스파이웨어 등 악성 프로그램의 침투 여부를 항시 점검•처리할 수 있도록 백신프로그램을 설치하여 개인정보 침해를 방지하기 위한 조치를 취하고 있습니다. 또한, 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 적용함으로써 개인정보가 침해되는 것을 방지하고 있습니다.</div>
                            <div><br /></div>
                            <div>다. “한터뉴스”는 암호알고리즘 등의 이용을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있는 보안장치를 채택하고 있습니다.</div>
                            <div><br /></div>
                            <div>라. 해킹 등 외부침입에 대비하여 침입차단시스템 및 침입탐지시스템을 운영하여 사내 네트워크를 보호하고, 시스템 접근 권한의 주기적 변경을 통해 보안을 강화하고 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>2. 관리적 대책</b>
                            </div>
                            <div><br /></div>
                            <div>가. “한터뉴스”는 개인정보의 안전한 보호를 위하여 주요 시스템 및 설비에 대하여 외부 전문기관으로부터 정보보호관리체계인증 등 객관적인 인증을 받아 운영되고 있습니다.</div>
                            <div><br /></div>
                            <div>나. “한터뉴스”는 고객의 개인정보에 대한 접근 및 관리에 필요한 절차 등을 마련하여 소속 직원으로 하여금 이를 숙지하고 준수하도록 하고 있습니다.</div>
                            <div><br /></div>
                            <div>다. “한터뉴스”는 고객의 개인정보를 취급할 수 있는 자를 최소한으로 제한하고 있습니다. 고객의 개인정보를 취급할 수 있는 자는 아래와 같습니다.</div>
                            <div>. 고객을 직/간접적으로 상대로 하여 마케팅 업무를 수행하는 자</div>
                            <div>. 개인정보관리책임자 및 개인정보보호담당자 등 개인정보관리 • 보호업무를 수행하는 자</div>
                            <div>. 기타 업무상 개인정보의 취급이 불가피한 자</div>
                            <div><br /></div>
                            <div>라. “한터뉴스”는 컴퓨터를 이용하여 고객의 개인정보를 처리하는 경우 개인정보에 대한 접근권한을 가진 담당자를 지정하여 식별부호(ID) 및 비밀번호를 부여하고, 해당 비밀번호를 정기적으로 갱신하고 있습니다.</div>
                            <div><br /></div>
                            <div>마. 개인정보를 취급하는 직원을 대상으로 새로운 보안기술 습득 및 개인정보보호 의무 등에 관해 정기적인 사내 교육 및 외부 위탁교육을 실시하고 있습니다.</div>
                            <div><br /></div>
                            <div>바. 신규직원 채용 시 정보보호서약서 또는 개인정보보호서약서에 서명함으로 직원에 의한 정보유출을 사전에 방지하고 「개인정보 취급방침」에 대한 이행사항 및 직원의 준수여부를 감사하기 위한 내부절차를 마련하여 지속적으로 시행하고 있습니다.</div>
                            <div><br /></div>
                            <div>사. 직원 퇴직 시 비밀유지서약서에 서명함으로 고객의 개인정보를 취급하였던 자가 직무상 알게 된 개인정보를 훼손/침해 또는 누설하지 않도록 하고 있습니다.</div>
                            <div><br /></div>
                            <div>아. 개인정보 취급자의 업무 인수인계는 보안이 유지된 상태에서 철저하게 이뤄지고 있으며, 입사 및 퇴사 후 개인정보 침해사고에 대한 책임을 명확하게 규정하고 있습니다.</div>
                            <div><br /></div>
                            <div>자. 전산실 및 자료보관실 등을 특별 보호구역으로 설정하여 출입 통제 등 출입관리절차를 시행하고 있습니다.</div>
                            <div><br /></div>
                            <div>차. 서비스이용계약체결 또는 서비스제공을 위하여 고객의 신용카드번호, 은행결제계좌, 휴대폰 소액결제 등 “서비스”를 이용하기 위한 결제수단의 대금결재에 관한 정보를 수집하거나 고객에게 제공하는 경우 당해 고객이 본인임을 확인하기 위하여 필요한 조치를 취하고 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제12장 고객불만 처리 및 의견 수렴</b>
                            </div>
                            <div><br /></div>
                            <div>1. “한터뉴스”는 고객의 고객의 불만 내역에 대해서 최대한 신속한 처리를 원칙으로 소중하게 생각합니다. 불만 내역, 기타 이용문의 등에 대해서 고객센터에 문의하시면 신속/정확한 답변을 드리겠습니다.</div>
                            <div><br /></div>
                            <div>2. 고객과의 원활한 의사소통을 위해 고객상담 채널을 운영하고 있으며, 고객센터의 연락처는 다음과 같습니다.</div>
                            <div><br /></div>
                            <div>[고객센터]</div>
                            <div>서비스 문의 : (02) 449-2347</div>
                            <div>기타 한터뉴스 이메일을 통해 1:1 문의 : news.cs@hanteo.com</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제13장 개인정보 관리책임자 및 연락처</b>
                            </div>
                            <div><br /></div>
                            <div>“한터뉴스”는 고객의 개인정보보호를 가장 중요시하며, 고객의 개인정보가 훼손 • 침해 또는 누설되지 않도록 최선을 다하고 있습니다. “한터뉴스”의 귀책사유에 의하여 고객의 개인정보에 대한 침해사고가 발생하는 경우에는 관련 법령에 따라 책임을 부담하나, 기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의 위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가 작성한 게시물에 의한 각종 분쟁에 관해서는 책임이 없습니다. 고객의 개인정보 보호 관련 문의시 고객센터에서 신속하고 성실하게 답변을 드리도록 하고 있습니다. 또한. 고객이 “한터뉴스”의 개인정보관리담당자와 연락을 원하실 경우 아래의 연락처 또는 이메일로 연락을 주시면 개인정보 관련 문의사항에 대하여 신속하고 성실하게 답변해드리겠습니다.</div>
                            <div><br /></div>
                            <div>
                                <b>1. 개인정보 보호책임자 및 연락처</b>
                            </div>
                            <div><br /></div>
                            <div>가. 보호책임자 : 서비스기획실 심세나 팀장</div>
                            <div>. 보호담당자 : 서비스기획실 박송이 주임</div>
                            <div><br /></div>
                            <div>나. 연락처</div>
                            <div>. 전화 : (02) 449-2256</div>
                            <div>. 이메일 : news.cs@hanteo.com</div>
                            <div><br /></div>
                            <div>
                                <b>2. 기타 개인정보 침해에 관한 상담이 필요한 경우에는 한국인터넷진흥원 개인정보침해신고센터, 경찰청 사이버안전국 등으로 문의하실 수 있습니다.</b>
                            </div>
                            <div><br /></div>
                            <div>가. 한국인터넷진흥원 개인정보침해신고센터</div>
                            <div>. 전화 : (국번없이) 118 (ARS 내선 2번)</div>
                            <div>. URL : http://privacy.kisa.or.kr</div>
                            <div><br /></div>
                            <div>나. 경찰청 사이버안전국</div>
                            <div>. 전화 : (국번없이) 182</div>
                            <div>. URL : http://www.ctrc.go.kr</div>
                            <div><br /></div>
                            <div>다. 회원 가입시 동의하신 바 있는 개인정보 취급 위탁에 대한 동의를 철회하시고자 하는 경우에는 고객센터로 연락주시기 바랍니다.</div>
                            <div>. 전화 : (02) 449.2347</div>
                            <div>. 이메일 : news.cs@hanteo.com</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제14장 개인정보 수집이용동의 고지 또는 통지 방법</b>
                            </div>
                            <div><br /></div>
                            <div>1. 현행 개인정보수집동의에 대한 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일전부터 홈페이지의 '공지사항'을 통해 변경이유 및 그 내용을 고지합니다. 다만, 개인정보의 수집 및 활용 등에 있어 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.</div>
                            <div><br /></div>
                            <div>2. “한터뉴스”는 고객이 동의한 범위를 넘어 고객의 개인정보를 이용하거나 제3자에게 취급위탁하기 위해 고객의 추가적인 동의를 얻고자 하는 때에는 미리 고객에게 개별적으로 서면, 전자우편, 전화 등으로 해당사항을 고지합니다.</div>
                            <div><br /></div>
                            <div>3. “한터뉴스”는 개인정보의 수집, 보관, 처리, 이용, 제공, 관리, 파기 등을 제3자에 위탁하는 경우에는 서비스이용약관, “한터뉴스” 홈페이지의 「개인정보 취급방침」 등을 통하여 그 사실을 고객에게 고지합니다.</div>
                            <div><br /></div>
                            <div>4. 만 14세 미만 아동의 개인정보를 수집, 이용하거나 제3자에게 제공하기 위하여 법정대리인의 동의를 받고자 하는 경우에는 전화, 팩스, 우편, 아동으로 하여금 통지내용을 법정대리인에게 제출하게 하는 방법, 법정대리인에게 이메일을 보내면서 “한터뉴스” 홈페이지의 「개인정보 취급방침」을 볼 수 있도록 연결(하이퍼링크)시키는 방법 또는 기타 법정대리인에게 고지내용이 도달할 수 있는 합리적인 방법을 통하여 법정대리인에게 고지합니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제15장 고객의 의무</b>
                            </div>
                            <div><br /></div>
                            <div>1. 고객은 자신의 개인정보를 항시 현행화하여 불의의 사고를 사전에 예방하여야 합니다. 부정확한 정보의 입력으로 인해 발생하는 사고의 책임은 고객에게 있으며, 타인 정보의 도용 등 허위정보를 입력할 경우 회원자격이 상실될 수 있습니다.</div>
                            <div><br /></div>
                            <div>2. “한터뉴스”를 이용하는 고객은 개인정보를 보호 받을 권리와 함께 스스로를 보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 고객은 아이디(ID), 패스워드를 포함한 개인정보가 유출되지 않도록 조심하여야 하며, 게시물을 포함한 타인의 개인정보를 훼손하지 않도록 유의해야 합니다. 만약 이 같은 책임을 다하지 못하고 타인의 정보를 훼손할 경우에는 “정보통신망 이용촉진 및 정보보호 등에 관한 법률” 등 관련 법령에 의해 처벌 받을 수 있습니다.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>제16장 링크 사이트</b>
                            </div>
                            <div><br /></div>
                            <div>“한터뉴스”는 홈페이지를 통하여 회원에게 다른 회사의 웹사이트 또는 자료에 대한 링크를 제공할 수 있습니다. 이 경우 해당 사이트의 「개인정보 취급방침」은 “한터뉴스”의 「개인정보 취급방침」과 무관하므로 새로 방문한 사이트의 「개인정보 취급방침」을 확인하시기 바랍니다.</div>
                            <div><br /></div>
                            <div>「개인정보 취급방침」 개정시행일자 : 2019.04.01</div>
                        </Body>
                    </>
                );
            default:
                return (
                    <>
                        <Header>
                            <h1>Privacy Policy</h1>
                        </Header>
                        <Body>
                            <div>HANTEOGLOBAL, Inc. takes your personal information very importantly and observes the Act on Promotion of Information & Communication Network Utilization and Information Protection. hanteonews.com informs through the personal information protection policy on how the user’s personal information is used and for what purpose and what actions the company takes to protect the users’ personal information.</div>
                            <div>After reading the personal information protection policy of the “Company” please understand how your personal information is used while using the service provided by the “Company.” The “Company’s” personal information protection policy can change by the guideline of the government law and through “Company’s” policy change. While using the service of HANTEOGLOBAL, Inc., please visit the hanteonews.com Web site frequently and confirm the changes.</div>
                            <div><br /></div>
                            <div>The order of this Personal Information Protection Policy is as the following.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>1. Purpose of collecting and using personal information</b>
                            </div>
                            <div><br /></div>
                            <div>HANTEOGLOBAL, Inc. “uses the personal information to confirm users, statistical analysis of payment of fee and product shipping as marketing data to provide the best service.”</div>
                            <div>The detail purpose for collecting and using the collected personal information is as follows.</div>
                            <div><br /></div>
                            <div>Name, User ID, Password.</div>
                            <div>- To confirm the user himself/herself to use their real name when posting their personal thoughts to the news service provided by media and for other services that require the user in person</div>
                            <div><br /></div>
                            <div>E-mail address, telephone number, address:</div>
                            <div>- To secure a communication path to provide notices regarding change in service and other information that the users must know and to receive complaints.</div>
                            <div>- To send newsletters of each service, event and high quality product information and new services under the approval of the user.</div>
                            <div>- To send presents to event prize winner and best users.</div>
                            <div>- To secure accurate address to send bill and accurate shipping of products.</div>
                            <div><br /></div>
                            <div>Bank account and credit card information:</div>
                            <div>- Payment for charged information and product purchase.</div>
                            <div><br /></div>
                            <div>Other selected items:</div>
                            <div>- Information to provide personalized service for individuals and groups.</div>
                            <div>- To provide information (advertisement included) that the user prefers by understanding the preference of the user: Joins.com posts advertisements to provide free services. To provide adequate advertisements, the “Company” plays the intermediate role. The advertiser informs the “Company” in which consumer they are targeting. (ex: male over 35 years old). “Company” receives this advertisement and views it to the adequate users. In this process, the advertisers cannot view the users’ personal information. Only the “Company” can access to the users’ personal information. This kind of advertisement is not limited to banner but also to the advertisement e-mails the company sends. If the user agrees to the option to receive advertisement mails, then the user can receive special proposals from the company through e-mails.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>2. Using it for different purposes and sharing, providing the information with a third party</b>
                            </div>
                            <div><br /></div>
                            <div>HANTEOGLOBAL, Inc. does not provide the personal information to a third party, third company or other organizations for purposes that exceeds the limit states in the 『 Purpose of collecting and using personal information』in any circumstances except when the user has agreed or it is by the related laws.</div>
                            <div>When providing or sharing the personal information of the members, we will inform the users in advance in who will receive the information for what purpose, what field the company focuses on, for what purpose the information is being provided or shared and what parts of information is being revealed and will go through a agreement process where users can either agree or refuse to this act through e-mail or through documents.</div>
                            <div>In most of the cases, only the e-mail address of the users will be disclosed. When we share the personal information of the users more than we have noticed, we will inform the users before we send the personal information.</div>
                            <div>However, in the following cases, we can provide the personal information without prior notice, regarding the related laws.</div>
                            <div><br /></div>
                            <div>- When it is needed to calculate the fee related to service.</div>
                            <div>- When the data is needed to make statistics, scientific research, market research and is provided in a form where the individual cannot be known.</div>
                            <div>- When there is a special regulation in the laws on real name financial transaction and secrets, Law on credit information and its use and protection, framework act on communications, framework act on communication business, local tax law, Consumer Protection Act, the Bank of Korea Act, Criminal Procedure Law.</div>
                            <div>- When request by government organizations by the Framework Act on Communications and other laws.</div>
                            <div>- When there is request from the Korea Internet Safety Commission to investigate for crimes and other procedures stated by law.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>3. Agreement to collecting personal information</b>
                            </div>
                            <div><br /></div>
                            <div>HANTEOGLOBAL, Inc. receives agreement for collecting the user’s personal information. You can either click “Agree” button on the user’s agreement or Hanteonews' personal information protection policy or click “Cancel” related to collecting your personal data. When you click the “agree” button, we regard that you have agreed to for us to collect your personal information.</div>
                            <div><br /></div>
                            <div>
                                <b>4. Person responsible and in charge of personal information</b>
                            </div>
                            <div><br /></div>
                            <div>To protect your personal information and to take care of the complaints related to personal information, Hanteonews has employees that are in charge of personal information. If you have any questions related to personal information, please contact the people in charge listed below. We will answer your questions as quickly as possible and sincerely.</div>
                            <div><br /></div>
                            <div>Person responsible personal information and teenager protection</div>
                            <div>Name: Sim Sena</div>
                            <div>Department: Service Design Team</div>
                            <div>Telephone: 02-449-2347</div>
                            <div>e-mail: news.cs@hanteo.com</div>
                            <div>Fax: 02-449-2256</div>
                            <div><br /></div>
                            <div>Person in charge of personal information and teenager protection</div>
                            <div>Name: Sim Sena</div>
                            <div>Department: Service Design Team</div>
                            <div>Telephone: 02-449-2347</div>
                            <div>e-mail: news.cs@hanteo.com</div>
                            <div>Fax: 02-449-2256</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>5. Collecting Personal Information</b>
                            </div>
                            <div><br /></div>
                            <div>Hanteonews collects the minimum amount of personal information to provide services when users join as members.</div>
                            <div><br /></div>
                            <div>To use the service provided by HANTEOGLOBAL, Inc., you must input the ID you prefer, password, name, address, phone number, birth date, and e-mail address essentially. However, the additional information as occupation, hobby, interest field, income, marriage status is not required and does not have any influence in using the service.</div>
                            <div>When you wish to purchase a product through Hanteonews, you must provide the following additional information to make the payments (and for product shipping).</div>
                            <div><br /></div>
                            <div>According to the payment</div>
                            <div>- Account transfer: Bank, Account number, name of user, Birth date(MM/DD/YYYY).</div>
                            <div>- Credit card: Type of card, card number, term of availability, Cardholder’s name.</div>
                            <div><br /></div>
                            <div>Information needed in product shipping as name, address, phone number of the sender and receiver</div>
                            <div>- There are times when the company request personal information besides joining as members and making payments. For example, when you participate in a contest or promotion events hosted by the “Company” or by the “Company’s” joint partner, or making complaints for services. When you contact the company, these communication information can be recorded. In addition, sometimes surveys are conducted for research purpose.</div>
                            <div>- When you think providing information is not adequate, you can choose not to participate in the event.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>6. Collecting personal information by cookie</b>
                            </div>
                            <div><br /></div>
                            <div>- HANTEOGLOBAL, Inc. operates “Cookie” which frequently stores user’s information. Cookie is a very small text file that the server used to operate the Web site sends to the users’ browsers and is stored in the user’s computer hard disk.</div>
                            <div><br /></div>
                            <div>- For the following purposes, HANTEOGLOBAL, Inc. uses cookie.</div>
                            <div><br /></div>
                            <div>It is used to analyze the access frequency and visiting time of members and non-members and to find out the users’ taste and interest field for further marketing and service reorganization.</div>
                            <div>To trace the products that user has looked at with interest while shopping on-line and to provide customized service for the next shopping.</div>
                            <div>It is used to provided individualized information according to the user’s interest and to provide differentiated chance to participate in events according to the participation rate and number of times the user has visited the Web site.</div>
                            <div><br /></div>
                            <div>- The user has the right to choose whether or not to install cookie. Therefore, by setting your Web browser option to allow all cookies (tool{'>'} internet option), confirm whenever a cookie is saved or refuse any kind of cookie storage.</div>
                            <div>- However, when the user does not allow Web browser cookie, the user will not be able to receive the various service provided by HANTEOGLOBAL, Inc.</div>
                            <div>- Setting for allowing installing cookie</div>
                            <div><br /></div>
                            <div>When using the Internet Explorer 6.0</div>
                            <div>1. Select [Internet Option] in the [Tool] menu</div>
                            <div>2. Click on the [Privacy Tab].</div>
                            <div>3. Set the [Level of Personal Information]</div>
                            <div>- How to see the received cookie</div>
                            <div><br /></div>
                            <div>When using the Internet Explorer 6.0</div>
                            <div>1. Click on [Tool] on the [Menu bar]</div>
                            <div>2. Select [Internet Option]</div>
                            <div>3. Click [settings] in the [General tab (default tab)]</div>
                            <div>4. Choose [view file].</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>8. Termination and Cancellation of Membership</b>
                            </div>
                            <div><br /></div>
                            <div>- The user can withdraw his or her agreement for collecting and using their personal data when they joined as a member at anytime they wish.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>9. General Practices regarding use and storage of personal information</b>
                            </div>
                            <div><br /></div>
                            <div>- While you are a member of HANTEOGLOBAL, Inc. and receive service by HANTEOGLOBAL, Inc., Joins will store your personal information and use it to provide service.</div>
                            <div><br /></div>
                            <div>- When a HANTEOGLOBAL, Inc. member requests to cancel its ID according to 8. Termination (Cancellation of membership) procedure and when the information received by the provider has met the purpose for collecting the personal data, the personal information is completely deleted from the hard disk and can not be restored by any way and viewed or used for any purpose.</div>
                            <div><br /></div>
                            <div>- But, to prevent indiscrete joining/cancelling of certain ID due to ownership dispute, the company holds the record whether the ID has been used and it an automatic system to prevent rejoining using a specific ID.</div>
                            <div><br /></div>
                            <div>- In addition, in cases where personal data should be stored even after the purpose has been achieved after collecting or receiving the personal data, the company can hold the personal data of the users.</div>
                            <div><br /></div>
                            <div>- HANTEOGLOBAL, Inc. does not provide a child’s personal information or share it with a third party and when the legal representative requests to change an error in the personal data collected from the child, the company prohibits using or providing the certain personal data until the error has been fixed.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>10. Personal Information Protection Policy for children under 14</b>
                            </div>
                            <div><br /></div>
                            <div>- Children under age 14 (Hereafter “Children”) can join as a member through a separate user agreement form that uses easy to understand terminologies and when collecting personal data, the company should receive agreement from their legal representatives in advance.</div>
                            <div><br /></div>
                            <div>- HANTEOGLOBAL, Inc. collects the minimum personal information as name and addresses of the legal representatives of the children to receive agreement and receives agreement from the legal representatives through the procedure states in the “Privacy Protection Guideline.”</div>
                            <div><br /></div>
                            <div>- The legal representatives of the children can request to view, change and delete the children’s personal data.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>

                            <div>
                                <b>11. Teenager Protection Policy</b>
                            </div>
                            <div><br /></div>
                            <div>- Hanteonews's teenager protection policy observes the Teenager Protection Law and is to protect the teenagers from harmful surroundings and to help them develop healthy characteristics. Hanteonews follows the Korea Internet Safety Commission guidelines and decisions on media that is harmful to youth, restrict youth under 19 to access to harmful information.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>12. Youth Protection Act</b>
                            </div>
                            <div><br /></div>
                            <div>- Hanteonews restricts harmful information as pornography and illegal information through service agreements, policies the company has set. The company tries hard to strictly restrict unethical and antisocial acts that harm the youth to grow soundly.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>13. Security prevention for loss, misuse, infringement, change of personal information</b>
                            </div>
                            <div><br /></div>
                            <div>- User’s personal information is protected through the password and only the user him/herself can have access to the personal information. You can make changes in your Joongang ilbo personal information using the user ID and password.</div>
                            <div><br /></div>
                            <div>- You must not your password to anyone. Hanteonews does not call you or send e-mails to ask your password. And when you have finished using the service, you must log out and close the Web browser. This is to prevent other people to see your personal information and the service record when you share a computer with another person or use internet in an internet café, library or other public places.</div>
                            <div><br /></div>
                            <div>- The company will do its best to protect the user’s personal information, but we cannot ensure you that sending data using the internet cannot be 100% safe. Therefore, one should follow the user guideline to protect the personal information and please pay special attention in protecting your precious personal data.</div>
                            <div><br /></div>
                            <div>- When on-line- for example through bulletin boards, e-mails or in chatting rooms- please always keep in mind that the information you provide voluntarily can be collected and used by others. In short, if you post your personal information online where people can access to freely, you might receive messages from places you don’t want to get.</div>
                            <div><br /></div>
                            <div>- The responsibility to maintain security for your own user ID and password is the member’s own responsibility. Therefore, when you are on-line, please pay special attention to it.</div>
                            <div><br /></div>
                            <div>- User guideline to protect personal information.</div>
                            <div><br /></div>
                            <div>① Provide personal information only when it is needed.</div>
                            <div>② Confirm the personal protection policy when providing personal information.</div>
                            <div>③ When users can chose between opening or closing the personal information, chose to close the information.</div>
                            <div>④ Do not post your own or others’ personal information on blog or bulletin boards.</div>
                            <div>⑤ Search your name, social security number, phone numbers and other personal information through portal search engines periodically to see if your information has been disclosed.</div>
                            <div>⑥ When your personal information has been disclosed, you should request immediately to the according Web site or portal search engine to delete your personal information.</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>14. Consultation and Report of Personal Information Infringement</b>
                            </div>
                            <div><br /></div>
                            <div>- If you need to report or receive consultation regarding personal information infringement, contact the personal information infringement reporting center within the Korea Information Security Agency Web site.</div>
                            <div><br /></div>
                            <div>Korea Information Security Agency</div>
                            <div>* Telephone: No area code needed 118</div>
                            <div>* E-mail: privacy@kisa.or.kr</div>
                            <div>* URL: http://privacy.kisa.or.kr/</div>
                            <div><br /></div>
                            <div><br /></div>
                            <div><br /></div>
                            <div>
                                <b>15. Responsibility to Notify Policy Changes</b>
                            </div>
                            <div>- This personal information protection policy has been amended on September 23, 2014 and if there is any change, additional information or elimination according to the law, policy or security technology change the company must inform this at least ten days before the policy is enacted on the hanteonews.com Web site and its reason for such change.</div>
                            <div><br /></div>
                            <div><br /></div>
                        </Body>

                    </>
                );
        }
    }

    render() {
        const {language} = this.props;
        return (
            <>
                {this.policyBody(language)}
            </>
        );
    }
}
const Header = styled.div`
    margin: 0 30px;
    h1{
        font-size: 2.5rem;
    }
`;
const Body = styled.div`
    margin: 50px 30px;
    line-height: 1.5;
    word-break: break-word;
    b{
        font-weight: 600;
    }
`;

export default Policy;
