import React, { Component } from "react";
import styled from "styled-components";
import { MENU_CONST, WEB_MAIN_STR } from "global/string";

import LatestArticleView from 'components/view/article/LatestArticleView';
import {getQueryParameter, getQueryParameters} from "../../../utils/UriUtils";
import {ConvertSystemHtmlChartoSource} from "../../../utils/StringUtils";
import { getListOfPost } from "api/PostAPI";
import { mapOfCategoryChild } from "utils/CategoryUtils";
import GoogleBannerAds from "../../view/ads/GoogleBannerAds";

class LatestArticleVM extends Component {

    constructor() {
        super();
        this.state = {
            data: undefined,
            isLoading: false
        }
        this.size = 10
    }

    componentDidMount() {
        let finalIdx = getQueryParameter('fc',window.location)

        if (finalIdx===undefined || finalIdx===null) {
            window.addEventListener('scroll', this.onScroll);
        }

        
        this.loadListOfPost(0, this.size)

    }

    loadListOfPost(offset, limit) {
        if (this.state.isLoading) {
            return
        }

        this.setState({
            isLoading: true
        })

        let section = MENU_CONST.latest;

        getListOfPost({
            offset: offset,
            limit: limit,
            order: "DESC",
            clientIdx: 1022,
            language: this.props.language,
            listOfSection: section[this.props.language],
            summary: true
        }, (res) => {
            var listOfPost = res.resultData;
            var list = [];

            listOfPost.map((post, i) => {
                
                let _cate = post.listOfCategory[0]

                var category = MENU_CONST.allCategory[post.meta.language].title;
                if (this.props.listOfHeader) {
                    let mapOfHeader = mapOfCategoryChild(this.props.listOfHeader)
                    let listOfKey = Object.keys(mapOfHeader);
                    for (var i = 0 ; i < listOfKey.length; i++) {
                        let key = listOfKey[i];
                        let listOfChildIdx = mapOfHeader[key]
                        if (listOfChildIdx.indexOf(`${_cate.idx}`) > -1) {
                            category = key
                            break;
                        }
                    }
                }
                
                
                list.push({
                    idx: post.ownership.idx,
                    thumbnail: post.meta.content.thumbnail,
                    title: ConvertSystemHtmlChartoSource(post.meta.content.title),
                    content: ConvertSystemHtmlChartoSource(post.meta.content.seo),
                    date: post.publish.startDate,
                    language: post.meta.language,
                    link: `/${post.meta.language.toLowerCase()}/article/${category.toLowerCase()}?fc=${post.ownership.idx}`
                })
                return true;
            })

            if (this.state.data) {
                var prev = [...this.state.data];
                list = prev.concat(list)
            } 

            this.setState({
                data: list,
                isLoading: false
            })  
            
        })
    }

    onScroll = (e) => {

        const scrollTop = ('scroll', e.srcElement.scrollingElement.scrollTop);
        if (document.body.clientHeight - scrollTop < 1100) {
            var objs = document.getElementsByClassName(ArticleListContainer.styledComponentId);
            var startPage = objs[0].childElementCount / 10;

            let offset = Number(startPage) * this.size
            let limit = this.size;
            this.loadListOfPost(offset, limit)
        }
    };


    // APP 최신기사 (APP 내 전체기사)
    render() {
        const {language} = this.props;
        return (
            <>
                {language?
                    <ContentsContainer>
                        <TitleContainer>
                            <Title>{WEB_MAIN_STR[language].title.all}</Title>
                        </TitleContainer>
                        <ArticleContainer>
                            <ArticleListContainer>
                                {this.state.data ? this.state.data.map((post, index) => {
                                    return <>
                                        <LatestArticleView
                                            key={index}
                                            data={post}
                                        />
                                        {index%4===3?
                                        <ListAds>
                                            <GoogleBannerAds
                                                id={`content-ad-${post.idx}`}
                                                adId={`div-gpt-ad-202407231234366-276-${post.idx}`}
                                                adType={"static"}
                                                slot={{
                                                    id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_MWEB_category_middle_320x100",
                                                    size: "[320,100]"
                                                }}
                                            />
                                        </ListAds>
                                        :<></>}
                                    </>
                                }) : <></>}
                            </ArticleListContainer>
                        </ArticleContainer>
                    </ContentsContainer>
                    : <></>}
            </>
        );
    }
}


const Title = styled.div``;
const TitleContainer = styled.div``;

const ListAds = styled.div``;
const ArticleListContainer = styled.div``;
const ArticleContainer = styled.div``;
const ContentsContainer = styled.div`
    margin: 40px 0px 28px 0px;

    ${TitleContainer}{

        ${Title}{
            display: inline-block;
            font-size: 18px;
            margin: 0 10px 7px 10px;
            font-weight: 600;
            line-height: 1.2;
        }
    }
    ${ArticleContainer}{
        border-top: 1px solid #7d7d7d;
        ${ArticleListContainer}{
            margin: 0 10px;
        }
    }
`;

export default LatestArticleVM;
