export function convertListOfIdx(listOfCategory) {
    var result = []

    listOfCategory.map((category) => {
        if (category.idx !== undefined) {
            result.push(`${category.idx}`)
        }

        if (category.listOfChild!==undefined && category.listOfChild!==null && category.listOfChild.length>0){
            let listOfChildIdx = convertListOfIdx(category.listOfChild);
            result = result.concat(listOfChildIdx)
        }
        return true;
    })
    
    return result;
}

export function mapOfCategoryChild(listOfHeader) {
    var mapOfHeader = {}
    listOfHeader.map((header) => {
        var result = [`${header.idx}`]
        if (header.listOfChild!==undefined && header.listOfChild!==null && header.listOfChild.length > 0 ) {
            let listOfChildIdx = convertListOfIdx(header.listOfChild);
            result = result.concat(listOfChildIdx);
        }
        mapOfHeader[header.title] = result;
        return true;
    })
    return mapOfHeader;
}