import React, { Component } from "react";
import SearchPageVM from 'components/view_model/search/SearchPageVM';
import styled from "styled-components";
import GoogleMainAds from "../../components/view/ads/GoogleMainAds";
import GoogleBannerAds from "../../components/view/ads/GoogleBannerAds";

class WebSearch extends Component {
  render() {
    const {language, listOfHeader} = this.props;
    return (
      <ContentsDiv>
        <LeftSideAds>
          <GoogleBannerAds
            id={"left-side-ad"}
            adId={"div-gpt-ad-202407231222167-201"}
            adType={"static"}
            slot={{
                  id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_w_lt_160x600",
                  size: '[160,600]'
              }}
          />
        </LeftSideAds>
        <ContentsContainer>
            <SearchPageVM language={language} listOfHeader={listOfHeader}/>
        </ContentsContainer>
        <RightSideAds>
          <GoogleBannerAds
            id={"right-side-ad"}
            adId={"div-gpt-ad-202407231222165-939"}
            adType={"static"}
            slot={{
              id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_wr_fl_160x600",
              size: '[160,600]'
            }}
          />
        </RightSideAds>

        <GoogleBannerAds
          id={"bottom-anchor-ad"}
          adType={"anchor"}
          slot={{
            id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_bottom_anchor_970x90"
          }}
        />
      </ContentsDiv>
    );
  }
}

const LeftSideAds = styled.div``;
const RightSideAds = styled.div``;
const ContentsContainer = styled.div``;
const ContentsDiv = styled.div`
  height: auto;

  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;


  ${LeftSideAds}, ${RightSideAds} {
    display: none;
    margin: 16px 20px 0 20px;
  }

  @media (min-width:1048px) {
    padding: 0px 0 0 0;

    ${ContentsContainer} {
      width: 1048px;
    }
  }

  @media (min-width:1448px) {
    ${LeftSideAds} {
      display: block;
    }
    ${RightSideAds} {
      display: block;
      position: sticky;
      top: 62px;
    }
  }
`;

export default WebSearch;