import React, { Component } from "react";
import styled from "styled-components";
import MenuBtnGroup from "components/company/MenuBtnGroup"
import Hanteo from "components/company/Hanteo"
import News from "components/company/News"

class Home extends Component {
  
  clickMenu = (id) => {
    var header = document.getElementsByClassName(HeaderDiv.styledComponentId)[0];
    var objs = header.querySelectorAll('h2');
  
    
    for(var i = 0; i < objs.length; i++) { // For each element
      var obj = objs[i];
      obj.className = obj.className.replace(/\active\b/g, "");
      if(obj.dataset.target===String(id)){
        obj.classList.add('active');
      }
    }

    var hanteo = document.getElementsByClassName(HanteoDiv.styledComponentId)[0];
    var news = document.getElementsByClassName(NewsDiv.styledComponentId)[0];
    if(id===0){
      news.className = news.className.replace(/\active\b/g, "");
      hanteo.classList.add('active');
    }else if(id===1){
      hanteo.className = hanteo.className.replace(/\active\b/g, "");
      news.classList.add('active');
    }
  }
  render() {
    const {language} = this.props;
    return (
      <>
      <MainDiv>
        <HeaderDiv>
          <h2 data-target="0" className="active">HANTEO</h2>
          <h2 data-target="1">HANTEO NEWS</h2>
        </HeaderDiv>
        <MenuDiv>
          <MenuBtnGroup 
            btn_list={[{
              'id':0,
              'title':'HANTEO',
              'isActive':true
            },{
              'id':1,
              'title':'HANTEO NEWS',
              'isActive':false
            }]}
            onBtnClickCallBack={this.clickMenu}/>
        </MenuDiv>
        <ContentDiv>
          <HanteoDiv className="active">
            <Hanteo language={language}></Hanteo>
          </HanteoDiv>
          <NewsDiv>
            <News language={language}></News>
          </NewsDiv>
        </ContentDiv>
      </MainDiv>
      <MobileDiv>
        <HanteoDiv>
          <Hanteo language={language}></Hanteo>
        </HanteoDiv>

        <NewsDiv>
          <News language={language}></News>
        </NewsDiv>
        <div className="bg"></div>
        <div className="bg bg2"></div>
        <div className="bg bg3"></div>
      </MobileDiv>
      </>
    );
  }
}



const NewsDiv = styled.div``;
const HanteoDiv = styled.div``;

const ContentDiv = styled.div``;
const MenuDiv = styled.div``;
const HeaderDiv = styled.div``;



const MobileDiv = styled.div`
  display: block;
  font-family: NanumSquare;
  margin: 50px 0px;
  
  @media all and (min-width:1048px) {
    display: none;
  }
  .bg{
    animation: slide 6s ease-in-out infinite alternate;
    background-image: linear-gradient(-60deg, #fff4f9 50%, #ffffff 50%);
    bottom: 0;
    left: -50%;
    opacity: .5;
    position: fixed;
    right: -50%;
    top: 0;
    z-index: -1;
  }
  .bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 9s;
  }
  .bg3 {
    animation-duration: 12s;
  }

  ${HanteoDiv}, ${NewsDiv}{
    padding: 0 0 60px 0;

    ${HeaderDiv}{
      text-align: center;
      h3{
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
        font-size: 2rem;
  
        span{
          &:last-child{
            position: relative;
            text-transform: uppercase;
            line-height: 0.7;
            letter-spacing: 7px;
            font-size: 24px;
            color: #212529;
            opacity: 0.9;
          }
        }
      }
      p{
        font-family: 'Lato';
        font-weight: 600;
        text-align: center;
        margin-top: 20px;
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        line-height: 1.6;
        span{
          opacity: 0.8;
          font-size: 24px;
        }
      }
    }
  }
  

`;

const MainDiv = styled.div`
  position:relative;
  display:none;

  @media all and (min-width:1048px) {
    display:block;
    width: 800px;
    padding: 50px 0 200px 0;
    margin: 0 auto;
  }

  ${HeaderDiv}{
    h2{
      display: none;
      font-size:2rem;
      line-height:1.3;
      font-weight: 500;
      margin-bottom: .5rem;

      &.active{
        display: block;
      }
    }
  }
  ${MenuDiv}{
      
    @media all and (min-width:1048px) {
      padding: 60px 0 75px 0;
      margin: 0 auto;
    }

  }
  ${ContentDiv}{

    ${HanteoDiv}{
      display: none;
      &.active{
        display: block;
      }
    }
    ${NewsDiv}{
      display: none;
      &.active{
        display: block;
      }
    }
  }
`;

export default Home;
