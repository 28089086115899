import React, { Component } from "react";
import styled from "styled-components";
import {SEARCH_ERROR_MSG, SEARCH_PLACE_HOLDER} from "../../../../global/string";
import {getLanguageWithPath} from "../../../../utils/LanguageUtils";

class ScrollHeader extends Component {
    constructor() {
        super();
    }

    search(){
        let divs = document.getElementsByClassName(SearchInput.styledComponentId);
        let keyword = divs[0].value;
        if(keyword===undefined || keyword===null || keyword===''){
            alert(SEARCH_ERROR_MSG[this.props.language])
            return;
        }
        let startPage = 1;
        let order = 0;
        window.location.href = `/${this.props.language}/search?keyword=${keyword}&startPage=${startPage}&order=${order}`;
    }
    searchOnClick = (e) => {
        this.search()
    }

    searchEnterKey = (e) => {
        if(e.key === 'Enter') {
            this.search();
        }
    }
    render() {
        const {show, data, header, language} = this.props;
        return (
            <Container className={show? "show":""}>
                <Cover>
                    <LogoContainer href={`/${language.toLowerCase()}`}>
                        <img className="hanteo-logo"
                            src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/real/hanteonews_logo.png" />
                    </LogoContainer>
                    <MenuContainer>
                        {data.map((_header, i)=>{
                        return <Menu key={i} className={header? (_header.title.toLowerCase()===header.toLowerCase()?"activate":""): ""}>
                            <a href={`/${language.toLowerCase()}/article/${_header.title.toLowerCase()}`}>
                                <p>{_header.title}</p>
                            </a>
                        </Menu>

                        })}
                    </MenuContainer>

                    <SearchContainer>
                        <SearchInput placeholder={SEARCH_PLACE_HOLDER[language]} onKeyUp={this.searchEnterKey.bind(this)} />
                        <img
                             src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/search_bolder.svg" onClick={this.searchOnClick.bind(this)}/>
                    </SearchContainer>
                </Cover>
            </Container>
        );
    }

}
const SearchInput = styled.input``;
const SearchContainer = styled.div``;
const Menu = styled.div``;
const MenuContainer = styled.div``;
const LogoContainer = styled.a``;
const Cover = styled.div``;
const Container = styled.div`

    
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9999;
    background: #f8f8f8;
    -webkit-box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);
    -moz-box-shadow: 0px 2px 3px rgba(0,0,0,.2);
    box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);
    
    @media (min-width: 1048px){
        display: inline-flex;
        visibility: hidden;
    }
    
    &.show{
        visibility: visible;
    }
    
    
    ${Cover}{
        display: inline-flex;
        width: 1048px;
        margin: 0 auto;
        padding: 8px 0 10px 12px;
        flex-direction: row;
        
        ${LogoContainer}{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            img{
                margin-right: 8px;
                width: 152px;
                height: auto;
            }
        }
        
        ${MenuContainer}{
            display: inline-flex;
            align-items: center;
            justify-content: center;
            margin: 0 0 0 30px;
            
            ${Menu}{
                margin: 0 10px 0 12px;
                color: #000000;
                font-size: 14px;
                &.activate{
                    color: #dd3a6c;
                }
            }
        }
        
        ${SearchContainer}{
            display: inline-flex;
            margin-left: auto;
            background-color: white;
            border: 1px solid #E8E7E7;
            
            ${SearchInput} {
                background-color: #fff;
                padding: 0 0 0 8px;
                margin: 0;
                border: none;
                width: 200px;
                height: 26px;
                font-size: 13px;
            }
            img{
                margin: 2px 6px 2px 6px;
                cursor: pointer;
                width: auto;
                height: auto;
            }
        }
    }
`;


export default ScrollHeader;
