import React, { Component } from "react";
import styled from "styled-components";

class MostArticleView extends Component {

    render() {
        const { data } = this.props;
        return (
            <>
                {this.props.data ?
                    <ArticleDiv onClick={this.props.onClickCallBack.bind(this, data)}>
                        <ArticleATag>
                            <Title dangerouslySetInnerHTML={{ __html: data.title }}></Title>
                            <ContentsDiv>
                                <ThumbnailDiv>
                                    <ThumbnailImg src={data.thumbnail}></ThumbnailImg>
                                </ThumbnailDiv>
                                <ContentsText dangerouslySetInnerHTML={{ __html: data.content }}></ContentsText>
                            </ContentsDiv>
                        </ArticleATag>
                    </ArticleDiv> : ''}
            </>
        );
    }
}

const ContentsText = styled.p``;
const ThumbnailImg = styled.img``;
const ThumbnailDiv = styled.div``;
const ContentsDiv = styled.div``;
const Title = styled.p``;
const ArticleATag = styled.a``;
const ArticleDiv = styled.div`
    cursor: pointer;
    @media (min-width:1048px) {
        border-bottom: 1px solid #e8e7e7;
    }

    ${ArticleATag}{
        ${Title}{
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: normal;
            text-align: left;
            word-wrap: break-word;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;

            margin: 14px 0 5px 0;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.2rem;
            font-family: NanumSquare;
            color: #111111;

        }
        ${ContentsDiv}{
            display:flex;
            margin: 2px 0 15px 0px;
            font-family: NanumSquare;
            font-weight: 400;
            @media (min-width:1048px) {
                margin: 0px 0px 15px 0px;
            }
            ${ThumbnailDiv}{
                width: 112px;
                min-width: 112px;
                max-width: 112px;
                height: 55px;
                overflow: hidden;
                flex-basis: inherit;
                flex-grow: inherit;
                margin: 2px 12px 0 0;
                @media (min-width:1048px) {    
                    margin: 2px 15px 0 0;
                }

                ${ThumbnailImg}{
                    width: 100%;
                    height: auto;
                }
            }
            ${ContentsText}{
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                text-align: left;
                word-wrap: break-word;
                word-break: break-word;
                display: -webkit-box;
                -webkit-box-orient: vertical;

                font-size: 14px;
                -webkit-line-clamp: 3;
                margin: 0px;
                line-height: 1.4;
                color: #444444;
                height: 56px;
            }
        }
    }
`;

export default MostArticleView;
