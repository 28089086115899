import Root from "Page/Main/Root";
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";



class HtRoutes extends React.Component {

  render() {
    return (
      <>
        <Router>
          <Routes>
              {/* APP */}
              <Route path="/app">
                  <Route path=":language"  element={<Root type={"appmain"} />} />
                  <Route path="" element={<Root type={"webmain"} />} />
              </Route>

              {/* WEB */}
              <Route path="/:language">
                  <Route path="article">
                      <Route path=":header" element={<Root type={"webcontent"} />} >
                          <Route path=":contentIdx" element={<Root type={"webcontent"} />} />
                          <Route path="" element={<Root type={"notfound"} />} />
                      </Route>
                      <Route path="" element={<Root type={"notfound"} />} />
                  </Route>
                  <Route path="company">
                      <Route path=":header" element={<Root type={"company"} />} />
                      <Route path="" element={<Root type={"notfound"} />} />
                  </Route>
                  <Route path="search" element={<Root type={"websearch"} />} />
                  <Route path="" element={<Root type={"webmain"} />} />
              </Route>
              <Route path="/" element={<Root type={"webmain"} />} />
              <Route path="*" element={<Root type={"notfound"} />} />
          </Routes>
        </Router>
      </>
    );
  }
}

export default HtRoutes;
