import React, { Component } from "react";
import styled from "styled-components";
import { MENU_CONST, SEARCH_POP_UP } from "global/string"

import ListView from 'components/view/list/ListView';
import SearchHeaderView from 'components/view/list/SearchHeader';
import PaginationVM from "components/view_model/common/PaginationVM";
import AdBannerAndMostArticleVM from 'components/view_model/article/AdBannerAndMostArticleVM';
import NoArticleView from "components/view/common/NoArticle"
import { getQueryParameter } from "utils/UriUtils";
import { getListOfPost } from "api/PostAPI";
import { SEARCH_ERROR_MSG } from "global/string";
import { ConvertSystemHtmlChartoSource } from "utils/StringUtils";
import {convertListOfIdx} from "../../../utils/CategoryUtils";
import GoogleBannerAds from "../../view/ads/GoogleBannerAds";

class SearchPageVM extends Component {

    constructor() {
        super();
        this.keyword = getQueryParameter('keyword', window.location) ?? ''
        this.startPage = getQueryParameter('startPage', window.location) ?? 1
        this.order = getQueryParameter('order', window.location) ?? '0'
        this.size = 10;
        this.state = {
            data: undefined
        }
    }

    onClickArticle(_result) {
        if (window.location.pathname.indexOf('search') > 0) {
            window.location.href = window.location.origin + _result.link
        } else {
            window.location.href = '?fc=' + _result.idx
        }
    }
    componentDidMount() {
        var offset = 0;
        if (this.startPage!==undefined && this.startPage!==null && this.startPage!=='') {
            try {
                var _startPage = parseInt(this.startPage);
                offset = (_startPage - 1) * this.size;
            } catch (error) {
                console.log(error); 
            }
            if (offset<0) offset = 0;
        }

        var limit = this.size;
        if (this.keyword===undefined || this.keyword===null || this.keyword === '' || this.keyword.length < 2) {
            alert(SEARCH_ERROR_MSG[this.props.language]);
            return;
        }
        var order = "DESC"
        if (this.order!==undefined && this.order!==null && this.order!== '' && this.order==='1'){
            order = "ASC"
        }

        let listOfCategory = convertListOfIdx(this.props.listOfHeader)
        getListOfPost({
            offset: offset,
            limit: limit,
            order: order,
            clientIdx: 1022,
            language: this.props.language,
            keyword: decodeURIComponent(this.keyword),
            summary: true,
            listOfCategory: listOfCategory.join(',')
        }, (res) => {
            var data = res.resultData

            let list = [];
            data.map((post) => {
                var category = MENU_CONST.allCategory[post.meta.language].title;
                list.push({
                    idx: post.ownership.idx,
                    thumbnail: post.meta.content.thumbnail,
                    title: ConvertSystemHtmlChartoSource(post.meta.content.title),
                    content: ConvertSystemHtmlChartoSource(post.meta.content.seo),
                    date: post.publish.startDate,
                    language: post.meta.language,
                    link: `/${post.meta.language.toLowerCase()}/article/${category.toLowerCase()}?fc=${post.ownership.idx}`
                })
                return true;
            })
            
            this.setState({
                data: list
            })
        });

    }
    onClickOrder = (_result) => {
        var link = `/${this.props.language.toLowerCase()}/search?startPage=${this.startPage}&keyword=${this.keyword}&order=${_result}`;
        window.location.href = link;
    }

    
                                                                   
    goNextPage = (data) => {
        
        var keyword = this.keyword
        var order = this.order

        var link = '?startPage=' + data.page
        if (keyword !== undefined && keyword !== '') {
            link = link + '&keyword=' + keyword
        }
        if (order !== undefined && order !== '') {
            link = link + '&order=' + order
        }

        window.location.href = link
    }

    render() {
        const {language} = this.props;
        return (
            <>
                <ListPageContainer>
                    <LeftSideDiv>
                        {this.keyword ?
                            <SearchHeaderView
                                data={{
                                    'keyword': decodeURIComponent(this.keyword),
                                    'order': this.order,
                                    'language': language.toLowerCase(),
                                    'search': SEARCH_POP_UP[language].search,
                                    'latest': SEARCH_POP_UP[language].latest,
                                    'oldest': SEARCH_POP_UP[language].oldest
                                }}
                                onClickCallBack={this.onClickOrder} />
                            : <></>}
                        <ArticleContainer>
                            <ArticleListDiv>
                                {this.state.data ? this.state.data.map((post, i) => {
                                    return <>
                                        <ListView
                                            key={i}
                                            data={post}
                                            onClickCallBack={this.onClickArticle}></ListView>
                                        {i%4===3?
                                            <ListAds>
                                                <GoogleBannerAds
                                                    id={`side-top-ad-${post.idx}`}
                                                    adId={`div-gpt-ad-202407231222165-661-${post.idx}`}
                                                    adType={"static"}
                                                    slot={{
                                                        id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_category_middle_728x90",
                                                        size: "[728,90]"
                                                    }}
                                                />
                                            </ListAds>
                                            :<></>}
                                    </>
                                }) : <NoArticleView language={language} />}
                            </ArticleListDiv>
                            <PaginationVM    
                                current={this.startPage} 
                                size={this.size} 
                                onClick={this.goNextPage} />
                        </ArticleContainer>
                    </LeftSideDiv>
                    <RightSideDiv>
                        <SideTopAds>
                            <GoogleBannerAds
                                id={"side-top-ad"}
                                adId={"div-gpt-ad-202407231222169-916"}
                                adType={"static"}
                                slot={{
                                    id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_famousarticle_top_300x250",
                                    size: "[300,250]"
                                }}
                            />
                        </SideTopAds>
                        <AdBannerAndMostArticleVM language={language} />

                        <SideBottomAds>
                            <GoogleBannerAds
                                id={"side-bottom-ad"}
                                adId={"div-gpt-ad-202407231234360-230"}
                                adType={"static"}
                                slot={{
                                    id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_famousarticle_bottom_300x600",
                                    size: "[300,600]"
                                }}
                            />
                        </SideBottomAds>
                    </RightSideDiv>
                </ListPageContainer>
            </>
        );
    }
}

const ArticleListDiv = styled.div``;
const ArticleContainer = styled.div``;

const ListAds = styled.div``;
const SideTopAds = styled.div``;
const SideBottomAds = styled.div``;

const RightSideDiv = styled.div``;
const LeftSideDiv = styled.div``;
const ListPageContainer = styled.div`
    display: block;
    overflow: hidden;
    @media (min-width:1048px) {
        display: flex;
    }
    ${LeftSideDiv}{
        line-height: 1.5;
        max-width: 100%;
        @media (min-width:1048px) {
            width: 728px;
            flex-basis: auto;
            flex-grow: unset;
            margin: 0 0 100px 0;
        }

        ${ArticleContainer}{
            ${ArticleListDiv}{
                margin: 0 0 0 0;
                border-top: 1px solid #999999;
                width: 100%;
                
                @media (min-width:1048px) {
                    margin: 0 0 22px 0;
                    font-size: 14px;
                    color: #444444;
                    border-top: 1px solid #EEEEEE;
                }
            }
        }
    }
    ${RightSideDiv}{
        line-height: 1.5;
    
        @media (min-width:1048px) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-end;
            width: 320px;
    
            ${SideTopAds}{
              margin: 16px 0 0 0;
            }
        }
    }
`;

export default SearchPageVM;
