import React, { Component } from "react";
import styled from "styled-components";
import { HANTEO_INFO } from "components/company/string"

class Hanteo extends Component {
  
  render() {
    const {language} = this.props;
    return (
      <>
      <MainDiv>
        <LogoDiv>
          <img src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/hanteo_logo.jpg"></img>
        </LogoDiv>
        <TitleDiv>
          {HANTEO_INFO[language].title}
        </TitleDiv>
        <ContentsDiv>
          {HANTEO_INFO[language].contents.map((content, index)=>(
            <DesDiv key={index}>
              <DesHeaderDiv>
                <img src={content.imageSrc} alt="company-icon"></img>
                <h3>{content.title}</h3>
              </DesHeaderDiv>
              <DesContentsDiv>
                {content.description}
              </DesContentsDiv>
            </DesDiv>
          ))}
        </ContentsDiv>
      </MainDiv>
      <MobileDiv>
        <TitleDiv>
          {HANTEO_INFO[language].titleMobile}
        </TitleDiv>
        <ContentsDiv>
          {HANTEO_INFO[language].contents.map((content, index)=>(
            <DesDiv key={index}>
              <DesImgDiv>
                <img src={content.imageSrcMobile} alt="company-icon"></img>
              </DesImgDiv>
              <DesHeaderDiv>
                <img src={content.imageSrc} alt="company-icon"></img>
                <h3>{content.title}</h3>
              </DesHeaderDiv>
              <DesContentsDiv>
                {content.description}
              </DesContentsDiv>
            </DesDiv>
          ))}
        </ContentsDiv>
      </MobileDiv>
      </>
    );
  }
}
const DesImgDiv = styled.div``;
const DesContentsDiv = styled.div``;
const DesHeaderDiv = styled.div``;
const DesDiv = styled.div``;
const ContentsDiv = styled.div``;
const TitleDiv = styled.div``;
const LogoDiv = styled.div``;

const MobileDiv = styled.div`
  display: block;
  font-family: NanumSquare;
  margin: 50px 20px;
  @media all and (min-width:1048px) {
    display: none;
  }

  ${TitleDiv}{
    text-align: center;
    h3{
      margin-bottom: .5rem;
      font-weight: 500;
      line-height: 1.2;
      font-size: 2rem;

      span{
        &:last-child{
          position: relative;
          text-transform: uppercase;
          line-height: 0.7;
          letter-spacing: 7px;
          font-size: 24px;
          color: #212529;
          opacity: 0.9;
        }
      }
    }
    p{
      font-family: 'Lato';
      font-weight: 600;
      text-align: center;
      margin-top: 20px;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.6;
      span{
        opacity: 0.8;
        font-size: 24px;
      }
    }
  }
  ${ContentsDiv}{
    padding: 0 0 60px 0;

    ${DesDiv}{
      margin: 40px 0 0 0;
      ${DesImgDiv}{
        img{
          width: 100%;
          max-width: 400px;
        }
      }
      ${DesHeaderDiv}{
        margin: 5px 5px 0 5px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        img{
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }
        h3{
          font-family: 'Lato';
          text-transform: uppercase;
          color: #4d5154;
          font-weight: 700;
          margin: 0;
          font-size: 1.17em;
          span{
            color: #dd3a6c;
            font-size: 1.8em;
          }
        }
      }
      ${DesContentsDiv}{
        display: inline-block;
        text-align: left;
        letter-spacing: 1px;
        line-height: 2.0;
        margin: 0 0 5px 0;
        strong{
          font-weight: bolder;
        }
      }
    }
  }

`;

const MainDiv = styled.div`
  display: none;
  @media all and (min-width:1048px) {
    display: block;
  }
  text-align: center;
  ${LogoDiv}{
    img{
      
      @media all and (min-width:1048px) {
        width: 350px;
        margin: 38px auto 0 auto;
      }
    }
  }

  ${TitleDiv}{
      
    @media all and (min-width:1048px) {
      font-family: 'Lato';
      font-weight: 600;
      text-align: center;
      margin-top: 40px;
      line-height: 1.6;
    }
    p{
      font-family: 'Lato';
      font-weight: 600;
      text-align: center;
      margin-top: 20px;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      line-height: 1.6;
      span{
        opacity: 0.8;
        font-size: 24px;
      }
    }
  }

  ${ContentsDiv}{
    @media all and (min-width:1048px) {
      margin: 75px 0 0 0;
    }
    ${DesDiv}{
      margin: 40px 0 0 0;
      ${DesHeaderDiv}{
        display: flex;
        align-items: center;
        text-transform: uppercase;
        img{
          width: 50px;
          height: 50px;
          margin-right: 10px;
        }
        h3{
          font-family: 'Lato';
          text-transform: uppercase;
          color: #4d5154;
          font-weight: 700;
          margin: 0;
          font-size: 1.17em;
          span{
            color: #dd3a6c;
            font-size: 1.8em;
          }
        }
      }
      ${DesContentsDiv}{
        display: inline-block;
        text-align: left;
        letter-spacing: 1px;
        line-height: 2.0;
        strong{
          font-weight: bolder;
        }
      }
    }
  }
`;

export default Hanteo;
