import {Component} from "react";
import FooterPC from "./FooterPC";
import FooterMobile from "./FooterMobile";

class Footer extends Component{

    render() {
        const {language} = this.props;
        return <>
            <FooterPC language={language}/>
            <FooterMobile language={language}/>
        </>
    }
}

export default Footer;