import React, { Component } from "react";
import styled from "styled-components";
import { APP_POINT_TEXT } from "global/string";

class newsVotePopUp extends Component {

    constructor() {
        super();
        this.state = {
            data: undefined
        }
    }

    onClickArtist = (el) => {
        var objs = document.getElementsByClassName(ArtistName.styledComponentId);
        if (objs !== undefined && objs !== null && objs.length > 0) {
            for (var i = 0; i < objs.length; i++) { // For each element
                var obj = objs[i];
                obj.className = obj.className.replace(/\active\b/g, "");
                if (obj.dataset.target === String(el.idx)) {
                    obj.classList.add('active');
                }
            }
        }
        this.setState({
            data: el
        })
    }

    render() {
        const {data, language, onClickCallBack, onClickCallBackClose} = this.props
        return (
            <>
                <BackGroundCover>
                    <CoverAndPopUpContainer>
                        <VotePopUpContainer>
                            <ImgDiv>
                                <LogoImg src="https://hanteoresource.s3.ap-northeast-2.amazonaws.com/hanteonews/asset/real/whosfan_logo_640.png" />
                            </ImgDiv>
                            <DescriptionDiv>
                                <Des>
                                    {data.listOfArtist.length === 0 ? APP_POINT_TEXT[language].popup.des.noArtist : APP_POINT_TEXT[language].popup.des.yesArtist}
                                </Des>
                            </DescriptionDiv>
                            <ArtistListDiv>
                                {data.listOfArtist.length > 0 ?
                                    data.listOfArtist.map((artist, i) => {
                                        return <ArtistName onClick={this.onClickArtist.bind(this, artist)} data-target={artist.idx}>
                                            <ArtistTag dangerouslySetInnerHTML={{ __html: artist.title }}></ArtistTag>
                                        </ArtistName>
                                    }) : <></>}
                            </ArtistListDiv>


                            {data.listOfArtist.length > 0 ?
                                <VoteBtnDiv onClick={onClickCallBack.bind(this, this.state.data)}>
                                    <Vote>{APP_POINT_TEXT[language].popup.btn.confirm}</Vote>
                                </VoteBtnDiv> : <></>}

                        </VotePopUpContainer>
                        <CloseBtnDiv onClick={onClickCallBackClose.bind(this, false)}>
                            {APP_POINT_TEXT[language].popup.btn.close}
                        </CloseBtnDiv>
                    </CoverAndPopUpContainer>
                </BackGroundCover>

            </>
        );
    }

}

const ImgDiv = styled.div``;
const LogoImg = styled.img``;
const DescriptionDiv = styled.div``;
const Des = styled.p``;
const ArtistListDiv = styled.div``;
const ArtistName = styled.a``;
const ArtistTag = styled.p``;
const VoteBtnDiv = styled.div``;
const Vote = styled.a``;
const CloseBtnDiv = styled.div``;
const CoverAndPopUpContainer = styled.div``;
const VotePopUpContainer = styled.div``;
const BackGroundCover = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0, .5);
    z-index: 10;

    ${CoverAndPopUpContainer}{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        display: -ms-flexbox;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        ${VotePopUpContainer}{
            text-align: center;
            width: 80%;
            max-height: 80%;
            overflow: auto;
            background-color: #f8f8f8;
            border-radius: 10px;
            padding: 27px 0;
            z-index: 1;

            ${ImgDiv}{
                display: flex;
                display: -ms-flexbox;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 200px;
                margin: 14px auto;

                ${LogoImg}{
                    width: 100%;
                }
            }
            ${DescriptionDiv}{
                ${Des}{
                    text-align: center;
                    margin: 5px 15px 15px 15px;
                    word-break: keep-all;
                    line-height: 1.5;
                }
            }
            ${ArtistListDiv}{
                display: block;
                text-align: center;
                margin: auto;
                width: 80%;

                ${ArtistName}{
                    display: inline-block;
                    text-align: center;
                    border: 1px solid #e8e7e7;
                    padding: 6px 12px;
                    margin: 4px 6px;
                    border-radius: 20px;

                    cursor: pointer;                
                    
                    &.active{
                        background-color: #e8e7e7;
                        color: #111111;
                        text-decoration: none;
                    }
                }
            }
            ${VoteBtnDiv}{
                text-align: center;
                margin: 15px 0 0 0;

                ${Vote}{
                    display: inline-block;
                    text-align: center;
                    color: #fff;
                    padding: 8px 0;
                    width: 80%;
                    background-color: #ff659b;
                    border-radius: 5px;

                    cursor: pointer;
                }
            }
        }
        ${CloseBtnDiv}{
            margin: 4px 0 0 0;
            text-align: center;
            color: #aeaeae;
            cursor: pointer;
        }
    }
`;

export default newsVotePopUp;
