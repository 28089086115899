import React, {Component, useEffect, useState} from "react";
import WebMain from "./WebMain";
import WebContents from "Page/Detail/WebContents";
import WebSearch from "Page/Search/WebSearch";
import Company from "Page/Company/Company";
import NotFound from "Page/Error/NotFound";
import Header from "Page/Header/Header";
import Footer from "Page/Footer/Footer";
import AppMain from "./AppMain";
import { getListOfCategory } from "api/CategoryAPI";
import { useParams } from "react-router";
import { getLanguage } from "utils/LanguageUtils";
import { MENU_CONST } from "global/string";
import {getDefaultCategory} from "../../utils/CategoryUtils";



const Root = (props) => {
  
  const { language, header, contentIdx } = useParams();
  const [listOfCategory, setListOfCategory] = useState(undefined)
  const [whosfanUserNum, setWhosfanUserNum] = useState(undefined)
  
  window.setWhosfanUser = function (data) {
    setWhosfanUserNum(data)
  }

  useEffect(() => {
    let lang = getLanguage(language)
    let listOfCategory = `${MENU_CONST.main[lang.toUpperCase()]}`

    getListOfCategory({
      language: lang,
      clientIdx: 1022,
      listOfCategory: listOfCategory
    }, (res) => {
      setListOfCategory(res.resultData)
    })
  }, [])

  const renderWebContents = (_type) => {
    if (listOfCategory===undefined) {
      return <></>
    }

    let listOfHeader = listOfCategory[0].listOfChild
    let result = []
    if (_type!=="appmain" && _type!=="notfound"){
      result.push(<Header data={listOfHeader} header={header} language={getLanguage(language)}/>)
    }

    let _l = getLanguage(language);
    switch (_type) {
      case 'webmain': result.push(<WebMain language={_l} listOfHeader={listOfHeader}/>); break;
      case 'webcontent': result.push(<WebContents language={_l} header={header} listOfHeader={listOfHeader} contentIdx={contentIdx}/> ); break;
      case 'websearch': result.push(<WebSearch language={_l} listOfHeader={listOfHeader} />); break;
      case 'company': result.push(<Company language={_l} header={header} />); break;
      
      case 'appmain': result.push(<AppMain language={_l} whosfanUserNum={whosfanUserNum} />); break;
    
      default: result.push(<NotFound />); break;
    }

    if (_type!=="appmain" && _type!=="notfound"){
      result.push(<Footer language={_l} />)
    }
    
    return result
  }

  return (
    listOfCategory?
    renderWebContents(props.type)
    :<>
      <div style={{"height": "100vh", "display": "flex", "align-items": "center", "justify-content": "center"}}>
        <img style={{width:"100px", height: "100px"}} src="https://resource.hanteonews.com/hanteonews/asset/loading-basic.svg"/>
      </div>
    </>
  )
}

export default Root