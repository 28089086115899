import React, { Component } from "react";
import styled from "styled-components";


class ArtistList extends Component {

    onClickEvent = (_data) => {
        var objs = document.getElementsByClassName(ArtistLogoDiv.styledComponentId);
        if (objs !== undefined && objs !== null && objs.length > 0) {
            for (var i = 0; i < objs.length; i++) { // For each element
                var obj = objs[i];
                obj.className = obj.className.replace(/\active\b/g, "");
                if (obj.dataset.target === String(_data.idx)) {
                    obj.classList.add('active');
                }
            }
        }
        this.props.onClickCallBack(_data);
    }

    render() {
        const { data, onClickCallBack, isSelected } = this.props;
        return (
            <>
                {this.props.data ?
                    <ArtistLogoDiv className={this.props.isSelected ? "active" : ""} onClick={this.onClickEvent.bind(this, data)} data-target={data.idx}>
                        {data.imgSrc ? <ArtistName><ArtistLogoImg src={data.imgSrc}></ArtistLogoImg></ArtistName>
                            : <ArtistName>{data.title}</ArtistName>}
                    </ArtistLogoDiv>
                    : <></>}
            </>
        );
    }
}

const ArtistLogoImg = styled.img``;
const ArtistName = styled.a``;
const ArtistLogoDiv = styled.div`

    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    border: 1px solid #33333333;
    background-color: white;
    width: 52px;
    height: 52px;
    min-width: 52px;
    min-height: 52px;
    margin: 0 20px 0 6px;
    cursor: pointer;

    &.active{
        border: 1px solid #EF3E7A!important;
        background-color: #EF3E7A!important;
    }

    @media (min-width:1048px){
        width: 65px;
        height: 65px;
        min-width: 65px;
        min-height: 65px;

        &:hover{
            border: 1px solid #EF3E7A;
            background-color: #EF3E7A;
        }
    }

    

    ${ArtistName}{
        width: 45px;
        height: 45px;
        min-width: 45px;
        min-height: 45px;

        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 18px;
        
        word-break: break-all;

        justify-content: center;
        align-items: center;
        text-align: center;

        @media (min-width:1048px){
            width: 56px;
            height: 56px;
            min-width: 56px;
            min-height: 56px;
        }
        ${ArtistLogoImg}{
            border-radius: 100%;
            width: 100%;
            height: 100%;
            background-color: white;
        }
    }
`;


export default ArtistList;
