import React, { Component } from "react";
import styled from "styled-components";
import { WEB_MAIN_STR } from "global/string";
import {ConvertSystemHtmlChartoSource} from "../../../utils/StringUtils";

class ArtistArticle extends Component {

    onClick(post) {
        var link = window.location.origin + post.link
        window.location.href = link;
    }


    render() {
        const {oneOfNew, listOfBest, language} = this.props;

        return (
            <>
                <ArtistArticleContainer>
                    <NewArticleContainer>
                        {oneOfNew ?
                            language === 'KO' ?
                                <ArticleTitle>
                                    <ArtistNameView>{oneOfNew.artist.name}</ArtistNameView>
                                    {'\u00A0'}
                                    <TitleTag>{WEB_MAIN_STR[language].subTitle.ofNew}</TitleTag>
                                </ArticleTitle>
                                : <ArticleTitle>
                                    <TitleTag>{WEB_MAIN_STR[language].subTitle.ofNew}</TitleTag>
                                    {'\u00A0'}
                                    <ArtistNameView>{oneOfNew.artistName}</ArtistNameView>
                                </ArticleTitle>
                            : ''}
                        <ArticleDivContainer>
                            {oneOfNew ?
                                <ArticleDiv onClick={this.onClick.bind(this, oneOfNew)}>
                                    <ThumbnailDiv><ThumbnailImg src={oneOfNew.thumbnail}></ThumbnailImg></ThumbnailDiv>
                                    <ContentsDiv>
                                        <Title dangerouslySetInnerHTML={{ __html: oneOfNew.title }}></Title>
                                        <Contents dangerouslySetInnerHTML={{ __html: ConvertSystemHtmlChartoSource(oneOfNew.content) }}></Contents>
                                        <FinalPublicTime>{oneOfNew.startDate}</FinalPublicTime>
                                    </ContentsDiv>
                                </ArticleDiv>
                                : ''}
                        </ArticleDivContainer>
                    </NewArticleContainer>
                    <BestArticleContainer>
                        {listOfBest && listOfBest.length > 0 ?
                            language === 'KO' ?
                                <ArticleTitle>
                                    <ArtistNameView>{listOfBest[0].artist.name}</ArtistNameView>
                                    {'\u00A0'}
                                    <TitleTag>{WEB_MAIN_STR[language].subTitle.ofBest}</TitleTag>
                                </ArticleTitle>
                                : <ArticleTitle>
                                    <TitleTag>{WEB_MAIN_STR[language].subTitle.ofBest}</TitleTag>
                                    {'\u00A0'}
                                    <ArtistNameView>{listOfBest[0].artistName}</ArtistNameView>
                                </ArticleTitle>
                            : ''}
                        <ArticleDivContainer>
                            {listOfBest && listOfBest.length > 0 ? listOfBest.map((post, i) => {
                                return (
                                    <ArticleDiv key={i} onClick={this.onClick.bind(this, post)}>
                                        <ThumbnailDiv><ThumbnailImg src={post.thumbnail}></ThumbnailImg></ThumbnailDiv>
                                        <ContentsDiv>
                                            <Title dangerouslySetInnerHTML={{ __html: post.title }}></Title>
                                        </ContentsDiv>
                                    </ArticleDiv>
                                )
                            }) : ''}
                        </ArticleDivContainer>
                    </BestArticleContainer>
                </ArtistArticleContainer>
            </>
        );
    }
}


const FinalPublicTime = styled.p``;
const Contents = styled.p``;
const Title = styled.p``;
const ContentsDiv = styled.div``;

const ThumbnailImg = styled.img``;
const ThumbnailDiv = styled.div``;
const ArticleDiv = styled.a``;
const ArticleDivContainer = styled.div``;

const ArtistNameView = styled.p``;
const ArticleTitle = styled.div``;
const TitleTag = styled.p``;


const BestArticleContainer = styled.div``;
const NewArticleContainer = styled.div``;
const ArtistArticleContainer = styled.div`
    margin: 0 10px;
    ${NewArticleContainer}{ 
        ${ArticleTitle}{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            white-space: nowrap;
            font-size: 18px;
            margin: 0 0 7px 0;   

            ${ArtistNameView}{
                color: #DA3A6C;
                font-weight: 600;
            }
        }
        ${ArticleDivContainer}{
            ${ArticleDiv}{
                cursor: pointer;
                @media (min-width:1048px){
                    display: inline-flex;
                }

                ${ThumbnailDiv}{
                    @media (min-width:1048px){
                       width: 482px;
                       min-width: 482px;
                       max-width: 482px;
                    }
                    ${ThumbnailImg}{
                        width:100%;
                        height:auto;
                    }
                }

                ${ContentsDiv}{
                    margin: 6px 5px 0 5px;
                    @media (min-width:1048px){    
                        margin: 7px 15px;
                    }
                    ${Title}{
                        font-weight: bolder;
                        word-break: break-all;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        
                        margin: 0px;
                        
                        height: auto;
                        line-height: 26px;
                        font-size: 16px;
                        
                        @media (min-width:1048px) {
                            font-size: 22px;
                            line-height: 34px;
                        }
                    }
                    ${Contents}{
                        font-size: 12px;
                        font-weight: normal;

                        word-break: break-all;
                        color: #7D7D7D;

                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;

                        line-height: 18px;
                        height: auto;
                        margin: 4px 0 6px 0;

                        @media (min-width:1048px){
                            font-size: 15px;
                            line-height: 26px;
                            -webkit-line-clamp: 4;
                            margin: 19px 0 0 0;
                        }
                    }
                    ${FinalPublicTime}{
                        display: none;
                        @media (min-width:1048px){
                            display: block;
                            font-size: 15px;
                            margin: 11px 0 0 0;
                        }
                    }
                }
            }
        }
    }

    ${BestArticleContainer}{
        margin: 18px 0 0 0;
        @media (min-width:1048px){
            margin: 28px 0 0 0;
        }
        ${ArticleTitle}{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            white-space: nowrap;
            font-size: 18px;
            margin: 0 0 7px 0;   

            ${ArtistNameView}{
                color: #DA3A6C;
                font-weight: 600;
            }
        }
        ${ArticleDivContainer}{
            ${ArticleDiv}{
                cursor: pointer;
                display: inline-block;
                width: calc(50% - 7px);
                vertical-align: top;
              
                @media (min-width:1048px){
                    display: inline-block;
                    width: 244px;
                }

                &:nth-child(2n){
                    margin: 0 0 0 13px;
                    @media (min-width:1048px){
                        margin: 0 0 0 0;
                    }
                }
                &:nth-child(n+2){
                    @media (min-width:1048px){
                        margin: 0 0 0 17px;
                    }
                }
                ${ThumbnailDiv}{
                    ${ThumbnailImg}{
                        width:100%;
                        height: auto;
                    }
                }
                ${ContentsDiv}{
                    ${Title}{
                        font-size: 13px;
                        font-weight: normal;


                        line-height: 20px;
                        height: auto;
                        margin: 6px 0 8px 0;

                        word-break: break-all;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;


                        @media (min-width:1048px){
                            font-size: 14px;
                            -webkit-line-clamp: 2;
                            line-height: 20px;
                            height: auto;
                            margin: 12px 0 0 0;
                        }
                    }
                }
            }
        }
    }
`;

export default ArtistArticle;
