import React, { Component } from "react";
import styled from "styled-components";

import MainArticleVM from 'components/view_model/article/MainArticleVM';
import NewArticleVM from 'components/view_model/article/NewArticleVM';
import AdBannerAndMostArticleVM from 'components/view_model/article/AdBannerAndMostArticleVM';
import PhotoAndVideoArticleVM from 'components/view_model/article/PhotoAndVideoArticleVM';
import ArtistArticleVM from 'components/view_model/article/ArtistArticleVM';
import GoogleBannerAds from "../../components/view/ads/GoogleBannerAds";

class Web extends Component {

  constructor() {
    super();
  }

  renderAnchorAds() {
    if (document.body.offsetWidth < 1048) {
      return <GoogleBannerAds
          id={"bottom-anchor-ad"}
          adType={"anchor"}
          slot={{
            id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_MWEB_bottom_anchor_320x100"
          }}
      />
    }

    return <GoogleBannerAds
        id={"bottom-anchor-ad"}
        adType={"anchor"}
        slot={{
          id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_bottom_anchor_970x90"
        }}
    />
  }

  render() {
    const {language, listOfHeader} = this.props;
    return (
      <>
        <ContentsDiv>
          <LeftSideAds>
            <GoogleBannerAds
                id={"left-side-ad"}
                adId={"div-gpt-ad-202407231222167-201"}
                adType={"static"}
                slot={{
                  id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_w_lt_160x600",
                  size: '[160,600]'
                }}
            />
          </LeftSideAds>
          <ContentsContainer>
            <MainArticleVM
              language={language} 
              listOfHeader={listOfHeader}/>
            <MiddleArticleDiv>
              <NewArticleDiv>
                <NewArticleVM
                  language={language} 
                  listOfHeader={listOfHeader} />
                <MiddleAds>
                  <GoogleBannerAds
                    id={"article-ad"}
                    adId={"div-gpt-ad-202407231222165-661"}
                    adType={"static"}
                    slot={{
                      id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_category_middle_728x90",
                      size: '[728,90]'
                    }}/>
                </MiddleAds>
              </NewArticleDiv>
              <AdBannerDiv>
                <AdBannerAndMostArticleVM
                  language={language} />
              </AdBannerDiv>
            </MiddleArticleDiv>

            <PhotoAndVideoArticleVM
              language={language}
              listOfHeader={listOfHeader} />
            <ArtistArticleVM
              language={language}
              listOfHeader={listOfHeader} />
          </ContentsContainer>
          <RightSideAds>
            <GoogleBannerAds
                id={"right-side-ad"}
                adId={"div-gpt-ad-202407231222165-939"}
                adType={"static"}
                slot={{
                  id: "/22996174068,23027290658/ca-pub-2917775418894286-tag/hanteonews_PC_wr_fl_160x600",
                  size: '[160,600]'
                }}
            />
          </RightSideAds>

          <BottomAnchorAds>
            {this.renderAnchorAds()}
          </BottomAnchorAds>
        </ContentsDiv>
      </>
    );
  }
}

const AdBannerDiv = styled.div``;
const NewArticleDiv = styled.div``;
const ContentsContainer = styled.div``;
const MiddleAds = styled.div``;
const MiddleArticleDiv = styled.div`
  @media(min-width:1048px) {
    display: flex;
  }

  ${NewArticleDiv}{
    @media (min-width:1048px) {
      width: 728px;
    }
  }
  ${AdBannerDiv}{
    @media (min-width:1048px) {
      width: 320px;
    }
  }
`;
const LeftSideAds = styled.div``;
const RightSideAds = styled.div``;
const BottomAnchorAds = styled.div``;
const ContentsDiv = styled.div`
  height: auto;
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  
  ${ContentsContainer} {
    width: 100%;
  }
  
  ${MiddleAds} {
    display: none;
  }
  
  ${LeftSideAds}, ${RightSideAds} {
    display: none;
    margin: 16px 20px 0 20px;
  }
  
  @media (min-width:1048px) {
    padding: 0px 0 0 0;

    ${MiddleAds} {
      display: block;
    }
    ${ContentsContainer} {
      width: 1048px;
    }
  }

  @media (min-width:1448px) {

    ${LeftSideAds} {
      display: block;
    }
    ${RightSideAds} {
      display: block;
      position: sticky;
      top: 62px;
    }
  }
  
`;


const WebMain = (props) => {
  return ( <> <Web language={props.language} listOfHeader={props.listOfHeader}/> </> )
}

export default WebMain;