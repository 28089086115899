import React, { Component } from "react";
import styled from "styled-components";
import { getCommentList } from "api/CommentAPI";
import CommentInput from "components/view/comment/CommentInput";
import CommentCount from "components/view/comment/CommentCount";
import CommentItem from "components/view/comment/CommentItem";
import CommentNothing from "components/view/comment/CommentNothing";
import DownIcon from "img/icon_down.svg";
import Spinner from "img/spinner.svg";
import CommentHeader from "components/view/comment/CommentHeader";
import LikeCommentVM from "components/view_model/comment/LikeCommentVM"

class MainCommentVM extends Component {
    constructor(){
        super();
        this.state = {
            counts: undefined,
            comments: [],
            likes: undefined,
            parent: undefined
        }
        this.orderType = "IDX_DESC"
        this.limit = 5
        this.offset = 0
    }
    componentDidMount() {
        // 댓글 Count 및 댓글 목록 불러와야됨.
        if(this.props.token===undefined){
            return;
        }
        getCommentList({
            contentIdx: this.props.contentIdx,
            parentIdx: this.props.parentIdx,
            index: 0,
            offset: 0,
            limit: this.limit,
            orderType: this.orderType,
            token: this.props.token
        }, this.setData,
        function(error){
            alert(error)
        })
    }
    setData = (json) => {

        if(json.code!==100){
            return
        }

        let result = json.resultData;
        if (result == undefined || result === null ) return;

        if(this.state.counts!==undefined && result.comments.length===0){
            return;
        }

        // 복사해서 추가.
        let commentList = this.state.comments.slice()
        commentList = commentList.concat(result.comments)

        this.setState({
            counts: result.counts,
            comments: commentList,
            likes: result.likes,
            parent: result.parent
        })
    }
    onLoadMoreClick = (e) => {
        let _this = this;
        let _spinnerClassName = 'spinner'
        let commentList = this.state.comments.slice()
        let _more = e.currentTarget.children[0]
        if(_more.classList.contains(_spinnerClassName)){
            alert('통신중입니다')
            return;
        }
        _more.src=Spinner
        _more.classList.add(_spinnerClassName)
        getCommentList({
            contentIdx: this.props.contentIdx,
            parentIdx: this.props.parentIdx,
            index: commentList[commentList.length-1].idx,
            limit: this.limit,
            offset: 1,
            orderType: this.orderType,
            token: this.props.token
        }, function(json){
            _more.src=DownIcon
            _more.classList.remove(_spinnerClassName)
            _this.setData(json)
        }, function(error){
            _more.src=DownIcon
            _more.classList.remove(_spinnerClassName)
            alert(error)
        })
    }
    registerCallback = (json) => {
        if(json===undefined || json===null){
            return;
        }
        let counts = this.state.counts
        let commentList = this.state.comments.slice()
        commentList.unshift(json);

        this.setState({
            counts: counts+1,
            comments: commentList
        })
    }
    deleteCallback = (itemIndex) => {
        if(itemIndex===undefined || itemIndex===null){
            return;
        }
        let counts = this.state.counts
        let commentList = this.state.comments.slice()
        commentList.splice(itemIndex, 1);

        this.setState({
            counts: counts-1,
            comments: commentList
        })
    }
    likeCallback = (_data) => {
        if(_data===undefined || _data===null){
            return;
        }
        
        let commentList = this.state.comments
        let comment = commentList[_data.itemIndex];
        comment.likeCount = _data.likeCount;
        commentList[_data.itemIndex] = comment;

        let likes = this.state.likes;
        
        if(_data.like===1){
            likes.push(String(_data.commentIdx))
        }else{
            likes.splice(likes.indexOf(String(_data.commentIdx)), 1)
        }
        
        

        this.setState({
            comments: commentList,
            likes:likes
        })
    }

    render() {
        const { contentIdx, parentIdx, langCode, token } = this.props;
        return (
            <>
                {this.state.counts === undefined? <></>:
                    
                    <Container>
                        {this.state.parent === undefined || this.state.parent === null ?
                        <></>:
                        <>  
                            <CommentHeader langCode={langCode}></CommentHeader>
                            <CommentParentContainer>
                                <CommentItem itemIndex={undefined} contentIdx={contentIdx} parentIdx={this.state.parent.parentIdx} comment={this.state.parent} mylikes={this.state.likes} langCode={langCode} isParent={true} token={token} deleteCallback={undefined}></CommentItem>
                            </CommentParentContainer>
                            <CommentCountLike className="hflex-sbc">
                                <CommentCount value={this.state.counts} langCode={langCode}></CommentCount>
                                <LikeCommentVM
                                    value={this.state.parent.likeCount}
                                    isLike={this.state.likes.indexOf(String(this.state.parent.idx))<0?false:true}
                                    contentIdx={contentIdx}
                                    parentIdx={this.state.parent.parentIdx}
                                    commentIdx={this.state.parent.idx}
                                    token={token}
                                    ></LikeCommentVM>
                            </CommentCountLike>
                        </>
                        }
                        <CommentInput contentIdx={contentIdx} parentIdx={parentIdx} token={token} registerCallback={this.registerCallback} langCode={langCode}></CommentInput>
                        {this.state.parent === undefined || this.state.parent === null?<CommentCountDiv><CommentCount value={this.state.counts} langCode={langCode}></CommentCount></CommentCountDiv>:<></>}
                        {(this.state.counts === 0? 
                            <>
                                <BlackLine></BlackLine>
                                <CommentNothing langCode={langCode}></CommentNothing>
                            </>
                        :
                        <>
                            <BlackLine className={this.state.parent === undefined || this.state.parent === null?"short":""}></BlackLine>
                            <CommentListContainer>
                            {this.state.comments.map((item, i)=>{
                                return <CommentItem
                                    itemIndex={i}
                                    contentIdx={contentIdx}
                                    parentIdx={parentIdx}
                                    comment={item}
                                    mylikes={this.state.likes}
                                    langCode={langCode}
                                    isParent={false}
                                    token={token}
                                    deleteCallback={this.deleteCallback}
                                    likeCallback={this.likeCallback}></CommentItem>
                            })}
                            </CommentListContainer>
                            {this.state.counts>5?
                                <MoreDiv className="hflex-cc" onClick={this.onLoadMoreClick}>
                                    <img alt="More" src={DownIcon}></img>
                                </MoreDiv>: <BlankBox></BlankBox>}

                        </>
                        )}

                    </Container>
                }
                
                
            </>
        );
    }
}
const BlankBox = styled.div``; 
const MoreDiv = styled.div``;
const BlackLine = styled.div``;
const CommentCountDiv = styled.div``;
const CommentListContainer = styled.div``;
const CommentCountLike = styled.div``;
const CommentParentContainer = styled.div``;
const Container = styled.div`

    font-family: 'Lato';

    ${CommentParentContainer}{
        padding: 5px 12px 0 12px;
        background: #FFF0F5;
    }
    ${CommentCountLike}{
        margin: 0 12px;
        padding: 10px 0 8px 0;
    }
    ${CommentListContainer}{
        margin: 0 12px;
    }
    ${CommentCountDiv}{
        margin: 0 12px;
    }
    ${BlackLine}{
        background: #3A3A3A;
        height: 1px;
        &.short{
            margin: 0 12px;
        }
    }
    ${MoreDiv}{
        padding: 10px 0;
        img{
            width: 20px;
            height: 20px;

            &.spinner{
                width: 30px;
                height: 30px;
            }
        }
    }
    ${BlankBox}{
        height: 60px;
    }
`;


export default MainCommentVM;
