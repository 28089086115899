import React, { Component } from "react";
import styled from "styled-components";
import HeaderLanguage from "./HeaderLanguage";
import HeaderMain from "./HeaderMain";
import ScrollHeader from "../../components/view/common/header/ScrollHeader";

class Header extends Component {
    constructor() {
        super();
        this.state = {
            showScroll: false
        }

        document.addEventListener("scroll", () => {
            let header = document.getElementsByClassName(Container.styledComponentId)[0];
            if (header===undefined || header===null) {
              return;
            }
            
            var isShow = false;
            if (window.scrollY > header.offsetHeight){
                isShow = true;
            }
            this.setState({showScroll: isShow})
        })
    }

  
  render() {
    const {data, header, language} = this.props;
    return (
        data ? <>
            <Container>
                <HeaderLanguage data={data} language={language}/>
                <HeaderMain data={data} header={header} language={language}/>
            </Container>
            <MobileBox />

            <ScrollHeader show={this.state.showScroll} data={data} header={header} language={language} />
        </>:<></>

    );
  }
}
const MobileBox = styled.div`
  height: 100px;
  @media (min-width: 1048px){
    display: none;
  }
`;
const Container = styled.div`
    
    position: fixed;
    top: 0;
    width: 100%;
    background-color: white;
    z-index: 9999;
    font-family: 'NanumSquareRound';
    
    @media (min-width: 1048px){
      position: unset;
    }
    
`;

export default Header;
