import React, { Component } from "react";
import styled from "styled-components";
import { MENU_CONST, WEB_MAIN_STR } from "global/string";

import PhotoArticleView from 'components/view/article/Photo';
import VideoArticleView from 'components/view/article/Video';
import { getListOfPost } from "api/PostAPI";
import { ConvertSystemHtmlChartoSource } from "utils/StringUtils";
import { mapOfCategoryChild } from "utils/CategoryUtils";

class PhotoAndVideoArticleVM extends Component {

    constructor() {
        super();
        this.state = {
            photoData: undefined,
            videoData: undefined,
            isApp: false
        }
    }

    componentDidMount() {

        if (window.location.pathname.indexOf('app') > 0) {
            this.setState({
                isApp: true
            })
        }

        
        // photo
        let listOfCategoryPhoto = MENU_CONST.photo[this.props.language];
        getListOfPost({
            offset: 0,
            limit: 4,
            order: "DESC",
            clientIdx: 1022,
            language: this.props.language,
            listOfCategory: listOfCategoryPhoto,
            summary: true
        }, (res) => {
            var listOfPost = res.resultData;
            var list = [];

            listOfPost.map((post, i) => {
                
                let _cate = post.listOfCategory[0]

                var category = MENU_CONST.allCategory[post.meta.language].title;
                if (this.props.listOfHeader) {
                    let mapOfHeader = mapOfCategoryChild(this.props.listOfHeader)
                    let listOfKey = Object.keys(mapOfHeader);
                    for (var i = 0 ; i < listOfKey.length; i++) {
                        let key = listOfKey[i];
                        let listOfChildIdx = mapOfHeader[key]
                        if (listOfChildIdx.indexOf(`${_cate.idx}`) > -1) {
                            category = key
                            break;
                        }
                    }
                }

                list.push({
                    idx: post.ownership.idx,
                    thumbnail: post.meta.content.thumbnail,
                    title: ConvertSystemHtmlChartoSource(post.meta.content.title),
                    content: ConvertSystemHtmlChartoSource(post.meta.content.description),
                    date: post.publish.startDate,
                    language: post.meta.language,
                    link: `/${post.meta.language.toLowerCase()}/article/${category.toLowerCase()}?fc=${post.ownership.idx}`
                })
                return true;
            })

            this.setState({
                photoData: list,
            })  
        })


        // video        
        let listOfCategoryVideo = MENU_CONST.video[this.props.language];
        getListOfPost({
            offset: 0,
            limit: 4,
            order: "DESC",
            clientIdx: 1022,
            language: this.props.language,
            listOfCategory: listOfCategoryVideo,
            summary: true
        }, (res) => {
            var listOfPost = res.resultData;
            var list = [];

            listOfPost.map((post, i) => {
                var category = MENU_CONST.allCategory[post.meta.language].title;
                list.push({
                    idx: post.ownership.idx,
                    thumbnail: post.meta.content.thumbnail,
                    title: ConvertSystemHtmlChartoSource(post.meta.content.title),
                    content: ConvertSystemHtmlChartoSource(post.meta.content.description),
                    date: post.publish.startDate,
                    language: post.meta.language,
                    link: `/${post.meta.language.toLowerCase()}/article/${category.toLowerCase()}?fc=${post.ownership.idx}`
                })
                return true;
            })

            this.setState({
                videoData: list,
            })  
        })
    }

    btnOnClick = (e) => {
        window.location.href = window.location.origin + '/' + this.props.language.toLowerCase() + e.currentTarget.dataset.target
    }

    render() {
        const {language} = this.props;
        return (
            <>
                <PhotoAndVideoArticleContainer>
                    <PVArticleContainer>
                        <TitleAndBtn>
                            <ArticleTitle>{WEB_MAIN_STR[language].title.photo}</ArticleTitle>
                            <BtnDiv onClick={this.btnOnClick.bind(this)} data-target={WEB_MAIN_STR[language].url.photo}>{this.state.isApp === false ? WEB_MAIN_STR[language].btn.more : ''}</BtnDiv>
                        </TitleAndBtn>
                        <PVArticleDiv>
                            {this.state.photoData ? (
                                <>
                                    <PVArticleCover>
                                        <PhotoArticleView data={this.state.photoData[0]}></PhotoArticleView>
                                    </PVArticleCover>
                                    <PVArticleList>
                                        {this.state.photoData.map((el, i) => {
                                            if (i > 0) {
                                                return <PVArticleSub key={el.idx}>
                                                    <PhotoArticleView data={el}></PhotoArticleView>
                                                </PVArticleSub>
                                            }
                                            return false;
                                        })}
                                    </PVArticleList>
                                </>
                            ) : (<></>)}
                        </PVArticleDiv>
                    </PVArticleContainer>
                    <PVArticleContainer>
                        <TitleAndBtn>
                            <ArticleTitle>{WEB_MAIN_STR[language].title.video}</ArticleTitle>
                            <BtnDiv onClick={this.btnOnClick.bind(this)} data-target={WEB_MAIN_STR[language].url.video}>{this.state.isApp === false ? WEB_MAIN_STR[language].btn.more : ''}</BtnDiv>
                        </TitleAndBtn>
                        <PVArticleDiv>
                            {this.state.videoData ? (
                                <>
                                    <PVArticleCover>
                                        <VideoArticleView data={this.state.videoData[0]}></VideoArticleView>
                                    </PVArticleCover>
                                    <PVArticleList>
                                        {this.state.videoData.map((el, i) => {
                                            if (i > 0) {
                                                return <PVArticleSub key={el.idx}>
                                                    <VideoArticleView data={el}></VideoArticleView>
                                                </PVArticleSub>
                                            }
                                            return false;
                                        })}
                                    </PVArticleList>
                                </>
                            ) : (<></>)}
                        </PVArticleDiv>
                    </PVArticleContainer>
                </PhotoAndVideoArticleContainer>
            </>
        );
    }
}

const PVArticleSub = styled.div``;
const PVArticleList = styled.div``;
const PVArticleCover = styled.div``;
const PVArticleDiv = styled.div``;

const BtnDiv = styled.div``;
const ArticleTitle = styled.div``;
const TitleAndBtn = styled.div``;

const PVArticleContainer = styled.div``;
const PhotoAndVideoArticleContainer = styled.div`
    @media (min-width:1048px){
        display: flex;
        width: 100%;
        margin: 45px 0 0 0;
    }

    ${PVArticleContainer}{
        @media (min-width:1048px){
            width: 50%;

            &:nth-child(1){
                margin: 0px 16px 0 0px;
            }
        }

        ${TitleAndBtn}{
            display: inline-flex;
            position: relative;
            width: 100%;
            margin: 15px 0 0px 0px;
            align-items: center;

            ${ArticleTitle}{
                display: inline-block;
                font-size: 18px;
                margin: 0 10px 0px 10px;
                font-weight: 600;

                @media (min-width:1048px){
                    margin: 0px 20px 0 0px;
                    font-size: 22px;
                }
            }
            ${BtnDiv}{
                cursor: pointer;

                position: absolute;
                right: 0%;
                margin-top: 0;
                border: none;
                margin-right: 10px;

                padding: 8px 0 10px 0;
                text-align: center;
                font-size: 12px;
            }
        }

        ${PVArticleDiv}{
            border-top: 1px solid #7d7d7d;
            margin: 10px 0 15px 0;
            padding: 15px 0 10px 0;
            
            @media (min-width:1048px){
                border-bottom: 1px solid #7d7d7d;
            }

            ${PVArticleCover}{
                margin: 0 10px;
                @media (min-width:1048px){
                    margin: 0 17px;
                }
            }
            ${PVArticleList}{
                display: inline-flex;
                margin: 0 10px 6px 10px;

                @media (min-width:1048px){
                    margin: 0 17px;
                }
                ${PVArticleSub}{
                    &:nth-child(2n){
                        margin: 0 14px;
                    }
                }
            }
        }
    }
`;
export default PhotoAndVideoArticleVM;
