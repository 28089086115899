import React, { Component } from "react";
import styled from "styled-components";
import { SEARCH_POP_UP } from "global/string";

class NoArticle extends Component {

    render() {
        const {language} = this.props;
        return (
            <>
                <NoArticleContainer>
                    <NoArticleDiv>
                        <NoArticleTextDiv>
                            <NoArticleText>
                                <Text dangerouslySetInnerHTML={{ __html: SEARCH_POP_UP[language].noList }}></Text>
                            </NoArticleText>
                        </NoArticleTextDiv>
                        <NoArticleImg src='https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/opinion_open_01.jpg'>
                        </NoArticleImg>
                    </NoArticleDiv>
                </NoArticleContainer>
            </>
        );
    }

}

const NoArticleDiv = styled.div``;
const NoArticleTextDiv = styled.div``;
const NoArticleText = styled.div``;
const Text = styled.p``;
const NoArticleImg = styled.img``;
const NoArticleContainer = styled.div`
    margin-top: 35px;
    text-align: center;

    @media (min-width:1048px){
        max-width: 1140px;
    }

    ${NoArticleDiv}{
        font-size: 18px;
        position: relative;

        ${NoArticleTextDiv}{
            position: absolute;
            display: table;
            height: 100%;
            width: 100%;
            background: rgba(255,255,255,.75);

            ${NoArticleText}{
                display: table-cell;
                vertical-align: middle;

                ${Text}{
                    line-height: 2.0;
                    font-weight: 600;
                    margin: 0px 60px;
                    font-size: 16px;
                }
            }
        }
    }
`;


export default NoArticle;
