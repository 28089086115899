import React, { Component } from "react";
import styled from "styled-components";

class GoogleMainAds extends Component {
    constructor() {
        super();
    }

    componentDidMount() {
        const _tag = document.createElement('script');
        _tag.async = true
        _tag.src = "https://securepubads.g.doubleclick.net/tag/js/gpt.js";

        let container = document.getElementById(this.props.id);
        container.appendChild(_tag);

        const _googleTag = document.createElement('script');
        let googleTagHtml = "window.googletag = window.googletag || {cmd: []};"
        if (this.props.adType === "anchor") {
            googleTagHtml = `${googleTagHtml} var ap_anchorSlot; googletag.cmd.push(function() { if (false) {ap_anchorSlot = googletag.defineOutOfPageSlot('${this.props.slot.id}', googletag.enums.OutOfPageFormat.TOP_ANCHOR); } else { ap_anchorSlot = googletag.defineOutOfPageSlot('${this.props.slot.id}', googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR);} if (ap_anchorSlot) { ap_anchorSlot.addService(googletag.pubads()); }else{ console.log('not supperted page size for anchor')} googletag.pubads().enableSingleRequest();googletag.pubads().set("page_url", "hanteonews.com");googletag.enableServices();googletag.display(ap_anchorSlot);});`
        }
        _googleTag.innerHTML = googleTagHtml;

        container.appendChild(_googleTag);


        if (this.props.adType === "static") {
            let _adContainer = document.createElement('div');
            _adContainer.id = this.props.adId;
            container.appendChild(_adContainer);

            const _push = document.createElement('script');
            _push.innerHTML = `googletag.cmd.push(function() {googletag.defineSlot('${this.props.slot.id}', ${this.props.slot.size}, '${this.props.adId}').addService(googletag.pubads());googletag.pubads().enableSingleRequest();googletag.pubads().set('page_url', 'hanteonews.com');googletag.enableServices();googletag.display('${this.props.adId}');});`
            _adContainer.appendChild(_push);
        }
    }

    render() {
        const {id} = this.props;
        return (
            <div id={id} style={{"text-align":"center"}}>
            </div>
        );
    }
}
export default GoogleMainAds;
