import React, { Component } from "react";
import styled from "styled-components";

class NewArticleView extends Component {
    render() {
        const { data, onClickCallBack } = this.props;
        return (
            <>
                {data ?
                    <ArticleList onClick={onClickCallBack.bind(this, data)}>
                        <ArticleDiv>
                            <ThumbnailDiv>
                                <ArticleImg src={data.thumbnail}></ArticleImg>
                            </ThumbnailDiv>
                            <ContentsDiv>
                                <ArticleTitle dangerouslySetInnerHTML={{ __html: data.title }}></ArticleTitle>
                                <ArticleContents dangerouslySetInnerHTML={{ __html: data.content }}></ArticleContents>
                                <ArticleFinalPublicTime dangerouslySetInnerHTML={{ __html: data.date }}></ArticleFinalPublicTime>
                            </ContentsDiv>
                        </ArticleDiv>
                    </ArticleList>
                    : <ArticleList></ArticleList>}
            </>
        );
    }
}

const ArticleFinalPublicTime = styled.p``;
const ArticleContents = styled.p``;
const ArticleTitle = styled.p``;
const ContentsDiv = styled.div``;
const ArticleImg = styled.img``;
const ThumbnailDiv = styled.div``;
const ArticleDiv = styled.a``;
const ArticleList = styled.div`
    margin: 0 0 9px 0;
    border-bottom: 1px solid #e8e7e7;

    @media (min-width:1048px){
        display: flex;
        align-items: center;
        padding: 0 0 19px 0;
        margin: 0 0 15px 0;
        border-bottom: 1px solid #7d7d7d;
    }

    ${ArticleDiv}{
        display: contents;
        cursor: pointer;
    
        @media (min-width:1048px){
            display: inline-flex;
            align-items: center;
        }

        ${ThumbnailDiv}{
            display: none;
    
            @media (min-width:1048px){
                display: block;
                width: 210px;
                height: 103px;
                min-width: 210px;
                max-width: 210px;
                overflow: hidden;
            }
            ${ArticleImg}{
                width: 100%;
                height: auto;
            }
        }
        ${ContentsDiv}{
            
            @media (min-width:1048px){
                margin-left: 13px;
            }
    
            ${ArticleTitle}{
                font-size: 15px;
                font-weight: 400;
                margin: 0 0 9px 0px;
                word-break: break-all;
                display: -webkit-box;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.5;
            
                @media (min-width:1048px){
                    margin: 0 0 8px 0px;
                    -webkit-line-clamp: 2;
                    font-weight: 600;
                    line-height: 1.6;
                }
            }
    
            ${ArticleContents}{
                display: none;
            
                @media (min-width:1048px){
                    font-size: 14px;
                    font-weight: normal;
                    margin: 0px 0px 7px 0;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #444444;
                    opacity: 1;
                    line-height: 1.2em;
                    height: 1.2em;
                }
            }
            ${ArticleFinalPublicTime}{
                display:none;
            
                @media (min-width:1048px){
                    display:block;
                    font-size: 13px;
                }
    
            }
        }
    }
    
`;


export default NewArticleView;