import React, { Component } from "react";
import styled from "styled-components";

class HeaderMenu extends Component {

  onClickNav = (e) => {
    e.stopPropagation();
    e.preventDefault();

    window.location.href = e.currentTarget.dataset.target;
  }

  render() {
    const { isSelected, link, title } = this.props;
    return (
      <>
        <NavDiv>
          <div className={isSelected?"active":""} onClick={this.onClickNav.bind(this)} data-target={link ? link : ''}>
            <p>{title}</p>
            <span></span>
          </div>
        </NavDiv>
      </>
    );
  }

}
const NavDiv = styled.div`
  div{
    position: relative;
    padding: 0 8px 0 8px;
    cursor: pointer;
    
    @media all and (min-width:1048px) {
      padding: 0 20px 0 20px;
      font-weight: 600;
    }
    &:hover{
      p{
        color: #dd3a6c;
      }
      span{
        display:block;
      }
    }

    p{

      padding: 12px 10px 6px 10px;
      font-size: 12px;
      color: #000;
      margin: 0px;
      
      @media all and (min-width:1048px) {
        padding: 19px 10px 17px 10px;
        font-size: 15px;
      }
    }

    span{
      height: 3px;
      background: #dd3a6c;
      position: absolute;
      top: 34px;
      left: 10px;
      right: 10px;
      display: none;
      @media all and (min-width:1048px) {
        top: 48px;
        left: 20px;
        right: 20px;
      }
    }

    
    &.active{
      p{
        color: #dd3a6c;
      }
      span{
        display:block;
      }
    }
  }
`;



export default HeaderMenu;
