import React, { Component } from "react";
import styled from "styled-components";
import {NEWS_BOTTOM_BAR, COMMENT_TEXT} from "global/string";
import { isMobile, isAndroid, isIOS, isConsole } from 'react-device-detect';
import { getLatestArticleByArtist } from "api/NewsAPI";
import { getQueryParameters } from "utils/UriUtils";
import { getListOfPost } from "api/PostAPI";

class BottomBar extends Component {

    constructor() {
        super();
    }

    componentDidMount() {
    }

    onCommentClick = (e) => {
        let articleHeight = e.currentTarget.parentNode.parentNode.parentNode.children[0].clientHeight
        window.scrollTo({ top: articleHeight, left: 0, behavior: 'smooth' });
        
    }

    onPointClick = (e) => {
        this.props.pointClickCallback()
    }

    onShareClick = (e) => {
        let link = "whosfanglobal://share?url="+encodeURIComponent(window.location.origin+'/app/' + this.props.language + '?fc=' + this.props.idx);
        window.location.href = link;
    }
    onShortcutsClick = (e) => {

        if(this.props.listOfArtist===undefined || this.props.listOfArtist.length===0){
            if(isAndroid){
                window.whosfanJs.closeActivity()
            }else if(isIOS){
                window.webkit.messageHandlers.closeActivity.postMessage({});
            }
            return
        }

        let artist = this.props.listOfArtist.filter((artist) => artist.isDefault === 1)
        if (artist.length < 1) {
            return;
        }

        let listOfArtist = `${artist[0].idx}`;
        getListOfPost({
            clientIdx: 1022,
            language: this.props.language,
            listOfArtist: listOfArtist,
            offset: 0, 
            limit: 1,
            order: "DESC",
            summary: true 
        }, (json) => {
            let result = json.resultData;
            if(result === undefined || result === null || result.length < 1){
                return;
            }
            
            
            let post = result[0];

            let idx = post.ownership.idx
            if(Number(getQueryParameters(window.location)['fc']) === idx){
                alert(COMMENT_TEXT[this.props.language].alert.latestMsg)
                return
            }
            
            var scheme = "whosfanglobal"
            let link = scheme+'://open?url=' + encodeURIComponent(window.location.origin+'/app/' + this.props.language + '?fc=' + this.props.idx);
            window.location.href = link;
            
        })

    }


    render() {
        const {language, pointClickCallback, listOfArtist, idx} = this.props;
        return (
            <>
                <Container className="hflex-sbc">
                    <FunctionDiv className="hflex-cc">
                        <ItemDiv onClick={this.onCommentClick}>
                            <img alt="comment icon" src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/comment_white.svg"></img>
                        </ItemDiv>
                        <ItemDiv onClick={this.onPointClick}>
                            <img alt="point icon" src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/point_white.svg"></img>
                        </ItemDiv>
                        {/*<ItemDiv onClick={this.onShareClick}>*/}
                        {/*    <img  alt="share icon" src="https://hanteonews.com/image/hanteonews/asset/share_white.svg"></img>*/}
                        {/*</ItemDiv>*/}
                    </FunctionDiv>
                    {listOfArtist && listOfArtist.length > 0?
                        <ArtistDiv className="hflex-cc" onClick={this.onShortcutsClick}>
                            <p>{NEWS_BOTTOM_BAR[language]}</p>
                            <img alt="shortcuts icon" src="https://s3.ap-northeast-2.amazonaws.com/hanteoresource/hanteonews/asset/arrow_shorcuts_white.svg"></img>
                        </ArtistDiv>
                        :<></>}
                </Container>
            </>
        );
    }
}
const ArtistDiv = styled.div``;

const ItemDiv = styled.div``;
const FunctionDiv = styled.div``;
const Container = styled.div`
    position: fixed;
    bottom:0;
    left: 0;
    right: 0;
    background: #FC5BA8;
    padding: 10px 0 10px 0;
    z-index: 2;

    ${FunctionDiv}{
        margin: 0 0 0 20px;
        
        @media only screen and (max-device-width: 320px) {
            margin: 0 0 0 10px;
        }
        ${ItemDiv}{
            width: 30px;
            height: 30px;
            margin: 0 30px 0 0;
            
            @media only screen and (max-device-width: 320px) {
                margin: 0 10px 0 0;
            }
        }
    }
    ${ArtistDiv}{
        color: #fff;
        p{
            font-size: 14px;
            line-height: 16px;
        }
        img{
            width: 20px;
            height: 20px;
            margin: 0 15px 0 8px;
            @media only screen and (max-device-width: 320px) {
                margin: 0 10px 0 6px;
            }
        }
    }
`;


export default BottomBar;
