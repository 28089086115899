import React, { Component } from "react";
import MainCommentVM from "components/view_model/comment/MainCommentVM"

class CommentPage extends Component {
    constructor() {
        super();
    }

    render() {
        const { userInfo, params} = this.props;
        return (
            <>
                {userInfo.userToken===undefined? <>No Token</>:
                    <MainCommentVM
                        contentIdx={params.fc}
                        parentIdx={params.comment}
                        token={userInfo.userToken}
                        langCode={userInfo.lang}></MainCommentVM>
                }
                
            </>
        );
    }

}

export default CommentPage;
