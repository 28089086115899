
import { COMMENT_READ, COMMENT_WRITE } from "global/api";
export function getCommentList(_params, successCallback, failCallback){
    let url = `${COMMENT_READ}/v1/comment/list/whosfan_news/${_params['contentIdx']}/${_params['parentIdx']}?orderType=${_params['orderType']}&index=${_params['index']}&offset=${_params['offset']}&limit=${_params['limit']}`
    fetch(url, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer '+_params['token']
        },
    })
    .then(response => response.json())
    .then(successCallback)
    .catch(failCallback);
}

export function writeComment(_params, successCallback, failCallback){
    let url = `${COMMENT_WRITE}/v1/comment/write/whosfan_news/${_params['contentIdx']}/${_params['parentIdx']}`

    fetch(url, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer '+_params['token'],
            'Content-Type' : 'application/json'
        },
        body: JSON.stringify({
            "content": _params.content,
            "language": _params.language
        })
    })
    .then(response => response.json())
    .then(successCallback)
    .catch(failCallback);
}


export function likeComment(_params, successCallback, failCallback){
    let url = `${COMMENT_WRITE}/v1/comment/like/whosfan_news/${_params['contentIdx']}/${_params['parentIdx']}`

    fetch(url, { 
        method: 'POST',
        headers: {
            'Authorization': 'Bearer '+_params['token'],
            'Content-Type' : 'application/json'
        },
        body: JSON.stringify({
            "commentIdx": _params.commentIdx,
            "like": _params.like
        })
    })
    .then(response => response.json())
    .then(successCallback)
    .catch(failCallback);
}


export function deleteComment(_params, successCallback, failCallback){
    let url = `${COMMENT_WRITE}/v1/comment/delete/whosfan_news/${_params['contentIdx']}/${_params['parentIdx']}`

    fetch(url, { 
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer '+_params['token'],
            'Content-Type' : 'application/json'
        },
        body: JSON.stringify({
            "commentIdx": _params.commentIdx
        })
    })
    .then(response => response.json())
    .then(successCallback)
    .catch(failCallback);
}



