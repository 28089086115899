
import { API } from "global/api";
import { convertJsonToQueryString } from "utils/UriUtils";

// 2023-03-31 (Checked)
export function getListOfPost(params, successCallback){
    fetch(`${API}/v4/post/search${convertJsonToQueryString(params)}`)
        .then(response => response.json())
        .then(successCallback);
}

export function getListOfRankPost(params, successCallback){
    fetch(`${API}/v4/post/rank${convertJsonToQueryString(params)}`)
        .then(response => response.json())
        .then(successCallback);
}